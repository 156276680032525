export default [
    {
        text: 'ID', value: 'id', checked: true, disabled: true, sort: 1, type: 'integer', subtype: 'simple'
    },
    {
        text: 'Номер заявки', value: 'number', checked: true, sort: 2, type: 'integer', subtype: 'simple'
    },
    {
        text: 'Заявка',
        value: 'name',
        componentName: 'td-link',
        checked: true,
        disabled: true,
        sort: 3,
        type: 'string',
        subtype: 'simple'
    },
    {
        text: 'Комментарий', value: 'description', checked: true, sort: 4, type: 'string', subtype: 'simple'
    },
    {
        text: 'Пользователь',
        value: 'user',
        componentName: 'td-user',
        sortable: false,
        checked: true,
        sort: 5,
        type: 'list',
        multiple: false,
        subtype: 'simple'
    },
    {
        text: 'Курс',
        value: 'course',
        componentName: 'td-course',
        sortable: false,
        checked: true,
        sort: 6,
        type: 'list',
        multiple: false,
        subtype: 'simple'
    },
    {
        text: 'Подборка',
        value: 'compilation',
        componentName: 'td-course',
        sortable: false,
        checked: true,
        sort: 7,
        type: 'list',
        multiple: false,
        subtype: 'simple'
    },
    {
        text: 'Папка',
        value: 'folder',
        sortable: false,
        checked: true,
        subtype: 'courseSimple',
        sort: 8,
        hasFilter: false,
        type: 'string'
    },
    {
        text: 'Статус',
        value: 'status.sort',
        componentName: 'td-status',
        checked: true,
        sort: 9,
        type: 'list',
        multiple: false,
        subtype: 'simple'
    },
    {
        text: 'Фиксированная цена',
        value: 'fix_price',
        componentName: 'td-fix',
        checked: true,
        sort: 10,
        type: 'string',
        subtype: 'simple'
    },
    {
        text: 'Стоимость',
        value: 'price',
        checked: true,
        sort: 11,
        type: 'integer',
        subtype: 'simple',
        sortable: false
    },
    {
        text: 'Дата оплаты',
        value: 'payed_at',
        componentName: 'td-date',
        checked: true,
        sort: 12,
        type: 'date',
        subtype: 'simple'
    },
    {
        text: 'Дата создания',
        value: 'createdAt',
        componentName: 'td-date',
        checked: true,
        sort: 13,
        type: 'date',
        subtype: 'simple'
    },
    {
        text: 'Дата изменения',
        value: 'updatedAt',
        componentName: 'td-date',
        checked: true,
        sort: 14,
        type: 'date',
        subtype: 'simple'
    }
];
