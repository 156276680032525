import { mixinMutations } from '@/store/mixins';
import defaultTableState from '@/store/modules/customField/enteties/defaultTableState';
const findItemById = (items, id) => items.find((i) => i.id === id);
const state = defaultTableState();
const getters = {
    calcPage(state) {
        return state.page - 1 > 1 ? state.page - 1 : 1;
    }
};
const mutations = {
    ...mixinMutations(),
    updateRelations(state, payload) {
        const { items } = state;
        const { variableId, typeRelation, relations } = payload;
        const variable = findItemById(items, variableId);
        variable[typeRelation] = relations;
    },
    init(state, payload) {
        state.customFieldId = payload.customFieldId;
    }
};
const actions = {
    async createVariable({ commit, dispatch }, value) {
        try {
            commit('setProperty', { name: 'isLoading', value: true });
            const { customFieldId } = state;
            const query = { customFieldId };
            const params = { value };
            const { data } = await dispatch('customFieldVariables/createVariable', { query, params }, { root: true });
            state.items.push({
                ...data,
                dependent_custom_fields: [],
                dependent_variables: []
            });
            commit('setProperty', { name: 'itemsTotal', value: state.itemsTotal + 1 });
        }
        catch (e) {
            throw new Error(e);
        }
        finally {
            commit('setProperty', { name: 'isLoading', value: false });
        }
    },
    async createMultiVariables({ commit, dispatch }, values) {
        try {
            commit('setProperty', { name: 'isLoading', value: true });
            const { customFieldId } = state;
            const query = { customFieldId };
            const params = { values };
            await dispatch('customFieldVariables/createMultiVariables', { query, params }, { root: true });
        }
        catch (e) {
            throw new Error(e);
        }
        finally {
            commit('setProperty', { name: 'isLoading', value: false });
        }
    },
    async getVariables({ commit, dispatch }, payload) {
        try {
            commit('setProperty', { name: 'isLoading', value: true });
            const { page, perPage, search } = state;
            const { customFieldId } = state;
            const query = { customFieldId };
            const params = {
                with: 'dependentVariables,dependentCustomFields',
                withCount: 'dependentVariables,dependentCustomFields',
                page,
                value: search,
                per_page: perPage,
                ...payload
            };
            const { data, meta } = await dispatch('customFieldVariables/getVariables', {
                query,
                params
            }, { root: true });
            commit('setProperty', [
                { name: 'items', value: data },
                { name: 'itemsTotal', value: meta.total },
                { name: 'page', value: meta.current_page }
            ]);
        }
        catch (e) {
            throw new Error(e);
        }
        finally {
            commit('setProperty', { name: 'isLoading', value: false });
        }
    },
    async updateVariable({ commit, dispatch }, payload) {
        try {
            commit('setProperty', { name: 'isLoading', value: true });
            const { id, value } = payload;
            const { customFieldId } = state;
            const query = { customFieldId, variableId: id };
            const params = { value };
            await dispatch('customFieldVariables/updateVariable', { query, params }, { root: true });
        }
        catch (e) {
            throw new Error(e);
        }
        finally {
            commit('setProperty', { name: 'isLoading', value: false });
        }
    },
    async deleteVariable({ commit, getters, dispatch }, id) {
        try {
            commit('setProperty', { name: 'isLoading', value: true });
            const { customFieldId } = state;
            const query = { customFieldId, variableId: id };
            await dispatch('customFieldVariables/deleteVariable', { query }, { root: true });
            const { items, itemsTotal } = state;
            const filteredVariables = items.filter((item) => item.id !== id);
            commit('setProperty', [
                { name: 'items', value: filteredVariables },
                { name: 'itemsTotal', value: itemsTotal - 1 }
            ]);
            if (filteredVariables.length === 0) {
                dispatch('getVariables', { page: getters.calcPage });
                commit('setProperty', { name: 'page', value: getters.calcPage });
            }
        }
        catch (e) {
            throw new Error(e);
        }
        finally {
            commit('setProperty', { name: 'isLoading', value: false });
        }
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
