import { mixinMutations } from '@/store/mixins';
import defaultState from './entities/defaultState';
const state = defaultState();
const mutations = { ...mixinMutations() };
const actions = {
    async getBranches({ rootGetters }, { course_id, params }) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_branches`, {
            sort: 'sort',
            with: 'coursePackagesBySort;bulletPoints',
            withCount: 'coursePackagesBySort.users',
            ...params
        });
    },
    async createBranch({ rootGetters }, { course_id, payload }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_branches`, payload);
    },
    async updateBranch({ rootGetters }, { course_id, id, payload }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_branches/${id}`, payload, 'PUT');
    },
    async deleteBranch({ rootGetters }, { course_id, id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_branches/${id}`, {}, 'DELETE');
    },
    async multiUpdateBranches({ rootGetters }, { course_id, payload }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_branches/multi_sort`, payload);
    },
    async getShortPackages({ rootGetters, commit }, { course_id }) {
        const res = await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_packages`, { only: 'name,is_default', all: 1 });
        if (!res.errors)
            commit('setProperty', { name: 'shortPackages', value: res.data });
        return res;
    },
    async getPackage({ rootGetters }, { course_branch_id, id }) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_branches/${course_branch_id}/course_packages/${id}`, {
            with_lessons_ids: 1, with_lessons_to_close_ids: 1, with_tests_to_close_ids: 1, with_homeworks_to_close_ids: 1
        });
    },
    async createPackage({ rootGetters }, { course_branch_id, payload }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_branches/${course_branch_id}/course_packages`, payload, 'POST');
    },
    async updatePackage({ rootGetters }, { branch_id, id, payload }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_branches/${branch_id}/course_packages/${id}`, {
            ...payload, with_lessons_ids: 1, with_lessons_to_close_ids: 1, with_tests_to_close_ids: 1, with_homeworks_to_close_ids: 1
        }, 'PUT');
    },
    async deletePackage({ rootGetters }, { course_branch_id, id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_branches/${course_branch_id}/course_packages/${id}`, {}, 'DELETE');
    },
    async multiUpdatePackages({ rootGetters }, { course_branch_id, payload }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_branches/${course_branch_id}/course_packages/multi_sort`, payload);
    },
    async changePackageLessons({ rootGetters }, { course_branch_id, id, action, lesson_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_branches/${course_branch_id}/course_packages/${id}/${action}_lessons`, { lesson_id });
    },
    async attachUsers({ rootGetters }, { course_id, payload }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/attach_users_packages`, payload);
    },
    async detachUsers({ rootGetters }, { course_id, payload }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/detach_users_packages`, payload);
    },
    async changeActiveBranch({ rootGetters }, { course_id, user_id, course_branch_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/users/${user_id}/change_branch`, { course_branch_id });
    },
    // reports
    async checkReportReady({ rootGetters }, fileName) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses_file`, { file_name: fileName });
    },
    async getReportsList({ rootGetters }) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses_download/history`);
    },
    async generateReport({ rootGetters }, filters) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses_with_course_branches_download`, filters);
    },
    async generateNewReport({ rootGetters }, { exportType, courseIds }) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses_with_course_branches_download`, { exportType, courseIds });
    },
    async generateCoursesReport({ rootGetters }, params) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses_with_course_branches_download`, params);
    },
    // branch bullets
    async addBulletPoint({ rootGetters }, { branch_id, ...payload }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_branches/${branch_id}/add_bullet_point`, {
            ...payload,
            with: 'bulletPoints'
        });
    },
    async updateBulletPoint({ rootGetters }, { branch_id, id, ...payload }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_branches/${branch_id}/update_bullet_point/${id}`, {
            ...payload,
            with: 'bulletPoints'
        }, 'PUT');
    },
    async deleteBulletPoint({ rootGetters }, { branch_id, id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_branches/${branch_id}/delete_bullet_point/${id}`, {}, 'DELETE');
    },
    async getAvailableBranchesToGroup({ rootGetters, commit }, course_id) {
        const response = await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_branches`, {
            sort: 'sort',
            with: 'doesntHaveRelationCoursePackages',
            UsedInCoursePackageRelations: '1'
        });
        commit('setProperty', { name: 'availableBranches', value: response.data.map((item) => ({ ...item, selected: '' })) });
        return response.data;
    },
    async createPackagesGroup({ rootGetters }, { course_id, course_packages_ids }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_package_relations/full_create`, { course_packages_ids }, 'POST');
    },
    async getCreatedGroups({ rootGetters }, course_id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_package_relations`, { with: 'coursePackages' });
        return data;
    },
    async deletePackagesGroup({ rootGetters }, { course_id, group_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_package_relations/${group_id}`, {}, 'DELETE');
    },
    async updatePackagesGroup({ rootGetters }, { course_id, course_packages_ids, group_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_package_relations/${group_id}/full_update`, { course_packages_ids }, 'PUT');
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
