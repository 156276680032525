import headers from './headers';
const state = () => ({
    courses: [],
    selectedCourses: new Set(),
    options: [
        {
            text: 'Активировать',
            value: 'visible',
            url: 'visible'
        },
        {
            text: 'Деактивировать',
            value: 'draft',
            url: 'draft'
        },
        {
            text: 'Скрыть',
            value: 'close',
            url: 'close'
        },
        {
            text: 'Добавить в папку',
            value: 'add_to_folder',
            url: '/course_folder/add_course'
        },
        {
            text: 'Удалить из папки',
            value: 'remove_from_folder',
            url: '/course_folder/delete/mass'
        },
        {
            text: 'Удалить',
            value: 'delete',
            url: 'delete'
        }
    ],
    sortedBy: '',
    isDesc: true,
    counter: 0,
    tabs: [
        {
            text: 'Активные',
            value: 'visible'
        },
        {
            text: 'Черновики',
            value: 'draft'
        },
        {
            text: 'Закрытые',
            value: 'close'
        }
    ],
    currentTab: {
        text: 'Активные',
        value: 'visible'
    },
    categories: [],
    loading: false,
    isSuccess: false,
    errors: '',
    page: 1,
    per_page: 20,
    stopLoading: true,
    finishLoading: false,
    isTableMode: false,
    headers,
    allHeaders: [],
    userFields: [],
    filterCategories: [],
    fieldsLoading: true,
    filtersLoading: true,
    isNewAlgorithm: false,
    courseFolders: []
});
export default state;
