import { mixinMutations } from '@/store/mixins';
import defaultState from '@/store/modules/courseAndLessonMenu/defaultState';
const state = defaultState();
const mutations = { ...mixinMutations() };
const actions = {
    async getCourseAndLessonMenu({ commit, rootGetters }, payload) {
        const { course_id, params } = payload;
        const getParams = params || {};
        const url = `${rootGetters.API_URL}/courses/${course_id}/lessons_and_themes`;
        const response = await this.getDataStore(url, getParams);
        commit('setProperty', { name: 'courseAndLessonMenu', value: response });
        commit('setProperty', { name: 'baseUrl', value: `${rootGetters.API_URL}/courses/${course_id}` });
        return response;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
