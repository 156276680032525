import { __decorate } from "tslib";
import { Component, Vue } from 'nuxt-property-decorator';
import { nanoid } from 'nanoid';
let AlertMessages = class AlertMessages extends Vue {
    constructor() {
        super(...arguments);
        this.messages = [];
    }
    sendMessage(text = 'Успешно', alertType = 'success', timeout = 4) {
        this.messages.unshift({
            guid: nanoid(10),
            text,
            alertType,
            timerClose: setTimeout(() => {
                clearTimeout(this.messages.slice(-1)[0].timerClose);
                this.messages.pop();
            }, timeout * 1000)
        });
    }
    getIconTypeAlert(type) {
        const vocabulary = {
            error: 'error_outline',
            success: 'check_circle',
            info: 'info',
            default: ''
        };
        return vocabulary[type] || vocabulary.default;
    }
};
AlertMessages = __decorate([
    Component
], AlertMessages);
export default AlertMessages;
