import { mixinMutations } from '@/store/mixins';
const mutations = { ...mixinMutations() };
const actions = {
    async list({ rootGetters }) {
        const url = `${rootGetters.API}/pages`;
        const { data } = await this.getDataStore(url);
        return data;
    },
    async get({ rootGetters }, id) {
        const url = `${rootGetters.API}/pages/${id}`;
        return await this.getDataStore(url);
    },
    async create({ rootGetters }, params) {
        const url = `${rootGetters.API}/pages`;
        return await this.postDataStore(url, params);
    },
    async update({ rootGetters }, params) {
        const { id } = params;
        const url = `${rootGetters.API}/pages/${id}`;
        return await this.postDataStore(url, params, 'PUT');
    },
    async delete({ rootGetters }, id) {
        const url = `${rootGetters.API}/pages/${id}`;
        return await this.postDataStore(url, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    mutations,
    actions
};
