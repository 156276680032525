import { mixinActions, mixinMutations } from '@/store/mixins';
import defaultState from '@/store/modules/integrations/defaultState';
const state = defaultState();
const mutations = { ...mixinMutations() };
const actions = {
    ...mixinActions(),
    async list({ rootGetters }, { params }) {
        const url = `${rootGetters.API_URL}/integrations`;
        const allParams = {
            with: 'channels',
            withCount: 'autoActions',
            ...params
        };
        return await this.getDataStore(url, allParams);
    },
    async getTypes() {
        return new Promise((resolve) => {
            resolve([
                { code: 'telegram', name: 'Телеграм' },
                { code: 'sendpulse', name: 'SendPulse' },
                { code: 'discord', name: 'Discord' },
                { code: 'bitrix24', name: 'Битрикс 24' }
            ]);
        });
    },
    async getSendTypes({ state }, payload) {
        return new Promise((resolve) => {
            const vocabulary = {
                telegram: [{ code: 'telegram', name: 'Телеграм' }],
                sendpulse: [{ code: 'sms', name: 'СМС' }],
                discord: [{ code: 'discord', name: 'Discord канал' }],
                bitrix24: [{ code: 'bitrix_blog_post', name: 'Новостная лента' }],
                default: []
            };
            const key = state.item && state.item?.type ? state.item?.type : payload && typeof payload === 'string' ? payload : '';
            resolve(vocabulary[key]);
        });
    },
    async getLogs({ rootGetters }, id) {
        const url = `${rootGetters.API_URL}/integrations/${id}/integration_logs`;
        const params = { active: 1, desc: 1, per_page: 1 };
        const { data } = await this.getDataStore(url, params);
        return data;
    },
    async show({ rootGetters }, id) {
        const url = `${rootGetters.API_URL}/integrations/${id}`;
        const { data } = await this.getDataStore(url);
        return data;
    },
    async create({ rootGetters }, params) {
        const url = `${rootGetters.API_URL}/integrations`;
        return await this.postDataStore(url, params);
    },
    async update({ rootGetters }, params) {
        const { id } = params;
        const url = `${rootGetters.API_URL}/integrations/${id}`;
        return await this.postDataStore(url, params, 'PUT');
    },
    async delete({ rootGetters }, id) {
        const url = `${rootGetters.API_URL}/integrations/${id}`;
        return await this.postDataStore(url, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
