import { mixinActions, mixinMutations } from '@/store/mixins';
import defaultState from '@/store/modules/categories/defaultState';
const state = defaultState();
const mutations = {
    ...mixinMutations(),
    setCategoriesTree(state, payload) {
        const tree = payload;
        const { length } = tree;
        const { categoriesPerPage } = state;
        const categoriesTree = tree.map((item) => {
            const { title, parent_category, id } = item;
            const parent_title = parent_category.title;
            return { title, parent_title, id };
        });
        if (categoriesPerPage - length > 0) {
            const count = categoriesPerPage - length;
            for (let i = 0; i < count; i++) {
                categoriesTree.push({
                    title: '',
                    parent_title: '',
                    parent_id: Symbol('parent_id')
                });
            }
        }
        state.categoriesTree = categoriesTree;
    },
    addCategory(state) {
        state.categoriesTree.push({
            title: '',
            parent_title: '',
            parent_id: Symbol('parent_id')
        });
    },
    removeCategory(state, index) {
        state.categoriesTree.splice(index, 1);
        if (state.categoriesTree.length < state.categoriesPerPage) {
            state.categoriesTree.push({
                title: '',
                parent_title: '',
                parent_id: Symbol('parent_id')
            });
        }
    },
    pushCategory(state, payload) {
        if (state.categoriesList && state.categoriesList.categories) {
            state.categoriesList.categories.push(payload);
        }
    }
};
const actions = {
    ...mixinActions(),
    async getCategories({ commit, rootGetters }, url) {
        const response = await this.getDataStore(`${rootGetters.API_URL}${url}`, { all: 1 });
        const { data } = response;
        const categories = data.filter(({ type }) => type === 'category');
        commit('setProperty', { name: 'categories', value: categories });
        const result = data.reduce((acc, curr) => {
            const { title, parent_category_id, id } = curr;
            if (parent_category_id) {
                const parent_id = parent_category_id;
                const parent = data.find((item) => item.id === parent_id);
                if (parent) {
                    const parent_title = parent.title;
                    acc.sub_categories.push({
                        title, parent_id, parent_title, id
                    });
                }
            }
            else {
                acc.categories.push(title);
            }
            return acc;
        }, { categories: [], sub_categories: [] });
        commit('setProperty', { name: 'categoriesList', value: result });
    },
    async categoryAction({ rootGetters }, payload) {
        const { action, url, ...data } = payload;
        const response = await this.postDataStore(`${rootGetters.API_URL}${url}/${action}`, data, 'POST');
        return response.data;
    },
    async updateCategory({ rootGetters }, { url, id, title }) {
        return await this.postDataStore(`${rootGetters.API_URL}${url}/${id}`, { title }, 'PUT');
    },
    async getList({ rootGetters }, payload) {
        const { params } = payload;
        return await this.getDataStore(`${rootGetters.API_URL}/course_categories`, params);
    },
    async getSubList({ rootGetters }, payload) {
        const { params } = payload;
        return await this.getDataStore(`${rootGetters.API_URL}/course_categories`, params);
    },
    async categoriesMultiUpdate({ rootGetters }, payload = []) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_categories/multi_sort`, payload);
    },
    async createCategory({ rootGetters }, payload) {
        const { id, parent_category_id } = payload;
        const isNewCategory = isNaN(Number(id));
        const url = isNewCategory ? '/course_categories' : `/course_categories/${id}`;
        const type = parent_category_id ? 'subcategory' : 'category';
        const { course_catalog_visible, title, course_catalog_column_count, course_catalog_filter_selection_type, course_catalog_view_type } = payload;
        const data = {
            course_catalog_visible,
            title,
            course_catalog_column_count: Number(course_catalog_column_count),
            course_catalog_filter_selection_type,
            course_catalog_view_type,
            type
        };
        if (parent_category_id)
            data.parent_category_id = parent_category_id;
        const method = isNewCategory ? 'POST' : 'PUT';
        return await this.postDataStore(`${rootGetters.API_URL}${url}`, data, method);
    },
    async deleteCategory({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_categories/${id}`, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
