import { mixinMutations } from '@/store/mixins';
import defaultState from './entities/defaultState';
const state = defaultState();
const mutations = { ...mixinMutations() };
const actions = {
    async list({ rootGetters }, { params } = {}) {
        const { data, errors, meta: { total } } = await this.getDataStore(`${rootGetters.API_URL}/external_webinars`, params);
        const { page, per_page } = params;
        // Данные по вебинарам приходят от стороннего сервиса Вебинаров
        // Для работы таблицы UniversitiesTable нужно в ручную собрать объект пагинации meta
        const meta = {
            current_page: page,
            last_page: Math.ceil(total / per_page),
            from: 1 + per_page * (page - 1),
            to: per_page * page > total ? total : per_page * page,
            total
        };
        return { data, errors, meta };
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
