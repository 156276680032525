var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{staticClass:"select",attrs:{"label":_vm.label,"placeholder":_vm.placeholder,"items":_vm.dataItems,"rules":_vm.rules,"error":_vm.error,"hide-details":_vm.hideDetails,"disabled":_vm.disabled,"menu-props":{ 'maxHeight': 400 },"outlined":"","height":"40","item-text":"text","item-value":"value"},on:{"change":_vm.changeSelect},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var ref_item = ref.item;
var text = ref_item.text;
var icon = ref_item.icon;
return [_c('div',{staticClass:"select__item"},[(icon)?_c('i',{staticClass:"material-icons select__icon",class:icon,style:(_vm.iconStyle)},[_vm._v(_vm._s(icon))]):_vm._e(),_c('span',{staticClass:"select__text"},[_vm._v(_vm._s(text))])])]}},{key:"item",fn:function(ref){
var ref_item = ref.item;
var text = ref_item.text;
var icon = ref_item.icon;
return [_c('div',{staticClass:"select__item"},[(icon)?_c('i',{staticClass:"material-icons select__icon",class:icon,style:(_vm.iconStyle)},[_vm._v(_vm._s(icon))]):_vm._e(),_c('span',{staticClass:"select__text"},[_vm._v(_vm._s(text))])])]}}]),model:{value:(_vm.dataValue),callback:function ($$v) {_vm.dataValue=$$v},expression:"dataValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }