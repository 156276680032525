import { mixinMutations } from '@/store/mixins';
const state = { courses_count: 0 };
const mutations = { ...mixinMutations() };
const actions = {
    async getAdvantageList({ rootGetters }, params = {}) {
        const [sortBy] = params?.sortBy || '';
        const [sortDesc] = params?.sortDesc || '';
        return await this.getDataStore(`${rootGetters.API_URL}/course_advantages`, {
            ...params,
            sort: sortBy,
            desc: Number(sortDesc),
            withCount: 'courses'
        });
    },
    async multiUpdateItems({ rootGetters }, params = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_advantages/multi_sort`, params, 'POST');
    },
    async deleteAdvantage({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_advantages/${id}`, {}, 'DELETE');
    },
    async createAdvantage({ rootGetters }, params = {}) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/course_advantages`, params);
        return data;
    },
    async getItem({ rootGetters, commit }, id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/course_advantages/${id}`, { withCount: 'courses' });
        commit('setProperty', { name: 'courses_count', value: data.courses_count });
        return data;
    },
    async updateItem({ rootGetters }, { id, params }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/course_advantages/${id}`, params, 'PUT');
        return data;
    },
    async attachOrDetachCourses({ rootGetters }, { action, course_id, id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_advantages/${id}/${action}`, { course_id });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
