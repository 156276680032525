import { TreeNode } from '@/helpers/utils/Tree';
export default function buildCategoriesTree(categories, total = 0) {
    const root = new TreeNode(0, 'Все статьи', {
        counter: total || 0,
        locked: false
    });
    const nodes = new Map();
    categories.forEach((category) => {
        const node = new TreeNode(category.id, category.title, {
            parentId: category.parent_id,
            counter: category.total_knowledge_article_count,
            locked: false
        });
        nodes.set(node.id, node);
    });
    nodes.forEach((node) => {
        if (!node.otherProps.parentId) {
            root.insertChild(node);
        }
        else if (nodes.has(node.otherProps.parentId)) {
            const target = nodes.get(node.otherProps.parentId);
            target.insertChild(node);
        }
    });
    return root;
}
