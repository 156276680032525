import { mixinMutations } from '@/store/mixins';
import defaultState from './entities/defaultState';
import { getFormFieldValues, getFormGroups } from './helpers';
const state = defaultState();
const getters = {
    coursesFormIds(state) {
        return [...new Set(state.courses.flatMap((course) => course?.form_id).filter(Boolean))];
    },
    coursesForms(state) {
        const forms = state.courses.reduce((acc, course) => {
            const groups = getFormGroups(course.form);
            !acc[course.form_id] && (acc[course.form_id] = groups);
            return acc;
        }, {});
        return Object.values(forms).flat();
    },
    coursesFormsValues(state) {
        return state.courses.reduce((acc, course) => {
            acc[course.id] = getFormFieldValues(course.custom_fields_values);
            return acc;
        }, {});
    },
    uniqFormsFields(_, getters) {
        const values = Object.values(getters.coursesFormsValues).reduce((acc, fieldsValues) => {
            const values = Object.entries(fieldsValues).map(([id, { values }]) => ({ custom_field_id: Number(id), values }));
            acc.push(...values);
            return acc;
        }, []);
        const mappedValues = values.reduce((acc, field) => {
            acc[field.custom_field_id]
                ? acc[field.custom_field_id].push(field.values?.join())
                : acc[field.custom_field_id] = [field.values?.join()];
            return acc;
        }, {});
        const isUniq = (array) => {
            const [head, ...tail] = array;
            for (const i of tail) {
                if (i !== head)
                    return true;
            }
            return false;
        };
        const uniqFields = Object.entries(mappedValues).filter(([_, value]) => value.filter(Boolean).length)
            .map(([id, values]) => ({ id, values }))
            .filter(({ values }) => isUniq(values))
            .map(({ id }) => Number(id));
        return uniqFields;
    },
    coursesInfo(state) {
        const info = state.courses.reduce((acc, course) => {
            const united_table = course.branches_by_sort?.reduce((acc, branch) => {
                const packageResidue = branch.available_course_packages_by_sort?.reduce((result, pkg) => {
                    const residue = pkg?.united_table_item?.cells_only_target_residue || [];
                    residue.forEach(({ type, value }) => {
                        result[type] += Number(value);
                    });
                    return result;
                }, { target: 0, residue: 0 });
                acc.target += packageResidue.target;
                acc.residue += packageResidue.residue;
                return acc;
            }, { target: 0, residue: 0 });
            acc.general[course.id] = {
                schedule: { start_date: course.start_date, end_date: course.end_date },
                goal: course.goal,
                weeks_time: course.weeks_time,
                anons: course.anons,
                united_table
            };
            acc.plans[course.id] = course.branches_by_sort;
            return acc;
        }, { general: {}, plans: {} });
        const forms = state.courses.flatMap((course) => getFormGroups(course).flat())
            .reduce((acc, form) => {
            if (!acc[form.id]) {
                acc[form.id] = { title: form.title, form: { [form.course_id]: form } };
            }
            if (!acc[form.id].form[form.course_id]) {
                acc[form.id].form[form.course_id] = form;
            }
            return acc;
        }, {});
        const fieldsValues = Object.values(forms)
            .flatMap(({ form }) => Object.values(form))
            .flatMap((form) => form.fields.flatMap((field) => ({
            form_id: form.id,
            custom_field_id: field.custom_field_id,
            value: field.custom_field?.value?.value
        })));
        const uniqFieldsIds = [];
        fieldsValues.forEach((field) => {
            const index = uniqFieldsIds.findIndex((f) => f.custom_field_id === field.custom_field_id && f.value !== field.value);
            index === -1 ? uniqFieldsIds.push(field) : uniqFieldsIds.splice(index, 1);
        });
        return {
            ...info, forms, fieldsValues, uniqFieldsIds: [...new Set(uniqFieldsIds.map((field) => [field.form_id, field.custom_field_id].join('|')))]
        };
    },
    courseDetails(state) {
        if (!state.detailCourseModal.id)
            return null;
        const course = state.courses.find((course) => course.id === state.detailCourseModal.id);
        if (!course)
            return null;
        const forms = getFormGroups(course.form).flat();
        return {
            course,
            forms,
            general: {
                schedule: { start_date: course.start_date, end_date: course.end_date },
                goal: course.goal,
                weeks_time: course.weeks_time,
                description_small: course.description_small
            }
        };
    },
    branchDetails(state) {
        if (!state.detailBranchModal.id || !state.detailBranchModal.course_id)
            return null;
        const course = state.courses.find((course) => course.id === state.detailBranchModal.course_id);
        if (!course)
            return null;
        const branch = course.branches_by_sort.find((branch) => branch.id === state.detailBranchModal.id);
        if (!branch)
            return null;
        return branch;
    }
};
const mutations = {
    ...mixinMutations(),
    selectFilter(state, payload) {
        state.selectedFilters.includes(payload)
            ? state.selectedFilters = state.selectedFilters.filter((item) => item !== payload)
            : state.selectedFilters.push(payload);
    },
    toggleView(state) {
        state.viewType = state.viewType === 'simple' ? 'extend' : 'simple';
    },
    toggleShowOnlyDifference(state) {
        state.showOnlyDifference = !state.showOnlyDifference;
    },
    toggleCommercialOffer(state, payload) {
        state.showCommecrialOffer = payload;
    },
    openCourseDetailModal(state, payload) {
        state.detailCourseModal = { id: payload, show: true };
    },
    closeCourseDetailModal(state) {
        state.detailCourseModal.show = false;
    },
    openBranchDetailModal(state, payload) {
        state.detailBranchModal = { ...payload, show: true };
    },
    closeBranchDetailModal(state) {
        state.detailBranchModal.show = false;
    },
    openCourseQuestionsModal(state, payload) {
        state.courseQuestionsModal = { id: payload, show: true };
    },
    closeCourseQuestionsModal(state) {
        state.courseQuestionsModal.show = false;
    }
};
const actions = {
    async getFilters({ rootGetters, commit }, params) {
        const res = await this.getDataStore(`${rootGetters.API_URL}/courses/commercial_offer/get_filters`, params);
        if (!res.errors) {
            const filters = res.data
                .map((category) => ({ ...category, subcategories: category.subcategories.sort((a, b) => a.sort - b.sort) }))
                .sort((a, b) => a.sort - b.sort);
            commit('setProperty', { name: 'filters', value: filters });
        }
        return res;
    },
    async getCourses({ rootGetters, state, commit }, filters) {
        commit('setProperty', { name: 'isCoursesLoading', value: true });
        const params = {
            all: 1,
            show_expired: Number(state.expires)
        };
        const res = await this.getDataStore(`${rootGetters.API_URL}/courses/commercial_offer/index`, {
            ...params,
            courseSubcategoryId: filters ? filters.join() : state.selectedFilters.join(),
            with: 'form;form.role:id,admin_ban_on_registration,learning_ban_on_registration;form.webhook;form.visibleFields;form.visibleFields.customField;form.visibleFields.customField.variables;formCustomFields;formCustomFieldsValues;branchesBySort;branchesBySort.availableCoursePackagesBySort;branchesBySort.availableCoursePackagesBySort.unitedTableItem;branchesBySort.availableCoursePackagesBySort.unitedTableItem.cellsOnlyTargetResidue;formCustomFieldsValues.file'
        });
        if (!res.errors) {
            commit('setProperty', { name: 'searched', value: true });
            commit('setProperty', { name: 'courses', value: res.data });
        }
        commit('setProperty', { name: 'isCoursesLoading', value: false });
        return res;
    },
    async getCourseQuestions({ rootGetters, commit }, { course_id }) {
        commit('setProperty', { name: 'questions', value: [] });
        const res = await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_remarks`, {
            with: 'createdByUser;updatedByUser',
            per_page: 1000
        });
        if (!res.errors) {
            commit('setProperty', { name: 'questions', value: res.data });
        }
        return res;
    },
    async createCourseQuestion({ rootGetters }, { course_id, description }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_remarks`, { description }, 'POST');
    },
    async getBitrixProfile({ rootGetters }, { id }) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses/commercial_offer/get_bitrix_contact?contact_id=${id}`);
    },
    async sendEmail({ rootGetters }, data) {
        return await this.postDataStore(`${rootGetters.API_URL}/commercial_offer/send_email`, data, 'POST');
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
