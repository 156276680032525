import { mixinMutations } from '@/store/mixins';
const mutations = { ...mixinMutations() };
const actions = {
    async list({ rootGetters }, { params }) {
        return await this.getDataStore(`${rootGetters.API_URL}/map_universities`, params);
    },
    async deleteUniversity({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/map_universities/${id}`, {}, 'DELETE');
    },
    async getCities({ rootGetters }, { params }) {
        return await this.getDataStore(`${rootGetters.API_URL}/map_cities`, params);
    },
    async deleteCity({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/map_cities/${id}`, {}, 'DELETE');
    },
    async createCity({ rootGetters }, payload) {
        return await this.postDataStore(`${rootGetters.API_URL}/map_cities`, payload, 'POST');
    },
    async updateCity({ rootGetters }, payload) {
        const { id, ...rest } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/map_cities/${id}`, rest, 'PUT');
    },
    async getUniversity({ rootGetters }, id) {
        return await this.getDataStore(`${rootGetters.API_URL}/map_universities/${id}`, { with: 'image;mapUniversityPrograms' });
    },
    async createUniversity({ rootGetters }, payload) {
        return await this.postDataStore(`${rootGetters.API_URL}/map_universities`, payload, 'POST');
    },
    async updateUniversity({ rootGetters }, payload) {
        const { id, ...rest } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/map_universities/${id}`, rest, 'PUT');
    },
    async getPrograms({ rootGetters }, { params }) {
        return await this.getDataStore(`${rootGetters.API_URL}/map_university_programs`, params);
    },
    async deleteProgram({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/map_university_programs/${id}`, {}, 'DELETE');
    },
    async createProgram({ rootGetters }, payload) {
        return await this.postDataStore(`${rootGetters.API_URL}/map_university_programs`, payload, 'POST');
    },
    async updateProgram({ rootGetters }, payload) {
        const { id, ...rest } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/map_university_programs/${id}`, rest, 'PUT');
    }
};
export default {
    namespaced: true,
    mutations,
    actions
};
