const defaultState = {
    order_id: 0,
    id: 0,
    user_id: 0,
    course_id: null,
    status_id: 0,
    invite_id: null,
    name: '',
    description: '',
    type: '',
    count: 0,
    price: 0,
    number: 0,
    fix_price: false,
    fix_course: null,
    payed_at: null,
    created_at: null,
    updated_at: null,
    user: null,
    course: null,
    invite: null,
    statuses: [],
    courses: [],
    users: [],
    lessons: [],
    teachers: [],
    fields: [],
    courseSearch: '',
    courseCopy: null,
    userFields: []
};
export default defaultState;
