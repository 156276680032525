import { mixinMutations } from '@/store/mixins';
const mutations = { ...mixinMutations() };
const state = {};
const actions = {
    async getAvailableManagers({ rootGetters }, params) {
        return await this.getDataStore(`${rootGetters.API_URL}/bitrix_generator_sales/managers`, { ...params, active: true });
    },
    async getSalesGenerator({ rootGetters }, { params }) {
        return await this.getDataStore(`${rootGetters.API_URL}/bitrix_generator_sales`, params);
    },
    async createSaleGenerator({ rootGetters }, form) {
        return await this.postDataStore(`${rootGetters.API_URL}/bitrix_generator_sales`, form);
    },
    async updateSaleGenerator({ rootGetters }, { salesGeneratorId, ...args }) {
        return await this.postDataStore(`${rootGetters.API_URL}/bitrix_generator_sales/${salesGeneratorId}`, args, 'PUT');
    },
    async attachSaleGeneratorManager({ rootGetters }, { bitrix_manager_ids, salesGeneratorId }) {
        return await this.postDataStore(`${rootGetters.API_URL}/bitrix_generator_sales/${salesGeneratorId}/attach_bitrix_managers`, { bitrix_manager_ids });
    },
    async detachSaleGeneratorManager({ rootGetters }, { bitrix_manager_ids, salesGeneratorId }) {
        return await this.postDataStore(`${rootGetters.API_URL}/bitrix_generator_sales/${salesGeneratorId}/detach_bitrix_managers`, { bitrix_manager_ids });
    },
    async importSaleGeneratorContacts({ rootGetters }, { bitrix_generator_sale_contacts_file, salesGeneratorId }) {
        return await this.postDataStore(`${rootGetters.API_URL}/bitrix_generator_sales/${salesGeneratorId}/import_contacts`, bitrix_generator_sale_contacts_file);
    },
    async startSaleGenerator({ rootGetters }, { salesGeneratorId }) {
        return await this.postDataStore(`${rootGetters.API_URL}/bitrix_generator_sales/${salesGeneratorId}/start_generate`, {});
    },
    async deleteSaleGenerator({ rootGetters }, { salesGeneratorId }) {
        return await this.postDataStore(`${rootGetters.API_URL}/bitrix_generator_sales/${salesGeneratorId}`, {}, 'DELETE');
    },
    async getCoursesSearch({ rootGetters }, payload) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/course_compilations_with_courses`, {
            name: payload,
            only: 'name'
        });
        if (errors)
            return [];
        return data;
    },
    async attachCoursesToGenerator({ rootGetters }, payload) {
        const { id, courses_ids } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/bitrix_generator_sales/${id}/attach_courses`, { courses_ids });
    },
    async importSaleGeneratorContactsCourses({ rootGetters }, { salesGeneratorId }) {
        return await this.postDataStore(`${rootGetters.API_URL}/bitrix_generator_sales/${salesGeneratorId}/import_contacts_courses`);
    },
    async checkSegmentsReady({ rootGetters }, fileName) {
        return await this.getDataStore(`${rootGetters.API_URL}/bitrix_generator_sales/get_import_contacts`, { filename: fileName });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
