import { render, staticRenderFns } from "./AccountQuestion.vue?vue&type=template&id=57b31c68&scoped=true&"
import script from "./AccountQuestion.vue?vue&type=script&lang=ts&"
export * from "./AccountQuestion.vue?vue&type=script&lang=ts&"
import style0 from "./AccountQuestion.vue?vue&type=style&index=0&id=57b31c68&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57b31c68",
  null
  
)

export default component.exports