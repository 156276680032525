var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{key:_vm.key,staticClass:"custom-autocomplete",class:{
    'filter-single-select': _vm.isSingleSelect
  },attrs:{"items":_vm.items,"attach":_vm.attachTo,"item-text":_vm.itemText,"item-value":_vm.itemValue,"filter":_vm.filter,"menu-props":{ maxHeight: 300, closeOnClick: false, closeOnContentClick: false},"search-input":_vm.searchString,"loading":_vm.loading,"clearable":_vm.clearable,"cache-items":_vm.useCache,"outlined":"","filled":"","placeholder":_vm.placeholder,"label":_vm.label,"multiple":!_vm.isSingleSelect,"deletable-chips":!_vm.isSingleSelect,"return-object":_vm.returnObject,"chips":_vm.hasChips,"disabled":_vm.disabled,"hide-details":"auto"},on:{"update:searchInput":function($event){_vm.searchString=$event},"update:search-input":[function($event){_vm.searchString=$event},_vm.updateData],"change":_vm.changeSingleSelect},scopedSlots:_vm._u([(!_vm.isSingleSelect && !_vm.showCheckboxes && !_vm.isCustomItem)?{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"complete-item static fr-view editor-content editor-link",domProps:{"innerHTML":_vm._s(item[_vm.itemText])},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.changeAutocomplete(item)}}})]}}:(_vm.isCustomItem)?{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('div',{class:_vm.isFlexItem?'reviews-autocomplete':'',on:{"click":function($event){return _vm.changeSingleSelect(item, $event)}}},[_vm._t("item",null,{"item":item})],2)]}}:{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"fr-view editor-content editor-link",domProps:{"innerHTML":_vm._s(item[_vm.itemText])}})]}},{key:"selection",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"fr-view editor-content editor-link",domProps:{"innerHTML":_vm._s(item[_vm.itemText])}})]}}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})}
var staticRenderFns = []

export { render, staticRenderFns }