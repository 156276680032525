import router from '@/router';
import defaultState from './enteties/defaultState';
import { mixinMutations } from '../../mixins';
const state = defaultState();
const mutations = { ...mixinMutations() };
const actions = {
    async getCustomField({ rootGetters, commit }) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/custom_fields/${state.customFieldId}`);
        if (!errors)
            commit('setState', data);
        return { data, errors };
    },
    async createCustomField({ commit, rootGetters }) {
        if (!state.type)
            return { errors: 'Необходимо выбрать тип поля' };
        const { name, description, prompt, type, subtype, multiple, list_show_type, field_list_bitrix_id } = state;
        const params = { name, description, prompt, type, subtype, multiple };
        if (type === 'list')
            params.list_show_type = list_show_type;
        if (type === 'list_bitrix') {
            params.field_list_bitrix_id = field_list_bitrix_id;
            params.list_show_type = list_show_type;
        }
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/custom_fields`, params);
        commit('setProperty', { name: 'customFieldId', value: data.id });
        await router.replace({ name: 'custom-fields', params: { customFieldId: data.id } });
        return { data, errors };
    },
    async updateCustomField({ dispatch }) {
        const { name, description, prompt, type, multiple, list_show_type, field_list_bitrix_id } = state;
        const params = {
            name, description, prompt, multiple
        };
        if (type === 'list')
            params.list_show_type = list_show_type;
        if (type === 'list_bitrix') {
            params.field_list_bitrix_id = field_list_bitrix_id;
            params.list_show_type = list_show_type;
        }
        return await dispatch('customFieldsTable/updateCustomField', { query: { id: state.customFieldId }, params }, { root: true });
    },
    async getVariables({ rootGetters, state }, payload) {
        const { customFieldId } = state;
        if (!customFieldId)
            return { data: [], meta: [] };
        return await this.getDataStore(`${rootGetters.API_URL}/custom_fields/${customFieldId}/custom_field_variables`, payload.params);
    },
    async updateVariable({ rootGetters, state }, payload) {
        const { id, value } = payload;
        const { customFieldId } = state;
        return await this.postDataStore(`${rootGetters.API_URL}/custom_fields/${customFieldId}/custom_field_variables/${id}`, { value }, 'PUT');
    },
    async createVariable({ rootGetters }, payload) {
        const { customFieldId } = state;
        return await this.postDataStore(`${rootGetters.API_URL}/custom_fields/${customFieldId}/custom_field_variables`, payload);
    },
    async createMultiVariables({ rootGetters, state }, payload) {
        const { customFieldId } = state;
        return await this.postDataStore(`${rootGetters.API_URL}/custom_fields/${customFieldId}/custom_field_variables/multi_creation`, payload);
    },
    async deleteVariable({ rootGetters, state }, payload) {
        console.log('payload ->', payload);
        const { params } = payload;
        const { customFieldId } = state;
        return await this.postDataStore(`${rootGetters.API_URL}/custom_fields/${customFieldId}/custom_field_variables/${params.id}`, {}, 'DELETE');
    },
    async syncronizeBitrixField({ rootGetters, state }) {
        const { customFieldId, field_list_bitrix_id } = state;
        if (!customFieldId || !field_list_bitrix_id)
            return { errors: 'Неверные параметры' };
        return await this.postDataStore(`${rootGetters.API_URL}/custom_fields/${customFieldId}/custom_field_variables/synchronization_bitrix`);
    },
    clearState({ commit }) {
        commit('clearState', defaultState());
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
