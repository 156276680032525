import { __decorate } from "tslib";
/*
* Синхронное модальное окно с компонентом Vuetify VDialog
* Для вызова первого окна используем await this.$root.$modal() - Данный метод вызовет метод this.open()
* Для всех модальных компонентов присваиваются по дефолту Props из миксина src\components\mixins\propsModal.js
*/
import { Component, Vue } from 'nuxt-property-decorator';
import propsModal from '@/components/mixins/propsModal';
let ModalVDialog = class ModalVDialog extends Vue {
    constructor() {
        super(...arguments);
        /*
        * modalOptions - Дефолтные значения для модальных окон
        * historyComponents - список компонентов для истории вызываем компонентов
        * */
        this.modalOptions = {
            scrollable: true,
            fullscreen: false,
            persistent: false,
            maxWidth: '720px'
        };
        this.historyComponents = [];
    }
    /**
     * Открытие модального окна
     * @param {Object} props параметры для Props в вызываемый компонент
     * @param {Component} component объект вызываем компонента
     * @param {Object} modalOptions параметры для модального окна (см. this.modalOptions)
     */
    async open(props, component, modalOptions = {}) {
        return new Promise((resolve, reject) => {
            const dataComponent = this.initComponent(component, props, modalOptions);
            setTimeout(() => {
                this.historyComponents.push({
                    ...dataComponent,
                    resolve,
                    reject
                });
            }, 200);
        });
    }
    /**
     * Закрытие модального окна
     * @param {any} data синхронный ответ от компонента
     */
    close(data) {
        const emitComponent = this.historyComponents.slice(-1)[0];
        emitComponent.show = false;
        setTimeout(() => {
            this.historyComponents = [];
        }, 200);
        emitComponent.resolve(data);
    }
    closeOutside() {
        if (this.modalOptions.persistent) {
            close();
        }
    }
    /**
     * Открытие модального окна
     * @param {Object} props параметры для Props в вызываемый компонент
     * @param {Component} component объект вызываем компонента
     * @param {Object} modalOptions параметры для модального окна (см. this.modalOptions)
     */
    nextComponent(props, component, modalOptions = {}) {
        const last = this.historyComponents.slice(-1)[0];
        last.show = false;
        return new Promise((resolve, reject) => {
            const dataComponent = this.initComponent(component, props, modalOptions);
            this.historyComponents.push({
                ...dataComponent,
                resolve,
                reject
            });
        });
    }
    /**
     * Закрытие последнего компонента окна
     * @param {any} data синхронный ответ от компонента
     */
    // @TODO Доработать кейс с пустой историей компонентов
    async prevComponent(data) {
        const emitComponent = this.historyComponents.slice(-1)[0];
        emitComponent.show = false;
        const last = this.historyComponents.slice(-2)[0];
        setTimeout(() => {
            emitComponent.resolve(data);
            last.show = true;
            this.historyComponents.pop();
        }, 200);
    }
    /**
     * Инициализация компонента
     */
    initComponent(component, props, modalOptions) {
        if (['md', 'xl', 'lg'].includes(this.$vuetify.breakpoint.name)) {
            modalOptions.fullscreen = false;
        }
        component.mixins = [...component.mixins || [], propsModal];
        return {
            component,
            modalOptions: { ...this.modalOptions, ...modalOptions },
            props: {
                ...props,
                close: this.close,
                prevComponent: this.prevComponent,
                nextComponent: this.nextComponent
            },
            show: true
        };
    }
};
ModalVDialog = __decorate([
    Component({ name: 'modal-v-dialog' })
], ModalVDialog);
export default ModalVDialog;
