const lessonVideoTypes = [
    // {
    //     text: 'Google Drive',
    //     value: 'google_drive'
    // },
    // {
    //     text: 'Youtube',
    //     value: 'youtube'
    // },
    {
        text: 'Ссылка',
        value: 'links'
    },
    {
        text: 'iFrame',
        value: 'iframe'
    },
    {
        text: 'Файл',
        value: 'files'
    }
];
export default lessonVideoTypes;
