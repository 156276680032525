import { nanoid } from 'nanoid';
export default () => ({
    id: nanoid(10),
    admin_name: {
        value: '',
        text_color: '#7F8FA4',
        background: '#F2F4F7'
    },
    public_name: {
        value: '',
        text_color: '#7F8FA4',
        background: '#F2F4F7'
    },
    text_color: '#7F8FA4',
    background: '#F2F4F7',
    sort: 0,
    type: { type: null },
    is_default: false
});
