const actions = {
    async list({ rootGetters }, { params, query }) {
        const { test_id } = query;
        return await this.getDataStore(`${rootGetters.API_URL}/tests/${test_id}/questions`, params);
    },
    async delete({ rootGetters }, { params }) {
        const { test_id, id } = params;
        return await this.postDataStore(`${rootGetters.API_URL}/tests/${test_id}/questions/${id}`, {}, 'DELETE');
    },
    async getQuestions({ rootGetters }, testId) {
        return await this.getDataStore(`/api/companies/${rootGetters.COMPANY_ID}/tests/${testId}`);
    }
};
export default {
    namespaced: true,
    actions
};
