import { nanoid } from 'nanoid';
import { mixinActions, mixinMutations } from '@/store/mixins';
import defaultState from './entities/defaultState';
import BuildPrice from '@/helpers/BuildPrice';
import BuildDate from '@/helpers/BuildDate';
const setCoursePackageParams = (item, price) => ({
    id: item.course?.id || item.id,
    image: item.course?.img_anons ? item.course?.img_anons : '/static-images/tmp.jpg',
    name: item.course?.name || item.name,
    numberPrice: Number(price),
    categories: item.course?.categories,
    package_id: item.id,
    package_name: item.name
});
const setCourseData = (commit, data) => {
    const { course_package, created_at, updated_at, load_custom_fields, user, course_compilation, course, price } = data;
    commit('setCustomFields', load_custom_fields);
    commit('setState', data);
    if (course_package?.course) {
        commit('setProperty', {
            name: 'course',
            value: {
                ...setCoursePackageParams(course_package, price), packages: course.packages, type: 'course', price: price ? `${BuildPrice(Number(price))} руб.` : 'Бесплатно'
            }
        });
    }
    if (course_compilation?.id)
        commit('setProperty', { name: 'course', value: { ...setCoursePackageParams(course_compilation, price), type: 'compilation', price: price ? `${BuildPrice(Number(price))} руб.` : 'Бесплатно' } });
    if (course?.packages) {
        if (Array.isArray(course.packages))
            commit('setProperty', { name: 'packages', value: course.packages });
        else
            commit('setProperty', { name: 'packages', value: [course.packages] });
    }
    if (created_at)
        commit('setProperty', { name: 'created_at', value: BuildDate(created_at, { showTime: true }).rusDate });
    if (updated_at)
        commit('setProperty', { name: 'updated_at', value: BuildDate(updated_at, { showTime: true }).rusDate });
    if (user && user.custom_fields)
        commit('setUserFields', user.custom_fields);
};
const setCourseParams = (item) => ({
    id: item.id,
    image: item.img_anons ? item.img_anons : '/static-images/tmp.jpg',
    name: item.name,
    categories: item.categories,
    packages: item.available_packages_by_sort,
    entity_type: item.entity_type,
    type: item.entity_type
});
const freezeState = { ...defaultState };
const state = defaultState;
const getters = {
    getApiURL(state, getters, rootState, rootGetters) {
        return rootGetters.API_URL;
    }
};
const mutations = {
    ...mixinMutations(),
    setCustomFields(state, payload) {
        if (!payload)
            return;
        const setSimpleValue = (type, order_values) => {
            if (!order_values || !order_values.length) {
                return { id: nanoid(10), value: '' };
            }
            const [currentVal] = order_values;
            const { id, value } = currentVal;
            return { id, value };
        };
        const setValue = (type, order_values, values, multiple) => {
            const vocabulary = {
                string: () => setSimpleValue(type, order_values),
                text: () => setSimpleValue(type, order_values),
                url: () => setSimpleValue(type, order_values),
                integer: () => setSimpleValue(type, order_values),
                email: () => setSimpleValue(type, order_values),
                phone: () => setSimpleValue(type, order_values),
                list: () => {
                    let value = null;
                    let backend = null;
                    if (multiple) {
                        value = order_values && order_values.length
                            ? order_values.map((item) => item.list_value_id) : [];
                        backend = order_values && order_values.length ? order_values : [];
                    }
                    else {
                        value = order_values && order_values.length
                            ? order_values.map((item) => item.list_value_id)[order_values.length - 1] : null;
                        backend = order_values && order_values.length
                            ? order_values[order_values.length - 1] : null;
                    }
                    return {
                        id: nanoid(10),
                        value,
                        backend
                    };
                },
                file: () => (values && values.length ? values : []),
                image: () => (values && values.length ? values : []),
                date: () => setSimpleValue(type, order_values),
                datetime: () => setSimpleValue(type, order_values),
                default: () => ''
            };
            return vocabulary[type] ? vocabulary[type]() : vocabulary.default();
        };
        state.fields = payload.map((item) => {
            const { order_values, required, values, type, description, multiple } = item;
            let { variables } = item;
            if (variables && variables.length) {
                variables = variables.map((item) => {
                    const { id, value, custom_field_id } = item;
                    return {
                        text: value, value: id, custom_field_id, id
                    };
                });
            }
            else {
                variables = [];
            }
            return {
                ...item,
                value: setValue(type, order_values, values, multiple),
                required,
                variables,
                description,
                render_id: nanoid(10),
                no_valueItem: !order_values || !order_values.length
            };
        });
    },
    setUserFields(state, payload) {
        if (!payload)
            return;
        const setValue = (type, user_values, multiple) => {
            const userTypesVocabulary = {
                list: () => user_values.map(({ value, id }) => ({ value, id })),
                default: () => {
                    if (!user_values || !user_values.length)
                        return '';
                    return multiple
                        ? user_values.map(({ value, id }) => ({ value, id })) : user_values[0].value;
                }
            };
            return userTypesVocabulary[type] ? userTypesVocabulary[type]() : userTypesVocabulary.default();
        };
        state.userFields = payload.map((item) => {
            const { user_values, type, name, multiple } = item;
            return {
                type, name, value: setValue(type, user_values, multiple), multiple
            };
        });
    }
};
const actions = {
    ...mixinActions(),
    setDefaultState({ commit }) {
        commit('clearState', freezeState);
    },
    async getOrder({ rootGetters, commit }, { order_id, params }) {
        let getParams = {
            with: 'user:id,name,surname,email;invite:id,code,user_limit;course;course.packages:id,course_id,name,color;courseCompilation',
            load_custom_fields: 1,
            sort_by_values: 1
        };
        if (params)
            getParams = { ...getParams, ...params };
        commit('setProperty', { name: 'order_id', value: order_id });
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/orders/${order_id}`, { ...getParams });
        setCourseData(commit, data);
    },
    async getStatuses({ rootGetters, commit }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/order_statuses`, {
            all: 1,
            only: 'admin_name,text_color,background'
        });
        commit('setProperty', { name: 'statuses', value: data });
    },
    async updateOrder({ rootGetters, state, dispatch, commit }, { name, value }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/orders/${state.order_id}`, { [name]: value, load_custom_fields: true, with: 'coursePackage.course;invite:id,code,user_limit;course;course.packages:id,course_id,name,color;courseCompilation' }, 'PUT');
        setCourseData(commit, data);
        dispatch('getOrderLessons');
        return data;
    },
    async getCoursesList({ rootGetters, commit }, payload) {
        commit('setProperty', { name: 'courses', value: [] });
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/course_compilations_with_courses`, {
            // const {data} = await this.getDataStore(`${rootGetters.API_URL}/courses`, {
            name: payload,
            with: 'availablePackagesBySort:name,color,full_price',
            only: 'name,img_anons,price',
            status: 'visible,close'
        });
        commit('setProperty', { name: 'courses', value: data.map(setCourseParams) });
    },
    async getUsersList({ rootGetters, commit }, payload) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/users`, {
            search: payload,
            only: 'name,surname,email,photo_color'
        });
        commit('setProperty', { name: 'users', value: data });
    },
    async createInvite({ rootGetters, state, commit }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/orders/${state.order_id}/create_invite`, {}, 'POST');
        commit('setProperty', { name: 'invite', value: data });
    },
    async getOrderLessons({ commit, state, rootGetters }, { courseId, coursePackageId } = { courseId: '', coursePackageId: '' }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/orders/${state.order_id}/lessons`, {
            only: 'name,teacher_price,lesson_date,type',
            courseId,
            coursePackageId
        });
        commit('setProperty', { name: 'lessons', value: data });
    },
    async getTeachers({ rootGetters, commit }, payload) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/orders/${state.order_id}/get_available_teacher_profile_users`, { ...payload });
        const teachers = data.map(({ id, name, surname }) => ({
            value: id,
            text: `${name} ${surname}`
        }));
        commit('setProperty', { name: 'teachers', value: teachers });
    },
    async updateOrderLesson({ state, rootGetters }, payload) {
        const { lesson_id, params } = payload;
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/orders/${state.order_id}/lessons/${lesson_id}`, { ...params }, 'POST');
        return data;
    },
    async setTeacherToAllLessons({ rootGetters, state }, payload) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/orders/${state.order_id}/lessons/add_teacher`, { ...payload }, 'POST');
        return data;
    },
    async createField({ rootGetters, state }, { custom_field_id, value, list_value_id, file_id }) {
        const payload = {
            custom_field_id, value, list_value_id, file_id
        };
        const params = {};
        if (Object.keys(payload).length === 0)
            return;
        Object.entries(payload).forEach(([key, value]) => {
            if (value) {
                params[key] = value;
            }
        });
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/orders/${state.order_id}/custom_field_order_values`, { ...params }, 'POST');
        return data;
    },
    async updateField({ rootGetters, state }, { id, value }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/orders/${state.order_id}/custom_field_order_values/${id}`, { value }, 'PUT');
        return data;
    },
    async deleteField({ rootGetters, state }, id) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/orders/${state.order_id}/custom_field_order_values/${id}`, {}, 'DELETE');
        return data;
    },
    async addConnectInvite({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/orders/${id}/create_invite_for_order_user`, {}, 'POST');
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
