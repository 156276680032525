import { mixinMutations } from '@/store/mixins';
import defaultState from '@/store/modules/publicMenu/defaultState';
const state = defaultState();
const mutations = { ...mixinMutations() };
const actions = {
    async getShortRoles({ rootGetters, commit }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/roles`, {
            all: 1,
            only: 'name'
        });
        commit('setProperty', { name: 'roles', value: data });
        return data;
    },
    async getMenu({ rootGetters, commit }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/menus`, { all: 1 });
        commit('setProperty', { name: 'menu', value: data.sort((a, b) => a.sort - b.sort) });
        return data;
    },
    async createMenu({ rootGetters }, params) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/menus`, params, 'POST');
        return data;
    },
    async updateMenu({ rootGetters }, { params, id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/menus/${id}`, params, 'PUT');
    },
    async deleteMenu({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/menus/${id}`, {}, 'DELETE');
    },
    async multiUpdate({ rootGetters }, params) {
        return await this.postDataStore(`${rootGetters.API_URL}/menus/multi_update`, params, 'POST');
    },
    async changeRole({ rootGetters }, { action, id, roles }) {
        return await this.postDataStore(`${rootGetters.API_URL}/menus/${id}/${action}`, { roles }, 'POST');
    },
    async downloadHtml({ rootGetters }) {
        return await this.getDataStore(`${rootGetters.API_PUBLIC_URL}/html/menus/download`, {});
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
