const defaultState = () => ({
    statuses: [
        { text: 'Без фильтра', value: '' },
        { text: 'Скоро начнется', value: 'coming_soon' },
        { text: 'Идет', value: 'play' },
        { text: 'Пауза', value: 'pause' },
        { text: 'Обработан', value: 'finished' },
        { text: 'Отменен', value: 'canceled' }
    ],
    webinars: []
});
export default defaultState;
