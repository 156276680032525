import { mixinMutations } from '@/store/mixins';
import initState from './entities/locationsDefaultState';
const state = initState();
const mutations = { ...mixinMutations() };
const actions = {
    async getLocationsList({ commit, rootGetters }, { params }) {
        const response = await this.getDataStore(`${rootGetters.API_URL}/places`, params);
        commit('setProperty', { name: 'locations', value: response.data });
        return response;
    },
    async getLocation({ rootGetters }, { id }) {
        return await this.getDataStore(`${rootGetters.API_URL}/places/${id}`);
    },
    async deleteLocation({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/places/${id}`, {}, 'DELETE');
    },
    async createLocation({ rootGetters }, { params }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/places`, params);
        return data;
    },
    async updateLocation({ rootGetters }, { id, params }) {
        return await this.postDataStore(`${rootGetters.API_URL}/places/${id}`, params, 'PUT');
    },
    async addLocationToCourse({ rootGetters }, { local_id, course_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/places/add_to_course`, {
            local_id,
            course_id
        });
    },
    async deleteImage({ rootGetters }, { media_id }) {
        return await this.postDataStore(`${rootGetters.API_PUBLIC_URL}/files/delete_media/${media_id}`, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
