import { mixinMutations } from '@/store/mixins';
import defaultState from '@/store/modules/userAccess/defaultState';
const state = defaultState();
const mutations = { ...mixinMutations() };
const actions = {
    async usersAjax(_, { url, data, action }) {
        await this.postDataStore(`${url}/${action}`, data, 'POST');
    },
    async getUsersForAdminAccess({ commit }, { url, params }) {
        const response = await this.getDataStore(url, params);
        commit('setProperty', { name: 'users_for_admin_access', value: response.data });
        return response;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
