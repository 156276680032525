import { mixinMutations } from '@/store/mixins';
const mutations = { ...mixinMutations() };
const actions = {
    async getManagersList({ rootGetters }, params = {}) {
        return await this.getDataStore(`${rootGetters.API_URL}/bitrix_auto_lead/managers`, { ...params, active: true, per_page: 500 });
    },
    async getManagerInfo({ rootGetters }, { id }) {
        return await this.getDataStore(`${rootGetters.API_URL}/bitrix_auto_lead/managers/${id}`);
    },
    async updateManagerInfo({ rootGetters }, { id, ...params } = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/bitrix_auto_lead/managers/${id}`, params, 'PUT');
    },
    async getManagersAnalytics({ rootGetters }, params = {}) {
        return await this.getDataStore(`${rootGetters.API_URL}/bitrix_auto_lead/managers_analytics`, params);
    },
    async getLeadCategories({ rootGetters }, params = {}) {
        return await this.getDataStore(`${rootGetters.API_URL}/bitrix_auto_lead/categories`, { ...params, per_page: 500 });
    },
    async getLeadCategory({ rootGetters }, { id }) {
        return await this.getDataStore(`${rootGetters.API_URL}/bitrix_auto_lead/categories/${id}`);
    },
    async createLeadCategory({ rootGetters }, params = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/bitrix_auto_lead/categories`, params, 'POST');
    },
    async updateLeadCategory({ rootGetters }, { id, ...params } = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/bitrix_auto_lead/categories/${id}`, params, 'PUT');
    },
    async getBitrixManagerList({ rootGetters }, { active }) {
        return await this.getDataStore(`${rootGetters.API_URL}/bitrix_contact_assigned_user/managers`, { active: Number(active) });
    },
    async checkBitrixReportReady({ rootGetters }, file_name) {
        return await this.getDataStore(`${rootGetters.API_URL}/bitrix_contact_assigned_user/analytics_file`, { file_name });
    },
    async generateBitrixAnalitycs({ rootGetters }, params) {
        return await this.getDataStore(`${rootGetters.API_URL}/bitrix_contact_assigned_user/analytics_download`, params);
    }
};
export default {
    namespaced: true,
    mutations,
    actions
};
