import tableModule from './table';
import router from '@/router';
import defaultState from './enteties/defaultState';
import { mixinMutations } from '../../mixins';
const state = defaultState();
const getters = {
    isFieldCreated(state) {
        return !!state.customFieldId;
    }
};
const mutations = {
    ...mixinMutations(),
    setCustomFieldId(state, customFieldId) {
        if (customFieldId === 'create')
            return;
        state.customFieldId = Number(customFieldId);
    }
};
const actions = {
    async createCustomField({ commit, dispatch }) {
        if (!state.type)
            return { errors: 'Необходимо выбрать тип поля' };
        const { name, description, prompt, type, subtype, multiple, list_show_type, field_list_bitrix_id } = state;
        const params = {
            name, description, prompt, type, subtype, multiple
        };
        if (type === 'list')
            params.list_show_type = list_show_type;
        if (type === 'list_bitrix') {
            params.field_list_bitrix_id = field_list_bitrix_id;
            params.list_show_type = list_show_type;
        }
        const res = await dispatch('customFieldsTable/createCustomField', params, { root: true });
        const { data: { id } } = res;
        commit('setProperty', { name: 'customFieldId', value: id });
        await router.replace({ name: 'custom-fields', params: { customFieldId: id } });
        return res;
    },
    async getCustomField({ commit, dispatch }) {
        const { data } = await dispatch('customFieldsTable/getCustomField', { query: { id: state.customFieldId } }, { root: true });
        const { name, description, prompt, type, subtype, multiple, list_show_type, field_list_bitrix_id } = data;
        commit('setProperty', { name: 'name', value: name });
        commit('setProperty', { name: 'description', value: description });
        commit('setProperty', { name: 'prompt', value: prompt });
        commit('setProperty', { name: 'type', value: type });
        commit('setProperty', { name: 'subtype', value: subtype });
        commit('setProperty', { name: 'multiple', value: multiple });
        commit('setProperty', { name: 'list_show_type', value: list_show_type });
        commit('setProperty', { name: 'field_list_bitrix_id', value: field_list_bitrix_id });
    },
    async getInviteCustomFields({ rootGetters }) {
        const url = `${rootGetters.API_URL}/custom_fields?only=name,type,multiple&subtype=user&all=1&notTypes[0]=image&notTypes[1]=file&notTypes[2]=avatar&notTypes[3]=choose_role`;
        const params = { subtype: 'user' };
        const { data } = await this.getDataStore(url, params);
        const result = data.map(({ id, name }) => ({ id, name }));
        return { data: result };
    },
    async updateCustomField({ dispatch }) {
        const { name, description, prompt, type, multiple, list_show_type, field_list_bitrix_id } = state;
        const params = {
            name, description, prompt, multiple
        };
        if (type === 'list')
            params.list_show_type = list_show_type;
        if (type === 'list_bitrix') {
            params.field_list_bitrix_id = field_list_bitrix_id;
            params.list_show_type = list_show_type;
        }
        return await dispatch('customFieldsTable/updateCustomField', { query: { id: state.customFieldId }, params }, { root: true });
    },
    async saveField({ dispatch, getters }) {
        if (state.name === '')
            return false;
        const action = getters.isFieldCreated
            ? 'updateCustomField'
            : 'createCustomField';
        return await dispatch(action);
    },
    clearState({ commit }) {
        commit('clearState', defaultState());
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: { table: tableModule }
};
