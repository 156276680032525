import typeRelations from '@/store/modules/customField/enteties/typeRelations';
const defaultState = () => ({
    variableId: null,
    customFieldId: null,
    selectCustomField: null,
    itemValue: 'name',
    typeRelation: null,
    typeRelations,
    items: [],
    itemsTotal: 0,
    isLoading: false,
    attachItems: [],
    detachItems: [],
    bindingItems: [],
    selectedItems: [],
    isShowModal: false
});
export default defaultState;
