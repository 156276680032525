import { defaultTabs } from '@/store/modules/course/entities/tabs';
import statuses from '@/store/modules/course/entities/courseStatuses';
import defaultHeaders from '@/store/modules/course/entities/defaultHeaders';
const paymentState = {
    id: 0,
    company_id: 0,
    course_id: 0,
    payer_id: 0,
    bitrix_contact_id: 0,
    created_at: '',
    updated_at: '',
    deleted_at: '',
    paid_sum: '',
    payments_sum: '',
    course: {
        id: 0,
        baseUrl: '',
        name: '',
        description: '',
        price: 0,
        old_price: 0,
        start_date: '',
        end_date: '',
        img_anons: '',
        img_banner: '',
        img_banner_mobile: '',
        img_header: '',
        img_vertical_banner: '',
        form_id: null,
        show_likes: false,
        use_comments: false,
        tabs: defaultTabs,
        currentTab: {
            text: 'О курсе',
            value: 'settings'
        },
        statuses,
        status: '',
        auth_user_favourites_exists: false,
        description_small: '',
        admin_access_users: [],
        users_for_admin_access: [],
        subcategories_with_categories: [],
        company_id: 0,
        finish_criteria: null,
        completion_estrictions: null,
        close_date: null,
        title_ceo: '',
        description_ceo: '',
        learning_process: null,
        keywords_ceo: '',
        close_days: null,
        certificate: '',
        program: '',
        instruction: '',
        success_result: '',
        fail_result: '',
        courses_list: [],
        courses_open_id: [],
        lessonsWithCheck: [],
        lessons_count: 0,
        locals: [],
        lessonTableHeaders: [
            {
                text: 'Уроки',
                value: 'name'
            },
            {
                text: 'Мин. балл прохождения',
                value: 'min_points_complete' /// минимальный балл урока
            },
            {
                text: 'Необходимый балл',
                value: 'min_points_course' // минимальный балл для прохождения
            },
            {
                text: 'Макс. балл',
                value: 'max_points'
            }
        ],
        criteria_lesson_counting: null,
        lessons_to_close: [],
        lessons_likes: null,
        lessons_comments: null,
        use_reviews: false,
        is_show_visit_offline_journal: false,
        use_rating_points: false,
        lessons_show_likes: null,
        anons: '',
        show_landing: false,
        fields: [],
        courseCustomFields: [],
        tableLessonsCount: null,
        is_constructor: false,
        isCourseFormEditing: false,
        defaultHeaders,
        selectItems: [],
        selectTitle: '',
        selectLoading: false,
        fieldsLoading: true,
        crm_form_id: null,
        crm_bitrix: null,
        crm_bitrix_form_id: null,
        crm_bitrix_form_url: null,
        course_packages_to_open: [],
        preposition_from: false,
        price_comment: '',
        can_be_bought: false,
        yoo_kassa_ready: false,
        weeks_time: '',
        video_with_teacher_recording: '',
        classes: '',
        goal: '',
        for_whom: '',
        currentStatus: null,
        videos: [],
        video_start_loading: false,
        active_link_chat_block: false,
        title_link_chat_block: 'Написать куратору курса',
        course_link_chat_blocks: null
    },
    payments_by_created_at: [{
            id: 0,
            company_id: 0,
            payer_id: 0,
            course_id: 0,
            payment_order_id: 0,
            course_branch_id: 0,
            status_id: 0,
            promo_code_id: 0,
            yandex_payment_id: 0,
            confirmation_token: '',
            idempotency_key: '',
            bitrix_deal_id: '',
            name: '',
            description: '',
            type: '',
            price: '',
            price_without_discount: '',
            status: '',
            payment_data: '',
            paid_at: '',
            reserved_until_at: '',
            created_at: '',
            updated_at: '',
            deleted_at: '',
            payer: {
                id: 0,
                name: '',
                photo: ''
            },
            payment_status: {
                id: 0,
                company_id: 0,
                type: '',
                is_default: false,
                sort: '',
                admin_name: '',
                public_name: '',
                text_color: '',
                background_color: '',
                created_at: '',
                updated_at: '',
                deleted_at: ''
            },
            learners: [
                {
                    id: 0,
                    name: '',
                    surname: '',
                    api_token: '',
                    email: '',
                    photo_color: '',
                    last_login: '',
                    created_at: '',
                    updated_at: '',
                    photo: ''
                }
            ],
            course_packages_by_sort: [
                {
                    id: 0,
                    name: ''
                }
            ]
        }]
};
export default paymentState;
