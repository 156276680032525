import API from '@/api/ServiceApi';
export default class CoursesListServices {
    async getCourses(config) {
        const cardParams = {
            withCount: 'students',
            with: 'subcategoriesWithCategories;coursePackagesBySort:id,course_id,name,color;coursePackagesBySort.course:id,name;folder;type',
            withExists: 'authUserFavourites',
            status: config.status,
            ...config.params,
            page: config.page,
            per_page: config.per_page
        };
        if (config.sort)
            cardParams.sort = config.sort;
        if (config.desc)
            cardParams.desc = 1;
        const resultParams = config.isTableMode
            ? {
                withCount: 'students',
                with: 'subcategoriesWithCategories;coursePackagesBySort:id,course_id,name,color;coursePackagesBySort.course:id,name;folder',
                withExists: 'authUserFavourites',
                entity_type: 'course',
                ...config?.params,
                ...config?.query
            }
            : cardParams;
        const url = `${API.baseURL}/courses_fast_edit`;
        return await API.getData(url, resultParams);
    }
    async add_to_folder({ courses_id, courses_compilations_id, folder_id }) {
        return await API.postData(`${API.baseURL}/course_folder/add_course`, { course_ids: courses_id, compilation_ids: courses_compilations_id, folder_id });
    }
    async remove_from_folder({ courses_id, courses_compilations_id }) {
        return await API.postData(`${API.baseURL}/course_folder/delete/mass`, { course_ids: courses_id, compilation_ids: courses_compilations_id }, 'DELETE');
    }
    async doOtherActionInCoursesTable({ courses_id, courses_compilations_id, url }) {
        if (courses_id?.length) {
            return await API.postData(`${API.baseURL}/courses/${url}`, { courses_id });
        }
        if (courses_compilations_id?.length) {
            return await API.postData(`${API.baseURL}/course_compilations/${url}`, { courses_compilations_id });
        }
        return Promise.resolve({ data: true });
    }
    async getCategories() {
        const { data } = await API.getData(`${API.baseURL}/course_categories`, {
            with: 'subcategories',
            type: 'category'
        });
        return data;
    }
    async getCustomFields() {
        const { data } = await API.getData(`${API.baseURL}/custom_fields`, {
            all: 1,
            subtype: 'course',
            only: 'id,name,multiple,subtype',
            'notTypes[0]': 'image',
            'notTypes[1]': 'file',
            'notTypes[2]': 'avatar',
            'notTypes[3]': 'choose_role'
        });
        return data;
    }
    async getCoursesListForTable({ query, params }) {
        return await API.getData(`${API.baseURL}/courses`, { ...query, ...params });
    }
    async getFoldersList() {
        const { data, errors } = await API.getData(`${API.baseURL}/course_folder`, { all: 1 });
        if (errors)
            return { errors };
        return { data };
    }
    async createFolder(payload) {
        return await API.postData(`${API.baseURL}/course_folder`, payload);
    }
    async updateFolder(id, payload) {
        return await API.postData(`${API.baseURL}/course_folder/${id}`, payload, 'PUT');
    }
    async deleteFolder(id) {
        return await API.postData(`${API.baseURL}/course_folder/${id}`, {}, 'DELETE');
    }
    async changeFavorite(course_id, attach) {
        const action = attach ? 'attach' : 'detach';
        const { data, errors } = await API.postData(`${API.baseURL}/courses/${course_id}/user_favourite/${action}`, {}, 'POST');
        if (errors)
            return { errors };
        return { data };
    }
    async checkReportReady(fileName) {
        return await API.getData(`${API.baseURL}/courses_file`, { file_name: fileName });
    }
    async getReportsList() {
        return await API.getData(`${API.baseURL}/courses_download/history`);
    }
    async generateReport(filters) {
        return await API.getData(`${API.baseURL}/courses_download`, {
            ...filters,
            with: 'subcategoriesWithCategories;coursePackagesBySort:id,course_id,name,color;coursePackagesBySort.course:id,name;folder',
            withCount: 'students',
            entity_type: 'course'
        });
    }
}
