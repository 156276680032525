import { mixinMutations } from '@/store/mixins';
const mutations = { ...mixinMutations() };
const actions = {
    async createTemplate({ rootGetters }, url) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/${url}`, {}, 'POST');
        return data;
    },
    async list({ rootGetters }, { params, query }) {
        return await this.getDataStore(`${rootGetters.API_URL}/library/all`, { ...params, ...query });
    },
    async createTemplateBanner({ rootGetters }, { course_id, compilation_id, bannerType, entityType = 'course' }) {
        const urlVocabulary = {
            compilation: `${rootGetters.API_URL}/library/banner_from_compilation/${compilation_id}`,
            default: `${rootGetters.API_URL}/library/banner_from_course/${course_id}`
        };
        const url = urlVocabulary[entityType] || urlVocabulary.default;
        const { data } = await this.postDataStore(url, { bannerType }, 'POST');
        return data;
    },
    async deleteLibraryItem({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/library/${id}`, {}, 'DELETE');
    },
    async getTemplatesTests({ rootGetters }, { params, query }) {
        return await this.getDataStore(`${rootGetters.API_URL}/library/tests`, { ...params, ...query });
    },
    async getTemplatesBlocks({ rootGetters }, { params, query }) {
        return await this.getDataStore(`${rootGetters.API_URL}/library/blocks`, { ...params, ...query });
    },
    async getTemplatesLessons({ rootGetters }, { params, query }) {
        return await this.getDataStore(`${rootGetters.API_URL}/library/lessons`, { ...params, ...query });
    },
    async getTemplatesHomeworks({ rootGetters }, { params, query }) {
        return await this.getDataStore(`${rootGetters.API_URL}/library/type/homework_files`, { ...params, ...query });
    },
    async getTemplatesVideo({ rootGetters }, { params, query }) {
        return await this.getDataStore(`${rootGetters.API_URL}/library/type/video`, { ...params, ...query });
    },
    async getTemplatesMaterial({ rootGetters }, { params, query }) {
        return await this.getDataStore(`${rootGetters.API_URL}/library/type/material_files`, { ...params, ...query });
    },
    async getTemplateBanners({ rootGetters }, { params, query }) {
        return await this.getDataStore(`${rootGetters.API_URL}/library/type/users_uploads`, { ...params, ...query });
    },
    async copyTestFromTemplate({ rootGetters }, { id, lesson_id }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/library/library_test/${id}/copy`, { lesson_id });
        return data;
    },
    async copyBlockFromTemplate({ rootGetters }, { id, lesson_id, course_id, sort_order = 1 }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/library/library_block/${id}/copy`, { lesson_id, course_id, sort_order });
        return data;
    },
    async copyLessonFromTemplate({ rootGetters }, { id, course_id }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/library/library_lesson/${id}/course/${course_id}/copy`, {});
        return data;
    },
    async copyMediaFromTemplate({ rootGetters }, { id, ...rest }) {
        const { lesson_media, test_media, media } = await this.getDataStore(`${rootGetters.API_URL}/library/library_media/${id}/copy`, rest);
        return media || lesson_media || test_media;
    },
    async copyBannerFromTemplate({ rootGetters }, { id, course_id, compilation_id, bannerType, entityType = 'course' }) {
        const paramsVocabulary = {
            compilation: { compilation_id, bannerType },
            default: { course_id, bannerType }
        };
        const params = paramsVocabulary[entityType] || paramsVocabulary.default;
        const { course_media, http_url, media } = await this.getDataStore(`${rootGetters.API_URL}/library/library_media/${id}/copy`, params);
        return { course_media: media || course_media, url: http_url };
    },
    async copyVideoFromTemplate({ rootGetters }, { lesson_id, lesson_block_id, video, id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/library/library_media/${id}/copy`, { lesson_id, lesson_block_id, video });
    }
};
export default {
    namespaced: true,
    mutations,
    actions
};
