import Vue from 'vue';
import { mixinMutations } from '@/store/mixins';
import defaultState from '@/store/modules/form/defaultState';
const state = defaultState();
const typesVocabulary = {
    name: {
        name: 'Имя',
        type: 'name'
    },
    surname: {
        name: 'Фамилия',
        type: 'surname'
    },
    email: {
        name: 'Email',
        type: 'email'
    },
    description: {
        name: 'Descripion',
        type: 'description'
    },
    line: {
        name: 'Line',
        type: 'line'
    },
    space: {
        name: 'Space',
        type: 'space'
    },
    title: {
        name: 'Title',
        type: 'title'
    }
};
const getters = {
    isRegistrationForm(state) {
        return !['register', 'profile', 'course_bid', 'free'].includes(state.dataForm.type);
    }
};
const mutations = {
    ...mixinMutations(),
    changeDataField(state, payload) {
        const { fields, updateFields, id, title, description, button_name, create_user, type, name, code, role, social_network_login, front_template, webhook, is_allow_sent_bitrix_phone_type } = payload;
        state.dataForm = {
            name,
            code,
            type,
            role,
            front_template,
            webhook
        };
        state.dataField = {
            id,
            title,
            description,
            button_name,
            create_user,
            social_network_login,
            is_allow_sent_bitrix_phone_type
        };
        if (updateFields && fields) {
            state.fields = fields.sort((a, b) => a.sort - b.sort)
                .map((el) => {
                if (!el.custom_field && el.type && typesVocabulary[el.type]) {
                    el.custom_field = typesVocabulary[el.type];
                }
                return el;
            });
        }
    },
    addFields(state, field) {
        state.fields.push(field);
    },
    remField(state, fieldIndex) {
        state.fields.splice(fieldIndex, 1);
    },
    updateField(state, { indexField, params }) {
        Vue.set(state.fields, indexField, { ...state.fields[indexField], ...params });
    }
};
const actions = {
    async create({ rootGetters }, payload) {
        return await this.postDataStore(`${rootGetters.API_URL}/forms`, payload);
    },
    async get({ rootGetters, commit }, payload) {
        const { query, params } = payload;
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/forms/${query.formId}`, params);
        commit('changeDataField', {
            ...data,
            updateFields: true
        });
        return data;
    },
    async listCustomFields({ rootGetters, commit, state }, payload) {
        const { params } = payload;
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/custom_fields`, params);
        const filterNames = {
            user: 'Пользовательские кастомные поля',
            bid: 'Заявочные кастомные поля',
            course: 'Кастомные поля курса'
        };
        const allowedTypes = {
            course: ['course'],
            self_bid: ['bid'],
            default: ['user']
        };
        const { type } = state.dataForm;
        const formAllowedTypes = allowedTypes[type] || allowedTypes.default;
        const customFields = data.reduce((acc, el) => {
            if (!formAllowedTypes.includes(el.subtype))
                return acc;
            const indexObjType = acc.findIndex((t) => t.subtype === el.subtype);
            if (indexObjType !== -1) {
                acc[indexObjType].value.push(el);
            }
            else {
                acc.push({
                    name: filterNames[el.subtype],
                    value: [el],
                    subtype: el.subtype
                });
            }
            return acc;
        }, []);
        commit('setProperty', {
            name: 'customFields',
            value: customFields
        });
        return data;
    },
    async addFieldToForm({ state, commit, rootGetters }, payload) {
        const { params } = payload;
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/forms/${state.dataField.id}/form_fields`, params);
        if (!data.custom_field && data.type && typesVocabulary[data.type]) {
            data.custom_field = typesVocabulary[data.type];
        }
        commit('addFields', data);
    },
    async getFieldsIds({ rootGetters }, params) {
        return await this.getDataStore(`${rootGetters.API_URL}/bitrix_fields`, params);
    },
    async remFieldToForm({ state, commit, rootGetters }, payload) {
        const { params, type = 'customField' } = payload;
        let indexField = -1;
        indexField = type === 'field'
            ? state.fields.findIndex((el) => el.custom_field_id === params.field_id)
            : state.fields.findIndex((el) => el.id === params.field_id);
        if (indexField === -1)
            return;
        await this.postDataStore(`${rootGetters.API_URL}/forms/${state.dataField.id}/form_fields/${state.fields[indexField].id}`, {}, 'DELETE');
        commit('remField', indexField);
    },
    async updateFieldToForm({ commit, state, rootGetters }, payload) {
        const { query, params } = payload;
        const indexField = state.fields.findIndex((el) => el.id === query.fieldId);
        if (indexField === -1)
            return;
        const { errors } = await this.postDataStore(`${rootGetters.API_URL}/forms/${state.dataField.id}/form_fields/${query.fieldId}`, params, 'PUT');
        if (!errors) {
            commit('updateField', {
                indexField,
                params
            });
        }
    },
    async updateFieldSort({ commit, state, rootGetters }, payload) {
        const { fieldBefore, fieldAfter } = payload;
        let newSortFields = [...state.fields];
        const indexBefore = state.fields.findIndex((el) => el.id === fieldBefore);
        const indexAfter = state.fields.findIndex((el) => el.id === fieldAfter);
        newSortFields.splice(indexAfter, 1);
        newSortFields.splice(indexBefore, 0, state.fields[indexAfter]);
        newSortFields = newSortFields.map((item, i) => ({
            ...item,
            sort: i + 1
        }))
            .sort((a, b) => a.sort - b.sort);
        await this.postDataStore(`${rootGetters.API_URL}/forms/${state.dataField.id}/form_fields/multi_update`, {
            form_fields: newSortFields.map(({ id, sort }) => ({
                id,
                sort
            }))
        });
        commit('setProperty', {
            name: 'fields',
            value: newSortFields
        });
    },
    async list({ rootGetters }, payload) {
        const { params } = payload;
        return await this.getDataStore(`${rootGetters.API_URL}/forms`, params);
    },
    async update({ state, commit, rootGetters }, payload) {
        const { params, formId } = payload;
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/forms/${formId || state.dataField.id}`, params, 'PUT');
        if (!errors) {
            commit('changeDataField', {
                ...data,
                updateFields: false
            });
        }
        return {
            data,
            errors
        };
    },
    async copy({ rootGetters }, { id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/forms/${id}/copy`, {}, 'POST');
    },
    async delete({ rootGetters }, { params: { id } }) {
        return await this.postDataStore(`${rootGetters.API_URL}/forms/${id}`, {}, 'DELETE');
    },
    async default({ rootGetters }, { field }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/forms/${state.dataField.id}/form_fields/add_basic_fields`, { field }, 'POST');
        return data;
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
