import { mixinMutations } from '@/store/mixins';
const mutations = { ...mixinMutations() };
const actions = {
    async getIdeas({ rootGetters }, payload) {
        return await this.getDataStore(`${rootGetters.API_URL}/updating-platform/ideas`, payload);
    },
    async toggleAffirmation({ rootGetters }, { idea_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/updating-platform/ideas/${idea_id}/toggle-affirmation`, {});
    },
    async updateIdea({ rootGetters }, payload) {
        const { id, ...data } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/updating-platform/ideas/${id}`, data, 'PUT');
    },
    async storeIdea({ rootGetters }, payload) {
        return await this.postDataStore(`${rootGetters.API_URL}/updating-platform/ideas`, payload);
    },
    async destroyIdea({ rootGetters }, { idea_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/updating-platform/ideas/${idea_id}`, {}, 'DELETE');
    },
};
export default {
    namespaced: true,
    mutations,
    actions
};
