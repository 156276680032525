const actions = {
    async createVariable({ rootGetters }, payload) {
        const { query, params } = payload;
        const { customFieldId } = query;
        return await this.postDataStore(`${rootGetters.API_URL}/custom_fields/${customFieldId}/custom_field_variables`, params);
    },
    async createMultiVariables({ rootGetters }, payload) {
        const { query, params } = payload;
        const { customFieldId } = query;
        return await this.postDataStore(`${rootGetters.API_URL}/custom_fields/${customFieldId}/custom_field_variables/multi_creation`, params);
    },
    async getVariables({ rootGetters }, payload) {
        const { query, params } = payload;
        const { customFieldId } = query;
        return await this.getDataStore(`${rootGetters.API_URL}/custom_fields/${customFieldId}/custom_field_variables`, params);
    },
    async updateVariable({ rootGetters }, payload) {
        const { query, params } = payload;
        const { customFieldId, variableId } = query;
        return await this.postDataStore(`${rootGetters.API_URL}/custom_fields/${customFieldId}/custom_field_variables/${variableId}`, params, 'PUT');
    },
    async deleteVariable({ rootGetters }, payload) {
        const { query } = payload;
        const { customFieldId, variableId } = query;
        return await this.postDataStore(`${rootGetters.API_URL}/custom_fields/${customFieldId}/custom_field_variables/${variableId}`, {}, 'DELETE');
    },
    async attachVariable({ rootGetters }, payload) {
        const { query, params } = payload;
        const { variableId } = query;
        return await this.postDataStore(`${rootGetters.API_URL}/custom_field_variables/${variableId}/attach`, params);
    },
    async detachVariable({ rootGetters }, payload) {
        const { query, params } = payload;
        const { variableId } = query;
        return await this.postDataStore(`${rootGetters.API_URL}/custom_field_variables/${variableId}/detach`, params);
    },
    async list({ rootGetters }, payload) {
        const { query, params = {} } = payload;
        const { customFieldId } = query;
        return await this.getDataStore(`${rootGetters.API_URL}/custom_fields/${customFieldId}`, params);
    }
};
export default {
    namespaced: true,
    actions
};
