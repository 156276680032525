import { mixinActions, mixinMutations } from '@/store/mixins';
import BuildDate from '@/helpers/BuildDate';
import defaultState from '@/store/modules/orders/entities/defaultState';
const state = defaultState();
const mutations = {
    ...mixinMutations(),
    setNewHeaders(state, payload) {
        const currentHeadersLength = state.headers.length;
        const headersValues = state.headers.map((item) => item.value);
        const ordersCustomValues = headersValues.filter((value) => String(value).includes('custom_field_'));
        const bidFields = payload.filter((item) => item.subtype === 'bid');
        const userFields = payload.filter((item) => item.subtype !== 'bid');
        const newHeaders = bidFields.map(({ id, name, type, multiple, subtype }, index) => ({
            text: name,
            value: `custom_field_${id}`,
            sortable: !['list', 'avatar', 'file', 'text', 'telegram_id'].includes(type) && !multiple,
            type,
            sort: index + currentHeadersLength,
            checked: false,
            multiple,
            subtype,
            componentName: 'td-custom'
        }));
        const newHeadersValues = newHeaders.map((item) => item.value);
        const actualHeaders = state.headers.filter(({ value }) => !ordersCustomValues.includes(value) || newHeadersValues.includes(value));
        state.headers = [
            ...actualHeaders,
            ...newHeaders.filter((item) => !headersValues.includes(item.value))
        ];
        state.userFields = userFields.map(({ id, name, type, multiple, subtype }, index) => ({
            text: name,
            value: id,
            type,
            sort: index + state.headers.length,
            multiple,
            subtype
        }));
        state.allHeaders = [
            ...state.headers.map(({ text, value, type, sort, multiple, subtype }) => ({
                text, value, type, sort, multiple, subtype
            })),
            ...state.userFields
        ];
    },
    setFilterCategories(state, payload) {
        const currentHeadersLength = state.headers.length;
        state.filterCategories = payload.map(({ id, title }, index) => ({
            text: title,
            value: `category_${id}`,
            type: 'list',
            sort: index + currentHeadersLength,
            multiple: true,
            subtype: 'category',
            sortable: false
        }));
        state.allHeaders = [
            ...state.allHeaders,
            ...state.filterCategories
        ];
    }
};
const actions = {
    ...mixinActions(),
    async list({ rootGetters }, payload) {
        const { params } = payload;
        params.notArchive = true;
        const response = await this.getDataStore(`${rootGetters.API_URL}/orders`, params);
        const { data, ...rest } = response;
        if (!data)
            return [];
        const newData = data.map((item) => {
            const { custom_field_values } = item;
            if (custom_field_values && custom_field_values.length) {
                const newValues = custom_field_values.reduce((acc, curr) => {
                    const { custom_field_id, custom_field_type, value } = curr;
                    if (!acc[custom_field_id]) {
                        acc[custom_field_id] = value;
                        if (custom_field_type === 'datetime') {
                            acc[custom_field_id] = BuildDate(value, { monthType: 'dashedNumbers', showTime: true }).rusDate.replaceAll(' - ', '-');
                        }
                    }
                    else {
                        const newVal = Array.isArray(acc[custom_field_id]) ? acc[custom_field_id] : [acc[custom_field_id]];
                        acc[custom_field_id] = { value: [...newVal, value] };
                    }
                    return acc;
                }, {});
                return {
                    ...item,
                    ...newValues
                };
            }
            return { ...item };
        });
        return {
            data: newData,
            ...rest
        };
    },
    async createOrder({ rootGetters }, payload) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/orders`, payload, 'POST');
        return data.id;
    },
    async getFilterCategories({ rootGetters, commit }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/course_categories`, {
            all: 1,
            only: 'type,title',
            type: 'category',
            used: 1
        });
        commit('setFilterCategories', data);
    },
    async getCustomFields({ rootGetters, commit, dispatch }) {
        commit('setProperty', { name: 'fieldsLoading', value: true });
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/custom_fields`, {
            all: 1,
            only: 'id,name,multiple,subtype',
            'notTypes[0]': 'image',
            'notTypes[1]': 'file',
            'notTypes[2]': 'avatar',
            'notTypes[3]': 'choose_role'
        });
        commit('setNewHeaders', data);
        await dispatch('getFilterCategories');
        commit('setProperty', { name: 'fieldsLoading', value: false });
    },
    // Order Folders
    async getFoldersList({ rootGetters, commit }) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/order_folder`, { all: 1 });
        if (errors)
            return { errors };
        commit('setProperty', { name: 'orderFolders', value: data });
        return { data };
    },
    async createFolder({ rootGetters }, payload) {
        return await this.postDataStore(`${rootGetters.API_URL}/order_folder`, payload);
    },
    async updateFolder({ rootGetters }, { id, payload }) {
        return await this.postDataStore(`${rootGetters.API_URL}/order_folder/${id}`, payload, 'PUT');
    },
    async deleteFolder({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/order_folder/${id}`, {}, 'DELETE');
    },
    async doAction({ rootGetters }, { action, selectedOrders, folder_id }) {
        const requestsVocabulary = {
            add_to_folder: async () => {
                await this.postDataStore(`${rootGetters.API_URL}/order_folder/add_order`, { folder_id, order_ids: selectedOrders });
            },
            remove_from_folder: async () => {
                await this.postDataStore(`${rootGetters.API_URL}/order_folder/delete/mass`, { order_ids: selectedOrders }, 'DELETE');
            },
            default: async () => ({})
        };
        const request = requestsVocabulary[action] || requestsVocabulary.default;
        await request();
        return {};
    },
    async checkReportReady({ rootGetters }, fileName) {
        return await this.getDataStore(`${rootGetters.API_URL}/orders_file`, { file_name: fileName });
    },
    async getReportsList({ rootGetters }) {
        return await this.getDataStore(`${rootGetters.API_URL}/orders_download/history`);
    },
    async generateReport({ rootGetters }, filters) {
        return await this.getDataStore(`${rootGetters.API_URL}/orders_download`, {
            ...filters,
            with: 'status;folder;course'
        });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
