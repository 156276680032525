export const createDefaultState = () => ({
    id: 0,
    max_use_count: 0,
    single_user_max_use_count: 0,
    single_user_max_use_count_in_course: 0,
    code: '',
    description: '',
    allowed_product_types: '',
    active: true,
    sale_type: 'sum',
    sale_sum: 0,
    sale_percent: 0,
    date_start: null,
    date_end: null,
    created_at: '2021-09-22T08:46:52.000000Z',
    updated_at: '2021-09-22T08:46:52.000000Z',
    deleted_at: null,
    coursePackages: [],
    courseCompilations: [],
    courses: [],
    for_all_courses: false,
    wholePromo: {}
});
class InitState {
    static createDefaultState() {
        return { ...this.promoCodeState };
    }
}
InitState.promoCodeState = createDefaultState();
export default InitState;
