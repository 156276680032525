import { mixinActions, mixinMutations } from '@/store/mixins';
import defaultState from '@/store/modules/testResults/defaultState';
const state = defaultState();
const mutations = { ...mixinMutations() };
const actions = {
    ...mixinActions(),
    async getTest({ commit, rootGetters }, id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/tests/${id}`, { with: 'questions:question,sort,part,max_points,active;subcategories_with_categories;lesson:id,course_id,name;lesson.course:id,name' });
        commit('setProperty', { name: 'test', value: data });
        return data;
    },
    async list({ commit, rootGetters }, payload) {
        const { params } = payload;
        const { test_id, ...rest } = params;
        const response = await this.getDataStore(`${rootGetters.API_URL}/tests/${test_id}/attempts`, rest);
        commit('setProperty', { name: 'results', value: response.data });
        return response;
    },
    async getUsersList({ commit, rootGetters }, payload) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/users`, {
            search: payload,
            only: 'name,surname,email'
        });
        commit('setProperty', { name: 'users', value: data.map(({ name, surname, id }) => ({ text: `${name} ${surname}`, value: id })) });
    },
    // async downloadAttempts({ rootGetters }, { test_id, filters }) {
    //   return await this.getDataStore(`${rootGetters.API_URL}/tests/${test_id}/attempts`, {
    //     ...filters,
    //     excel: 1
    //   });
    // },
    async generateReport({ rootGetters }, { test_id, filters }) {
        return await this.getDataStore(`${rootGetters.API_URL}/tests/${test_id}/attempts_download/create`, { ...filters });
    },
    async checkReportReady({ rootGetters }, { test_id, file_name }) {
        return await this.getDataStore(`${rootGetters.API_URL}/tests/${test_id}/attempts_download/search_file`, { test_id, file_name });
    },
    async getReportsHistory({ commit, rootGetters }, test_id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/tests/${test_id}/attempts_download/exports`);
        // this can be a local state now but we might want to use it in other component later
        // if (data) commit('setProperty', {neme: 'test_result_history', value: data});
        return data;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
