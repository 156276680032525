const defaultState = () => ({
    attributeValues: [],
    attributes: [],
    classes: null,
    company_id: 0,
    coursesInPool: [],
    created_at: '',
    deleted_at: '',
    duration: '',
    schedule: '',
    sets: [],
    image: [],
    updated_at: '',
    id: 0,
    name: '',
    description: '',
    new_price: null,
    price: null,
    groupTypes: [],
    sort: null
});
export default defaultState;
