import { __decorate, __metadata } from "tslib";
import { Component, Vue, Prop } from 'nuxt-property-decorator';
let TabItem = class TabItem extends Vue {
    setCurrentTab(text, value) {
        if (this.disabled || this.$route.query?.tab === value)
            return;
        if (this.writeToUrl)
            this.$router.push({ query: { tab: value } }).catch(() => ({}));
        this.$emit('setCurrentTab', { text, value });
    }
};
__decorate([
    Prop({
        type: String,
        default: () => ''
    }),
    __metadata("design:type", Object)
], TabItem.prototype, "text", void 0);
__decorate([
    Prop({
        type: [String, Number],
        default: () => ''
    }),
    __metadata("design:type", Object)
], TabItem.prototype, "value", void 0);
__decorate([
    Prop({
        type: Object,
        default: () => ({})
    }),
    __metadata("design:type", Object)
], TabItem.prototype, "currentTab", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: false
    }),
    __metadata("design:type", Object)
], TabItem.prototype, "writeToUrl", void 0);
__decorate([
    Prop({ default: () => false }),
    __metadata("design:type", Object)
], TabItem.prototype, "disabled", void 0);
TabItem = __decorate([
    Component({})
], TabItem);
export default TabItem;
