const defaultState = () => ({
    availableHeaders: {
        loading: false,
        data: []
    },
    listHeaders: {
        loading: false,
        data: []
    },
    totals: [],
    headersOptions: {
        accompanying: { width: '76px' },
        budgetary: { width: '64px' },
        commerce: { width: '84px' },
        commerce_reserve: { width: '74px' },
        company: { width: '64px' },
        company_reserve: { width: '56px' },
        discount: { width: '60px' },
        fact: { width: '52px' },
        item: { width: '196px' },
        main_column: { width: '420px' },
        making_decision: { width: '80px' },
        payment_link: { width: '80px' },
        postponed_buy: { width: '88px' },
        pre_reserve: { width: '120px' },
        residue: { width: '64px' },
        fact_plan_ratio: { width: '100px' },
        target: { width: '52px' },
        trial_day: { width: '72px' },
        waiting_list: { width: '72px' }
    },
    selectedHeader: null,
    lists: [],
    currentListId: null,
    mainColumns: [],
    contextMenu: {
        type: null,
        reference: null,
        position: { x: 0, y: 0 },
        mode: null,
        show: false
    },
    access: {
        all: 'Всем',
        except: 'Всем, кроме сотрудников, указанных ниже',
        only: 'Только сотрудникам, указанным ниже'
    },
    editCellDialog: {
        options: { pos: { x: 0, y: 0 }, show: false },
        cell: null
    },
    downloadingEvents: []
});
export default defaultState;
