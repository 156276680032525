import { mixinActions, mixinMutations } from '@/store/mixins';
import defaultItem from '@/pages/AutoActions/entities/templateEntities';
const state = () => ({
    isLoading: false,
    item: defaultItem
});
const mutations = { ...mixinMutations() };
const actions = {
    ...mixinActions(),
    async getTemplate({ commit, rootGetters }, { id }) {
        if (!id || id === 'create')
            return;
        commit('setProperty', { name: 'isLoading', value: true });
        const url = `${rootGetters.API_URL}/auto_action_templates/${id}`;
        const { data } = await this.getDataStore(url);
        commit('setProperty', { name: 'item', value: data });
        commit('setProperty', { name: 'isLoading', value: false });
    },
    async get({ commit, rootGetters }, { id }) {
        const url = `${rootGetters.API_URL}/auto_action_templates/${id}`;
        const response = await this.getDataStore(url);
        commit('setProperty', { name: 'item', value: response.data });
        return response;
    },
    async getTypes({ rootGetters }) {
        const url = `${rootGetters.API_URL}/auto_actions_templates_types/`;
        const { data } = await this.getDataStore(url);
        return data;
    },
    async getEntities({ rootGetters }) {
        const url = `${rootGetters.API_URL}/auto_actions_entities`;
        const { data } = await this.getDataStore(url);
        return data;
    },
    async getEvents({ state, rootGetters }) {
        const url = `${rootGetters.API_URL}/auto_actions_events`;
        const { entity } = state.item;
        const params = { entity };
        const { data } = await this.getDataStore(url, params);
        return data;
    },
    async getVariables({ rootGetters }, payload) {
        const url = `${rootGetters.API_URL}/auto_actions_templates_variables/`;
        const { data } = await this.getDataStore(url, payload);
        return data;
    },
    async create({ rootGetters }, params) {
        const url = `${rootGetters.API_URL}/auto_action_templates`;
        return await this.postDataStore(url, params);
    },
    async update({ rootGetters }, params) {
        const url = `${rootGetters.API_URL}/auto_action_templates/${params.id}`;
        return await this.postDataStore(url, params, 'PUT');
    },
    async toggleActivity({ rootGetters }, { id, params }) {
        const url = `${rootGetters.API_URL}/auto_action_templates/${id}`;
        const { data } = await this.postDataStore(url, params, 'PUT');
        return data;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
