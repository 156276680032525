const assessment = [
    {
        text: '5-балльная шкала',
        value: '5'
    },
    {
        text: '10-балльная шкала',
        value: '10'
    },
    {
        text: '100-балльная шкала',
        value: '100'
    },
    {
        text: 'Зачет/незачет',
        value: '1'
    }
];
export default assessment;
