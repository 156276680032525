export var QuestionNames;
(function (QuestionNames) {
    QuestionNames["input"] = "\u0422\u0435\u043A\u0441\u0442\u043E\u0432\u043E\u0435 \u043F\u043E\u043B\u0435";
    QuestionNames["numeric_input"] = "\u0427\u0438\u0441\u043B\u043E\u0432\u043E\u0435 \u043F\u043E\u043B\u0435";
    QuestionNames["radio"] = "\u041E\u0434\u0438\u043D\u043E\u0447\u043D\u044B\u0439 \u0432\u044B\u0431\u043E\u0440";
    QuestionNames["checkbox"] = "\u041C\u043D\u043E\u0436\u0435\u0441\u0442\u0432\u0435\u043D\u043D\u044B\u0439 \u0432\u044B\u0431\u043E\u0440";
    QuestionNames["sort"] = "\u0421\u043E\u0440\u0442\u0438\u0440\u043E\u0432\u043A\u0430";
    QuestionNames["multi_input"] = "\u041C\u043D\u043E\u0436\u0435\u0441\u0442\u0432\u0435\u043D\u043D\u043E\u0435 \u0442\u0435\u043A\u0441\u0442\u043E\u0432\u043E\u0435 \u043F\u043E\u043B\u0435";
    QuestionNames["textarea"] = "\u0421\u0432\u043E\u0431\u043E\u0434\u043D\u044B\u0439 \u043E\u0442\u0432\u0435\u0442";
    QuestionNames["correspondence"] = "\u0421\u043E\u043E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0438\u0435";
    QuestionNames["select_input"] = "\u0412\u044B\u0431\u043E\u0440 \u0432 \u0442\u0435\u043A\u0441\u0442\u0435";
    QuestionNames["text_input"] = "\u0412\u0432\u043E\u0434 \u0432 \u0442\u0435\u043A\u0441\u0442\u0435";
    QuestionNames["assessment_by_criterion"] = "\u041E\u0446\u0435\u043D\u043A\u0430 \u043F\u043E \u043A\u0440\u0438\u0442\u0435\u0440\u0438\u044F\u043C";
    QuestionNames["default"] = "\u041D\u0435\u0438\u0437\u0432\u0435\u0441\u0442\u043D\u044B\u0439 \u0442\u0438\u043F";
    QuestionNames["audio_input"] = "\u0410\u0443\u0434\u0438\u043E \u043E\u0442\u0432\u0435\u0442";
})(QuestionNames || (QuestionNames = {}));
const questionTypes = [
    {
        text: QuestionNames.input,
        value: 'input',
        icon: 'text_format'
    },
    {
        text: QuestionNames.numeric_input,
        value: 'numeric_input',
        icon: 'tag'
    },
    {
        text: QuestionNames.radio,
        value: 'radio',
        icon: 'radio_button_checked'
    },
    {
        text: QuestionNames.checkbox,
        value: 'checkbox',
        icon: 'check_box'
    },
    {
        text: QuestionNames.sort,
        value: 'sort',
        icon: 'sort'
    },
    {
        text: QuestionNames.multi_input,
        value: 'multi_input',
        icon: 'view_module'
    },
    {
        text: QuestionNames.textarea,
        value: 'textarea',
        icon: 'view_module'
    },
    {
        text: QuestionNames.correspondence,
        value: 'correspondence',
        icon: 'sync_alt'
    },
    {
        text: QuestionNames.select_input,
        value: 'select_input',
        icon: 'text_fields'
    },
    {
        text: QuestionNames.text_input,
        value: 'text_input',
        icon: 'text_fields'
    },
    {
        text: QuestionNames.assessment_by_criterion,
        value: 'assessment_by_criterion',
        icon: 'hashtag'
    },
    {
        text: QuestionNames.audio_input,
        value: 'audio_input',
        icon: 'music_note'
    }
];
export default questionTypes;
