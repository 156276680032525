import { mixinMutations } from '@/store/mixins';
const state = { dataLoading: false };
const mutations = {
    ...mixinMutations(),
    setLoading(state, isLoading) {
        state.dataLoading = isLoading;
    }
};
export default {
    namespaced: true,
    state,
    mutations
};
