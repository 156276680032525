import { mixinMutations } from '@/store/mixins';
const state = () => ({
    isLoading: false,
    isLoadingItem: null,
    isSearching: false,
    categories: [],
    found: [],
    category: null
});
const getters = {
    genericCategories(state) {
        if (!state.categories || !Array.isArray(state.categories))
            return [];
        return [...state.categories]
            .sort((a, b) => a.sort - b.sort)
            .filter((item) => item.common);
    },
    companyCategories(state) {
        if (!state.categories || !Array.isArray(state.categories))
            return [];
        return [...state.categories]
            .sort((a, b) => a.sort - b.sort)
            .filter((item) => !item.common);
    },
    genericPublicCategories(state) {
        if (!state.categories || !Array.isArray(state.categories))
            return [];
        return [...state.categories]
            .sort((a, b) => a.sort - b.sort)
            .filter((item) => !!item.public && item.common);
    },
    companyPublicCategories(state) {
        if (!state.categories || !Array.isArray(state.categories))
            return [];
        return [...state.categories]
            .sort((a, b) => a.sort - b.sort)
            .filter((item) => !!item.public && !item.common);
    }
};
const mutations = {
    ...mixinMutations(),
    updateItem(state, category) {
        const index = state.categories.findIndex((item) => item.id === category.id);
        if (index !== -1)
            state.categories.splice(index, 1, category);
    },
    updateItemPublic(state, { id, status }) {
        const item = state.categories.find((item) => item.id === id);
        item.public = status;
    },
    clearSearch(state) {
        state.found = [];
    }
};
const actions = {
    async list({ commit, rootState, rootGetters }, payload = {}) {
        const { noChangeLoading } = payload;
        if (!noChangeLoading)
            commit('setProperty', { name: 'isLoading', value: true });
        // eslint-disable-next-line
        const { type } = rootState.Instructions;
        const belongs = type === 'admin' ? 'admin' : 'cabinet';
        const params = { ...payload, belongs, all: 1 };
        const url = `${rootGetters.API_URL}/categories`;
        const { data } = await this.getDataStore(url, params);
        commit('setProperty', { name: payload?.search ? 'found' : 'categories', value: data });
        if (!noChangeLoading)
            commit('setProperty', { name: 'isLoading', value: false });
        return data;
    },
    async listByCategory({ commit, rootGetters }, id) {
        commit('setProperty', { name: 'isLoading', value: true });
        const url = `${rootGetters.API_PUBLIC_URL}/instructions/category/${id}`;
        const { category, data } = await this.getDataStore(url);
        commit('setProperty', { name: 'category', value: { ...category, instructions: data } });
        commit('setProperty', { name: 'isLoading', value: false });
    },
    async get({ rootGetters }, { id }) {
        const url = `${rootGetters.API}/instruction_category/${id}`;
        return await this.getDataStore(url);
    },
    async create({ dispatch, rootState, rootGetters }, payload) {
        // eslint-disable-next-line
        const { type } = rootState.Instructions;
        const belongs_to = type === 'admin' ? 'admin' : 'cabinet';
        const params = { ...payload, belongs_to };
        const url = `${rootGetters.API_URL}/categories`;
        const result = await this.postDataStore(url, params);
        dispatch('list');
        return result;
    },
    async update({ commit, rootGetters }, params) {
        commit('setProperty', { name: 'isLoadingItem', value: params });
        const { id } = params;
        const url = `${rootGetters.API_URL}/categories/${id}`;
        const { data } = await this.postDataStore(url, params, 'PUT');
        commit('updateItem', data);
        commit('setProperty', { name: 'isLoadingItem', value: null });
        return data;
    },
    async updateCommon({ commit, rootGetters }, params) {
        commit('setProperty', { name: 'isLoadingItem', value: params });
        const { id } = params;
        const url = `${rootGetters.API}/instruction_category/${id}`;
        const { data } = await this.postDataStore(url, params, 'PUT');
        commit('updateItem', data);
        commit('setProperty', { name: 'isLoadingItem', value: null });
        return data;
    },
    async updateCategoryInList({ rootGetters, commit }, { id }) {
        const url = `${rootGetters.API}/instruction_category/${id}`;
        const { data: { public: status } } = await this.getDataStore(url);
        commit('updateItemPublic', { id, status });
    },
    async sort({ rootState, rootGetters, dispatch }, payload) {
        // eslint-disable-next-line
        const { type } = rootState.Instructions;
        const belongs = type === 'admin' ? 'admin' : 'cabinet';
        const params = { ...payload, belongs };
        const url = `${rootGetters.API_PUBLIC_URL}/categories/sort`;
        // const listUrl = `${rootGetters.API_URL}/categories`;
        await this.postDataStore(url, params);
        await dispatch('list', { noChangeLoading: true });
        // const { data } = await this.getDataStore(listUrl);
        // commit('setProperty', { name: 'categories', value: data });
    },
    async delete({ rootGetters }, id) {
        const url = `${rootGetters.API_URL}/categories/${id}`;
        return await this.postDataStore(url, {}, 'DELETE');
    },
    async deleteCommon({ rootGetters }, id) {
        const url = `${rootGetters.API}/instruction_category/${id}`;
        return await this.postDataStore(url, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
