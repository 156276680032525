const actions = {
    async list({ rootGetters }, { query, params }) {
        const { courseId, type } = query;
        return await this.getDataStore(`${rootGetters.API_URL}/${type}/${courseId}/students`, params);
    }
};
export default {
    namespaced: true,
    actions
};
