import { mixinMutations } from '@/store/mixins';
import defaultState from './entities/defaultState';
const state = defaultState();
const mutations = { ...mixinMutations() };
const actions = {
    async getTypes({ rootGetters }, { params }) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_types`, { withCount: 'courses', ...params });
    },
    async createType({ rootGetters }, body) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_types`, body, 'POST');
    },
    async updateType({ rootGetters }, { id, ...body }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_types/${id}`, body, 'PUT');
    },
    async deleteType({ rootGetters }, { id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_types/${id}`, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
