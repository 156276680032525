const actions = {
    async getCoursesList({ rootGetters }, params) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses`, params);
    },
    async getSchoolsList({ rootGetters }, params) {
        return await this.getDataStore(`${rootGetters.API_URL}/users`, {
            only: 'name,surname,email',
            ...params
        });
    },
    async generateReport({ rootGetters }, params) {
        return await this.postDataStore(`${rootGetters.API_URL}/users_generate`, params);
    },
    async requestForFile({ rootGetters }, { user_id, file_name }) {
        return await this.getDataStore(`${rootGetters.API_URL}/users/${user_id}/users_generated_file`, { file_name });
    }
};
export default {
    namespaced: true,
    actions
};
