import { mixinMutations } from '@/store/mixins';
const mutations = { ...mixinMutations() };
const actions = {
    // Banners
    async getBanners({ rootGetters }, { params = {} } = {}) {
        return await this.getDataStore(`${rootGetters.API_URL}/public_page_banners`, { ...params });
    },
    async getBanner({ rootGetters }, { id, params = {} } = {}) {
        return await this.getDataStore(`${rootGetters.API_URL}/public_page_banners/${id}`, { ...params });
    },
    async createBanner({ rootGetters }, data = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/public_page_banners`, data);
    },
    async updateBanner({ rootGetters }, { id, body = {} } = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/public_page_banners/${id}`, body, 'PUT');
    },
    async multiUpdateBanners({ rootGetters }, { public_page_id, public_page_banners_id } = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/public_page_banners/multi_sort`, { public_page_id, public_page_banners_id });
    },
    async deleteBanner({ rootGetters }, id) {
        return this.postDataStore(`${rootGetters.API_URL}/public_page_banners/${id}`, {}, 'DELETE');
    },
    // Pages/Views
    async getPages({ rootGetters }, { params = {} } = {}) {
        return await this.getDataStore(`${rootGetters.API_URL}/public_pages`, { ...params });
    },
    async getPage({ rootGetters }, id) {
        return await this.getDataStore(`${rootGetters.API_URL}/public_pages/${id}`, { with: 'banners' });
    },
    async createPage({ rootGetters }, data = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/public_pages`, data);
    },
    async updatePage({ rootGetters }, { id, body = {} } = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/public_pages/${id}`, body, 'PUT');
    },
    async doActionPage({ rootGetters }, { id, type, public_page_banners_id } = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/public_pages/${id}/${type}`, { public_page_banners_id });
    },
    async deletePage({ rootGetters }, id) {
        return this.postDataStore(`${rootGetters.API_URL}/public_pages/${id}`, {}, 'DELETE');
    },
    // Image
    async deleteImage({ rootGetters }, { id, media_id } = {}) {
        return this.postDataStore(`${rootGetters.API_URL}/public_page_banners/${id}/delete_media/${media_id}`, {});
    }
};
export default {
    namespaced: true,
    mutations,
    actions
};
