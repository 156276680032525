import defaultState from './entities/defaultState';
import { mixinMutations } from '@/store/mixins';
import CoursesListServices from './services';
const CoursesListService = new CoursesListServices();
const state = defaultState();
const getters = {
    isDesc() {
        return state.isDesc;
    }
};
const mutations = {
    ...mixinMutations(),
    setCourses(state, payload) {
        state.courses = payload;
    },
    pushCourses(state, payload) {
        state.courses = [...state.courses, ...payload];
    },
    setFirstPage(state) {
        state.courses = [];
        state.page = 1;
        state.per_page = 20;
        state.stopLoading = true;
        state.finishLoading = false;
    },
    clearSelected(state) {
        state.selectedCourses = new Set();
        state.courses.forEach((item) => item.selected = false);
    },
    setNewHeaders(state, payload) {
        const currentHeadersLength = state.headers.length;
        const headersValues = state.headers.map((item) => item.value);
        const headersCustomValues = headersValues.filter((value) => String(value).includes('custom_field_course_'));
        const newHeaders = payload.map(({ id, name, type, multiple, subtype }, index) => ({
            text: name,
            value: `custom_field_course_${id}`,
            sortable: !['list', 'avatar', 'file', 'text', 'telegram_id'].includes(type) && !multiple,
            type,
            sort: index + currentHeadersLength,
            checked: false,
            multiple,
            subtype,
            componentName: 'td-custom'
        }));
        const newHeadersValues = newHeaders.map((item) => item.value);
        const actualHeaders = state.headers.filter(({ value }) => !headersCustomValues.includes(value) || newHeadersValues.includes(value));
        state.headers = [
            ...actualHeaders,
            ...newHeaders.filter((item) => !headersValues.includes(item.value))
        ];
        state.allHeaders = [
            ...state.headers.map(({ text, value, type, sort, multiple, subtype }) => ({
                text, value, type, sort, multiple, subtype
            }))
        ];
    },
    setFilterCategories(state, payload) {
        const currentHeadersLength = state.headers.length;
        state.filterCategories = payload.map(({ id, title }, index) => ({
            text: title,
            value: `category_${id}`,
            type: 'list',
            sort: index + currentHeadersLength,
            multiple: true,
            subtype: 'category'
        }));
        state.allHeaders = [
            ...state.allHeaders,
            ...state.filterCategories
        ];
    },
    changeFavorite(state, { course_id, isFavorite }) {
        const targetIndex = state.courses.findIndex(({ id, entity_type }) => id === course_id && entity_type !== 'Подборка курсов');
        const targetCourse = state.courses[targetIndex];
        state.courses = [
            ...state.courses.slice(0, targetIndex),
            { ...targetCourse, auth_user_favourites_exists: !!isFavorite },
            ...state.courses.slice(targetIndex + 1)
        ];
    }
};
const actions = {
    async getCourses({ rootGetters, commit, state }, payload = {}) {
        commit('setProperty', { name: 'loading', value: true });
        const { page, per_page } = state;
        const params = {
            page,
            per_page,
            with: 'subcategoriesWithCategories',
            entity_type: 'course',
            ...payload.query,
            ...payload.params
        };
        const res = await this.getDataStore(`${rootGetters.API_URL}/courses_fast_edit`, params);
        commit('setProperty', { name: 'loading', value: false });
        return res;
    },
    async updateCourseFormValues({ rootGetters }, { course_id, custom_field_value_items }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/custom_field_course_values_multiple`, { custom_field_value_items });
    },
    async setOrPushCourses({ commit, dispatch }, { push = false, payload = {} }) {
        const { data } = await dispatch('getCourses', payload);
        const commitName = !push ? 'setCourses' : 'pushCourses';
        commit(commitName, data);
        return data;
    },
    async addCourses({ commit, state, dispatch }, { payload = {} }) {
        if (state.finishLoading)
            return;
        commit('setProperty', { name: 'stopLoading', value: false });
        const data = await dispatch('setOrPushCourses', { push: true, payload });
        if (!data.length) {
            commit('setProperty', { name: 'finishLoading', value: true });
            return;
        }
        commit('setProperty', { name: 'stopLoading', value: true });
    },
    async sortCourses({ state, commit, dispatch }, { sortBy = '', payload }) {
        const { sortedBy, isDesc } = state;
        const desc = sortedBy === sortBy ? !isDesc : true;
        commit('setFirstPage');
        commit('setProperty', [{ name: 'sortedBy', value: sortBy }, { name: 'isDesc', value: desc }]);
        const { data } = await dispatch('getCourses', payload);
        commit('setCourses', data);
    },
    async doAction({ commit, state }, { action, folder_id }) {
        const actionObject = state.options.find((item) => item.value === action);
        const { selectedCourses } = state;
        const { url } = actionObject;
        const courses_id = [...selectedCourses].filter(({ entity_type }) => entity_type === 'Курс').map(({ id }) => id);
        const courses_compilations_id = [...selectedCourses].filter(({ entity_type }) => entity_type === 'Подборка курсов').map(({ id }) => id);
        const requestsVocabulary = {
            add_to_folder: async () => await CoursesListService.add_to_folder({ courses_id, courses_compilations_id, folder_id }),
            remove_from_folder: async () => await CoursesListService.remove_from_folder({ courses_id, courses_compilations_id }),
            default: async () => await CoursesListService.doOtherActionInCoursesTable({ courses_id, courses_compilations_id, url })
        };
        const request = requestsVocabulary[action] || requestsVocabulary.default;
        await request();
        commit('clearSelected');
        return {};
    },
    async sendPropertyAjax({ rootGetters }, { name, value, course_id }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}`;
        const response = await this.postDataStore(url, { [name]: value }, 'PUT');
        return response;
    },
    async getCategories({ commit }) {
        const categories = await CoursesListService.getCategories();
        commit('setFilterCategories', categories);
    },
    async getCustomFields({ commit, dispatch }) {
        commit('setProperty', { name: 'fieldsLoading', value: true });
        const fields = await CoursesListService.getCustomFields();
        commit('setNewHeaders', fields);
        await dispatch('getCategories');
        commit('setProperty', { name: 'fieldsLoading', value: false });
    },
    async getCoursesListForTable(_, { query, params }) {
        return await CoursesListService.getCoursesListForTable({ query, params });
    },
    // Course Folders
    async getFoldersList({ commit }) {
        const { data, errors } = await CoursesListService.getFoldersList();
        if (errors)
            return { errors };
        commit('setProperty', { name: 'courseFolders', value: data });
        return { data };
    },
    async createFolder(_, payload) {
        return await CoursesListService.createFolder(payload);
    },
    async updateFolder(_, { id, payload }) {
        return CoursesListService.updateFolder(id, payload);
    },
    async deleteFolder(_, id) {
        return await CoursesListService.deleteFolder(id);
    },
    // Favorite
    async attachToFavorite({ commit }, { course_id }) {
        const result = CoursesListService.changeFavorite(course_id, true);
        commit('changeFavorite', { course_id, isFavorite: true });
        return result;
    },
    async detachFromFavorite({ commit }, { course_id }) {
        const result = CoursesListService.changeFavorite(course_id, false);
        commit('changeFavorite', { course_id, isFavorite: false });
        return result;
    },
    async checkReportReady(_, fileName) {
        return await CoursesListService.checkReportReady(fileName);
    },
    async getReportsList() {
        return await CoursesListService.getReportsList();
    },
    async generateReport(_, filters) {
        return await CoursesListService.generateReport(filters);
    },
    async getHistory({ rootGetters }, { course_id, params } = {}) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/audits`, params);
    },
    async getComment({ rootGetters, commit }, { id }) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API}/course_field_comments/${id}`, { with: 'user' });
        if (!errors)
            commit('setComment', data);
        return { data, errors };
    },
    async getCommentList({ rootGetters }, { params } = {}) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_field_comments`, params);
    },
    async createComment({ rootGetters }, params = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_field_comments`, params, 'POST');
    },
    async updateComment({ rootGetters }, { id, ...params }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/course_field_comments/${id}`, params, 'PUT');
        return { data, errors };
    },
    async deleteComment({ rootGetters }, { id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_field_comments/${id}`, {}, 'DELETE');
    },
    async multipleUpdate({ rootGetters }, { isCustom, ...params }) {
        const url = isCustom ? 'courses/custom_field_course_values_multiple' : 'courses/values_multiple';
        return await this.postDataStore(`${rootGetters.API_URL}/${url}`, params, 'POST');
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
