import { mixinMutations } from '@/store/mixins';
import defaultState from '@/store/modules/news/defaultState';
const state = {
    ...defaultState(), newsCounter: 0, tags: [], filter: 'all'
};
const mutations = { ...mixinMutations() };
const actions = {
    async getNewsCount({ commit, rootGetters }) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/matetech_news/not_viewed_news_count`);
        if (errors)
            return;
        commit('setProperty', { name: 'newsCounter', value: data });
    },
    async getNewsTags({ commit, rootGetters }) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/matetech_news_tags`);
        if (errors)
            return;
        commit('setProperty', { name: 'tags', value: data });
    },
    async getViewedUsers({ rootGetters }, { id, search = '', page = 1, per_page = 20 }) {
        return await this.getDataStore(`${rootGetters.API_URL}/matetech_news/${id}/viewed_users`, {
            only: 'name,surname,email,photo_color,viewed_at',
            desc: 1,
            search,
            page,
            per_page
        });
    },
    async getNews({ state, commit, rootGetters }, { search = '', noSetFullLoad } = {}) {
        const { fullLoaded, page, filter, per_page, news } = state;
        if (fullLoaded)
            return;
        const params = {
            with: 'tags,viewedUsers',
            desc: 1,
            withCount: 'viewedUsers',
            isViewedAuthUser: 1,
            viewedUsersLimit: 2,
            page,
            per_page,
            search
        };
        const filtersParams = {
            read: 1,
            new: 0
        };
        if (filtersParams[filter] !== undefined)
            params.isViewed = filtersParams[filter];
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/matetech_news`, params);
        if (errors)
            return { data, errors };
        if (!data.length && !noSetFullLoad) {
            commit('setProperty', { name: 'fullLoaded', value: true });
            return { data, errors };
        }
        commit('setProperty', { name: 'news', value: [...news, ...data] });
        return { data, errors };
    },
    async createNews({ rootGetters }, payload) {
        return await this.postDataStore(`${rootGetters.API_URL}/matetech_news`, payload);
    },
    async updateNews({ rootGetters }, { form, id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/matetech_news/${id}`, form, 'PUT');
    },
    async readNews({ state, commit, dispatch, rootGetters }, { id, user }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/matetech_news/${id}/view`, {});
        if (errors)
            return { errors };
        dispatch('getNewsCount');
        const { filter, news } = state;
        if (filter === 'new')
            return { data };
        const updateIndex = news.findIndex((news) => news.id === id);
        const updatedNewsItem = {
            ...news[updateIndex],
            viewed_auth_user: true,
            viewed_users: [...news[updateIndex].viewed_users, user],
            viewed_users_count: news[updateIndex].viewed_users_count + 1
        };
        const updatedNews = [...news.slice(0, updateIndex), updatedNewsItem, ...news.slice(updateIndex + 1)];
        commit('setProperty', { name: 'news', value: updatedNews });
        return { data };
    },
    async deleteNews({ rootGetters, dispatch }, { id }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/matetech_news/${id}`, {}, 'DELETE');
        if (errors)
            return { errors };
        dispatch('getNewsCount');
        return { data };
    },
    async clearState({ commit }) {
        commit('clearState', defaultState());
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
