class Api {
    constructor() {
        this.baseURL = '';
    }
    initialize({ getData, postData, API_URL }) {
        this.getData = getData;
        this.postData = postData;
        this.baseURL = API_URL;
    }
}
const instanceApi = new Api();
export default instanceApi;
