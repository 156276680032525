import { mixinActions, mixinMutations } from '@/store/mixins';
const state = {
    baseUrl: '',
    layers: []
};
const getters = {
    getApiURL(state, getters, rootState, rootGetters) {
        return rootGetters.API_URL;
    }
};
const mutations = { ...mixinMutations() };
const actions = {
    ...mixinActions(),
    async getBlocks({ rootGetters, state, commit }, payload = {}) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/${state.baseUrl}/lesson_with_blocks`, {
            with: 'blockSubLayers:id;test:id,block_id;otherMedia;master',
            withExists: 'webinarData',
            sort: 'sort_order',
            ...payload
        });
        commit('lessonPage/setProperty', { name: 'blocks', value: data.sort((a, b) => a.sort - b.sort) }, { root: true });
    },
    async getBlockTest({ rootGetters }, id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/tests/${id}`, { with: 'questions' });
        return data;
    },
    async createBlock({ state, rootGetters }, params) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/${state.baseUrl}/lesson_blocks`, params, 'POST');
        return data;
    },
    async deleteBlock({ rootGetters, dispatch }, block_id) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/${state.baseUrl}/lesson_blocks/${block_id}`, {}, 'DELETE');
        if (data) {
            dispatch('removeConstructorCloseCondition', { block_id });
        }
        return data;
    },
    async updateBlock({ rootGetters, dispatch }, { block_id, params }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/${state.baseUrl}/lesson_blocks/${block_id}`, params, 'PUT');
        if (!data?.errors && params?.published_at === null) {
            dispatch('removeConstructorCloseCondition', { block_id });
        }
        return data;
    },
    async getWebinarLink({ rootGetters }, { course_id, lesson_id, block_id }) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/lessons/${lesson_id}/lesson_blocks/${block_id}/admin_webinar_link`);
    },
    async updateSorting({ rootGetters }, params) {
        return await this.postDataStore(`${rootGetters.API_URL}/${state.baseUrl}/lesson_blocks/multi_update`, params, 'POST');
    },
    async getLayers({ rootGetters, commit }, course_id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/block_layers`, {
            with: 'sortedSubLayers:title,type,sort_order',
            sort: 'sort_order',
            only: 'title,type,sort_order',
            all: 1
        });
        const layers = data
            .map(({ sorted_sub_layers, ...rest }) => ({ ...rest, sub_layers: sorted_sub_layers }))
            .filter(({ sub_layers }) => sub_layers && sub_layers.length);
        commit('setProperty', { name: 'layers', value: layers });
        return layers;
    },
    async attachOrDetachLayer({ rootGetters }, { course_id, action, layer_id, lesson_block_id }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/block_layers/${layer_id}/lesson_block_${action}`;
        return await this.postDataStore(url, { lesson_block_id }, 'POST');
    },
    async removeConstructorCloseCondition({ dispatch, rootState, commit }, { block_id }) {
        // eslint-disable-next-line
        const check_criterion = rootState.lessonPage.check_criterion;
        if (check_criterion !== 'constructor')
            return;
        // eslint-disable-next-line
        const constructor_close_condition = rootState.lessonPage.constructor_close_condition;
        if (constructor_close_condition?.homework?.blocks) {
            constructor_close_condition.homework.blocks = constructor_close_condition.homework.blocks.filter((homework) => homework.block_id !== block_id);
        }
        if (constructor_close_condition?.test?.blocks) {
            constructor_close_condition.test.blocks = constructor_close_condition.test.blocks.filter((test) => test.block_id !== block_id);
        }
        commit('lessonPage/setProperty', { name: 'constructor_close_condition', value: constructor_close_condition }, { root: true });
        dispatch('lessonPage/sendPropertyAjax', { name: 'constructor_close_condition', value: constructor_close_condition }, { root: true });
    },
    async getZoomUsers({ rootGetters }) {
        try {
            const { data } = await this.getDataStore(`${rootGetters.API_URL}/company_integrations/zoom/users`);
            return data.map((item) => {
                return {
                    ...item,
                    display_name: `${item.display_name} ${item.email}`
                };
            });
        }
        catch (e) {
            return [];
        }
    },
    async createZoomMeeting({ rootGetters }, payload) {
        const { email, course_id, lesson_id, block_id } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/lessons/${lesson_id}/lesson_blocks/${block_id}/zoom/create_meeting`, {
            email
        }, 'POST');
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
