const defaultState = () => ({
    id: 0,
    company_name: '',
    domain: '',
    company_files: [],
    video_preview_image_id: null,
    firstLink: null,
    yoo_kassa_ready: false,
    view_course_on_full_profile: false,
    /// временно бля будущей интеграции с битриксом
    bitixScripts: [],
    bitrixElements: [],
    menuParams: {},
    verification_account_types: null,
    use_verification_account_type: false,
    loading: true
});
export default defaultState;
