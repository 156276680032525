import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import ru from 'vuetify/src/locale/ru';
Vue.use(Vuetify);
const opts = {
    lang: {
        locales: { ru },
        current: 'ru'
    },
    icons: { iconfont: 'md' },
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#2C66F5',
                secondary: '#9AA6CA',
                lightblue: '#4D7CFE',
                warning: '#FF974A',
                info: '#7F8FA4'
            }
        }
    }
};
// @ts-ignore
export default new Vuetify(opts);
