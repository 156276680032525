import headers from '@/store/modules/customField/enteties/variablesTableHeaders';
const defaultTableState = () => ({
    customFieldId: null,
    headers,
    items: [],
    itemsTotal: 1,
    perPage: 5,
    page: 1,
    isLoading: false,
    search: '',
    footerProps: {
        'items-per-page-text': 'Показывать по',
        'items-per-page-options': [5, 10, 15]
    }
});
export default defaultTableState;
