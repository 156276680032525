import { mixinMutations } from '@/store/mixins';
import defaultState from '@/store/modules/breadcrumbs/defaultState';
const state = defaultState();
const mutations = { ...mixinMutations() };
const actions = {
    set({ commit }, payload) {
        commit('setProperty', { name: 'items', value: payload });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
