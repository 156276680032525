import { __decorate, __metadata } from "tslib";
import { Component, Vue, Prop } from 'nuxt-property-decorator';
let ProgressBar = class ProgressBar extends Vue {
};
__decorate([
    Prop({
        type: Boolean,
        default: () => false
    }),
    __metadata("design:type", Object)
], ProgressBar.prototype, "loading", void 0);
__decorate([
    Prop({
        type: String,
        default: () => '#2F75EC'
    }),
    __metadata("design:type", Object)
], ProgressBar.prototype, "color", void 0);
ProgressBar = __decorate([
    Component
], ProgressBar);
export default ProgressBar;
