import { mixinMutations } from '@/store/mixins';
const state = () => ({
    opened: false,
    url: null,
    items: [],
    instruction: null,
    type: 'admin'
});
const getters = {
    typePrefix(state) {
        return state.type === 'admin' ? 'admin/' : 'public/';
    }
};
const mutations = { ...mixinMutations() };
const actions = {
    toggle({ commit, dispatch, state }, payload) {
        const status = payload ?? !state.opened;
        if (status) {
            commit('setProperty', { name: 'opened', value: status });
        }
        else {
            dispatch('close');
        }
    },
    open({ commit, state }, payload) {
        !state.opened && commit('setProperty', { name: 'opened', value: true });
        commit('setProperty', [
            { name: 'instruction', value: payload },
            { name: 'url', value: payload }
        ]);
    },
    close({ commit }) {
        commit('setProperty', [
            { name: 'instruction', value: null },
            { name: 'url', value: null },
            { name: 'opened', value: false }
        ]);
    },
    backToCategory({ state, commit }) {
        const { url } = state;
        url.instruction = null;
        commit('setProperty', { name: 'url', value: url });
    },
    backToHome({ state, commit }) {
        const { url } = state;
        url.category = null;
        url.instruction = null;
        commit('setProperty', { name: 'url', value: url });
    },
    async list({ state, rootGetters }) {
        const { type } = state;
        const belongs = type === 'admin' ? 'admin' : 'cabinet';
        const params = { belongs };
        const url = `${rootGetters.API_URL}/instructions`;
        return await this.getDataStore(url, params);
    },
    async listByPage({ state, rootGetters }, { slug }) {
        const { type } = state;
        const belongs = type === 'admin' ? 'admin' : 'cabinet';
        const params = { belongs };
        const url = `${rootGetters.API_PUBLIC_URL}/instructions/bypage/${slug}`;
        const { data } = await this.getDataStore(url, params);
        return data.filter((item) => !!item.public && !!item.pinned && (!!item.common || !item.common && item.company_id === rootGetters.COMPANY_ID));
    },
    async search({ rootGetters, state }, word) {
        const { type } = state;
        const belongs = type === 'admin' ? 'admin' : 'cabinet';
        const params = { word, belongs, public: '1' };
        const url = `${rootGetters.API_PUBLIC_URL}/instructions`;
        const { data } = await this.getDataStore(url, params);
        return data;
    },
    async increment({ rootGetters }, id) {
        const url = `${rootGetters.API_URL}/instructions/${id}`;
        return await this.getDataStore(url, { increment: 1 });
    },
    async get({ dispatch, rootGetters }, { id, increment = false, user_id = null }) {
        const url = `${rootGetters.API}/instruction/${id}`;
        const params = user_id ? { user_id } : {};
        const { data } = await this.getDataStore(url, params);
        if (increment) {
            dispatch('increment', id);
        }
        return data;
    },
    async create({ rootGetters, dispatch, state }, payload) {
        const { type } = state;
        const belongs_to = type === 'admin' ? 'admin' : 'cabinet';
        const params = { ...payload, belongs_to };
        const url = `${rootGetters.API_URL}/instructions`;
        const response = await this.postDataStore(url, params);
        const { data: { category_id } } = response;
        dispatch('InstructionCategories/updateCategoryInList', { id: category_id }, { root: true });
        return response;
    },
    async update({ rootGetters, dispatch }, params) {
        const { id } = params;
        const url = `${rootGetters.API_URL}/instructions/${id}`;
        const response = await this.postDataStore(url, params, 'PUT');
        const { data: { category_id } } = response;
        dispatch('InstructionCategories/updateCategoryInList', { id: category_id }, { root: true });
        return response;
    },
    async like({ rootGetters }, params) {
        const { id, like } = params;
        const url = `${rootGetters.API}/likes/instruction/${id}/param/${like}`;
        return await this.postDataStore(url, params);
    },
    async delete({ rootGetters }, id) {
        const url = `${rootGetters.API_URL}/instructions/${id}`;
        return await this.postDataStore(url, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
