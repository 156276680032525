import { mixinMutations } from '@/store/mixins';
import defaultState from '@/store/modules/company/defaultState';
import API from '@/api/ServiceApi';
import { getData, postData } from '@/api';
const state = defaultState();
const getters = {
    api(state) {
        return `/api/companies/${state.id}`;
    },
    apiUrl() {
        return '/api/companies';
    },
    addMediaUrl() {
        return `/api/companies/${state.id}/add_media`;
    },
    deleteMediaUrl() {
        return `/api/companies/${state.id}/delete_media`;
    },
    defaultPosterImageUrl(state) {
        const posterImage = state.company_files.find((file) => file.id === state.video_preview_image_id);
        return posterImage?.link || '';
    }
};
const mutations = { ...mixinMutations() };
const actions = {
    async get({ getters, commit }, id = null) {
        commit('setProperty', { name: 'loading', value: true });
        const res = await this.getDataStore(!id ? getters.api : `${getters.apiUrl}/${id}`);
        if (res.errors)
            return res.errors;
        commit('setState', res.data);
        commit('setProperty', { name: 'loading', value: false });
        return res;
    },
    async update({ getters }, params) {
        return await this.postDataStore(getters.api, params, 'PUT');
    },
    async getByCode({ commit, rootGetters }, code) {
        const params = { code };
        const url = '/api/company/get_id_by_code';
        const { data } = await this.getDataStore(url, params);
        commit('setState', data);
        commit('setProperty', { name: 'company_name', value: code });
        API.initialize({ getData, postData, API_URL: rootGetters.API_URL });
        return data;
    },
    async deleteMedia({ getters }, id) {
        await this.postDataStore(`${getters.deleteMediaUrl}/${id}`, {});
    },
    async getDomainSetting({ getters }) {
        return await this.getDataStore(`${getters.api}/self_domain`);
    },
    async updateDomainSetting({ getters }, params) {
        return await this.postDataStore(`${getters.api}/self_domain`, params);
    },
    async deleteDomainSettings({ getters }, id) {
        return await this.postDataStore(`${getters.api}/self_domain/${id}`, {}, 'DELETE');
    },
    async getFormData(_, { companyId, formId }) {
        return await this.getDataStore(`/api/public/companies/${companyId}/admin/forms/${formId}`);
    },
    async sendRegistrationForm(_, { companyId, formId, fieldsModel }) {
        return await this.postDataStore(`/api/public/companies/${companyId}/admin/forms/${formId}`, fieldsModel);
    },
    async subscriptionGetStatus({ rootGetters }) {
        const url = `${rootGetters.API_URL}/subscription_check`;
        return await this.getDataStore(url);
    },
    async updatePaySettings({ rootGetters }, payload) {
        const url = `${rootGetters.API_URL}`;
        return await this.postDataStore(url, payload, 'PUT');
    },
    async acceptPayForAll({ rootGetters }, payload) {
        const url = `${rootGetters.API_URL}/apply_can_be_bought`;
        return await this.postDataStore(url, payload, 'POST');
    },
    async checkYooKassaData({ rootGetters }) {
        const url = `${rootGetters.API_URL}/actualize_yoo_client`;
        return await this.getDataStore(url);
    },
    async getYooKassaData({ rootGetters }) {
        const url = `${rootGetters.API_URL}/yoo_kassa_config`;
        return await this.getDataStore(url);
    },
    async subscriptionUpdateStatus({ rootGetters }, payload) {
        const url = `${rootGetters.API_URL}/subscription_update`;
        return await this.postDataStore(url, payload);
    },
    async listSubscribers({ rootGetters }, payload) {
        const { params } = payload;
        const url = `${rootGetters.API_URL}/subscription_users`;
        return await this.getDataStore(url, params);
    },
    async getSocial({ rootGetters }) {
        return await this.getDataStore(`${rootGetters.API_URL}/company_socialite/show`);
    },
    async changeSocial({ rootGetters }, payload) {
        return await this.postDataStore(`${rootGetters.API_URL}/company_socialite/change`, payload, 'POST');
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
