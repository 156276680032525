const defaultState = () => ({
    tabs: [
        {
            text: 'Разделы',
            value: 'sections'
        },
        {
            text: 'Баннеры',
            value: 'banners'
        },
        {
            text: 'Истории',
            value: 'stories'
        }
    ],
    currentTab: {
        text: 'Разделы',
        value: 'sections'
    },
    filterCategories: []
});
export default defaultState;
