import { BASE_URL } from '@/api';
import defaultState from './defaultState';
import { mixinMutations } from '@/store/mixins';
const state = defaultState();
const getters = {
    dropzoneURLBase(state, getters, _, { API_URL }) {
        return {
            BASE_URL,
            API_URL
        };
    }
};
const mutations = { ...mixinMutations() };
const actions = {
    // Stories
    async getStoriesList({ rootGetters }, { params = {} } = {}) {
        return await this.getDataStore(`${rootGetters.API_URL}/stories`, params);
    },
    async createStory({ rootGetters }, payload = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/stories`, payload);
    },
    async updateStory({ rootGetters }, { id, payload = {} } = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/stories/${id}`, payload, 'PUT');
    },
    async storiesMultiUpdate({ rootGetters }, { payload = [] } = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/stories/multi_sort`, { stories: payload });
    },
    async deleteStory({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/stories/${id}`, {}, 'DELETE');
    },
    async deleteStoryMedia({ rootGetters }, { id, storyId }) {
        return await this.postDataStore(`${rootGetters.API_URL}/stories/${storyId}/delete_media/${id}`, {}, 'DELETE');
    },
    // Sections
    async getSectionsList({ rootGetters, state, commit }, { params = {} } = {}) {
        const { data, meta, errors, used_filtering_entities } = await this.getDataStore(`${rootGetters.API_URL}/course_sections`, {
            ...params,
            filteringEntities: 1
        });
        if (errors)
            return { errors };
        commit('setProperty', { name: 'filterCategories', value: used_filtering_entities });
        const mappedData = data.map((item) => {
            if (item.type !== 'custom' || !item.get_parameters)
                return { ...item, filterCategories: [] };
            const categoryIds = JSON.parse(item.get_parameters)?.courseSubcategoryId.split(',');
            const categories = categoryIds.map((id) => state.filterCategories.find((category) => category.id === Number(id)));
            return { ...item, filterCategories: categories };
        });
        return { data: mappedData, meta };
    },
    async getSectionsForBanner({ rootGetters }) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_sections`, {
            only: 'name',
            all: 1
        });
    },
    async createSection({ rootGetters }, payload = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_sections`, payload);
    },
    async updateSection({ rootGetters }, { id, payload = {} } = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_sections/${id}`, payload, 'PUT');
    },
    async sectionsMultiUpdate({ rootGetters }, { payload = [] } = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_sections/multi_sort`, { course_sections: payload });
    },
    async deleteSection({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_sections/${id}`, {}, 'DELETE');
    },
    async deleteSectionMedia({ rootGetters }, { id, sectionId }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_sections/${sectionId}/delete_media/${id}`, {}, 'DELETE');
    },
    // Banners
    async getBannersList({ rootGetters }, { params = {} } = {}) {
        return await this.getDataStore(`${rootGetters.API_URL}/banners`, {
            with: 'courseSections',
            ...params
        });
    },
    async createBanner({ rootGetters }, payload = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/banners`, payload);
    },
    async updateBanner({ rootGetters }, { id, payload = {} } = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/banners/${id}`, payload, 'PUT');
    },
    async attachSectionsToBanner({ rootGetters }, { id, payload = {} } = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/banners/${id}/attach_multiple_course_sections`, payload, 'POST');
    },
    async deleteBanner({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/banners/${id}`, {}, 'DELETE');
    },
    async deleteBannerMedia({ rootGetters }, { id, bannerId }) {
        return await this.postDataStore(`${rootGetters.API_URL}/banners/${bannerId}/delete_media/${id}`, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
