import { mixinMutations } from '@/store/mixins';
const mutations = { ...mixinMutations() };
const actions = {
    // Groups
    async getCourseGroups({ rootGetters }, { params = {} } = {}) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_groups`, { ...params });
    },
    async createCourseGroup({ rootGetters }, groupName) {
        const { data: { id } } = await this.postDataStore(`${rootGetters.API_URL}/course_groups`, { name: groupName });
        return id;
    },
    async deleteCourseGroup({ rootGetters }, groupId) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_groups/${groupId}`, {}, 'DELETE');
    },
    // Types
    async getCourseTypes({ rootGetters }, { params = {} } = {}) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_group_attributes`, { ...params, sort: 'sort', with: 'values' });
    },
    async createCourseType({ rootGetters }, type) {
        const { id, ...form } = type;
        return await this.postDataStore(`${rootGetters.API_URL}/course_group_attributes`, form);
    },
    async updateCourseType({ rootGetters }, type) {
        const { id, ...form } = type;
        return await this.postDataStore(`${rootGetters.API_URL}/course_group_attributes/${id}`, form, 'PUT');
    },
    async attachTypeToGroup({ rootGetters }, { type_id, group_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_groups/${group_id}/attach_course_group_attribute`, { course_course_group_attribute_id: type_id });
    },
    async detachTypeToGroup({ rootGetters }, { type_id, group_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_groups/${group_id}/detach_course_group_attribute`, { course_course_group_attribute_id: type_id });
    },
    async deleteCourseType({ rootGetters }, typeId) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_group_attributes/${typeId}`, {}, 'DELETE');
    },
    async getTypeRelationCourses({ rootGetters }, typeId) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_group_attributes/${typeId}/get_relation_course_groups`);
    },
    async getPropRelationCourses({ rootGetters }, { type_id, prop_id }) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_group_attributes/${type_id}/values/${prop_id}/get_relation_course_groups`);
    },
    async sortCourseTypes({ rootGetters }, { payload }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_group_attributes/multi_sort`, { course_group_attributes: payload });
    },
    // Type Props
    async createCourseTypeProp({ rootGetters }, { type_id, name, sort }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_group_attributes/${type_id}/values`, { value: name, sort });
    },
    async updateCourseTypeProp({ rootGetters }, { type_id, prop_id, name, slug }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_group_attributes/${type_id}/values/${prop_id}`, { value: name, slug }, 'PUT');
    },
    async deleteCourseTypeProp({ rootGetters }, { type_id, prop_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_group_attributes/${type_id}/values/${prop_id}`, {}, 'DELETE');
    },
    async sortCourseTypeProp({ rootGetters }, { type_id, values }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_group_attributes/${type_id}/values/multi_sort`, { course_group_attribute_values: values });
    }
};
export default {
    namespaced: true,
    mutations,
    actions
};
