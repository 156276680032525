import defaultState from './entites/defaultState';
import { mixinMutations } from '@/store/mixins';
const state = defaultState();
const getters = {
    // Getter для дробления массива на чанки по N элементов (в данном случе по 2) c дополнительной сортировкой по order
    // На выходе получим [1,2,3,4,5,6] => [[1,2], [3,4], [5,6]]
    chunkedPlanTypes(state) {
        return [...state.planTypes].sort((a, b) => a.order - b.order).reduce((plans, plan, i) => {
            const chunkIndex = Math.floor(i / 2);
            plans[chunkIndex] = [].concat(plans[chunkIndex] || [], plan);
            return plans;
        }, []);
    }
};
const mutations = { ...mixinMutations() };
const actions = {
    async getDeals({ rootGetters, commit }) {
        const deals = await this.getDataStore(`${rootGetters.API_URL}/bitrix_report_manager/deal_categories`);
        if (deals?.length)
            commit('setProperty', { name: 'deals', value: deals });
    },
    async getLeads({ rootGetters, commit }) {
        const leads = await this.getDataStore(`${rootGetters.API_URL}/bitrix_report_manager/lead_categories`);
        if (leads?.length)
            commit('setProperty', { name: 'leads', value: leads });
    },
    async getPlanList({ rootGetters }, params = {}) {
        return await this.getDataStore(`${rootGetters.API_URL}/bitrix_report_manager/plans`, { ...params, per_page: 500 });
    },
    async getPlans({ rootGetters, commit }, params = {}) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/bitrix_report_manager/plans`, { ...params, per_page: 500 });
        if (!errors)
            commit('setProperty', { name: 'plans', value: data });
        return { data, errors };
    },
    async createPlan({ rootGetters }, params = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/bitrix_report_manager/plans`, params, 'POST');
    },
    async updatePlan({ rootGetters }, { id, ...params } = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/bitrix_report_manager/plans/${id}`, params, 'PUT');
    },
    async getPlanTypes({ rootGetters, commit }) {
        const planTypes = await this.getDataStore(`${rootGetters.API_URL}/bitrix_report_manager/plan_types`);
        if (planTypes?.length)
            commit('setProperty', { name: 'planTypes', value: planTypes.map((type) => ({ ...type, name: type.name.replaceAll('(', ' (') })) });
    },
    async getReports({ rootGetters, commit }, params = {}) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/bitrix_report_manager/managers`, { ...params, active: true });
        if (!errors)
            commit('setProperty', { name: 'reports', value: data });
        return { data, errors };
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
