import buildDate from '@/helpers/BuildDate';
const actions = {
    async list({ rootGetters }, { params, query }) {
        return await this.getDataStore(`${rootGetters.API_URL}/invites${query.inviteId ? `/${query.inviteId}/user_invites` : ''}`, params);
    },
    async getDoubleInvites({ rootGetters }, { params }) {
        return await this.getDataStore(`${rootGetters.API_URL}/invite_item`, params);
    },
    async updateDoubleInvite({ rootGetters }, { invite_id, params }) {
        return await this.postDataStore(`${rootGetters.API_URL}/invite_item/${invite_id}`, params, 'PUT');
    },
    async changeDoubleInvitesActive({ rootGetters }, { invite_item, active }) {
        return await this.postDataStore(`${rootGetters.API_URL}/invite_item/multi_update`, { invite_item, active });
    },
    async createOrUpdateDoubleInvite({ rootGetters }, { form, invite_id }) {
        const method = invite_id ? 'PUT' : 'POST';
        const url = `${rootGetters.API_URL}/invite_item${invite_id ? `/${invite_id}` : ''}`;
        const { date_start, date_end, emails } = form;
        const emailToSend = emails.map(({ email, roles, groups, tests, course_packages, course_compilations, id, bitrix_integration }) => {
            const result = {
                email,
                bitrix_integration: Number(bitrix_integration),
                role: roles.map((item) => (item.value ? item.value : item)),
                group: groups.map((item) => (item.value ? item.value : item)),
                test: tests.map((item) => (item.value ? item.value : item)),
                course_package: course_packages.map((item) => (item.value ? item.value : item)),
                course_compilation: course_compilations.map((item) => (item.value ? item.value : item))
            };
            if (id && typeof id === 'number') {
                result.id = id;
            }
            return result;
        });
        const invite = {
            name: form.name,
            description: form.description,
            active: true,
            date_start: buildDate(date_start, { monthType: 'dashedNumbers' }).rusDate.split(' ').reverse().join(''),
            date_end: date_end ? buildDate(date_end, { monthType: 'dashedNumbers' }).rusDate.split(' ').reverse().join('') : '',
            emails: emailToSend
        };
        return await this.postDataStore(url, invite, method);
    },
    async deleteDoubleInvite({ rootGetters }, invite_id) {
        return await this.postDataStore(`${rootGetters.API_URL}/invite_item/${invite_id}`, {}, 'DELETE');
    },
    async getInviteInfo({ rootGetters }, id) {
        return await this.getDataStore(`${rootGetters.API_URL}/invites/${id}`);
    },
    async getDoubleRoles({ rootGetters }) {
        return await this.getDataStore(`${rootGetters.API_URL}/invite_item/test_role`);
    },
    async activateInvite({ rootGetters }, payload) {
        const { id, active } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/invites/${id}`, { active }, 'PUT');
    },
    async activateInviteGroup({ rootGetters }, payload) {
        const { data, type } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/invites/${type}`, data);
    },
    async singleWelcomeActions({ rootGetters }, payload) {
        const { id, data, type } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/invites/${id}/user_invites/${type}`, data);
    },
    async createInvite({ rootGetters }, payload) {
        const { type, id, form: { name, description, course_compilations, user_limit, date_start, date_end, course_packages, roles, groups, tests, send_type, send_to, customFields, customFieldsRequired }, isUpdate } = payload;
        const invite = {
            name,
            description,
            active: true,
            send: true,
            type: type.slice(0, -1),
            date_start: buildDate(date_start, { monthType: 'dashedNumbers' }).rusDate.split(' ').reverse().join(''),
            date_end: date_end ? buildDate(date_end, { monthType: 'dashedNumbers' }).rusDate.split(' ').reverse().join('') : '',
            course_packages_id: course_packages.map((item) => (item.value ? item.value : item)),
            roles_id: roles.map((item) => (item.value ? item.value : item)),
            groups_id: groups.map((item) => (item.value ? item.value : item)),
            course_compilations_id: course_compilations.map((item) => (item.value ? item.value : item)),
            tests_id: tests.map((item) => (item.value ? item.value : item)),
            custom: customFields.map(({ value }) => ({ id: value, required: !!customFieldsRequired?.find((item) => item.value === value) ?? false }))
        };
        if (type === 'welcomes' && !isUpdate)
            send_type === 'phone' ? invite.phones = send_to : invite.emails = send_to;
        const method = isUpdate ? 'PUT' : 'POST';
        const url = `${rootGetters.API_URL}/invites${id ? `/${id}` : ''}`;
        const actions = {
            default: () => this.postDataStore(url, { ...invite, user_limit: Number(user_limit) }, method),
            welcomes: () => this.postDataStore(url, { ...invite, send_type }, method)
        };
        const action = actions[type] || actions.default;
        return await action();
    },
    async addUsersToWelcome({ rootGetters }, payload) {
        const { data, id } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/invites/${id}/user_invites/multi_store`, {
            send: 1,
            ...data
        });
    },
    async getInvite({ rootGetters }, id) {
        return await this.getDataStore(`${rootGetters.API_URL}/invites/${id}`, {
            with: 'roles:name;groups:name;courses:name;tests:title;courseCompilations;connectedUser:name;coursePackages:name;coursePackages.course:name;coursePackages.courseBranch:id,name',
            withCount: 'activatedUserInvites'
        });
    },
    async deleteSingleInvite({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/invites/${id}`, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    actions
};
