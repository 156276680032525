import { mixinMutations } from '@/store/mixins';
const mutations = { ...mixinMutations() };
const actions = {
    async getPages({ rootGetters }, { params = {} } = {}) {
        return await this.getDataStore(`${rootGetters.API_URL}/seo_pages`, { ...params });
    },
    async getPage({ rootGetters }, id) {
        return await this.getDataStore(`${rootGetters.API_URL}/seo_pages/${id}`);
    },
    async createPage({ rootGetters }, data = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/seo_pages`, data);
    },
    async updatePage({ rootGetters }, { id, body = {} } = { id: undefined }) {
        return await this.postDataStore(`${rootGetters.API_URL}/seo_pages/${id}`, body, 'PUT');
    },
    async deletePage({ rootGetters }, { id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/seo_pages/${id}`, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    mutations,
    actions
};
