var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, namespace, Prop, Vue, Watch } from 'nuxt-property-decorator';
const CommentsModule = namespace('Comments');
const ProfileModule = namespace('adminProfile');
let comment = class comment extends Vue {
    constructor() {
        super(...arguments);
        this.message = '';
        this.dataComment = null;
        this.currentComment = null;
        this.editableCommentId = 0;
    }
    setParams(comment) {
        const { creator, created_at, text, ...rest } = comment;
        const { id, name, surname, photo } = creator;
        const newComment = {
            ...rest,
            name,
            surname,
            photo,
            text,
            author_id: id,
            initials: `${name[0].toUpperCase()}${surname[0].toUpperCase()}`,
            fullName: `${name} ${surname}`,
            rusDate: `${this.$root.$buildDate(created_at).rusDate}`,
            comment_id: comment.id
        };
        this.$set(this, 'dataComment', newComment);
    }
    removeComment(comment) {
        const { order_id } = this.$route.params;
        this.deleteComment({
            order_id: Number(order_id),
            comment_id: comment.id
        });
    }
    showEdit(id) {
        this.$set(this, 'editableCommentId', 0);
        this.$nextTick(() => {
            this.hideEdit();
            this.$set(this, 'editableCommentId', id);
        });
    }
    onCommentChange(newVal) {
        this.setParams(newVal);
    }
    focusForm(comment) {
        this.$set(this, 'currentComment', comment);
    }
    blurForm() {
        this.$set(this, 'currentComment', null);
    }
    showForm(id) {
        this.setCurrentCommentId(id);
        this.$nextTick(() => this.$el.querySelector('.comment-inner__textarea').focus());
    }
    clear() {
        this.$set(this, 'message', '');
        this.setCurrentCommentId(0);
        this.$set(this, 'currentComment', null);
    }
    hideTooltip(ev) {
        if (!ev.target.closest('.comment__admin-actions')) {
            this.setEditCommentId(0);
        }
    }
    hideEdit() {
        this.$set(this, 'editableCommentId', 0);
    }
    submitComment(comment) {
        if (!this.message.length)
            return;
        const { comment_id, is_public } = comment;
        this.replyToComment({
            comment_id,
            is_public: Number(is_public),
            type: 'answer',
            message: this.message
        });
        this.clear();
    }
    changeComment(comment) {
        const { comment_id, text } = comment;
        this.updateComment({
            comment_id: Number(comment_id),
            text,
            order_id: Number(this.$route.params.order_id)
        });
        this.hideEdit();
    }
    submitToEnter(ev) {
        if (ev.key === 'Enter' && ev.shiftKey === false && this.currentComment) {
            ev.preventDefault();
            this.submitComment(this.currentComment);
        }
    }
    mounted() {
        document.addEventListener('click', this.hideTooltip);
        document.addEventListener('keyup', this.submitToEnter);
        this.setParams(this.comment);
    }
    destroyed() {
        document.removeEventListener('click', this.hideTooltip);
        document.removeEventListener('keyup', this.submitToEnter);
    }
};
__decorate([
    ProfileModule.State('id'),
    __metadata("design:type", Object)
], comment.prototype, "user_id", void 0);
__decorate([
    CommentsModule.Action('replyToComment'),
    __metadata("design:type", Object)
], comment.prototype, "replyToComment", void 0);
__decorate([
    CommentsModule.Mutation('setCurrentCommentId'),
    __metadata("design:type", Object)
], comment.prototype, "setCurrentCommentId", void 0);
__decorate([
    CommentsModule.Mutation('setEditCommentId'),
    __metadata("design:type", Object)
], comment.prototype, "setEditCommentId", void 0);
__decorate([
    CommentsModule.State('currentCommentId'),
    __metadata("design:type", Object)
], comment.prototype, "currentCommentId", void 0);
__decorate([
    CommentsModule.State('editCommentId'),
    __metadata("design:type", Object)
], comment.prototype, "editCommentId", void 0);
__decorate([
    CommentsModule.Action('deleteComment'),
    __metadata("design:type", Object)
], comment.prototype, "deleteComment", void 0);
__decorate([
    CommentsModule.Action('updateComment'),
    __metadata("design:type", Object)
], comment.prototype, "updateComment", void 0);
__decorate([
    Prop({}),
    __metadata("design:type", typeof (_a = typeof Comment !== "undefined" && Comment) === "function" ? _a : Object)
], comment.prototype, "comment", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: () => false
    }),
    __metadata("design:type", Boolean)
], comment.prototype, "banned", void 0);
__decorate([
    Watch('comment'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Comment !== "undefined" && Comment) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], comment.prototype, "onCommentChange", null);
comment = __decorate([
    Component
], comment);
export default comment;
