const headers = [
    {
        text: 'ID',
        value: 'id',
        checked: true,
        sort: 1,
        disabled: true,
        subtype: 'simple',
        type: 'integer'
    },
    {
        text: 'Пользователь',
        value: 'surname',
        componentName: 'td-user',
        class: 'th-user',
        checked: true,
        sort: 2,
        disabled: true,
        subtype: 'simple',
        type: 'list'
    },
    {
        text: 'Роль',
        value: 'roles',
        componentName: 'td-labels',
        checked: true,
        sortable: false,
        sort: 3,
        subtype: 'simple',
        type: 'list',
        multiple: true
    },
    {
        text: 'Группа',
        value: 'groups',
        componentName: 'td-labels',
        checked: true,
        sortable: false,
        sort: 4,
        subtype: 'simple',
        type: 'list',
        multiple: true
    },
    {
        text: 'Курсы',
        value: 'public_courses',
        componentName: 'td-labels',
        checked: false,
        sortable: false,
        sort: 5,
        subtype: 'simple',
        type: 'list',
        multiple: true
    },
    {
        text: 'Регистрация',
        value: 'created_at',
        componentName: 'td-date',
        checked: true,
        sort: 6,
        subtype: 'simple',
        type: 'date'
    },
    {
        text: 'Активность',
        value: 'last_login',
        componentName: 'td-date',
        checked: true,
        sort: 7,
        subtype: 'simple',
        type: 'date'
    },
    {
        text: 'Дата блокировки',
        value: 'login_block_date',
        componentName: 'td-date',
        checked: true,
        sort: 8,
        subtype: 'simple',
        type: 'date'
    },
    {
        text: 'Неудачных попыток',
        value: 'failed_login_count',
        checked: true,
        sort: 9,
        disabled: true,
        subtype: 'simple',
        type: 'integer'
    },
    {
        text: 'Редактировать',
        value: 'user_id',
        componentName: 'td-edit-user',
        checked: true,
        sortable: false,
        sort: 10,
        subtype: 'simple',
    }
];
export default headers;
