export default [
    {
        text: 'Информация о курсе',
        value: 'course-info',
        icon: ` <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7992 1.43982H10.0792V13.6798H10.7992V1.43982ZM12.9597 1.43982H12.2397V13.6798H12.9597V1.43982ZM4.12061 16.5218C3.88124 16.6415 3.59961 16.4674 3.59961 16.1998V14.0398C3.59961 13.841 3.76079 13.6798 3.95961 13.6798H6.83961C7.03843 13.6798 7.19961 13.841 7.19961 14.0398V16.1998C7.19961 16.4674 6.91798 16.6415 6.67861 16.5218L5.39961 15.8823L4.12061 16.5218ZM5.23848 15.1578L4.31948 15.6173V14.3998H6.47948V15.6173L5.56047 15.1578C5.45912 15.1071 5.33983 15.1071 5.23848 15.1578Z" fill="#437FEF"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6004 1.44H3.96037C3.3639 1.44 2.88037 1.92353 2.88037 2.52V13.32C2.88037 13.9165 3.3639 14.4 3.96037 14.4H12.6004C13.9921 14.4 15.1204 13.2718 15.1204 11.88V3.96C15.1204 2.56824 13.9921 1.44 12.6004 1.44ZM3.60066 2.51986C3.60066 2.32104 3.76183 2.15986 3.96066 2.15986H12.6007C13.5948 2.15986 14.4007 2.96575 14.4007 3.95986V11.8799C14.4007 12.874 13.5948 13.6799 12.6007 13.6799H3.96066C3.76183 13.6799 3.60066 13.5187 3.60066 13.3199V2.51986Z" fill="#437FEF"/>
              </svg>`
    },
    {
        text: 'FAQ',
        value: 'faq',
        icon: ` <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.49977 9.5C8.49977 8 9.68177 7.74897 10.0888 7.34296C10.4971 6.93564 10.7498 6.37232 10.7498 5.75C10.7498 4.50736 9.74241 3.5 8.49977 3.5C7.82783 3.5 7.22469 3.79454 6.81241 4.26156C6.62177 4.47751 6.47195 4.73033 6.375 5.00796" stroke="#437FEF" stroke-linecap="round"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 12.5C8.91421 12.5 9.25 12.1642 9.25 11.75C9.25 11.3358 8.91421 11 8.5 11C8.08579 11 7.75 11.3358 7.75 11.75C7.75 12.1642 8.08579 12.5 8.5 12.5Z" fill="#437FEF"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 15.5C12.6421 15.5 16 12.1421 16 8C16 3.85786 12.6421 0.5 8.5 0.5C4.35786 0.5 1 3.85786 1 8C1 12.1421 4.35786 15.5 8.5 15.5Z" stroke="#437FEF"/>
                    </svg>`
    }
    // {
    //   text: 'Участники',
    //   value: 'users',
    //   icon: ` <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    //           <path d="M0.833496 11C0.833496 9.125 3.3335 9.125 4.5835 7.875C5.2085 7.25 3.3335 7.25 3.3335 4.125C3.3335 2.04188 4.16662 1 5.8335 1C7.50037 1 8.3335 2.04188 8.3335 4.125C8.3335 7.25 6.4585 7.25 7.0835 7.875C8.3335 9.125 10.8335 9.125 10.8335 11" stroke="#437FEF" stroke-linecap="round"/>
    //           <path d="M9.99561 9.53592C10.4681 9.21028 11.0815 8.97583 11.6684 8.69314C12.1273 8.47212 12.57 8.22162 12.9167 7.875C13.5417 7.25 11.6667 7.25 11.6667 4.125C11.6667 2.04188 12.4998 1 14.1667 1C15.8335 1 16.6667 2.04188 16.6667 4.125C16.6667 7.25 14.7917 7.25 15.4167 7.875C16.6667 9.125 19.1667 9.125 19.1667 11" stroke="#437FEF" stroke-linecap="round"/>
    //       </svg>`
    // },
    //   {
    //     text: 'Комментарии',
    //     value: 'comments',
    //     icon: `<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    // <path d="M6.11791 12.941L6.24724 12.4581C6.12918 12.4264 6.00362 12.4392 5.89431 12.4938L6.11791 12.941ZM2.5 14.75L2.00503 14.6793C1.97872 14.8634 2.05678 15.0469 2.20764 15.1556C2.35851 15.2644 2.55727 15.2804 2.72361 15.1972L2.5 14.75ZM2.98992 11.3205L3.4849 11.3913C3.50895 11.2229 3.44578 11.0539 3.31722 10.9426L2.98992 11.3205ZM8.5 13.75C12.8084 13.75 16.5 10.9382 16.5 7.25H15.5C15.5 10.1892 12.4759 12.75 8.5 12.75V13.75ZM5.98859 13.424C6.77942 13.6358 7.6239 13.75 8.5 13.75V12.75C7.71071 12.75 6.9532 12.6471 6.24724 12.4581L5.98859 13.424ZM2.72361 15.1972L6.34152 13.3883L5.89431 12.4938L2.27639 14.3028L2.72361 15.1972ZM2.49495 11.2498L2.00503 14.6793L2.99497 14.8207L3.4849 11.3913L2.49495 11.2498ZM0.5 7.25C0.5 8.99025 1.33743 10.551 2.66262 11.6985L3.31722 10.9426C2.17201 9.95091 1.5 8.65175 1.5 7.25H0.5ZM8.5 0.75C4.1916 0.75 0.5 3.5618 0.5 7.25H1.5C1.5 4.31078 4.52412 1.75 8.5 1.75V0.75ZM16.5 7.25C16.5 3.5618 12.8084 0.75 8.5 0.75V1.75C12.4759 1.75 15.5 4.31078 15.5 7.25H16.5Z" fill="#437FEF"/>
    // </svg>`
    //   }
];
