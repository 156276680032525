const defaultState = () => ({
    filters: [],
    selectedFilters: [],
    courses: [],
    questions: [],
    viewType: 'simple',
    showOnlyDifference: false,
    showCommecrialOffer: false,
    searched: false,
    isCoursesLoading: false,
    all: false,
    expires: false,
    detailCourseModal: { id: null, show: false },
    detailBranchModal: { id: null, course_id: null, show: false },
    courseQuestionsModal: { id: null, show: false }
});
export default defaultState;
