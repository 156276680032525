import { __decorate } from "tslib";
import { Component, Vue } from 'nuxt-property-decorator';
let ConfirmModal = class ConfirmModal extends Vue {
    constructor() {
        super(...arguments);
        this.isDialogOpen = false;
        this.dialogText = '';
        this.dialogTitle = 'Внимание!';
        this.dialogSuccessBtn = 'Подтвердить';
        this.dialogCancelBtn = 'Отменить';
        this.dialogCallback = () => ({});
        this.defaultConfig = {
            title: 'Внимание!',
            successText: 'Подтвердить',
            cancelText: 'Отменить'
        };
    }
    openDialog(text, config = {}) {
        const mergeConfig = { ...this.defaultConfig, ...config };
        this.applyConfig(mergeConfig);
        this.dialogText = text;
        this.isDialogOpen = true;
        return new Promise((resolve) => {
            this.dialogCallback = resolve;
        });
    }
    applyConfig(config) {
        this.dialogTitle = config.title;
        this.dialogSuccessBtn = config.successText;
        this.dialogCancelBtn = config.cancelText;
    }
    closeDialog(value) {
        this.dialogCallback(value);
        this.isDialogOpen = false;
    }
};
ConfirmModal = __decorate([
    Component
], ConfirmModal);
export default ConfirmModal;
