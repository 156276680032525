import { mixinMutations } from '@/store/mixins';
const state = { shortPackages: [] };
const mutations = { ...mixinMutations() };
const actions = {
    async list({ rootGetters }, payload) {
        const { query, params = {} } = payload;
        const { course_id } = query;
        return await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_packages`, { ...params, withCount: 'users,invites,welcomes,courseCompilations' });
    },
    async getPackage({ rootGetters }, { course_id, package_id }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_packages/${package_id}`, { with_lessons_ids: 1 });
        return data;
    },
    async createPackage({ rootGetters }, { course_id, params }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_packages`, { ...params, with_lessons_ids: 1 }, 'POST');
        return data;
    },
    async removePackage({ rootGetters }, { course_id, package_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_packages/${package_id}`, {}, 'DELETE');
    },
    async updatePackage({ rootGetters }, { course_id, package_id, params }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_packages/${package_id}`, { ...params, with_lessons_ids: 1 }, 'PUT');
        return { data, errors };
    },
    async changePackageLessons({ rootGetters }, { course_id, package_id, lesson_id, action }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_packages/${package_id}/${action}_lessons`, { lesson_id });
        return data;
    },
    async getShortPackages({ rootGetters, commit }, course_id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_packages`, {
            only: 'name,is_default',
            all: 1
        });
        commit('setProperty', { name: 'shortPackages', value: data });
        return data;
    },
    async attachUsers({ rootGetters }, { course_id, package_id, user_id = [] }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_packages/${package_id}/attach_users`, { user_id });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
