const defaultConfig = {
    monthType: 'default',
    showTime: false,
    GMT: false,
    GTMPlus: false,
    showYear: true,
    isShortYear: false
};
export default (date, config = {}) => {
    if (!date)
        return null;
    const jsDate = new Date(date);
    const { monthType, showTime, showYear, GMT, GTMPlus, isShortYear } = { ...defaultConfig, ...config };
    const currentTimeZoneOffsetInHours = jsDate.getTimezoneOffset() / 60;
    if (GMT)
        jsDate.setHours(jsDate.getHours() - currentTimeZoneOffsetInHours);
    if (GTMPlus)
        jsDate.setHours(jsDate.getHours() + currentTimeZoneOffsetInHours);
    const monthVariants = {
        numbers: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        dashedNumbers: ['- 01 -', '- 02 -', '- 03 -', '- 04 -', '- 05 -', '- 06 -', '- 07 -', '- 08 -', '- 09 -', '- 10 -', '- 11 -', ' -12- '],
        short: ['Янв.', 'Фев.', 'Март', 'Апр.', 'Май', 'Июнь', 'Июль', 'Авг.', 'Сен.', 'Окт.', 'Нбр.', 'Дек.'],
        default: ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря']
    };
    const daysVariants = { default: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'] };
    const month = monthVariants[monthType || 'default'] || monthVariants.default;
    const minutes = jsDate.getMinutes() > 9 ? `${jsDate.getMinutes()}` : `0${jsDate.getMinutes()}`;
    const hours = jsDate.getHours() > 9 ? `${jsDate.getHours()}` : `0${jsDate.getHours()}`;
    const time = ` ${hours}:${minutes}`;
    let rusDate = jsDate.getDate() ? `${jsDate.getDate()} ${month[jsDate.getMonth()]} ${showYear ? isShortYear ? String(jsDate.getFullYear()).slice(2) : jsDate.getFullYear() : ''}${showTime ? time : ''}` : '-';
    if (monthType === 'numbers') {
        rusDate = jsDate.getDate() ? `${jsDate.getDate()}.${month[jsDate.getMonth()]}.${showYear ? isShortYear ? String(jsDate.getFullYear()).slice(2) : jsDate.getFullYear() : ''}${showTime ? time : ''}` : '-';
    }
    const weekday = daysVariants.default[jsDate.getDay()];
    const inTime = ` в ${hours}:${minutes}`;
    return {
        rusDate, jsDate, time, weekday, inTime
    };
};
