const statuses = [
    {
        text: 'Видимый',
        icon: 'remove_red_eye',
        value: 'visible',
        action_text: 'Сделать видимым'
    },
    {
        text: 'Скрытый',
        icon: 'lock',
        value: 'close',
        action_text: 'Скрыть'
    },
    {
        text: 'Черновик',
        icon: 'edit',
        value: 'draft',
        action_text: 'Черновик'
    },
    {
        text: 'Удаленный',
        icon: 'delete',
        value: 'deleted',
        action_text: 'Удалить'
    }
];
export default statuses;
