import { mixinMutations } from '@/store/mixins';
import defaultState from './entities/defaultState';
import { buildToday, buildMonthAgo, buildWeekAgo } from './entities/utils';
const state = defaultState();
const mutations = {
    ...mixinMutations(),
    setToday(state) {
        state.button_selected = 0;
        state.period_start_date = buildToday();
        state.period_end_date = buildToday();
    },
    setWeekAgo(state) {
        state.button_selected = 1;
        state.period_start_date = buildWeekAgo();
        state.period_end_date = buildToday();
    },
    setMonthAgo(state) {
        state.button_selected = 2;
        state.period_start_date = buildMonthAgo();
        state.period_end_date = buildToday();
    }
};
const actions = {};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
