import { __decorate, __metadata } from "tslib";
import { Component, namespace, Prop, Vue } from 'nuxt-property-decorator';
import { validationMixin } from 'vuelidate';
import { apiCheck, minLength, regEx, required } from '@/helpers/validate/customValidate';
import validateMixin from '@/helpers/validate/validateMixin';
import { getData, postData } from '@/api';
const CompanyModule = namespace('company');
let CreateCompanyModal = class CreateCompanyModal extends Vue {
    constructor() {
        super(...arguments);
        this.isBtnLoading = false;
        this.form = {
            name: '',
            description: '',
            code: ''
        };
    }
    async createCompany() {
        if (this.$v.$invalid) {
            this.$v.$touch();
            return;
        }
        this.isBtnLoading = true;
        const { data } = await postData('/api/companies', { ...this.form });
        this.isBtnLoading = false;
        this.changePage(data);
    }
    changePage(data) {
        const { code, id } = data;
        this.$router.push({ name: 'settings', params: { company_name: code } });
        this.setProperty({ name: 'id', value: id });
    }
    async checkCodeUnique() {
        if (!(this.$v.form.code.minLength && this.$v.form.code.regEx))
            return true;
        const { data } = await getData('/api/company/check_code', { code: this.form.code });
        return data;
    }
    closeModal() {
        this.$emit('close');
    }
    successModal() {
        this.createCompany();
    }
};
__decorate([
    CompanyModule.Mutation('setProperty'),
    __metadata("design:type", Object)
], CreateCompanyModal.prototype, "setProperty", void 0);
__decorate([
    CompanyModule.Getter('addMediaUrl'),
    __metadata("design:type", Object)
], CreateCompanyModal.prototype, "uploadUrl", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Object)
], CreateCompanyModal.prototype, "isModalOpen", void 0);
CreateCompanyModal = __decorate([
    Component({
        components: { SingleDropzone: () => import('@/components/SingleDropzone.vue') },
        mixins: [validationMixin, validateMixin],
        validations() {
            return {
                form: {
                    name: { required: required('Обязательное поле') },
                    code: {
                        required: required('Обязательное поле'),
                        minLength: minLength(3, 'Требуется минимум 3 символа'),
                        regEx: regEx(/^([a-z\d-]+)$/i, 'Невалидное название поддомена'),
                        apiCheck: apiCheck(this.checkCodeUnique, 'Поддомен уже существует')
                    }
                }
            };
        }
    })
], CreateCompanyModal);
export default CreateCompanyModal;
