const defaultState = () => ({
    customFieldId: null,
    name: '',
    description: '',
    prompt: '',
    type: '',
    subtype: 'user',
    multiple: false,
    list_show_type: 'radio_checkbox',
    isSaved: false,
    currentVariablesTab: { text: 'Список', value: 'list' },
    field_list_bitrix_id: null
});
export default defaultState;
