export default [
    {
        text: 'ID',
        value: 'id',
        checked: true,
        sort: 1,
        disabled: true,
        subtype: 'simple',
        type: 'integer'
    },
    {
        text: 'Пользователь',
        value: 'surname',
        componentName: 'td-user',
        class: 'th-user',
        checked: true,
        sort: 2,
        disabled: true,
        subtype: 'simple',
        type: 'list'
    },
    {
        text: 'Роль',
        value: 'roles',
        componentName: 'td-labels',
        checked: true,
        sortable: false,
        sort: 3,
        subtype: 'simple',
        type: 'list',
        multiple: true
    },
    {
        text: 'Группа',
        value: 'groups',
        componentName: 'td-labels',
        checked: true,
        sortable: false,
        sort: 4,
        subtype: 'simple',
        type: 'list',
        multiple: true
    },
    {
        text: 'Пакеты и ветки',
        value: 'course_packages',
        componentName: 'td-packs',
        checked: true,
        sortable: false,
        sort: 5,
        subtype: 'simple',
        type: 'list',
        multiple: false
    },
    {
        text: 'Регистрация',
        value: 'created_at',
        componentName: 'td-date',
        checked: true,
        sort: 6,
        subtype: 'simple',
        type: 'date'
    },
    {
        text: 'Активность',
        value: 'last_login',
        componentName: 'td-date',
        checked: true,
        sort: 7,
        subtype: 'simple',
        type: 'date'
    },
    {
        text: 'Добавлен в курс',
        value: 'course_order_created_at',
        componentName: 'td-date',
        checked: true,
        sortable: false,
        sort: 8,
        subtype: 'simple',
        type: 'date'
    },
    {
        text: 'Редактировать',
        value: 'user_id',
        componentName: 'td-edit-user',
        checked: true,
        sortable: false,
        sort: 9,
        subtype: 'simple',
    }
];
