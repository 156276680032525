import { __decorate, __metadata } from "tslib";
import { Component, namespace, Vue } from 'nuxt-property-decorator';
import AdminHeader from '@/components/AdminHeader.vue';
import AccountQuestion from '@/pages/Account/AccountQuestion/AccountQuestion.vue';
import CreateCompanyModal from '@/components/CreateCompanyModal.vue';
import ProfileDropdown from '@/components/ProfileDropdown/ProfileDropdown.vue';
import { bodyMenu, questions, topMenu } from '@/pages/Account/entities/accountEntities';
const CompanyModule = namespace('company');
let AccountPage = class AccountPage extends Vue {
    constructor() {
        super(...arguments);
        this.isNavigationHandling = false;
        this.isShowCreate = false;
        this.topMenu = topMenu;
        this.bodyMenu = bodyMenu;
        this.questions = questions;
    }
    showCreateModal() {
        this.isShowCreate = true;
    }
};
__decorate([
    CompanyModule.State('company_name'),
    __metadata("design:type", Object)
], AccountPage.prototype, "company_name", void 0);
AccountPage = __decorate([
    Component({
        components: {
            ProfileDropdown,
            AdminHeader,
            AccountQuestion,
            CreateCompanyModal
        }
    })
], AccountPage);
export default AccountPage;
