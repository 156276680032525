import headers from '@/store/modules/orders/entities/headers';
const defaultState = () => ({
    headers,
    allHeaders: [],
    userFields: [],
    orderFolders: [],
    filterCategories: [],
    fieldsLoading: true,
    filtersLoading: true
});
export default defaultState;
