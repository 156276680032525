import { mixinMutations } from '@/store/mixins';
const state = { courses_count: 0 };
const mutations = { ...mixinMutations() };
const actions = {
    async getNewsList({ rootGetters }, { params }) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_news`, params);
    },
    async deleteNewsItem({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_news/${id}`, {}, 'DELETE');
    },
    async createNews({ rootGetters }, params) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/course_news`, params);
        return data;
    },
    async getItem({ rootGetters, commit }, id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/course_news/${id}`, { withCount: 'courses' });
        commit('setProperty', { name: 'courses_count', value: data.courses_count });
        return data;
    },
    async updateItem({ rootGetters }, { id, params }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/course_news/${id}`, params, 'PUT');
        return data;
    },
    async attachOrDetachCourses({ rootGetters }, { action, course_id, id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_news/${id}/${action}`, { course_id });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
