const notEmpty = new RegExp(/^[а-яА-ЯёЁa-zA-Z0-9]+.+$/gimu);
const calculateSum = (tree) => tree.reduce((acc, current) => {
    acc += 1;
    if (current.answers && current.answers.length) {
        acc += calculateSum(current.answers);
    }
    return acc;
}, 0);
const getCurrentComment = (comments, id, total) => {
    const finded = comments.find((comment) => comment.id === id);
    if (finded)
        return total ? comments : finded;
    const flat = comments
        .filter((comment) => comment.hasOwnProperty('answers') && comment.answers.length)
        .map((comment) => comment.answers)
        .reduce((a, b) => a.concat(b));
    return getCurrentComment(flat, id);
};
const findCurrentParent = (comments, item) => {
    const id = item.comment_id;
    const finded = comments.find((comment) => comment.answers && comment.answers.find((item) => item.comment_id === id));
    return finded || findCurrentParent(comments.map((comment) => comment.answers).flat(1), item);
};
export { notEmpty, calculateSum, getCurrentComment, findCurrentParent };
