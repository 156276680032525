import Vue from 'vue';
const getters = {
    hiddenHeaders(state) {
        return state.listHeaders.data.filter((header) => header.state === 'hidden').map((header) => header.id);
    },
    sortedListHeaders(state) {
        return [...state.listHeaders.data].sort((a, b) => a.sort - b.sort);
    }
};
const mutations = {
    selectHeader(state, payload) {
        Vue.set(state, 'selectedHeader', payload);
    },
    updateListHeader(state, payload) {
        const headerIndex = state.listHeaders.data.findIndex((header) => header.id === payload.id);
        const list = state.lists.find((list) => list.id === payload.list_id);
        if (headerIndex !== -1)
            state.listHeaders.data.splice(headerIndex, 1, payload);
        if (list) {
            const headerIndex = list.headers_by_sort.findIndex((header) => header.id === payload.id);
            if (headerIndex !== -1)
                list.headers_by_sort.splice(headerIndex, 1, payload);
        }
    }
};
const actions = {
    async getAvailableHeaders({ rootGetters, commit, state }) {
        commit('setProperty', { name: 'availableHeaders', value: { data: [], loading: true } });
        const { data, errors } = await this.getDataStore(`${rootGetters.API}/united_table_list_headers/available_types`);
        if (!errors) {
            commit('setProperty', { name: 'availableHeaders', value: { ...state.availableHeaders, data } });
        }
        commit('setProperty', { name: 'availableHeaders', value: { ...state.availableHeaders, loading: false } });
        return { data, errors };
    },
    async getListHeaders({ rootGetters, commit, state }, { id }) {
        commit('setProperty', { name: 'listHeaders', value: { data: [], loading: false } });
        const { data, errors } = await this.getDataStore(`${rootGetters.API}/united_table_lists/${id}/united_table_list_headers`, { with: 'comment;type;accessUsers' });
        if (!errors) {
            commit('setProperty', { name: 'listHeaders', value: { ...state.listHeaders, data } });
        }
        commit('setProperty', { name: 'listHeaders', value: { ...state.listHeaders, loading: false } });
        return { data, errors };
    },
    async updateListHeader({ rootGetters, commit }, { id, list_id, access_users, ...header }) {
        // commit('updateListHeader', {
        //   id, list_id, access_users, ...header
        // });
        const access_users_ids = access_users.map((user) => user.id);
        const { data, errors } = await this.postDataStore(`${rootGetters.API}/united_table_lists/${list_id}/united_table_list_headers/${id}`, { ...header, access_users_ids, with: 'accessUsers;comment' }, 'PUT');
        if (!errors)
            commit('updateListHeader', data);
        return { data, errors };
    }
};
export default { getters, mutations, actions };
