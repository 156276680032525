import { __decorate, __metadata } from "tslib";
import { Component, Vue, Prop } from 'nuxt-property-decorator';
let NoSearchData = class NoSearchData extends Vue {
};
__decorate([
    Prop({
        type: String,
        default: () => 'курсы'
    }),
    __metadata("design:type", Object)
], NoSearchData.prototype, "searchName", void 0);
__decorate([
    Prop({
        type: String,
        default: () => ''
    }),
    __metadata("design:type", Object)
], NoSearchData.prototype, "title", void 0);
__decorate([
    Prop({
        type: String,
        default: () => ''
    }),
    __metadata("design:type", Object)
], NoSearchData.prototype, "description", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: () => false
    }),
    __metadata("design:type", Object)
], NoSearchData.prototype, "showSlot", void 0);
NoSearchData = __decorate([
    Component
], NoSearchData);
export default NoSearchData;
