import { mixinMutations } from '@/store/mixins';
const mutations = { ...mixinMutations() };
const state = { courses_count: 0 };
const actions = {
    async getRatingList({ rootGetters }, params) {
        const [sortByParam] = params?.sortBy || '';
        const [sortDescParam] = params?.sortDesc || '';
        return await this.getDataStore(`${rootGetters.API_URL}/course_ratings`, {
            ...params,
            sort: sortByParam,
            desc: Number(sortDescParam),
            withCount: 'courses'
        });
    },
    async multiUpdateItems({ rootGetters }, params) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_ratings/multi_sort`, params, 'POST');
    },
    async deleteRating({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_ratings/${id}`, {}, 'DELETE');
    },
    async createRating({ rootGetters }, params) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/course_ratings`, params);
        return data;
    },
    async getItem({ rootGetters, commit }, id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/course_ratings/${id}`, { withCount: 'courses' });
        commit('setProperty', { name: 'courses_count', value: data.courses_count });
        return data;
    },
    async updateItem({ rootGetters }, { id, params }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/course_ratings/${id}`, params, 'PUT');
        return data;
    },
    async attachOrDetachCourses({ rootGetters }, { action, course_id, id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_ratings/${id}/${action}`, { course_id });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
