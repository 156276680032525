const mutations = {
    setMainColumns(state, payload) {
        state.mainColumns = payload;
    },
    deleteMainColumn(state, payload) {
        const index = state.mainColumns.findIndex((column) => column.id === payload.id);
        state.mainColumns.splice(index, 1);
    },
    createItems(state, payload) {
        const column = state.mainColumns.find((column) => column.id === payload.id);
        column.items_by_sort.push(...payload.items);
    },
    updateItem(state, payload) {
        const column = state.mainColumns.find((column) => column.id === payload.main_column_id);
        if (!column)
            return;
        const index = column.items_by_sort.findIndex((item) => item.id === payload.id);
        column.items_by_sort.splice(index, 1, payload);
    },
    removeItem(state, payload) {
        const column = state.mainColumns.find((col) => col.id === payload.column_id);
        const index = column.items_by_sort.findIndex((item) => item.id === payload.item_id);
        column.items_by_sort.splice(index, 1);
    },
    updateMainColumn(state, payload) {
        const columnIndex = state.mainColumns.findIndex((column) => column.id === payload.id);
        if (columnIndex === -1)
            return;
        state.mainColumns.splice(columnIndex, 1, payload);
    },
    socketUpdateColumns(state, payload) {
        payload.forEach((column) => {
            const mainColumns = state.mainColumns.find((c) => c.id === column.id);
            if (!mainColumns)
                return;
            column.items_by_sort.forEach((item) => {
                const index = mainColumns.items_by_sort.findIndex((i) => i.id === item.id);
                if (index !== -1) {
                    mainColumns.items_by_sort.splice(index, 1, item);
                }
            });
        });
    },
    socketUpdateTotals(state, payload) {
        payload.forEach((total) => {
            // @ts-ignore
            const currentTotal = state.totals.find((t) => t.id === total.id);
            if (!currentTotal)
                return;
            // @ts-ignore
            currentTotal.cells_by_sort = total.cells_by_sort;
            // @ts-ignore
            currentTotal.items_ids = total.items_ids;
        });
    }
};
const actions = {
    async getMainColumnList({ rootGetters, commit, state }, { params } = {}) {
        if (params.page === 1) {
            commit('setProperty', { name: 'mainColumns', value: [] });
        }
        const res = await this.getDataStore(`${rootGetters.API}/united_table_main_columns`, {
            ...params,
            listId: state.currentListId,
            with: 'comment:id;itemsBySort.comment:id;itemsBySort.cellsBySort.comment:id,entity_id,entity_type;itemsBySort.coursePackage:id,course_branch_id,name;itemsBySort.coursePackage.courseBranch:id,name;course:id,name;additional'
        });
        if (!res.errors) {
            const payload = params.page === 1 ? res.data : state.mainColumns.concat(res.data);
            commit('setProperty', { name: 'mainColumns', value: payload });
        }
        return res;
    },
    async createMainColumn({ rootGetters, dispatch }, params) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API}/united_table_main_columns`, params, 'POST');
        await dispatch('getMainColumnList', { params: { page: 1, per_page: 10 } });
        return { data, errors };
    },
    async updateMainColumn({ rootGetters, commit }, { id, ...mainColumn }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API}/united_table_main_columns/${id}`, {
            ...mainColumn,
            with: 'comment:id,entity_id,entity_type;itemsBySort.comment:id,entity_id,entity_type;itemsBySort.cellsBySort.comment:id,entity_id,entity_type;itemsBySort.coursePackage:id,course_branch_id,name;itemsBySort.coursePackage.courseBranch:id,name;course:id,name;'
        }, 'PUT');
        if (!errors) {
            commit('updateMainColumn', data);
        }
        return { data, errors };
    },
    async createItems({ rootGetters, commit }, { id, items_ids }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API}/united_table_main_columns/${id}/united_table_items/multi_create`, { items_ids, with: 'cells_by_sort;coursePackage;coursePackage.courseBranch' }, 'POST');
        if (!errors)
            commit('createItems', { id, items: data });
        return { data, errors };
    },
    async updateItem({ rootGetters, commit }, { id, column_id, ...item }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API}/united_table_main_columns/${column_id}/united_table_items/${id}`, {
            ...item,
            with: 'cells_by_sort;coursePackage;coursePackage.courseBranch'
        }, 'PUT');
        if (!errors)
            commit('updateItem', data);
        return { data, errors };
    },
    async removeItem({ rootGetters, commit }, { id, column_id }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API}/united_table_main_columns/${column_id}/united_table_items/${id}`, {}, 'DELETE');
        if (!errors)
            commit('removeItem', { id, column_id });
        return { data, errors };
    },
    async multiSortColumns({ rootGetters }, { main_columns }) {
        return await this.postDataStore(`${rootGetters.API}/united_table_main_columns/multi_sort`, { main_columns }, 'POST');
    },
    async deleteMainColumn({ rootGetters, commit }, { id }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API}/united_table_main_columns/${id}`, {}, 'DELETE');
        if (!errors)
            commit('deleteMainColumn', { id });
        return { data, errors };
    },
    async updateCell({ rootGetters }, { id, value }) {
        return await this.postDataStore(`${rootGetters.API}/united_table_item_cells/${id}`, { value }, 'PUT');
    },
    async downloadFile({ rootGetters }, { id, file_name }) {
        return await this.postDataStore(`${rootGetters.API}/united_table_main_columns/${id}/download_file`, { file_name }, 'POST');
    },
    async createRegList({ rootGetters }, { id }) {
        return this.postDataStore(`${rootGetters.API}/united_table_main_columns/${id}/create_reg_list`, {}, 'POST');
    },
    async createReportList({ rootGetters }, { id }) {
        return this.postDataStore(`${rootGetters.API}/united_table_main_columns/${id}/create_sum_report`, {}, 'POST');
    },
};
export default { mutations, actions };
