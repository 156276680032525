const actions = {
    async createCustomField({ rootGetters }, payload) {
        return await this.postDataStore(`${rootGetters.API_URL}/custom_fields`, payload);
    },
    async getCustomField({ rootGetters }, payload) {
        const { query, params } = payload;
        const cfId = query.id;
        return await this.getDataStore(`${rootGetters.API_URL}/custom_fields/${cfId}`, params);
    },
    async getCustomFields({ rootGetters }, payload) {
        const { params } = payload;
        return await this.getDataStore(`${rootGetters.API_URL}/custom_fields`, params);
    },
    async updateCustomField({ rootGetters }, payload) {
        const { query, params } = payload;
        const cfId = query.id;
        return await this.postDataStore(`${rootGetters.API_URL}/custom_fields/${cfId}`, params, 'PUT');
    },
    async create({ rootGetters }, payload) {
        return await this.postDataStore(`${rootGetters.API_URL}/custom_fields`, payload);
    },
    async get({ rootGetters }, payload) {
        const { query, params } = payload;
        const customFieldId = query.id;
        return await this.getDataStore(`${rootGetters.API_URL}/custom_fields/${customFieldId}`, params);
    },
    async list({ rootGetters }, payload) {
        const { params } = payload;
        return await this.getDataStore(`${rootGetters.API_URL}/custom_fields`, params);
    },
    async update({ rootGetters }, payload) {
        const { query, params } = payload;
        const customFieldId = query.id;
        return await this.postDataStore(`${rootGetters.API_URL}/custom_fields/${customFieldId}`, params, 'PUT');
    },
    async delete({ rootGetters }, { params: { id } }) {
        return await this.postDataStore(`${rootGetters.API_URL}/custom_fields/${id}`, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    actions
};
