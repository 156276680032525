export default class DownloadService {
    constructor() {
        this.loadingScripts = new Map();
        this.uploadScript = this.uploadScript.bind(this);
    }
    uploadScript(options) {
        const { targetClass, url, type, attributes } = options;
        const scriptWatchers = this.loadingScripts.get(targetClass);
        if (scriptWatchers) {
            return new Promise((resolve) => {
                scriptWatchers.push(resolve);
            });
        }
        return new Promise((resolve) => {
            if (document.querySelector(`.${targetClass}`))
                resolve(true);
            this.loadingScripts.set(targetClass, []);
            const elementVocabulary = {
                css: () => {
                    const element = document.createElement('link');
                    element.setAttribute('rel', 'stylesheet');
                    element.setAttribute('href', url);
                    return element;
                },
                default: () => {
                    const element = document.createElement('script');
                    element.setAttribute('type', 'text/javascript');
                    element.src = url;
                    return element;
                }
            };
            const element = (elementVocabulary[type] || elementVocabulary.default)();
            element.setAttribute('class', targetClass);
            if (attributes) {
                Object.entries(attributes).forEach(([key, value]) => {
                    element.setAttribute(key, value);
                });
            }
            element.onload = () => {
                this.loadingScripts.get(targetClass)?.forEach((callback) => {
                    callback(true);
                });
                this.loadingScripts.set(targetClass, null);
                resolve(true);
            };
            element.onerror = () => {
                this.loadingScripts.get(targetClass)?.forEach((callback) => {
                    callback(false);
                });
                this.loadingScripts.set(targetClass, null);
                resolve(false);
            };
            document.head.appendChild(element);
        });
    }
}
