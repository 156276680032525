import { mixinMutations } from '@/store/mixins';
const state = {};
const mutations = { ...mixinMutations() };
const actions = {
    async list({ rootGetters }, payload) {
        const { params = {} } = payload;
        return await this.getDataStore(`${rootGetters.API_URL}/infoblocks`, { ...params });
    },
    async createInfoBlock({ rootGetters }, payload) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/infoblocks`, payload, 'POST');
        return data;
    },
    async getInfoBlock({ rootGetters }, { block_id, useWith }) {
        const withParam = useWith
            ? { with: 'customFieldsBySort;customFieldsBySort.variables;customFieldsValues;infoblockElements;infoblockElements.customFieldsValues;createdByUser;updatedByUser' }
            : {};
        const response = await this.getDataStore(`${rootGetters.API_URL}/infoblocks/${block_id}`, withParam);
        return response.data;
    },
    async updateInfoBlock({ rootGetters }, payload) {
        const { block_id, ...rest } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/infoblocks/${block_id}`, rest, 'PUT');
    },
    async deleteInfoBlock({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/infoblocks/${id}`, {}, 'DELETE');
    },
    async updateFieldsSort({ rootGetters }, payload) {
        const { block_id, infoblock_custom_field_ids } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/infoblocks/${block_id}/multi_sort_custom_fields`, { infoblock_custom_field_ids }, 'POST');
    },
    async attachCustomFields({ rootGetters }, payload) {
        const { block_id, custom_field_id } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/infoblocks/${block_id}/attach_custom_fields`, { custom_field_id }, 'POST');
    },
    async detachCustomFields({ rootGetters }, payload) {
        const { block_id, custom_field_id } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/infoblocks/${block_id}/detach_custom_fields`, { custom_field_id }, 'POST');
    },
    async getFields({ rootGetters }, name) {
        return await this.getDataStore(`${rootGetters.API_URL}/custom_fields`, { name });
    },
    async getInfoBlockElements({ rootGetters }, payload) {
        const { sort, desc, search = '', page = 1 } = payload.params;
        const block_id = payload.block_id ? payload.block_id : payload.params ? payload.params.block_id : '-1';
        return await this.getDataStore(`${rootGetters.API_URL}/infoblocks/${block_id}/infoblock_elements`, { search: search || '', page, sort, desc });
    },
    async updateInfoBlockElementsSort({ rootGetters, dispatch }, payload) {
        const { block_id, infoblock_element_ids } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/infoblocks/${block_id}/infoblock_elements/multi_sort`, { infoblock_element_ids }, 'POST');
    },
    async updateInfoBlockCustomFieldValues({ rootGetters }, payload) {
        const { block_id, value } = payload;
        return await this.postDataStore(`${rootGetters.API_URL}/infoblocks/${block_id}/custom_field_infoblock_values_multiple`, value, 'POST');
    },
    async updateInfoBlockElement({ rootGetters }, { block_id, active, title, element_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/infoblocks/${block_id}/infoblock_elements/${element_id}`, { title, active }, 'PUT');
    },
    async deleteInfoBlockElement({ rootGetters }, { block_id, element_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/infoblocks/${block_id}/infoblock_elements/${element_id}`, {}, 'DELETE');
    },
    async getInfoBlockElement({ rootGetters }, { block_id, element_id }) {
        const withParam = 'customFieldsValues;customFieldsValues.customField;customFieldsValues.infoblockCustomField;customFieldsValues.file;';
        const response = await this.getDataStore(`${rootGetters.API_URL}/infoblocks/${block_id}/infoblock_elements/${element_id}`, { with: withParam });
        return response.data;
    },
    async createInfoBlockElement({ rootGetters }, { block_id, title, active, }) {
        const response = await this.postDataStore(`${rootGetters.API_URL}/infoblocks/${block_id}/infoblock_elements/`, {
            title, active
        }, 'POST');
        return response.data;
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
