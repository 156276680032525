import { mixinMutations } from '@/store/mixins';
import defaultStateFactory from './entities/defaultState';
const state = defaultStateFactory();
const mutations = {
    ...mixinMutations(),
    setTopics(state, topics) {
        state.topics = topics;
    },
};
const actions = {
    async fetchSubjects({ commit, rootGetters }, { name } = {}) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/subjects?with=topics${name ? `&name=${name}` : ''}`);
        commit('setProperty', { name: 'subjects', value: data });
    },
    async getSubjectsData({ rootGetters }, { query, params }) {
        return await this.getDataStore(`${rootGetters.API_URL}/subjects?with=topics`, { ...query, ...params });
    },
    async createSubject({ dispatch, rootGetters }, payload) {
        await this.postDataStore(`${rootGetters.API_URL}/subjects`, payload, 'POST');
    },
    async updateSubject({ dispatch, rootGetters }, { subjectId, subjectData }) {
        await this.postDataStore(`${rootGetters.API_URL}/subjects/${subjectId}`, subjectData, 'PUT');
    },
    async deleteSubject({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/subjects/${id}`, {}, 'DELETE');
    },
    async createTopic({ dispatch, rootGetters }, payload) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/topics`, payload, 'POST');
        return data;
    },
    async updateTopic({ dispatch, rootGetters }, payload) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/topics/${payload.id}`, { name: payload.name }, 'PUT');
        return data;
    },
    async attachTopicsToSubject({ dispatch, rootGetters }, { subjectId, topicIds }) {
        await this.postDataStore(`${rootGetters.API_URL}/subjects/${subjectId}/attach_topics`, { topic_ids: topicIds });
    },
    async detachTopicsFromSubject({ dispatch, rootGetters }, { subjectId, topicIds }) {
        await this.postDataStore(`${rootGetters.API_URL}/subjects/${subjectId}/detach_topics`, { topic_ids: topicIds });
    },
    async fetchTopics({ commit, rootGetters }, name) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/topics${name ? `?name=${name}` : ''}`);
        commit('setProperty', { name: 'topics', value: data });
    },
    async fetchCurrentTopics({ commit, rootGetters }, { testId, page = 1, name = '' }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/test_topics_statistics/${testId}`, {
            params: { page, name },
        });
        commit('setTopics', data);
    },
    async fetchTestTopicStatistics({ commit, rootGetters }, { testId, name }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/test_topics_statistics/${testId}`, { name });
        commit('setProperty', { name: 'testTopicStatistics', value: data.data });
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
