import { mixinActions, mixinMutations } from '@/store/mixins';
const state = {
    item: {},
    searchString: '',
    loading: false,
    page: 1,
    per_page: 20
};
const mutations = { ...mixinMutations() };
const actions = {
    ...mixinActions(),
    async getAutoActions({ rootGetters }, { params }) {
        const url = `${rootGetters.API_URL}/auto_actions`;
        const allParams = {
            replaceProperties: 1,
            desc: 1,
            with: 'integration:id,active',
            ...params
        };
        return await this.getDataStore(url, allParams);
    },
    async toggleActivity({ rootGetters }, { id, params }) {
        const url = `${rootGetters.API_URL}/auto_actions/${id}`;
        const { data } = await this.postDataStore(url, params, 'PUT');
        return data;
    },
    async delete({ rootGetters }, id) {
        const url = `${rootGetters.API_URL}/auto_actions/${id}`;
        return await this.postDataStore(url, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
