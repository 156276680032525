export default [
    {
        title: 'Связь с кастомными полями',
        text: 'Данный тип связи обозначает, что создании генератора мы использовали небезызвестный универсальный код речей.',
        value: 'dependent_custom_fields'
    },
    {
        title: 'Связь с значениями кастомного поля',
        text: 'Данный тип связи обозначает, что создании генератора мы использовали небезызвестный универсальный код речей.',
        value: 'dependent_variables'
    }
];
