export default [
    {
        text: 'Строка',
        value: 'string',
        icon: 'text_rotation_none'
    },
    {
        text: 'Текст',
        value: 'text',
        icon: 'text_format'
    },
    {
        text: 'Число',
        value: 'integer',
        icon: 'looks_5'
    },
    {
        text: 'Файл',
        value: 'file',
        icon: 'description'
    },
    {
        text: 'Изображение',
        value: 'image',
        icon: 'insert_photo'
    },
    {
        text: 'Список',
        value: 'list',
        icon: 'subject'
    },
    {
        text: 'Дата',
        value: 'date',
        icon: 'event'
    },
    {
        text: 'Дата и время',
        value: 'datetime',
        icon: 'event'
    },
    {
        text: 'Телефон',
        value: 'phone',
        icon: 'local_phone'
    },
    {
        text: 'Почта',
        value: 'email',
        icon: 'mail_outline'
    },
    {
        text: 'Ссылка',
        value: 'url',
        icon: 'insert_link'
    }
];
