export const testActions = [
    {
        text: 'Деактивировать',
        value: 'unactive'
    },
    {
        text: 'Активировать',
        value: 'active'
    },
    {
        text: 'Удалить',
        value: 'remove'
    }
];
export const testMove = [
    {
        text: 'Перенести внутри этого курса',
        value: 'current'
    },
    {
        text: 'Перенести в другой курс',
        value: 'another'
    }
];
