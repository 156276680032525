const permissionsRole = {
    // Курсы
    courses_show_all: false,
    courses_show_my: false,
    courses_create: false,
    courses_update_all: false,
    courses_delete_all: false,
    courses_update_my: false,
    courses_delete_my: false,
    courses_statistics: false,
    courses_manage_access_and_other_settings: false,
    // Уроки
    lessons_view: false,
    lessons_create: false,
    lessons_update_all: false,
    lessons_delete_all: false,
    lessons_update_my: false,
    lessons_delete_my: false,
    lessons_show_results: false,
    lessons_manage_access_and_other_settings: false,
    lessons_tests_manage: false,
    // Тесты
    tests_create: false,
    tests_show_all: false,
    tests_update_all: false,
    tests_delete_all: false,
    tests_show_my: false,
    tests_update_my: false,
    tests_delete_my: false,
    tests_show_results: false,
    tests_statistics: false,
    // Пользователи
    users_show: false,
    users_delete: false,
    users_export: false,
    users_payments_and_statistic: false,
    users_welcome: false,
    users_manage: false,
    users_settings: false,
    // Order
    crm_show: false,
    crm_create: false,
    crm_update: false,
    crm_delete: false,
    crm_setting: false,
    // Компания
    company_settings: false,
    company_statistics: false,
    // Учебный кабинет
    learn_courses: false,
    learn_tests: false,
    courses_catalog: false,
    course_bid: false,
    course_self_bid: false,
    pay_course: false,
    course_gift: false,
    connect_users: false,
    users_observer: false,
    increased_users_observer: false,
    // Преподовательский раздел
    teacher_courses_and_lessons_access_all: false,
    teacher_courses_and_lessons_access_my: false,
    teacher_department: false,
    teacher_edit_lessons: false,
    teacher_edit_user_wave_results: false,
    teacher_edit_tests: false,
    teacher_check_homework: false,
    teacher_offline_lessons: false,
    teacher_journal: false,
    teacher_webinar_lessons: false,
    teacher_manage_chat: false,
    teacher_feedback_on_user_comments: false,
    teacher_check_test_all: false,
    teacher_check_test_my: false,
    teacher_salary: false,
    admin_part_access: false,
    learning_part_access: false,
    edit_points: false,
    mailing_auto: false,
    mailing_by_groups: false,
    mailing_by_courses: false,
    mailing_by_course_waves: false,
    mailing_by_filters: false // Создавать/производить рассылки по
};
export default () => ({
    roleData: {
        id: null,
        priority: null,
        name: null,
        description: null,
        default: false,
        admin_ban_on_registration: false,
        learning_ban_on_registration: false
    },
    roleForms: [],
    permissionsRole
});
