import { helpers } from 'vuelidate/lib/validators';
export const required = (mesErr) => helpers.withParams({ type: 'required', mesErr }, (value) => helpers.req(value));
export const email = (mesErr) => helpers.withParams({ type: 'email', mesErr }, (value) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return value === '' || re.test(value);
});
export const url = (mesErr) => helpers.withParams({ type: 'url', mesErr }, (value) => value === '' || !!value.match(new RegExp('^https?:\\/\\/.*', 'gm')));
export const minLengthArray = (num, mesErr) => helpers.withParams({ type: 'minLength', mesErr }, (value) => helpers.len(value.length) >= num);
export const regEx = (regEx, mesErr) => helpers.withParams({ type: 'regEx', mesErr }, (value) => regEx.test(value));
export const typeFloat = (mesErr) => helpers.withParams({ type: 'typeFloat', mesErr }, 
// eslint-disable-next-line eqeqeq
(value) => Number(value) == value && value % 1 !== 0);
export const minLength = (countLength, mesErr) => helpers.withParams({ type: 'minLength', mesErr }, (value) => value === '' || value.length >= countLength);
export const apiCheck = (action, mesErr) => helpers.withParams({ type: 'apiCheck', mesErr }, async () => await action());
export const maxLength = (countLength, mesErr) => helpers.withParams({ type: 'maxLength', mesErr }, (value) => value && value.length <= countLength);
