import { mixinActions, mixinMutations } from '@/store/mixins';
import { defaultItem, entityFilter } from '@/pages/AutoActions/entities/detailEntities';
const state = {
    isLoading: false,
    item: defaultItem,
    actions: [],
    recipient: null,
    integrationSendType: null
};
const mutations = { ...mixinMutations() };
const actions = {
    ...mixinActions(),
    async getEntities({ rootGetters }) {
        const url = `${rootGetters.API_URL}/auto_actions_entities`;
        const { data } = await this.getDataStore(url);
        return data;
    },
    async getEvents({ state, rootGetters }) {
        const url = `${rootGetters.API_URL}/auto_actions_events`;
        const { code } = state.item.entity;
        const params = { entity: code };
        const { data } = await this.getDataStore(url, params);
        return data;
    },
    async getFirstFilterItems({ state, rootGetters }, search) {
        const url = `${rootGetters.API_URL}/auto_actions_action_filter_data`;
        const { code: event } = state.item.event;
        const { code, only, mapFn } = entityFilter
            .find((item) => item.type === state.item.entity.code);
        const params = {
            ...search && { search }, code, only, event
        };
        const { data } = await this.getDataStore(url, params);
        return data.map(({ id, ...rest }) => ({ id, name: mapFn(rest) }));
    },
    async getFilteredLessons({ state, rootGetters }, search) {
        const url = `${rootGetters.API_URL}/auto_actions_action_filter_data`;
        const { data: items = [] } = state.item.action_filter?.find((item) => item.code === 'courses');
        const { code: event } = state.item.event;
        const params = {
            ...items.length === 1 && { course_id: items[0].id },
            ...search && { search },
            code: 'lessons',
            only: 'name',
            event
        };
        const { data } = await this.getDataStore(url, params);
        return data.map(({ id, name }) => ({ id, name }));
    },
    async getFilteredCourseFields({ state, rootGetters }, search) {
        const url = `${rootGetters.API_URL}/auto_actions_action_filter_data`;
        const { code: event } = state.item.event;
        const params = {
            ...search && { search }, code: 'course_custom_fields', only: 'name', event
        };
        const { data } = await this.getDataStore(url, params);
        return data.map(({ id, name }) => ({ id, name }));
    },
    async getFilterTypes({ state, rootGetters }, search) {
        const url = `${rootGetters.API_URL}/auto_actions_filters`;
        const { code: event } = state.item.event;
        const { code: entity } = state.item.entity;
        const params = { ...search && { search }, entity, event };
        const { data } = await this.getDataStore(url, params);
        return data;
    },
    async getActionActions({ commit, rootGetters }) {
        const url = `${rootGetters.API_URL}/auto_actions_actions`;
        const { data } = await this.getDataStore(url);
        commit('setProperty', { name: 'actions', value: data });
        return data;
    },
    async getChannels({ rootGetters, state }) {
        const id = state.item.integration_id;
        const url = `${rootGetters.API_URL}/integrations/${id}/channels`;
        const params = { only: 'name' };
        const { data } = await this.getDataStore(url, params);
        return data;
    },
    async getRoles({ rootGetters }, search) {
        const url = `${rootGetters.API_URL}/roles`;
        const params = { ...search && { search }, desc: 1, only: 'name,code' };
        const { data } = await this.getDataStore(url, params);
        return data;
    },
    async getGroups({ rootGetters }, search) {
        const url = `${rootGetters.API_URL}/groups`;
        const params = { ...search && { search }, only: 'name' };
        const { data } = await this.getDataStore(url, params);
        return data;
    },
    async getUsers({ rootGetters }, search) {
        const url = `${rootGetters.API_URL}/users`;
        const params = { ...search && { search }, only: 'name,surname,email' };
        const { data } = await this.getDataStore(url, params);
        return data.map(({ id, name, surname, email }) => ({ id, name: `${name} ${surname} <${email}>` }));
    },
    async getTemplates({ rootGetters }) {
        const url = `${rootGetters.API_URL}/auto_action_templates`;
        const { code: action } = state.item.action;
        const { code: event } = state.item.event;
        const { code: entity } = state.item.entity;
        const params = { action, entity, event };
        const { data } = await this.getDataStore(url, params);
        const mappedData = data.map(({ id, name }) => ({ id, name }));
        return [...[{ id: null, name: 'Новый шаблон' }], ...mappedData];
    },
    async getIntegrations({ rootGetters, state }, search) {
        const url = `${rootGetters.API_URL}/integrations`;
        const hasChannels = state.recipient === 'channel';
        const params = {
            active: 1,
            send_type: state.integrationSendType,
            ...hasChannels && { hasChannels: 1 },
            ...search && { search }
        };
        const { data } = await this.getDataStore(url, params);
        return data;
    },
    async getAction({ commit, rootGetters }, { id }) {
        if (!id || id === 'create')
            return;
        try {
            commit('setProperty', { name: 'isLoading', value: true });
            const url = `${rootGetters.API_URL}/auto_actions/${id}`;
            const params = { with: 'template;integration', replaceProperties: 1 };
            const { data } = await this.getDataStore(url, params);
            commit('setProperty', { name: 'item', value: data });
            commit('setProperty', { name: 'isLoading', value: false });
            return true;
        }
        catch (e) {
            return false;
        }
    },
    async create({ rootGetters }, params) {
        const url = `${rootGetters.API_URL}/auto_actions`;
        return await this.postDataStore(url, params);
    },
    async update({ rootGetters }, params) {
        const url = `${rootGetters.API_URL}/auto_actions/${params.id}`;
        return await this.postDataStore(url, params, 'PUT');
    },
    async toggleActivity({ rootGetters }, { id, params }) {
        const url = `${rootGetters.API_URL}/auto_actions/${id}`;
        const { data } = await this.postDataStore(url, { ...params }, 'PUT');
        return data;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
