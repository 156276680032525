import { __decorate, __metadata } from "tslib";
import { Component, namespace, Vue } from 'nuxt-property-decorator';
const ProfileModule = namespace('adminProfile');
let ProfileDropdown = class ProfileDropdown extends Vue {
    created() {
        if (this.name && this.name.length)
            return;
        this.getProfile();
    }
    logOut() {
        localStorage.removeItem('access_token');
        this.$router.push('/login');
    }
};
__decorate([
    ProfileModule.Action('getProfile'),
    __metadata("design:type", Object)
], ProfileDropdown.prototype, "getProfile", void 0);
__decorate([
    ProfileModule.State('name'),
    __metadata("design:type", Object)
], ProfileDropdown.prototype, "name", void 0);
__decorate([
    ProfileModule.State('surname'),
    __metadata("design:type", Object)
], ProfileDropdown.prototype, "surname", void 0);
__decorate([
    ProfileModule.State('photo'),
    __metadata("design:type", Object)
], ProfileDropdown.prototype, "photo", void 0);
__decorate([
    ProfileModule.State('email'),
    __metadata("design:type", Object)
], ProfileDropdown.prototype, "email", void 0);
__decorate([
    ProfileModule.Getter('initials'),
    __metadata("design:type", Object)
], ProfileDropdown.prototype, "initials", void 0);
ProfileDropdown = __decorate([
    Component
], ProfileDropdown);
export default ProfileDropdown;
