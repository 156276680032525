import { defaultTabs } from './tabs';
import statuses from './courseStatuses';
import defaultHeaders from './defaultHeaders';
const courseState = {
    id: 0,
    baseUrl: '',
    name: '',
    description: '',
    price: 0,
    old_price: 0,
    start_date: '',
    end_date: '',
    img_anons: '',
    img_banner: '',
    send_email: false,
    img_banner_mobile: '',
    img_header: '',
    img_vertical_banner: '',
    description_main_manager: '',
    form_id: null,
    show_likes: false,
    use_comments: false,
    tabs: defaultTabs,
    currentTab: {
        text: 'О курсе',
        value: 'settings'
    },
    statuses,
    status: '',
    auth_user_favourites_exists: false,
    description_small: '',
    admin_access_users: [],
    users_for_admin_access: [],
    subcategories_with_categories: [],
    company_id: 0,
    finish_criteria: null,
    completion_estrictions: null,
    close_date: null,
    title_ceo: '',
    description_ceo: '',
    keywords_ceo: '',
    close_days: null,
    certificate: '',
    program: '',
    instruction: '',
    success_result: '',
    fail_result: '',
    courses_list: [],
    bullet_points: [],
    type: null,
    courses_open_id: [],
    lessonsWithCheck: [],
    lessons_count: 0,
    locals: [],
    lessonTableHeaders: [
        {
            text: 'Уроки',
            value: 'name'
        },
        {
            text: 'Мин. балл прохождения',
            value: 'min_points_complete' /// минимальный балл урока
        },
        {
            text: 'Необходимый балл',
            value: 'min_points_course' // минимальный балл для прохождения
        },
        {
            text: 'Макс. балл',
            value: 'max_points'
        }
    ],
    criteria_lesson_counting: null,
    lessons_to_close: [],
    lessons_likes: null,
    lessons_comments: null,
    use_reviews: false,
    is_show_visit_offline_journal: false,
    use_rating_points: false,
    lessons_show_likes: null,
    anons: '',
    show_landing: false,
    fields: [],
    courseCustomFields: [],
    tableLessonsCount: null,
    learning_process: null,
    is_constructor: false,
    isCourseFormEditing: false,
    defaultHeaders,
    selectItems: [],
    selectTitle: '',
    selectLoading: false,
    fieldsLoading: true,
    crm_form_id: null,
    crm_bitrix: null,
    crm_bitrix_form_id: null,
    crm_bitrix_form_url: null,
    course_packages_to_open: [],
    preposition_from: false,
    price_comment: '',
    can_be_bought: false,
    yoo_kassa_ready: false,
    weeks_time: '',
    video_with_teacher_recording: '',
    classes: '',
    goal: '',
    for_whom: '',
    currentStatus: null,
    videos: [],
    video_start_loading: false,
    full_course_discount_percentage: 0,
    special_offer_discount_percentage: 0,
    use_full_course_discount_percentage: false,
    use_special_offer_discount_percentage: false,
    active_link_chat_block: false,
    title_link_chat_block: 'Написать куратору курса',
    course_link_chat_blocks: null,
    catalog_first_url_course_category_id: 0,
    catalog_first_url_course_category_slug: '',
    catalog_second_url_course_category_id: 0,
    catalog_second_url_course_category_slug: '',
};
export default courseState;
