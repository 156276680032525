const defaultState = () => ({
    dataForm: {
        name: '',
        code: '',
        type: '',
        webhook: null,
        front_template: '',
        role: null
    },
    dataField: {
        id: '',
        title: '',
        description: '',
        button_name: '',
        create_user: false,
        social_network_login: false,
        is_allow_sent_bitrix_phone_type: false
    },
    fields: [],
    customFields: []
});
export default defaultState;
