import tabs from './tabs';
class InitState {
    static createDefaultState() {
        return { ...this.courseLandingState };
    }
}
InitState.courseLandingState = {
    tabs,
    currentTab: tabs[0],
    courseNews: [],
    news: [],
    baseUrl: '',
    faq: [],
    courseFaq: [],
    course: null,
    courseAdvantages: [],
    allAdvantages: [],
    courseCategories: [],
    newsLastPage: 1,
    userProfiles: [],
    courseProfiles: [],
    profile: null,
    courseStructure: [],
    courseMeta: {},
    ratings: [],
    ratingsNames: [],
    filteredRatings: [],
    allRatingsNames: [],
    lastRatingPage: 1,
    packageForLanding: [],
    packagesTrs: [],
    defaultCompanySort: [],
    companySort: [],
    isSortDefault: false,
    use_custom_sort: false,
    recommendations: [],
    recommendationsLoading: false
};
export default InitState;
