import Vue from 'vue';
const mutations = {
    openContextMenu(state, payload) {
        Vue.set(state.contextMenu, 'reference', payload.reference);
        state.contextMenu.type = payload.type;
        state.contextMenu.position = payload.position;
        state.contextMenu.mode = payload.mode;
        state.contextMenu.show = true;
    },
    closeContextMenu(state) {
        state.contextMenu = {
            type: null,
            reference: null,
            position: { x: 0, y: 0 },
            mode: null,
            show: false
        };
    }
};
export default { mutations };
