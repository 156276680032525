import Vue from 'vue';
import './entities/use';
import './entities/directives';
import VueMask, { VueMaskDirective, VueMaskFilter } from 'v-mask';
import '@/assets/sass/vuetifyCustom.scss';
import FroalaEditor from '@/components/FroalaEditor/FroalaEditor.vue';
import SimpleFroalaEditor from '@/components/SimpleFroalaEditor/SimpleFroalaEditor.vue';
import InfoTooltip from '@/components/InfoTooltip.vue';
import MultipleDropzone from '@/components/MultipleDropzone.vue';
import NoSearchData from '@/components/NoSearchData.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import CustomDatepicker from '@/components/DatePicker.vue';
import AdminAutocomplete from '@/components/AdminAutocomplete.vue';
import Modal from '@/components/Modal.vue';
import SelectWithIcons from '@/components/SelectWithIcons.vue';
import Tab from '@/components/Tab.vue';
import SingleDropzone from '@/components/SingleDropzone.vue';
import Comment from '@/components/Comments/Comment.vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { getData, postData } from '@/api';
store.getDataStore = getData;
store.postDataStore = postData;
Vue.use(VueMask);
Vue.directive('mask', VueMaskDirective);
Vue.component('froala-editor', FroalaEditor);
Vue.component('simple-froala-editor', SimpleFroalaEditor);
Vue.component('info-tooltip', InfoTooltip);
Vue.component('multiple-dropzone', MultipleDropzone);
Vue.component('no-search-data', NoSearchData);
Vue.component('progress-bar', ProgressBar);
Vue.component('custom-datepicker', CustomDatepicker);
Vue.component('admin-autocomplete', AdminAutocomplete);
Vue.component('modal', Modal);
Vue.component('select-with-icons', SelectWithIcons);
Vue.component('tab-item', Tab);
Vue.component('single-dropzone', SingleDropzone);
Vue.component('Comment', Comment);
Vue.filter('VMask', VueMaskFilter);
Vue.config.productionTip = process.env.NODE_ENV === 'production';
Vue.config.performance = process.env.NODE_ENV === 'development';
export default new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App)
}).$mount('#app');
