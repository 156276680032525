export default function errorHandlers(response, callback, successMessage = 'Данные успешно сохранены') {
    if (!response)
        return;
    const { errors } = response;
    if (errors) {
        callback(errors, 'error');
        return;
    }
    callback(successMessage, 'success');
}
