import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'nuxt-property-decorator';
let InfoTooltip = class InfoTooltip extends Vue {
};
__decorate([
    Prop({ type: String, default: () => '' }),
    __metadata("design:type", Object)
], InfoTooltip.prototype, "text", void 0);
__decorate([
    Prop({ type: String, default: () => 'right' }),
    __metadata("design:type", Object)
], InfoTooltip.prototype, "position", void 0);
__decorate([
    Prop({ type: Number, default: () => 24 }),
    __metadata("design:type", Object)
], InfoTooltip.prototype, "fontSize", void 0);
__decorate([
    Prop({ type: String, default: () => '' }),
    __metadata("design:type", Object)
], InfoTooltip.prototype, "iconClass", void 0);
__decorate([
    Prop({ type: String, default: 'mdi-information' }),
    __metadata("design:type", String)
], InfoTooltip.prototype, "customIcon", void 0);
InfoTooltip = __decorate([
    Component({ inheritAttrs: false })
], InfoTooltip);
export default InfoTooltip;
