import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=6364b01a&"
import script from "./Modal.vue?vue&type=script&lang=ts&"
export * from "./Modal.vue?vue&type=script&lang=ts&"
import style0 from "./Modal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VCard,VCardText,VDialog,VProgressLinear})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Resize,Ripple})
