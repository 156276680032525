import { mixinActions, mixinMutations } from '@/store/mixins';
import { calculateSum, getCurrentComment, notEmpty, findCurrentParent } from './helpers';
import defaultState from './defaultState';
const state = defaultState();
const getters = {
    commentsCount(state) {
        return calculateSum(state.comments);
    }
};
const mutations = {
    ...mixinMutations(),
    setCurrentCommentId(state, payload) {
        state.currentCommentId = payload;
    },
    setEditCommentId(state, payload) {
        state.editCommentId = state.editCommentId === payload ? 0 : payload;
    }
};
// eslint-disable-next-line
const actions = {
    ...mixinActions(),
    async getComments({ commit }, { url, tab }) {
        const response = await this.getDataStore(url, {
            sort: 'created_at',
            with: 'creator:name,surname',
            nesting: 1,
            desc: 0,
            ...tab
        });
        const { data } = response;
        commit('setProperty', { name: 'comments', value: data });
    },
    async addComment({ state, rootState, rootGetters }, { message, params, type, no_push, is_public }) {
        if (!message || !message.match(notEmpty))
            return;
        // const text = buildMessage(message);
        const text = message;
        const { order_id, ...rest } = params;
        const response = await this.postDataStore(`${rootGetters.API_URL}/orders/${order_id}/order_comments`, {
            ...rest, text, type, is_public
        }, 'POST');
        const { data, errors } = response;
        if (errors)
            return;
        const { id, name, surname, photo } = rootState.adminProfile;
        const result = {
            ...data,
            creator: {
                id, name, surname, photo
            },
            answers: []
        };
        if (no_push)
            return result;
        state.comments.push(result);
        return result;
    },
    async replyToComment({ state, dispatch }, { message, type, comment_id, question, is_public }) {
        if (!message || !message.match(notEmpty))
            return;
        const currentComment = getCurrentComment(state.comments, comment_id);
        const data = {
            message, params: { ...state.params, comment_id, question }, is_public, type, no_push: true
        };
        const result = await dispatch('addComment', data);
        currentComment.answers.push(result);
    },
    async deleteComment({ state, rootGetters }, { order_id, comment_id }) {
        await this.postDataStore(`${rootGetters.API_URL}/orders/${order_id}/order_comments/${comment_id}`, {}, 'DELETE');
        const { comments } = state;
        const parents = getCurrentComment(comments, comment_id, 'total');
        const hasChildren = parents.hasOwnProperty('answers');
        if (!hasChildren) {
            const searched = parents.find((parent) => parent.id === comment_id);
            parents.splice(parents.indexOf(searched), 1);
            return;
        }
        const currentParent = findCurrentParent(comments, parents);
        const searched = currentParent.answers.find((child) => child.id === comment_id);
        currentParent.answers.splice(currentParent.answers.indexOf(searched), 1);
    },
    async updateComment({ rootGetters }, { order_id, comment_id, text }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/orders/${order_id}/order_comments/${comment_id}`, { text }, 'PUT');
        return data;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
