const actions = {
    async list({ rootGetters }, payload) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses`, payload);
    },
    async listWithCompilations({ rootGetters }, payload) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_compilations_with_courses`, payload);
    },
    async delete({ rootGetters }, { id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/${id}`, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    actions
};
