/* eslint-disable @typescript-eslint/no-explicit-any */
export const getFormFieldValues = (cutomFieldValues) => {
    if (!cutomFieldValues?.length)
        return {};
    const mappedValues = cutomFieldValues.reduce((acc, fieldValue) => {
        if (acc[fieldValue.custom_field_id]) {
            const { updated_at } = acc[fieldValue.custom_field_id];
            if (fieldValue.updated_at.localeCompare(updated_at) > 0) {
                acc[fieldValue.custom_field_id].updated_at = fieldValue.updated_at;
            }
            acc[fieldValue.custom_field_id].values.push(fieldValue.value);
        }
        else {
            acc[fieldValue.custom_field_id] = {
                type: fieldValue.custom_field_type,
                values: [fieldValue.value],
                media: fieldValue.media,
                updated_at: fieldValue.updated_at
            };
        }
        return acc;
    }, {});
    return mappedValues;
};
export const getFormGroups = (form) => {
    if (!form?.visible_fields?.length)
        return [];
    const fields = [];
    const titles = form.visible_fields
        .filter(({ type }) => type === 'title')
        .sort((a, b) => a.sort - b.sort);
    const others = form.visible_fields
        .filter(({ type, name }) => type !== 'title' && name)
        .sort((a, b) => a.sort - b.sort);
    if (!titles.length) {
        fields.push(...others);
    }
    const groups = titles.reduce((acc, field, index) => {
        const { sort } = field;
        const nextSort = titles[index + 1]?.sort || Infinity;
        if (index === 0) {
            fields.push(...others.filter((item) => item.sort < sort));
        }
        const wholeFields = others
            .filter((item) => item.sort > sort && item.sort < nextSort);
        acc.push({
            id: field.id,
            title: field.text,
            fields: wholeFields
        });
        return acc;
    }, [{ id: form.id, title: form.title, fields }]);
    return groups.filter((group) => group.fields.length);
};
