import { BASE_URL } from '@/api';
const getters = {
    getApiURL(state, getters, rootState, rootGetters) {
        return {
            base: BASE_URL,
            api: rootGetters.API_URL
        };
    }
};
const actions = {
    async list({ rootGetters }, { params }) {
        return await this.getDataStore(`${rootGetters.API_URL}/file_storage`, {
            with: 'media', sort: 'created_at', desc: 1, ...params
        });
    },
    async delete({ rootGetters }, { params: { id } }) {
        return await this.postDataStore(`${rootGetters.API_URL}/file_storage/${id}`, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    getters,
    actions
};
