import Vue from 'vue';
import { mixinMutations } from '@/store/mixins';
import defaultState from './entities/defaultState';
import contextMenu from './entities/contextMenu';
import changeHistories from './entities/changeHistories';
import column from './entities/column';
import comment from './entities/comment';
import header from './entities/header';
import list from './entities/list';
import total from './entities/total';
const state = defaultState();
const getters = { ...header.getters };
const mutations = {
    ...mixinMutations(),
    ...contextMenu.mutations,
    ...column.mutations,
    ...header.mutations,
    ...list.mutations,
    ...total.mutations,
    showCellEditDialog(state, payload) {
        Vue.set(state.editCellDialog, 'cell', payload.cell);
        state.editCellDialog.options = { pos: payload.pos, show: true };
    },
    hideCellEditDialog(state) {
        state.editCellDialog = {
            options: { pos: { x: 0, y: 0 }, show: false },
            cell: null
        };
    },
    addDownloadingEvent(state, payload) {
        state.downloadingEvents.push(payload);
    },
    removeDownloadingEvent(state, { id, type }) {
        state.downloadingEvents = state.downloadingEvents.filter((event) => event.id !== id && event.type !== type);
    }
};
const actions = {
    ...changeHistories.actions,
    ...column.actions,
    ...comment.actions,
    ...header.actions,
    ...list.actions,
    ...total.actions
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
