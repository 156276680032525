import { __decorate } from "tslib";
import { Component, Vue } from 'nuxt-property-decorator';
import ModalVDialog from '@/components/ModalVDialog.vue';
import AlertMessages from '@/components/AlertMessages.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import handleErrors from '@/helpers/ErrorHandlers';
import copyToClipboard from '@/helpers/CopyToClipboard';
import buildDate from '@/helpers/BuildDate';
import DownloadService from '@/helpers/UploadScripts';
const striptags = require('striptags');
let App = class App extends Vue {
    constructor() {
        super(...arguments);
        this.isChromeAlert = false;
        this.downloadService = new DownloadService();
    }
    mounted() {
        this.$root.$modal = this.$refs.modal.open;
        this.$root.$uploadScripts = this.downloadService.uploadScript;
        this.$root.$alert = this.$refs.alert.sendMessage;
        this.$root.$copyToClipboard = (text, successMessage) => copyToClipboard(text, this.$root.$alert, successMessage);
        this.$root.$hideHtmlOverflow = (flag) => {
            // eslint-disable-next-line
            const [html] = document.getElementsByTagName('html');
            if (flag)
                html.style.overflow = 'hidden';
            else
                html.style.overflow = 'auto';
        };
        this.$root.striptags = striptags;
        this.$root.$buildDate = buildDate;
        this.$root.$handleErrors = (response, successMessage = 'Данные успешно сохранены') => {
            handleErrors(response, this.$root.$alert, successMessage);
        };
        this.$root.$confirm = this.$refs.confirm.openDialog;
        const isChrome = window.navigator && window.navigator.vendor === 'Google Inc.';
        if (!isChrome)
            this.showAlert();
    }
    showAlert() {
        this.isChromeAlert = true;
    }
    hideAlert() {
        this.isChromeAlert = false;
    }
};
App = __decorate([
    Component({ components: { AlertMessages, ConfirmModal, ModalVDialog } })
], App);
export default App;
