export const mixinMutations = () => ({
    setProperty(state, payload) {
        if (!Array.isArray(payload)) {
            const { name, value } = payload;
            state[name] = value;
            return;
        }
        payload.forEach(({ name, value }) => state[name] = value);
    },
    setShowSuccess(state, payload) {
        state.showSuccess = payload;
    },
    setCurrentTab(state, payload) {
        state.currentTab = payload;
    },
    setState(state, payload) {
        if (!payload)
            return;
        Object.entries(payload).forEach(([key, value]) => {
            if (state.hasOwnProperty(key)) {
                state[key] = value;
            }
        });
    },
    clearState(state, freezeState) {
        Object.entries(freezeState).forEach(([key, value]) => {
            state[key] = value;
        });
    }
});
export const mixinActions = () => ({
    async showingSuccess({ commit }, response) {
        const { errors } = response;
        if (!errors) {
            commit('setProperty', { name: 'showSuccess', value: true });
            commit('setProperty', { name: 'errors', value: '' });
        }
        else {
            commit('setProperty', { name: 'errors', value: errors });
        }
    }
});
