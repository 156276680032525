import { nanoid } from 'nanoid';
import buildDate from '@/helpers/BuildDate';
import buildPrice from '@/helpers/BuildPrice';
function getStatus(status) {
    const statusVocabulary = {
        draft: 'Черновик',
        close: 'Закрытый',
        default: 'Активный'
    };
    return statusVocabulary[status] || statusVocabulary.default;
}
export default ({ id, folder_compilation, auth_user_favourites_exists, folder_course, entity_type, course_packages_by_sort, name, img_anons, status, img, price, created_at, is_constructor, subcategories_with_categories, start_date, end_date, students_count, customFieldCourseValues }) => {
    let resultDate = '';
    if (start_date && end_date) {
        resultDate = `${start_date.split('T')[0].split('-').reverse().join('.')}-${end_date.split('T')[0].split('-').reverse().join('.')}`;
    }
    else if (start_date) {
        resultDate = `Дата начала: ${start_date.split('T')[0].split('-').reverse().join('.')}`;
    }
    else if (created_at) {
        resultDate = `Дата создания: ${created_at.split('T')[0].split('-').reverse().join('.')}`;
    }
    const rusDate = created_at ? buildDate(created_at, { monthType: 'numbers' }).rusDate : '';
    const jsDate = created_at ? buildDate(created_at).jsDate : null;
    const values = customFieldCourseValues || [];
    const params = values.reduce((acc, { custom_field_id, ...elements }) => {
        const key = `custom_field_course_${[custom_field_id]}`;
        acc[key] = { value: elements };
        return acc;
    }, {});
    const labelGroups = subcategories_with_categories.reduce((result, category) => {
        const parentTitle = category.parent_category?.title;
        if (!parentTitle)
            return result;
        result[parentTitle]
            ? result[parentTitle] = [...result[parentTitle], { id: category.id, title: category.title }]
            : result[parentTitle] = [{ id: category.id, title: category.title }];
        return result;
    }, {});
    const itemGroups = Object.entries(labelGroups).map(([key, value]) => ({ title: key, items: value }));
    const type = entity_type || 'course';
    const paramsVocabulary = {
        course_compilation: { compilation_id: id },
        default: { course_id: id }
    };
    const routeParams = paramsVocabulary[type || 'default'] || paramsVocabulary.default;
    const namesVocabulary = {
        course_compilation: 'CourseCompilation',
        default: 'Course'
    };
    const routeName = namesVocabulary[type || 'default'] || namesVocabulary.default;
    return {
        id,
        price: price || 0,
        rusPrice: buildPrice(price) || 'Бесплатно',
        rusDate,
        jsDate,
        auth_user_favourites_exists,
        favorite: { id, isFavorite: auth_user_favourites_exists, entity_type },
        img: { img: img_anons || img || '/static-images/tmp.jpg' },
        selected: false,
        resultDate,
        subcategories: subcategories_with_categories && subcategories_with_categories.length
            ? subcategories_with_categories.map((cat) => ({ ...cat, render_id: nanoid(10) })) : [],
        students_count,
        render_id: nanoid(10),
        status: getStatus(status),
        start_date: { date: buildDate(start_date || '') },
        end_date: { date: buildDate(end_date || '') },
        date_start: { date: buildDate(start_date || '') },
        date_end: { date: buildDate(end_date || '') },
        created_at: { date: buildDate(created_at || '') },
        categories: {
            items: subcategories_with_categories && subcategories_with_categories.length
                ? subcategories_with_categories
                : [],
            itemValue: 'title',
            itemGroups,
            showGroups: true
        },
        course_packages_by_sort,
        is_constructor,
        name: {
            title: name || 'Без названия', routeName, params: routeParams, classTd: 'course-name__td', condition: true
        },
        type: entity_type === 'course_compilation' ? 'Подборка курсов' : is_constructor ? 'Курс конструктор' : 'Обычный курс',
        entity_type: entity_type === 'course_compilation' ? 'Подборка курсов' : 'Курс',
        folder: folder_compilation?.name || folder_course?.name || '-',
        ...params
    };
};
