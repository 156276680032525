export const defaultTabs = [
    {
        text: 'О курсе',
        value: 'settings'
    }
];
export const constructorTabs = [
    {
        text: 'Слои',
        value: 'layers'
    }
];
export const tabs = [
    ...defaultTabs,
    {
        text: 'Преподаватели и доступы',
        value: 'roles'
    },
    {
        text: 'Пользователи курса',
        value: 'users'
    },
    {
        text: 'CRM',
        value: 'crm'
    },
    {
        text: 'Сведения для сотрудников',
        value: 'course_form'
    },
    {
        text: 'История изменений',
        value: 'history'
    },
    {
        text: 'Ветки курса',
        value: 'branches'
    },
    {
        text: 'SEO',
        value: 'seo'
    },
    {
        text: 'Статистика',
        value: 'statistics'
    }
    // {
    //   text: 'Пакеты курса',
    //   value: 'packages'
    // }
];
