const defaultTabsPrepend = [
    {
        text: 'Содержимое урока',
        value: 'settings'
    },
    {
        text: 'Настройки',
        value: 'access'
    },
    {
        text: 'Роли и доступы',
        value: 'roles'
    },
    {
        text: 'Пользователи',
        value: 'users'
    }
];
const defaultTabsAppend = [
    {
        text: 'История изменений',
        value: 'history'
    }
];
const statisticsTab = [
    {
        text: 'Статистика тестов',
        value: 'statistics'
    }
];
const params = {
    webinar: [...defaultTabsPrepend, {
            text: 'Тесты',
            value: 'test'
        }, {
            text: 'Вебинар',
            value: 'webinar'
        }, ...defaultTabsAppend],
    full_video: [...defaultTabsPrepend, {
            text: 'Тесты',
            value: 'test'
        }, ...defaultTabsAppend],
    test: [...defaultTabsPrepend, {
            text: 'Тесты',
            value: 'test'
        }, ...defaultTabsAppend],
    constructor: [...defaultTabsPrepend, ...statisticsTab, ...defaultTabsAppend],
    default: [...defaultTabsPrepend, ...defaultTabsAppend]
};
export default (type) => params[type] || params.default;
