import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
let Modal = class Modal extends Vue {
    constructor() {
        super(...arguments);
        this.isOpen = false;
        this.isSmallScreen = window.innerWidth <= 425;
        this.disableAfterClick = false;
    }
    created() {
        this.isOpen = this.isModalOpen;
    }
    handleModal(isOpen) {
        this.isOpen = isOpen;
        this.$root.$hideHtmlOverflow(isOpen);
        if (!isOpen) {
            this.$nextTick(() => {
                this.disableAfterClick = false;
            });
        }
    }
    closeModal(event) {
        this.$emit('closeModal', event);
        this.$nextTick(() => {
            this.disableAfterClick = false;
        });
    }
    closeModalOutside(event) {
        if (!this.persistent)
            this.$emit('closeModal', event);
    }
    successModal() {
        if (!this.noDisable)
            this.disableAfterClick = true;
        this.$emit('successModal');
    }
    onResize() {
        this.isSmallScreen = window.innerWidth <= 425;
    }
    beforeDestroy() {
        this.$root.$hideHtmlOverflow(false);
    }
};
__decorate([
    Prop({ type: String, default: () => '' }),
    __metadata("design:type", Object)
], Modal.prototype, "modalTitle", void 0);
__decorate([
    Prop({ type: String, default: () => '' }),
    __metadata("design:type", Object)
], Modal.prototype, "modalText", void 0);
__decorate([
    Prop({ type: String, default: () => 'Применить' }),
    __metadata("design:type", Object)
], Modal.prototype, "successText", void 0);
__decorate([
    Prop({ type: String, default: () => 'Отменить' }),
    __metadata("design:type", Object)
], Modal.prototype, "cancelText", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Object)
], Modal.prototype, "hideCancel", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Object)
], Modal.prototype, "hideSuccess", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Object)
], Modal.prototype, "actionsDivider", void 0);
__decorate([
    Prop({ type: Boolean, default: () => true }),
    __metadata("design:type", Object)
], Modal.prototype, "showBtns", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Object)
], Modal.prototype, "reverseActions", void 0);
__decorate([
    Prop({}),
    __metadata("design:type", Object)
], Modal.prototype, "disabledSuccess", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Object)
], Modal.prototype, "persistent", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Object)
], Modal.prototype, "isModalOpen", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Object)
], Modal.prototype, "loading", void 0);
__decorate([
    Prop({ type: String, default: () => '' }),
    __metadata("design:type", Object)
], Modal.prototype, "customClass", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Object)
], Modal.prototype, "noDisable", void 0);
__decorate([
    Prop({ type: [String, Number], default: () => '550' }),
    __metadata("design:type", Object)
], Modal.prototype, "maxWidth", void 0);
__decorate([
    Watch('isModalOpen'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], Modal.prototype, "handleModal", null);
Modal = __decorate([
    Component
], Modal);
export default Modal;
