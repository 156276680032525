const types = [
    {
        text: 'Видео, тест и домашнее задание',
        value: 'full_video',
        icon: 'ondemand_video'
    },
    {
        text: 'Видео',
        value: 'video',
        icon: 'ondemand_video'
    },
    {
        text: 'Вебинар',
        value: 'webinar',
        icon: 'ondemand_video'
    },
    {
        text: 'Конспект',
        value: 'text',
        icon: 'format_size'
    },
    {
        text: 'Домашнее задание',
        value: 'homework',
        icon: 'format_size'
    },
    {
        text: 'Тест',
        value: 'test',
        icon: 'playlist_add_check'
    },
    {
        text: 'Очное занятие',
        value: 'offline',
        icon: 'school'
    },
    {
        text: 'Конструктор',
        value: 'constructor',
        icon: 'school'
    }
];
export default types;
