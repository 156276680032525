var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Ref, Vue, Watch } from 'nuxt-property-decorator';
import { Bind, Debounce } from '@/helpers/decorators';
import { BASE_URL } from '@/api';
import locale from './locale';
import toolbarButtons from './toolbarButtons';
let FroalaPage = class FroalaPage extends Vue {
    constructor() {
        super(...arguments);
        // Данные внутри редактора
        this.innerData = this.value || '';
        // Отображать ли ошибку об обязательности заполнения поля
        this.isRequiredError = false;
        // Объект редактора
        this.editor = null;
        this.baseEditorConfig = {
            language: 'ru',
            key: 'te1C2sB5D6D5B4D3D4jC1QUd1Xd1OZJ1ABVJRDRNGGUE1ITrE1D4A3A11B1B6D6B1F4E4==',
            htmlAllowedTags: ['.*'],
            htmlAllowedAttrs: ['.*'],
            htmlRemoveTags: [],
            fileUploadURL: `${BASE_URL}/api/profile/file`,
            charCounterCount: false,
            imageUploadParam: 'image',
            heightMin: this.height || 400,
            heightMax: this.heightMax || 10000,
            tableResizerOffset: 5,
            tableResizingLimit: 10,
            tableStyles: {
                'fr-dashed-borders': 'Пунктирные рамки',
                'fr-alternate-rows': 'Чередующиеся ряды',
                'fr-no-table-borders': 'Без рамок'
            },
            pluginsEnabled: ['align', 'emoticons', 'inlineStyle', 'lists'],
            placeholderText: this.placeholder,
            toolbarSticky: this.toolbarSticky,
            imageUploadURL: `${BASE_URL}/api/profile/images`,
            requestHeaders: { Authorization: localStorage.getItem('access_token'), Accept: 'application/json' },
            pastePlain: true,
            imagePaste: false,
            toolbarButtons,
            htmlAllowedEmptyTags: ['iframe']
        };
    }
    created() {
        this.initFroalaScripts();
    }
    async initFroalaScripts() {
        const isFroalaLoaded = await this.$root.$uploadScripts({ url: '/static-scripts/froala.js', targetClass: 'froala-script', type: 'js' });
        if (!isFroalaLoaded)
            return;
        const res = await Promise.all([
            this.$root.$uploadScripts({ url: '/static-scripts/froala-plugins.js', targetClass: 'froala-plugins.js-script', type: 'js' })
        ]);
        if (res.filter((res) => !res).length)
            return;
        this.setEditor();
    }
    beforeDestroy() {
        this.mathEditorRemove();
    }
    /**
     * @vuese
     * Прекращает передачу события
     */
    catchHtmlEvent(el) {
        el.addEventListener('focusin', (event) => {
            event.stopPropagation();
        });
    }
    /**
     * @vuese
     * Главная функция, в которой происходит настройка и создание редактора
     */
    setEditor() {
        const { FroalaEditor } = window;
        FroalaEditor.DefineIcon('clear', { NAME: 'remove', SVG_KEY: 'remove' });
        FroalaEditor.RegisterCommand('clear', {
            title: 'Очистить редактор',
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            callback() {
                this?.html.set('');
                this.events.focus();
            }
        });
        FroalaEditor.LANGUAGE.ru = locale;
        const editor = new FroalaEditor(`#froala_${this.name}`, {
            ...this.baseEditorConfig,
            events: {
                initialized: () => {
                    const { value } = this;
                    const htmlVal = value || '';
                    editor?.html.set(htmlVal);
                    // eslint-disable-next-line
                    editor.wiris?._init();
                },
                focus: () => {
                    /** Отправляет наверх событие фокусировке на редакторе */
                    this.$emit('focusEditor');
                },
                blur: this.updateAfterBlur,
                input: () => {
                    const innerHtmlFroala = editor?.html.get();
                    this.onInnerDataChanged(innerHtmlFroala);
                    /** Отправляет наверх событие input */
                    this.$emit('input');
                },
                'commands.after': (cmd) => {
                    if (cmd === 'wirisEditor' || cmd === 'imageAlt') {
                        const selector = cmd === 'wirisEditor' ? '.wrs_modal_desktop' : '.fr-image-alt-layer';
                        const element = document.querySelector(selector);
                        this.catchHtmlEvent(element);
                    }
                    const isHideFullScreen = cmd === 'fullscreen' && (!document.querySelectorAll('.fr-fullscreen-wrapper')
                        || !document.querySelectorAll('.fr-fullscreen-wrapper').length);
                    if (isHideFullScreen) {
                        const el = document.querySelector(`#froala_${this.name}`);
                        if (!el)
                            return;
                        const { top } = el.getBoundingClientRect();
                        window.scrollBy({ top: top - 90, behavior: 'smooth' });
                    }
                }
            }
        });
        this.editor = editor;
    }
    /**
     * @vuese
     * Вставляет текст в редактор
     */
    insertText(text) {
        this.editor?.html.insert(text);
    }
    /**
     * @vuese
     * Отправляет наверх событие blur
     * @arg Текст, который нужно передать наверх
     */
    blurEditor(value) {
        /** Отправляет наверх событие blur - клик вне редактора
         * @arg Текст, который нужно передать наверх
         */
        this.$emit('blurEditor', { value, name: this.name });
    }
    /**
     * @vuese
     * Удаляет математический редактор
     */
    mathEditorRemove() {
        let tempArray = [];
        tempArray.push(document.querySelector('.wrs_modal_dialogContainer'));
        tempArray.push(document.querySelector('.wrs_modal_overlay'));
        if (tempArray.length) {
            tempArray.forEach((item) => {
                item?.parentNode?.removeChild(item);
            });
            tempArray = [];
        }
    }
    /**
     * @vuese
     * Вызывается редактором при клике вне его зоны
     */
    updateAfterBlur() {
        const rootEl = this.froala;
        this.contentPostprocessing(rootEl);
        const htmlContent = this.editor?.codeView?.isActive() ? this.editor?.codeView?.get() : this.froala?.querySelector('.fr-element.fr-view').innerHTML;
        const { required, withEmpty } = this;
        if (withEmpty) {
            const clearString = htmlContent.replaceAll('<br>', '')
                .replaceAll('<p></p>', '').trim();
            this.blurEditor(clearString);
            return;
        }
        if (required) {
            const clearString = htmlContent.replaceAll('<br>', '')
                .replaceAll('<p></p>', '').trim();
            if (clearString) {
                this.blurEditor(htmlContent);
                this.isRequiredError = false;
                return;
            }
            this.blurEditor(clearString);
            this.isRequiredError = true;
        }
        if (!required) {
            this.blurEditor(htmlContent);
        }
    }
    /**
     * @vuese
     * Обработка контента перед отдачей из редактора
     */
    contentPostprocessing(rootEl) {
        const hiddenInputs = rootEl?.querySelectorAll('.editor__hidden-text__input');
        Array.from(hiddenInputs).forEach((input) => {
            input.removeAttribute('checked');
        });
    }
    /**
     * @vuese
     * Вызывается при изменении контента редактора пользователем
     */
    onInnerDataChanged(innerHtmlFroala) {
        this.$emit('input', innerHtmlFroala);
        /**
         * Отправляет наверх событие change при изменении контента
         * @arg Текущий контент редактора
         */
        this.$emit('change', innerHtmlFroala);
    }
    /**
     * @vuese
     * Переопределяет контент внутри редактора при его изменении вне
     */
    onValueChange(newVal) {
        this.innerData = newVal || '';
    }
    /**
     * @vuese
     * Переопределяет необходимость показывать ошибку при изменении вне редактора
     */
    inShowErrorChange(value) {
        this.isRequiredError = value;
    }
};
__decorate([
    Ref(),
    __metadata("design:type", typeof (_a = typeof HTMLElement !== "undefined" && HTMLElement) === "function" ? _a : Object)
], FroalaPage.prototype, "froala", void 0);
__decorate([
    Prop({
        type: String,
        default: () => ''
    }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "value", void 0);
__decorate([
    Prop({
        type: [String, Number],
        default: () => ''
    }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "name", void 0);
__decorate([
    Prop({
        type: [Number, String],
        default: () => 290
    }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "height", void 0);
__decorate([
    Prop({
        type: [Number, String],
        default: () => 10000
    }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "heightMax", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: () => false
    }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "toolbarSticky", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: () => false
    }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "required", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: () => false
    }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "showError", void 0);
__decorate([
    Prop({
        type: Boolean,
        default: () => false
    }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "withEmpty", void 0);
__decorate([
    Prop({
        type: String,
        default: () => ''
    }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "placeholder", void 0);
__decorate([
    Bind,
    Debounce(500),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], FroalaPage.prototype, "onInnerDataChanged", null);
__decorate([
    Watch('value'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], FroalaPage.prototype, "onValueChange", null);
__decorate([
    Watch('showError'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], FroalaPage.prototype, "inShowErrorChange", null);
FroalaPage = __decorate([
    Component
], FroalaPage);
export default FroalaPage;
