class InitState {
    static createDefaultState() {
        return { ...this.user };
    }
}
InitState.user = {
    id: 0,
    name: '',
    surname: '',
    email: '',
    photo_color: '',
    photo: '',
    page: 1,
    finishLoading: false,
    courses: [],
    custom_fields: []
};
export default InitState;
