import { mixinMutations } from '@/store/mixins';
const state = { layers: [] };
const mutations = { ...mixinMutations() };
const actions = {
    async getLayersList({ rootGetters, commit }, { course_id }) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/block_layers`, {
            with: 'subLayersWithLessonBlocks',
            withCount: 'lessonBlocksBlockLayer',
            sort: 'sort_order',
            type: 'layer'
        });
        if (!errors)
            commit('setProperty', { name: 'layers', value: data });
        return { data, errors };
    },
    async createLayer({ rootGetters, state, commit }, { course_id }) {
        const params = { title: 'Новая категория' };
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/block_layers`, params);
        if (!errors)
            commit('setProperty', { name: 'layers', value: [...state.layers, data] });
        return { data, errors };
    },
    async createSublayer({ rootGetters, state, commit }, { course_id, parent_layer_id }) {
        const params = {
            title: 'Новый слой',
            parent_layer_id
        };
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/block_layers`, params);
        if (errors)
            return { data, errors };
        const currentLayerIndex = state.layers.findIndex(({ id }) => id === parent_layer_id);
        const currentLayer = state.layers[currentLayerIndex];
        const updatedLayers = [
            ...state.layers.slice(0, currentLayerIndex),
            { ...currentLayer, sub_layers_with_lesson_blocks: currentLayer.sub_layers_with_lesson_blocks ? [...currentLayer.sub_layers_with_lesson_blocks, data] : [data] },
            ...state.layers.slice(currentLayerIndex + 1)
        ];
        commit('setProperty', { name: 'layers', value: updatedLayers });
        return { data, errors };
    },
    async updateLayer({ rootGetters, state, commit }, { course_id, layer_id, params }) {
        const requestURL = `${rootGetters.API_URL}/courses/${course_id}/block_layers/${layer_id}`;
        const { data, errors } = await this.postDataStore(requestURL, params, 'PUT');
        if (errors)
            return { errors };
        const currentLayerIndex = state.layers.findIndex(({ id }) => id === layer_id);
        const currentLayer = state.layers[currentLayerIndex];
        const updatedLayers = [
            ...state.layers.slice(0, currentLayerIndex),
            { ...currentLayer, ...data },
            ...state.layers.slice(currentLayerIndex + 1)
        ];
        commit('setProperty', { name: 'layers', value: updatedLayers });
        return { data, errors };
    },
    async updateSublayer({ rootGetters, state, commit }, { course_id, sublayer_id, parent_layer_id, params }) {
        const requestURL = `${rootGetters.API_URL}/courses/${course_id}/block_layers/${sublayer_id}`;
        const { data, errors } = await this.postDataStore(requestURL, params, 'PUT');
        if (errors)
            return { errors };
        const currentLayerIndex = state.layers.findIndex(({ id }) => id === parent_layer_id);
        const currentLayer = state.layers[currentLayerIndex];
        const currentSublayerIndex = currentLayer.sub_layers_with_lesson_blocks.findIndex(({ id }) => id === sublayer_id);
        const currentSublayer = currentLayer.sub_layers_with_lesson_blocks[currentSublayerIndex];
        const updatedSublayers = [
            ...currentLayer.sub_layers_with_lesson_blocks.slice(0, currentSublayerIndex),
            { ...currentSublayer, ...data },
            ...currentLayer.sub_layers_with_lesson_blocks.slice(currentSublayerIndex + 1)
        ];
        const updatedLayers = [
            ...state.layers.slice(0, currentLayerIndex),
            { ...currentLayer, sub_layers_with_lesson_blocks: updatedSublayers },
            ...state.layers.slice(currentLayerIndex + 1)
        ];
        commit('setProperty', { name: 'layers', value: updatedLayers });
        return { data, errors };
    },
    async updateLayersSorting({ rootGetters }, { course_id, params }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/block_layers/multi_update`, params);
    },
    async deleteLayer({ rootGetters, state, commit }, { course_id, layer_id }) {
        const requestURL = `${rootGetters.API_URL}/courses/${course_id}/block_layers/${layer_id}`;
        const { data, errors } = await this.postDataStore(requestURL, {}, 'DELETE');
        if (errors)
            return { errors };
        const currentLayerIndex = state.layers.findIndex(({ id }) => id === layer_id);
        const updatedLayers = [
            ...state.layers.slice(0, currentLayerIndex),
            ...state.layers.slice(currentLayerIndex + 1)
        ];
        commit('setProperty', { name: 'layers', value: updatedLayers });
        return { data, errors };
    },
    async deleteSublayer({ rootGetters, state, commit }, { course_id, sublayer_id, parent_layer_id }) {
        const requestURL = `${rootGetters.API_URL}/courses/${course_id}/block_layers/${sublayer_id}`;
        const { data, errors } = await this.postDataStore(requestURL, {}, 'DELETE');
        if (errors)
            return { errors };
        const currentLayerIndex = state.layers.findIndex(({ id }) => id === parent_layer_id);
        const currentLayer = state.layers[currentLayerIndex];
        const currentSublayerIndex = currentLayer.sub_layers_with_lesson_blocks.findIndex(({ id }) => id === sublayer_id);
        const updatedSublayers = [
            ...currentLayer.sub_layers_with_lesson_blocks.slice(0, currentSublayerIndex),
            ...currentLayer.sub_layers_with_lesson_blocks.slice(currentSublayerIndex + 1)
        ];
        const updatedLayers = [
            ...state.layers.slice(0, currentLayerIndex),
            { ...currentLayer, sub_layers_with_lesson_blocks: updatedSublayers },
            ...state.layers.slice(currentLayerIndex + 1)
        ];
        commit('setProperty', { name: 'layers', value: updatedLayers });
        return { data, errors };
    },
    async getSublayerLessons({ rootGetters }, { course_id, sublayer_id }) {
        const requestURL = `${rootGetters.API_URL}/courses/${course_id}/block_layers/${sublayer_id}/get_lesson_block_sublayer`;
        return await this.getDataStore(requestURL, { only: 'name' });
    },
    clearState({ commit }) {
        commit('clearState', { layers: [] });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
