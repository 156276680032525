import { mixinMutations } from '@/store/mixins';
const mutations = { ...mixinMutations() };
const state = { courses_count: 0 };
const actions = {
    async list({ rootGetters }, { params }) {
        return await this.getDataStore(`${rootGetters.API_URL}/review`, params);
    },
    async getReview({ rootGetters }, id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/review/${id}`, { with: 'user;teacher' });
        return data;
    },
    async getCourse({ rootGetters }, { course_id }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}`);
        return data;
    },
    async getTeacherReview({ rootGetters }, id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/review/${id}`);
        return data;
    },
    async createReview({ rootGetters }, params) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/review`, params);
        return data;
    },
    async updateReview({ rootGetters }, { id, params }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/review/${id}`, params, 'PUT');
        return data;
    },
    async delete({ rootGetters }, { params: { id } }) {
        return await this.postDataStore(`${rootGetters.API_URL}/review/${id}`, {}, 'DELETE');
    },
    async getUsers({ rootGetters }, { search }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/users`, { search, only: 'name,surname' });
        return data;
    },
    async getTeachers({ rootGetters }, { search }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/teacher/users`, { search, only: 'name,surname' });
        return data;
    },
    async getCourses({ rootGetters }, { search }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/courses`, { search, only: 'name' });
        return data;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
