import initState from './entities/initState';
const state = initState();
const mutations = {
    changePermission(state, payload) {
        const permissionOff = Object.keys(state.permissionsRole).reduce((acc, key) => {
            acc[key] = false;
            return acc;
        }, {});
        state.permissionsRole = { ...permissionOff, ...payload };
    },
    changeDataRole(state, payload) {
        state.roleData = payload;
    },
    setForms(state, payload) {
        state.roleForms = payload;
    },
    clearState(state) {
        const s = initState();
        Object.keys(s).forEach((key) => {
            state[key] = s[key];
        });
    }
};
const actions = {
    async getRole({ state, commit, rootGetters }, payload) {
        const { path, params } = payload;
        const roleId = path.id;
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/roles/${roleId}`, params);
        const permFetch = Object.keys(state.permissionsRole).reduce((acc, key) => {
            acc = { ...acc, [key]: data[key] };
            return acc;
        }, {});
        const dataFetch = Object.keys(state.roleData).reduce((acc, key) => {
            acc = { ...acc, [key]: data[key] };
            return acc;
        }, {});
        commit('changePermission', permFetch);
        commit('changeDataRole', dataFetch);
        commit('setForms', data.forms);
    },
    async changeDataRole({ state, commit, rootGetters }) {
        const roleId = state.roleData.id;
        const dataSave = { ...state.roleData, ...state.permissionsRole };
        delete dataSave.id;
        if (roleId) {
            return await this.postDataStore(`${rootGetters.API_URL}/roles/${roleId}`, dataSave, 'PUT');
        }
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/roles`, dataSave);
        const dataFetch = Object.keys(state.roleData).reduce((acc, key) => {
            acc = { ...acc, [key]: data[key] };
            return acc;
        }, {});
        commit('changeDataRole', dataFetch);
        return data.id;
    },
    async getRoles({ rootGetters }) {
        return await this.getDataStore(`${rootGetters.API_URL}/roles`, {
            all: 1,
            only: 'name'
        });
    },
    async getCoursesList({ rootGetters }) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses`, { all: 1 });
    },
    async list({ rootGetters }, payload) {
        const { params } = payload;
        return await this.getDataStore(`${rootGetters.API_URL}/roles`, params);
    },
    async delete({ rootGetters }, { params: { id } }) {
        return await this.postDataStore(`${rootGetters.API_URL}/roles/${id}`, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
