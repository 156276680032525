const convertDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    return `${year}-${month}-${day}`;
};
export const buildToday = () => {
    const today = new Date;
    return convertDate(today);
};
export const buildMonthAgo = () => {
    const today = new Date;
    const monthToday = today.getMonth() + 1;
    const previousMonth = monthToday === 1 ? 12 : monthToday - 1;
    const thisYear = today.getFullYear();
    const previousYear = previousMonth === 12 ? thisYear - 1 : thisYear;
    const todayDay = today.getDate();
    const longMonths = [1, 3, 5, 7, 8, 10, 12];
    const getTargetDay = () => {
        if (longMonths.includes(previousMonth))
            return todayDay;
        if (previousMonth === 2 && todayDay > 28)
            return 28;
        return todayDay <= 30 ? todayDay : 30;
    };
    const targetDay = getTargetDay();
    return `${String(previousYear)}-${String(previousMonth).padStart(2, '0')}-${String(targetDay).padStart(2, '0')}`;
};
export const buildWeekAgo = () => {
    const todayInMilliseconds = Date.now();
    const weekAgoInMilliseconds = todayInMilliseconds - (7 * 24 * 60 * 60 * 1000);
    const targetDay = new Date(weekAgoInMilliseconds);
    return convertDate(targetDay);
};
