export const topMenu = [
    {
        text: 'Мой профиль',
        routeName: 'profile'
    }
];
export const bodyMenu = [
    {
        text: 'Мои компании',
        routeName: 'companies'
    }
];
export const questions = [
    {
        title: 'Как создать Компанию?',
        text: 'Незарегистрированные пользователи могут создать компанию при регистрации, а зарегистрированные – в профиле: в правом верхнем углу перейдите в свой профиль и нажмите "Добавить организацию".',
        tooltip: 'Вы можете создавать неограниченное количество компаний с разными доменами.'
    },
    {
        title: 'Как редактировать профиль компании?',
        text: 'Для редактирования профиля организации на главной странице перейдите в левом нижнем углу в раздел "Настройки компании".',
        tooltip: 'В профиле организации вы можете настроить отображение компании, обновить логотип, загрузить лицензию и другие документы.'
    },
    {
        title: 'Хотите удалить Компанию?',
        text: 'Для удаления компании на главной странице перейдите в левом нижнем углу в раздел "Настройки компании" и нажмите "удалить"',
        tooltip: 'После удаления восстановление компании невозможно.'
    }
];
