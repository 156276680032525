export default [
    {
        text: 'ID', value: 'id', sortable: true, checked: true, subtype: 'courseSimple', sort: 1, type: 'integer', disabled: true, width: '48px'
    },
    {
        text: 'Название', value: 'name', sortable: true, checked: true, subtype: 'courseSimple', sort: 2, disabled: false, type: 'string', width: '320px'
    },
    {
        text: 'Дата начала', value: 'start_date', sortable: true, checked: true, subtype: 'courseSimple', sort: 3, type: 'date'
    },
    {
        text: 'Дата окончания', value: 'end_date', sortable: true, checked: true, subtype: 'courseSimple', sort: 4, type: 'date'
    },
    {
        text: 'Статус', value: 'status', checked: true, subtype: 'courseSimple', sort: 5, type: 'list'
    },
    {
        text: 'Стоимость', value: 'price', checked: true, subtype: 'courseSimple', sort: 6, type: 'price'
    },
    {
        text: 'Стоимость старая', value: 'old_price', checked: true, subtype: 'courseSimple', sort: 7, type: 'price'
    },
    {
        text: 'Предлог "от"', value: 'preposition_from', checked: true, subtype: 'courseSimple', sort: 8, type: 'boolean'
    },
    {
        text: 'Может быть куплен', value: 'can_be_bought', checked: true, subtype: 'courseSimple', sort: 9, type: 'boolean'
    },
    {
        text: 'Комментарий к цене', value: 'price_comment', checked: true, subtype: 'courseSimple', sort: 10, type: 'froala', width: '240px'
    },
    {
        text: 'Название SEO', value: 'seo_title', checked: true, subtype: 'courseSimple', sort: 11, type: 'string'
    },
    {
        text: 'Расписание', value: 'weeks_time', checked: true, subtype: 'courseSimple', sort: 12, type: 'string'
    },
    {
        text: 'Цель подготовки', value: 'goal', checked: true, subtype: 'courseSimple', sort: 13, type: 'string'
    },
    {
        text: 'Краткое описание', value: 'anons', checked: true, subtype: 'courseSimple', sort: 14, type: 'string'
    },
    {
        text: 'Описание', value: 'description', checked: true, subtype: 'courseSimple', sort: 15, type: 'froala'
    },
    {
        text: 'Использовать скидку на покупку полного курса', value: 'use_full_course_discount_percentage', checked: true, subtype: 'courseSimple', sort: 16, type: 'boolean'
    },
    {
        text: 'Использовать доп. скидку в модальном окне', value: 'use_special_offer_discount_percentage', checked: true, subtype: 'courseSimple', sort: 17, type: 'boolean'
    },
    {
        text: 'Скидка на покупку полного курса (%)', value: 'full_course_discount_percentage', checked: true, subtype: 'courseSimple', sort: 18, type: 'integer'
    },
    {
        text: 'Дополнительная скидка в модальном окне (%)', value: 'special_offer_discount_percentage', checked: true, subtype: 'courseSimple', sort: 19, type: 'integer'
    },
    {
        text: 'Программа курса', value: 'program', checked: true, subtype: 'courseSimple', sort: 20, type: 'froala', width: '320px'
    }
];
