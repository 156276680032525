const defaultStateFactory = () => {
    return {
        unique_user_statistic_count: 0,
        all_user_statistic_count: 0,
        new_users_count: 0,
        visits_count: 0,
        avg_times_count: 0 // - среднее время проведенное на платформе (в секундах)
    };
};
export default defaultStateFactory;
