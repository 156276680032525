import { mixinMutations } from '@/store/mixins';
import defaultState from './defaultState';
import buildCategoriesTree from './buildCategoriesTree';
const state = defaultState();
const mutations = {
    ...mixinMutations(),
    setCategories(state, payload) {
        state.categories = [...payload];
        state.categoriesTree = buildCategoriesTree(payload, state.articles_total);
    },
    setFirstPage(state) {
        state.page = 1;
    }
};
const actions = {
    async getArticles({ dispatch }, { params = {} } = {}) {
        return await dispatch('getArticlesTable', { params: { ...params, sort: 'published_at', desc: 1 } });
    },
    async getArticlesTable({ rootGetters, commit }, { params = {} } = {}) {
        const response = await this.getDataStore(`${rootGetters.API_URL}/knowledge_articles`, { ...params });
        const articles_total = response?.meta?.total;
        commit('setProperty', { name: 'articles_total', value: articles_total || 0 });
        return response;
    },
    async getArticlesList({ commit, dispatch }, { params = {} } = {}) {
        const { data, errors } = await dispatch('getArticles', { params });
        if (errors)
            return { errors };
        commit('setProperty', { name: 'articles', value: data });
        if (data && data.length === 0)
            commit('setProperty', { name: 'finishLoading', value: true });
        return { data };
    },
    async pushArticles({ commit, dispatch, state }, { params = {} } = {}) {
        commit('setProperty', { name: 'page', value: state.page + 1 });
        const { data, errors } = await dispatch('getArticles', { params: { ...params, page: state.page, per_page: state.per_page } });
        if (errors)
            return { errors };
        commit('setProperty', { name: 'articles', value: [...state.articles, ...data] });
        if (data && data.length === 0)
            commit('setProperty', { name: 'finishLoading', value: true });
        return { data };
    },
    async createArticle({ rootGetters }) {
        return await this.postDataStore(`${rootGetters.API_URL}/knowledge_articles`, {
            title: 'Название статьи',
            access_type: 'open'
        });
    },
    async createCategory({ rootGetters }, { payload }) {
        return await this.postDataStore(`${rootGetters.API_URL}/knowledge_categories`, payload, 'POST');
    },
    async updateCategory({ rootGetters }, { payload, id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/knowledge_categories/${id}`, payload, 'PUT');
    },
    async deleteCategory({ rootGetters }, { id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/knowledge_categories/${id}`, {}, 'DELETE');
    },
    async getArticleCategories({ rootGetters, commit }) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/knowledge_categories`, { all: 1 });
        if (errors)
            return { errors };
        commit('setCategories', data);
    },
    clearState({ commit }) {
        commit('clearState', defaultState());
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
