import statuses from '@/store/modules/course/entities/courseStatuses';
const defaultState = () => ({
    id: null,
    name: '',
    description: '',
    description_short: '',
    price: 0,
    price_discounted: 0,
    img_anons: '',
    img_banner: '',
    img_banner_mobile: '',
    img_vertical_banner: '',
    img_header: '',
    info_form_id: null,
    tabs: [
        {
            text: 'О подборке',
            value: 'settings'
        },
        {
            text: 'Курсы',
            value: 'courses'
        },
        {
            text: 'Преимущества',
            value: 'advantages'
        },
        {
            text: 'CRM',
            value: 'crm'
        },
        {
            text: 'Сведения для сотрудников',
            value: 'compilation_form'
        },
        {
            text: 'История изменений',
            value: 'history'
        }
    ],
    currentTab: {
        text: 'О подборке',
        value: 'settings'
    },
    statuses,
    status: '',
    currentStatus: null,
    subcategories_with_categories: [],
    company_id: 0,
    fields: [],
    courseCustomFields: [],
    isCompilationFormEditing: false,
    course_packages_by_sort: [],
    advantages_by_sort: [],
    crm_form_id: null,
    crm_bitrix: null,
    crm_bitrix_form_id: null,
    crm_bitrix_form_url: null,
    preposition_from: false,
    can_be_bought: false,
    yoo_kassa_ready: false,
    price_comment: ''
});
export default defaultState;
