import { BASE_URL } from '@/api';
import { mixinMutations } from '@/store/mixins';
import defaultState from './defaultState';
const state = defaultState();
const getters = {
    getApiURL(state, getters, rootState, rootGetters) {
        return {
            base: BASE_URL,
            api: rootGetters.API_URL
        };
    }
};
const mutations = {
    ...mixinMutations(),
    updateBlock(state, block) {
        const targetIndex = state.blocks.findIndex(({ id }) => id === block.id);
        state.blocks = [
            ...state.blocks.slice(0, targetIndex),
            block,
            ...state.blocks.slice(targetIndex + 1)
        ];
    },
    removeRelatedArticles(state, article_ids) {
        state.related_articles = state.related_articles.filter(({ id }) => !article_ids.includes(id));
    },
    appendUserAccess(state, access) {
        state.users_access = [...state.users_access, ...access];
    },
    appendCourseAccess(state, access) {
        state.courses_access = [...state.courses_access, ...access];
    },
    removeUserAccess(state, entity_ids) {
        state.users_access = state.users_access.filter(({ user_id }) => !entity_ids.includes(user_id));
    },
    removeCourseAccess(state, entity_ids) {
        state.courses_access = state.courses_access.filter(({ course_id }) => !entity_ids.includes(course_id));
    }
};
const actions = {
    async getArticle({ rootGetters, commit }, { article_id }) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/knowledge_articles/${article_id}`, { with: 'knowledgeBlocks,categories,accessAssignments,relatedArticles,media' });
        if (errors)
            return { errors };
        const { media, knowledge_blocks } = data;
        commit('setState', data);
        commit('setProperty', [
            { name: 'media', value: media ? media[0] : null },
            { name: 'blocks', value: knowledge_blocks?.sort((a, b) => (a?.sort < b?.sort ? -1 : 1)) || [] }
        ]);
        return { data };
    },
    async getUsersAccess({ rootGetters, commit }, { article_id }) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/knowledge_articles/${article_id}/access_assignments/list`, {
            onlyUsers: 1,
            all: 1,
            with: 'user'
        });
        if (errors)
            return { errors };
        commit('setProperty', { name: 'users_access', value: data });
        return { data };
    },
    async getCoursesAccess({ rootGetters, commit }, { article_id }) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/knowledge_articles/${article_id}/access_assignments/list`, {
            onlyCourses: 1,
            all: 1,
            with: 'course'
        });
        if (errors)
            return { errors };
        commit('setProperty', { name: 'courses_access', value: data });
        return { data };
    },
    async updateArticle({ rootGetters, commit }, { article_id, payload }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/knowledge_articles/${article_id}`, { ...payload }, 'PUT');
        if (errors)
            return { errors };
        commit('setProperty', Object.entries(payload).map(([key, value]) => ({ name: key, value })));
        return { data };
    },
    async createBlock({ rootGetters, state, commit }, { article_id, type }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/knowledge_blocks`, { type, knowledge_article_id: article_id });
        if (errors)
            return { errors };
        commit('setProperty', { name: 'blocks', value: [...state.blocks, data] });
    },
    async updateBlock({ rootGetters, commit }, { block_id, params = {} } = {}) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/knowledge_blocks/${block_id}`, { ...params }, 'PUT');
        if (errors)
            return { errors };
        commit('updateBlock', data);
        return { data };
    },
    async deleteBlock({ rootGetters, state, commit }, { block_id } = {}) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/knowledge_blocks/${block_id}`, {}, 'DELETE');
        if (errors)
            return { errors };
        commit('setProperty', { name: 'blocks', value: state.blocks.filter(({ id }) => id !== block_id) });
        return { data };
    },
    async sortBlocks({ rootGetters, commit }, { sorted_blocks = [] } = {}) {
        const params = sorted_blocks.map(({ id, sort }) => ({ id, sort }));
        const { errors, data } = await this.postDataStore(`${rootGetters.API_URL}/knowledge_blocks/multi_sort`, { knowledge_blocks: params });
        if (errors)
            return { errors };
        commit('setProperty', { name: 'blocks', value: [...sorted_blocks] });
        return { data };
    },
    async attachCategories({ rootGetters, state, commit }, { category_ids = [], article_ids = [], categories = [] } = {}) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/knowledge_categories/attach`, {
            category_ids,
            article_ids
        });
        if (errors)
            return { errors };
        commit('setProperty', { name: 'categories', value: [...state.categories, ...categories] });
        return { data };
    },
    async detachCategories({ rootGetters, state, commit }, { category_ids = null, article_ids = [] } = {}) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/knowledge_categories/detach`, {
            category_ids,
            article_ids
        });
        if (errors)
            return { errors };
        commit('setProperty', { name: 'categories', value: state.categories.filter(({ id }) => !category_ids.includes(id)) });
        return { data };
    },
    async provideAccess({ rootGetters, commit }, { entity_ids = [], article_id, access_type = 'user' }) {
        if (!article_id)
            return { errors: 'Нет id статьи' };
        const paramsVocabulary = {
            user: {
                user_ids: entity_ids,
                with: 'user'
            },
            default: {
                course_ids: entity_ids,
                with: 'course'
            }
        };
        const params = paramsVocabulary[access_type] || paramsVocabulary.default;
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/knowledge_articles/${article_id}/access_assignments/attach`, params);
        if (errors)
            return { errors };
        const commitVocabulary = {
            user: 'appendUserAccess',
            default: 'appendCourseAccess'
        };
        commit(commitVocabulary[access_type] || commitVocabulary.default, data);
        return { data };
    },
    async removeAccess({ rootGetters, commit }, { entity_ids = [], article_id, access_type = 'user' }) {
        if (!article_id)
            return { errors: 'Нет id статьи' };
        const paramsVocabulary = {
            user: { user_ids: entity_ids },
            default: { course_ids: entity_ids }
        };
        const params = paramsVocabulary[access_type] || paramsVocabulary.default;
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/knowledge_articles/${article_id}/access_assignments/detach`, params);
        if (errors)
            return { errors };
        const commitVocabulary = {
            user: 'removeUserAccess',
            default: 'removeCourseAccess'
        };
        commit(commitVocabulary[access_type] || commitVocabulary.default, entity_ids);
        return { data };
    },
    async attachArticle({ rootGetters, state, commit }, { article_id, related_articles = [], articles = [] } = {}) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/knowledge_articles/${article_id}/attach_related_articles`, { related_articles });
        if (errors)
            return { errors };
        commit('setProperty', { name: 'related_articles', value: [...state.related_articles, ...articles] });
        return { data };
    },
    async detachArticle({ rootGetters, commit }, { article_id, related_articles = [] } = {}) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/knowledge_articles/${article_id}/detach_related_articles`, { related_articles });
        if (errors)
            return { errors };
        commit('removeRelatedArticles', related_articles);
        return { data };
    },
    async deleteArticle({ rootGetters }, { article_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/knowledge_articles/${article_id}`, {}, 'DELETE');
    },
    clearState({ commit }) {
        commit('clearState', defaultState());
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
