const buildQuestionNumber = (questions) => {
    let number = 0;
    return questions.map((item) => {
        const { active } = item;
        if (active) {
            number++;
        }
        return active ? { ...item, number } : { ...item, number: null };
    });
};
export default buildQuestionNumber;
