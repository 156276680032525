import { mixinActions, mixinMutations } from '@/store/mixins';
import defaultState from '@/store/modules/users/entities/defaultState';
const state = defaultState();
const mutations = {
    ...mixinMutations(),
    setNewHeaders(state, payload) {
        const currentHeadersLength = state.defaultHeaders.length;
        const headersValues = state.defaultHeaders.map((item) => item.value);
        const coursesCustomValues = headersValues.filter((value) => String(value).includes('custom_field_'));
        const newHeaders = payload.map(({ id, name, type, multiple }, index) => ({
            text: name,
            value: `custom_field_${id}`,
            checked: false,
            sortable: (!['list', 'avatar', 'file', 'text', 'telegram_id'].includes(type) && !multiple) || (id === 399),
            type,
            multiple,
            sort: index + currentHeadersLength,
            componentName: 'td-custom',
            subtype: 'custom'
        }));
        const newHeadersValues = newHeaders.map((item) => item.value);
        const actualHeaders = state.defaultHeaders.filter(({ value }) => !coursesCustomValues.includes(value) || newHeadersValues.includes(value));
        state.defaultHeaders = [
            ...actualHeaders,
            ...newHeaders.filter((item) => !headersValues.includes(item.value))
        ];
    },
    cleanItems(state) {
        state.selectTitle = '';
        state.selectItems = [];
        state.selectLoading = false;
    },
    setItems(state, { title, data }) {
        const items = data.map(({ id, name, available_packages_by_sort }) => ({ text: name, value: id, packages: available_packages_by_sort }));
        state.selectTitle = title;
        state.selectItems = items;
        state.selectLoading = false;
    }
};
const actions = {
    ...mixinActions(),
    async getUserList({ rootGetters }, payload) {
        return await this.getDataStore(`${rootGetters.API_URL}/users`, payload);
    },
    async getCustomFields({ rootGetters, commit }) {
        commit('setProperty', { name: 'fieldsLoading', value: true });
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/custom_fields`, {
            only: 'name,type,multiple',
            subtype: 'user',
            all: 1,
            'notTypes[0]': 'image',
            'notTypes[1]': 'file',
            'notTypes[2]': 'avatar',
            'notTypes[3]': 'choose_role'
        });
        commit('setNewHeaders', data);
        commit('setProperty', { name: 'fieldsLoading', value: false });
    },
    getSelectItems({ commit, rootGetters }, { title, type, name }) {
        commit('setProperty', { name: 'selectLoading', value: true });
        commit('cleanItems');
        const vocabulary = {
            course_orders: async () => {
                const { data } = await this.getDataStore(`${rootGetters.API_URL}/courses`, {
                    only: 'name', name: name || '', with: 'availablePackagesBySort:name,color', status: 'visible,close'
                });
                await commit('setItems', { title: title || '', data });
                return data;
            },
            groups: async () => {
                const { data } = await this.getDataStore(`${rootGetters.API_URL}/groups`, { only: 'name', all: 1 });
                await commit('setItems', { title: title || '', data });
                return data;
            },
            roles: async () => {
                const { data } = await this.getDataStore(`${rootGetters.API_URL}/roles`, { only: 'name', all: 1 });
                await commit('setItems', { title: title || '', data });
                return data;
            }
        };
        if (!vocabulary[type]) {
            commit('setProperty', { name: 'selectLoading', value: false });
            return [];
        }
        return vocabulary[type]();
    },
    async doUserAction({ rootGetters }, { users_id, selectedIds, type, action }) {
        const actionsVocabulary = {
            attach_packages: 'attach',
            detach_packages: 'detach'
        };
        const vocabulary = {
            roles: 'roles_id',
            groups: 'groups_id',
            course_orders: action === 'detach' ? 'courses_id' : 'course_packages_id'
        };
        if (!vocabulary[type])
            return { error: 'неизвестное действие' };
        const key = vocabulary[type];
        const action_type = actionsVocabulary[action] || action;
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/${type}/${action_type}`, { users_id, [key]: selectedIds });
        return { data };
    },
    async generateNewPassword({ rootGetters }, { id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/users/${id}/change_password`, {}, 'POST');
    },
    async resetUserAuth({ rootGetters }, { id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/users/${id}/reset_login_block`, {}, 'DELETE');
    },
    async checkReportReady({ rootGetters }, fileName) {
        return await this.getDataStore(`${rootGetters.API_URL}/users_file`, { file_name: fileName });
    },
    async getReportsList({ rootGetters }) {
        return await this.getDataStore(`${rootGetters.API_URL}/users_download/history`);
    },
    async generateReport({ rootGetters }, filters) {
        return await this.getDataStore(`${rootGetters.API_URL}/users_download`, { ...filters, with: 'roles;groups;publicCourses' });
    },
    async generateProfileReport({ rootGetters }, filters) {
        return await this.getDataStore(`${rootGetters.API_URL}/users_download/fields_courses`, { ...filters });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
