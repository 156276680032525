var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import { Bind, Debounce } from '@/helpers/decorators';
let AdminAutocomplete = class AdminAutocomplete extends Vue {
    constructor() {
        super(...arguments);
        this.searchString = '';
        this.model = [];
        this.key = 0;
    }
    created() {
        this.model = this.value;
    }
    onValueChange(newVal) {
        this.model = newVal;
    }
    /**
     * @vuese
     * Вызывается при выборе пункта меню в случае множественного автокомплита
     * @arg Объект выбранного пункта
     */
    changeAutocomplete(item) {
        const { model } = this;
        const matchItem = (el) => el[this.itemValue] === item[this.itemValue];
        if (this.returnObject) {
            model.some(matchItem)
                ? model.splice(model.findIndex(matchItem), 1)
                : model.push(item);
        }
        else {
            const { value } = item;
            model.includes(value)
                ? model.splice(model.indexOf(value), 1)
                : model.push(value);
        }
        this.$nextTick(() => {
            this.$set(this, 'searchString', '');
        });
        /**
         * Сообщает, что пользователь изменил значение в автокомплите
         * @arg Имя поля и текущий набор значений
         */
        this.$emit('changeAutocomplete', {
            name: this.name,
            value: this.model
        });
        if (this.hardReset) {
            this.model = this.value;
            this.key++;
        }
    }
    /**
     * @vuese
     * Обрабатывает событие change на автокомплите
     */
    changeSingleSelect(item = null, event = null) {
        this.$nextTick(() => { this.$set(this, 'searchString', ''); });
        if (this.inReviews) {
            this.$emit('changeAutocomplete', !item ? { name: this.name, value: this.model } : item);
            if (!event)
                return;
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            this.$emit('changeAutocomplete', { name: this.name, value: this.model });
        }
        if (this.hardReset) {
            this.model = this.value;
            this.key++;
        }
    }
    /**
     * @vuese
     * Вызывается при вводе данных в строку для поиска по значениям автокомплита
     */
    updateData() {
        const { searchString } = this;
        if (!searchString)
            return;
        /**
         * Сообщает, что пользователь ввел данные в строку поиска
         * @arg Значение строки поиска
         */
        this.$emit('selectSearch', searchString);
    }
};
__decorate([
    Prop({ default: () => null }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "value", void 0);
__decorate([
    Prop({ type: String, default: '' }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "name", void 0);
__decorate([
    Prop({ type: String, default: '' }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "label", void 0);
__decorate([
    Prop({ type: String, default: '' }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "placeholder", void 0);
__decorate([
    Prop({ type: String, default: 'text' }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "itemText", void 0);
__decorate([
    Prop({ type: String, default: 'value' }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "itemValue", void 0);
__decorate([
    Prop({ type: Array, default: () => [] }),
    __metadata("design:type", Array)
], AdminAutocomplete.prototype, "items", void 0);
__decorate([
    Prop({ type: Array, default: () => [] }),
    __metadata("design:type", typeof (_a = typeof Array !== "undefined" && Array) === "function" ? _a : Object)
], AdminAutocomplete.prototype, "rules", void 0);
__decorate([
    Prop({ type: Boolean, default: true }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "hasChips", void 0);
__decorate([
    Prop({ type: Boolean, default: true }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "useCache", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "isSingleSelect", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "loading", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "clearable", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "returnObject", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "disabled", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "showCheckboxes", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "isFlexItem", void 0);
__decorate([
    Prop({
        type: Function,
        default: (item, queryText, itemText) => itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "filter", void 0);
__decorate([
    Prop({ type: [Boolean, String], default: false }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "attachTo", void 0);
__decorate([
    Prop({ type: [Boolean, String], default: false }),
    __metadata("design:type", Object)
], AdminAutocomplete.prototype, "isCustomItem", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Boolean)
], AdminAutocomplete.prototype, "hardReset", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Boolean)
], AdminAutocomplete.prototype, "inReviews", void 0);
__decorate([
    Watch('value'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AdminAutocomplete.prototype, "onValueChange", null);
__decorate([
    Bind,
    Debounce(500),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AdminAutocomplete.prototype, "updateData", null);
AdminAutocomplete = __decorate([
    Component
], AdminAutocomplete);
export default AdminAutocomplete;
