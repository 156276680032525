import { mixinMutations } from '@/store/mixins';
const state = () => ({
    isLoading: false,
    isSearching: false,
    slug: '',
    currentCategory: {
        name: '',
        slug: '',
        common: false
    }
});
const mutations = { ...mixinMutations() };
const actions = {
    async list({ rootGetters }, params) {
        const url = `${rootGetters.API_PUBLIC_URL}/instructions/all`;
        return await this.getDataStore(url, params);
    },
    async get({ rootGetters }, id) {
        const url = `${rootGetters.API_PUBLIC_URL}/instructions/${id}`;
        return await this.getDataStore(url);
    },
    async listByPage({ rootGetters }, page) {
        const url = `${rootGetters.API_PUBLIC_URL}/instructions/page/${page}`;
        const { instructions } = await this.getDataStore(url);
        return instructions;
    },
    async listByCategory({ commit, rootGetters }, page) {
        commit('setProperty', { name: 'isLoading', value: true });
        const url = `${rootGetters.API_PUBLIC_URL}/instructions/category/${page}`;
        const response = await this.getDataStore(url);
        commit('setProperty', { name: 'isLoading', value: false });
        return response;
    },
    async listByCategorySlug({ rootState, state, commit, dispatch, rootGetters }, { loading = true, params }) {
        if (loading)
            commit('setProperty', { name: 'isLoading', value: true });
        const { slug: paramsSlug } = params;
        const { slug: storeSlug } = state;
        const slug = paramsSlug || storeSlug;
        // eslint-disable-next-line
        const { type } = rootState.Instructions;
        const typePrefix = type === 'admin' ? 'admin/' : 'public/';
        const url = `${rootGetters.API_PUBLIC_URL}/instructions/${slug}`;
        const response = await this.getDataStore(url);
        const { data: instructions, category, meta } = response;
        const { name, common } = category;
        if (loading)
            commit('setProperty', { name: 'isLoading', value: false });
        commit('setProperty', { name: 'currentCategory', value: { name, slug, common } });
        dispatch('breadcrumbs/set', [
            {
                text: 'Управление поддержкой',
                disabled: false,
                href: `instructions/${typePrefix}`
            },
            {
                text: name,
                disabled: true,
                href: `instructions/${typePrefix}${slug}`
            }
        ], { root: true });
        return {
            data: instructions.sort((a, b) => a.sort - b.sort),
            meta
        };
    },
    async increment({ rootGetters }, count) {
        const url = `${rootGetters.API_URL}/instruction_plus/${count}`;
        return await this.getDataStore(url);
    },
    async sort({ rootGetters }, { payload }) {
        const params = { instructions: payload };
        const url = `${rootGetters.API_PUBLIC_URL}/instructions/sort`;
        return await this.postDataStore(url, params);
    },
    async like({ rootGetters }, { id, like }) {
        const url = `${rootGetters.API_PUBLIC_URL}/instructions/${id}/like/${like}`;
        return await this.postDataStore(url, {});
    },
    async delete({ rootGetters }, id) {
        const url = `${rootGetters.API_URL}/instructions/${id}`;
        return await this.postDataStore(url, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
