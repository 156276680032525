import { mixinActions, mixinMutations } from '@/store/mixins';
import InitState, { createDefaultState } from './entities/defaultState';
const defaultState = InitState.createDefaultState();
const state = InitState.createDefaultState();
const mutations = {
    ...mixinMutations(),
    clearPartState(state, stateName) {
        state[stateName] = defaultState[stateName];
    }
};
const actions = {
    ...mixinActions(),
    async createPromoCode({ commit, rootGetters }, { body }) {
        const url = `${rootGetters.API_URL}/promo_codes`;
        const { data, errors } = await this.postDataStore(url, body);
        commit('setState', data);
        return { data, errors };
    },
    async delete({ rootGetters }, { params: { id } }) {
        return await this.postDataStore(`${rootGetters.API_URL}/promo_codes/${id}`, {}, 'DELETE');
    },
    async getPromoCode({ commit, rootGetters }, { promoId }) {
        const url = `${rootGetters.API_URL}/promo_codes/${promoId}`;
        const { data, errors } = await this.getDataStore(url, { with: 'courses;coursePackages;coursePackages.course;coursePackages.courseBranch;courseCompilations;courseCompilations.coursePackages' });
        commit('setState', data);
        commit('setProperty', { name: 'wholePromo', value: data });
        return { data, errors };
    },
    async list({ rootGetters }, { params }) {
        const url = `${rootGetters.API_URL}/promo_codes`;
        return await this.getDataStore(url, params);
    },
    async activatedList({ rootGetters }, { params }) {
        const url = `${rootGetters.API_URL}/used_promo_codes`;
        return await this.getDataStore(url, params);
    },
    async updatePromoCode({ commit, rootGetters }, { promoId, body }) {
        const url = `${rootGetters.API_URL}/promo_codes/${promoId}`;
        const { data, errors } = await this.postDataStore(url, {
            ...body,
            with: 'courses;coursePackages;courseCompilations'
        }, 'PUT');
        commit('setState', data);
        return { data, errors };
    },
    async attach({ rootGetters }, { promoId, course_packages_ids, courses_ids, course_compilations_ids }) {
        const url = `${rootGetters.API_URL}/promo_codes/${promoId}/attach_products`;
        const { data, errors } = await this.postDataStore(url, {
            course_packages_ids,
            courses_ids,
            course_compilations_ids
        });
        return { data, errors };
    },
    async attachAll({ rootGetters }, { promoId }) {
        const url = `${rootGetters.API_URL}/promo_codes/${promoId}/attach_for_all_courses`;
        const { data, errors } = await this.postDataStore(url, {});
        return { data, errors };
    },
    async detach({ rootGetters }, { promoId, course_packages_ids, courses_ids, course_compilations_ids }) {
        const url = `${rootGetters.API_URL}/promo_codes/${promoId}/detach_products`;
        const { data, errors } = await this.postDataStore(url, {
            course_packages_ids,
            courses_ids,
            course_compilations_ids
        });
        return { data, errors };
    },
    async detachAll({ rootGetters }, { promoId }) {
        const url = `${rootGetters.API_URL}/promo_codes/${promoId}/detach_for_all_courses`;
        const { data, errors } = await this.postDataStore(url, {});
        return { data, errors };
    },
    async getPackagesCourses({ rootGetters }, { name = '', courseName = '', notPromoCodeId = 0, params = {} }) {
        const { data, errors, meta } = await this.getDataStore(`${rootGetters.API_URL}/course_packages`, {
            name, courseName, with: 'course:name,img_anons;courseBranch', notPromoCodeId, ...params
        });
        return { data, errors, meta };
    },
    setDefaultState({ commit }) {
        commit('setState', createDefaultState());
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
