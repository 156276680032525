import axios from 'axios';
import Vue from '@/main';
export const token = localStorage.getItem('access_token');
export const BASE_URL = process.env.VUE_APP_SERVER_HOST || 'http://localhost:8080';
export const getData = async (url, params = {}, returnStatus = false) => {
    try {
        const response = await axios.get(`${BASE_URL}${url}`, {
            headers: {
                Authorization: localStorage.getItem('access_token'),
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            params
        });
        const { data } = response;
        if (returnStatus) {
            data.status = response.status;
        }
        return data;
    }
    catch (error) {
        const isShowErrors = error.response?.status >= 400 && error.response?.status < 500;
        const errorMessage = error.response && error.response.data && error.response.data.errors ? error.response.data?.errors : '';
        if (errorMessage && isShowErrors) {
            if (Array.isArray(errorMessage)) {
                errorMessage.forEach((item) => {
                    Vue.$alert(item, 'error');
                });
            }
            else {
                Vue?.$alert(errorMessage, 'error');
            }
        }
        return { errors: errorMessage };
    }
};
export const postData = async (url, params, method = 'POST') => {
    try {
        const response = await axios({
            headers: {
                Authorization: localStorage.getItem('access_token'),
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            method,
            url: `${BASE_URL}${url}`,
            data: params
        });
        const { data } = response;
        return data;
    }
    catch (error) {
        const isShowErrors = error.response?.status >= 400 && error.response?.status < 500;
        const errorMessage = error.response && error.response.data && error.response.data.errors ? error.response.data.errors : '';
        if (errorMessage && isShowErrors) {
            if (Array.isArray(errorMessage)) {
                errorMessage.forEach((item) => {
                    Vue.$alert(item, 'error');
                });
            }
            else {
                Vue?.$alert(errorMessage, 'error');
            }
        }
        return { errors: errorMessage, error: error?.response?.data?.error };
    }
};
