import { mixinActions, mixinMutations } from '@/store/mixins';
import InitState from './entities/defaultState';
const state = InitState.createDefaultState();
const getters = {
    getApiURL(state, getters, rootState, rootGetters) {
        return rootGetters.API_URL;
    }
};
const mutations = { ...mixinMutations() };
const actions = {
    ...mixinActions(),
    async getUserInfo({ commit, rootGetters }, { id }) {
        const { data: { user, custom_fields } } = await this.getDataStore(`${rootGetters.API_URL}/users/${id}/show_all_custom_fields_with_values`);
        commit('setState', { ...user, custom_fields });
    },
    async getInitialUserCourses({ commit, state, rootGetters }, { id }) {
        commit('setProperty', { page: 1 });
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/users/${id}/courses`, {
            page: state.page,
            only: 'name,img_anons'
        });
        if (!data || !data.length) {
            commit('setProperty', { name: 'finishLoading', value: true });
            return;
        }
        commit('setProperty', { name: 'courses', value: data });
    },
    async addUserCourses({ commit, state, rootGetters }, { id }) {
        if (state.finishLoading)
            return;
        commit('setProperty', { name: 'page', value: state.page + 1 });
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/users/${id}/courses`, {
            page: state.page,
            only: 'name,img_anons'
        });
        if (!data || !data.length) {
            commit('setProperty', { name: 'finishLoading', value: true });
            return;
        }
        commit('setProperty', { name: 'courses', value: [...state.courses, ...data] });
    },
    async updateUserEmail({ rootGetters }, { user_id, body }) {
        return await this.postDataStore(`${rootGetters.API_URL}/users/${user_id}/change_email`, { ...body });
    },
    async updateUserCustomFields({ rootGetters }, { user_id, body }) {
        return await this.postDataStore(`${rootGetters.API_URL}/users/${user_id}/store_custom_field_values`, { ...body });
    },
    clearState({ commit }) {
        commit('clearState', InitState.createDefaultState());
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
