const defaultSteps = () => [
    {
        id: 0,
        title: '1. Событие',
        description: 'Когда произойдет автодействие.'
    },
    {
        id: 1,
        title: '2. Действие',
        description: 'Как платформа отреагирует на событие.'
    },
    {
        id: 2,
        title: '3. Фильтр',
        description: 'Определите тип получателей.'
    }
];
const defaultItem = {
    company_id: 0,
    template_id: null,
    integration_id: null,
    recipient: '',
    active: false,
    schedule: false,
    name: '',
    description: '',
    entity: null,
    event: null,
    action: null,
    filter: null,
    action_filter: null,
    action_data: null,
    time_settings: null
};
const entityFilter = [
    {
        type: 'lesson',
        code: 'courses',
        only: 'name',
        mapFn: ({ name }) => name
    },
    {
        type: 'course',
        code: 'courses',
        only: 'name',
        mapFn: ({ name }) => name
    },
    {
        type: 'test',
        code: 'tests',
        only: 'title',
        mapFn: ({ title }) => title
    },
    {
        type: 'user',
        code: 'users',
        only: 'name,surname,email',
        mapFn: ({ name, surname, email }) => `${name} ${surname} <${email}>`
    },
    {
        type: 'order',
        code: 'orders',
        only: 'number,name',
        mapFn: ({ name, number }) => `[${number}] ${name}`
    }
];
export { defaultSteps, defaultItem, entityFilter };
