import { mixinMutations } from '@/store/mixins';
import defaultState from './defaultState';
const state = defaultState();
const getters = {
    parsedIntegrations(state) {
        return state.integrations.map(({ settings, ...rest }) => {
            try {
                const parsedSettings = JSON.parse(settings || '{}');
                return { ...rest, settings: Object.entries(parsedSettings).map(([key, value]) => ({ key, value })) };
            }
            catch {
                return { ...rest, settings: [] };
            }
        });
    }
};
const mutations = {
    ...mixinMutations(),
    createIntegration(state, payload) {
        state.integrations.push(payload);
    },
    updateIntegration(state, payload) {
        const index = state.integrations.findIndex((integration) => integration.id === payload.id);
        if (index !== -1)
            state.integrations.splice(index, 1, payload);
    },
    deleteIntegration(state, id) {
        const index = state.integrations.findIndex((integration) => integration.id === id);
        if (index !== -1)
            state.integrations.splice(index, 1);
    }
};
const actions = {
    async getIntegrations({ commit, rootGetters }) {
        const res = await this.getDataStore(`${rootGetters.API_URL}/company_integrations?per_page=100`);
        if (!res.errors)
            commit('setProperty', { name: 'integrations', value: res.data });
        return res;
    },
    async createIntegration({ rootGetters, commit }, { params = {} } = {}) {
        const settings = JSON.stringify(params?.settings?.reduce((acc, option) => {
            acc[option.key] = option.value;
            return acc;
        }, {}));
        const res = await this.postDataStore(`${rootGetters.API_URL}/company_integrations`, { ...params, settings }, 'POST');
        if (!res.errors)
            commit('createIntegration', res.data);
        return res;
    },
    async updateIntegration({ rootGetters, commit }, { id, params = {} } = {}) {
        const settings = JSON.stringify(params?.settings?.reduce((acc, option) => {
            acc[option.key] = option.value;
            return acc;
        }, {}));
        const res = await this.postDataStore(`${rootGetters.API_URL}/company_integrations/${id}`, { ...params, settings }, 'PUT');
        if (!res.errors)
            commit('updateIntegration', res.data);
        return res;
    },
    async deleteIntegration({ rootGetters, commit }, { id } = {}) {
        const res = await this.postDataStore(`${rootGetters.API_URL}/company_integrations/${id}`, {}, 'DELETE');
        if (!res.errors)
            commit('deleteIntegration', id);
        return res;
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
