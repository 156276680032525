var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Prop, Ref, Vue, Watch } from 'nuxt-property-decorator';
import { Bind, Debounce } from '@/helpers/decorators';
import { BASE_URL } from '@/api';
import locale from './locale';
import toolbarButtons from './toolbarButtons';
let FroalaPage = class FroalaPage extends Vue {
    constructor() {
        super(...arguments);
        // Данные внутри редактора
        this.innerData = this.value || '';
        // Отображать ли ошибку об обязательности заполнения поля
        this.isRequiredError = false;
        // Объект редактора
        this.editor = null;
        this.baseEditorConfig = {
            language: 'ru',
            key: 'te1C2sB5D6D5B4D3D4jC1QUd1Xd1OZJ1ABVJRDRNGGUE1ITrE1D4A3A11B1B6D6B1F4E4==',
            htmlAllowedTags: ['.*'],
            htmlAllowedAttrs: ['.*'],
            htmlRemoveTags: [],
            fileUploadURL: `${BASE_URL}/api/profile/file`,
            charCounterMax: this.charCounterMax,
            charCounterCount: true,
            imageUploadParam: 'image',
            heightMin: this.height || 400,
            heightMax: this.heightMax || 10000,
            tableResizerOffset: 5,
            tableResizingLimit: 10,
            tableStyles: {
                'fr-dashed-borders': 'Пунктирные рамки',
                'fr-alternate-rows': 'Чередующиеся ряды',
                'fr-no-table-borders': 'Без рамок'
            },
            pluginsEnabled: ['wiris', 'align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontAwesome', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageTUI', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quote', 'save', 'table', 'url', 'video', 'wordPaste', ...this.extraPlugins],
            placeholderText: this.placeholder,
            toolbarSticky: this.toolbarSticky,
            imageUploadURL: `${BASE_URL}/api/profile/images`,
            requestHeaders: { Authorization: localStorage.getItem('access_token'), Accept: 'application/json' },
            pastePlain: true,
            imagePaste: false,
            toolbarButtons: this.buttons,
            htmlAllowedEmptyTags: ['iframe'],
            linkText: true,
            linkAttributes: this.isOwnChangeRel ? { rel: 'Атрибут Rel' } : {},
            linkNoOpener: !this.isOwnChangeRel,
            linkNoReferrer: !this.isOwnChangeRel,
            quickInsertEnabled: this.quickInsertEnabled
        };
    }
    created() {
        this.initFroalaScripts();
    }
    async initFroalaScripts() {
        const isFroalaLoaded = await this.$root.$uploadScripts({ url: '/static-scripts/froala.js', targetClass: 'froala-script', type: 'js' });
        if (!isFroalaLoaded)
            return;
        const res = await Promise.all([
            this.$root.$uploadScripts({ url: '/static-scripts/wiris.js', targetClass: 'froala-mathtype-script', type: 'js' }),
            this.$root.$uploadScripts({ url: '/static-scripts/froala-plugins.js', targetClass: 'froala-plugins.js-script', type: 'js' }),
            this.$root.$uploadScripts({ url: '/static-scripts/embedly.js', targetClass: 'froala-embedly-script', type: 'js' }),
            this.$root.$uploadScripts({ url: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css', targetClass: 'froala-second-style', type: 'css' })
        ]);
        if (res.filter((res) => !res).length)
            return;
        this.setEditor();
    }
    beforeDestroy() {
        this.mathEditorRemove();
    }
    /**
     * @vuese
     * Прекращает передачу события
     */
    catchHtmlEvent(el) {
        el.addEventListener('focusin', (event) => {
            event.stopPropagation();
        });
    }
    setExtraButtons(FroalaEditor) {
        if (!FroalaEditor)
            return;
        FroalaEditor.DefineIcon('clear', { NAME: 'remove', SVG_KEY: 'remove' });
        FroalaEditor.RegisterCommand('clear', {
            title: 'Очистить редактор',
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            callback() {
                this?.html.set('');
                this.events.focus();
            }
        });
        FroalaEditor.DefineIcon('insertFaq', { SRC: '/static-images/plus.svg', ALT: 'insertFaq', template: 'image' });
        FroalaEditor.RegisterCommand('insertFaq', {
            title: 'Добавить вопрос-ответ',
            focus: false,
            undo: true,
            refreshAfterCallback: true,
            callback() {
                this?.html.insert('<div class="accordeon__item"><div class="accordeon__title">Напишите вопрос</div><div class="accordeon__content"><p>Здесь Вы можете написать ответ</p></div></div><p></p>');
                this.undo.saveStep();
            }
        });
        FroalaEditor.DefineIcon('insertHiddenText', { NAME: 'visibility', PATH: 'M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z' });
        FroalaEditor.RegisterCommand('insertHiddenText', {
            title: 'Добавить скрытый текст',
            focus: true,
            undo: true,
            refreshAfterCallback: true,
            callback() {
                this?.html.insert(
                // Шаблонная строка заменена на конкатенацию
                // чтоб убрать лишние пробелы из визуального редактора
                '<label class="editor__hidden-text__wrap">'
                    + '<input class="editor__hidden-text__input" style="display: none" type="checkbox">'
                    + '<span class="editor__hidden-text__btn">&nbsp;Название кнопки</span>'
                    + '<span class="editor__hidden-text__content">'
                    + '<span class="editor__hidden-text__content__wrap">&nbsp;Скрытый контент, появится при клике на кнопку</span>'
                    + '</span>'
                    + '</label>'
                    + '<p><br></p>');
                this.undo.saveStep();
            }
        });
    }
    /**
     * @vuese
     * Главная функция, в которой происходит настройка и создание редактора
     */
    setEditor() {
        const { FroalaEditor } = window;
        if (this.hasExtraButtons) {
            this.setExtraButtons(FroalaEditor);
        }
        FroalaEditor.LANGUAGE.ru = locale;
        const editor = new FroalaEditor(`#froala_${this.name}`, {
            ...this.baseEditorConfig,
            events: {
                initialized: () => {
                    const { value } = this;
                    const htmlVal = value || '';
                    const resultVal = htmlVal.replaceAll('fr-error', '').replaceAll('fr-draggable', '');
                    editor?.html.set(resultVal);
                    // eslint-disable-next-line
                    // editor.wiris?._init();
                },
                // eslint-disable-next-line
                'image.uploaded': (response) => {
                    const { data } = JSON.parse(response);
                    if (!data)
                        return false;
                    //     editor.image.insert(img_url, false, null, editor.image.get(), response);
                    editor.image.insert(data.url, false, null, editor.image.get(), { link: data.url });
                    if (!this.noUpdateAfterImageAndFile) {
                        this.updateAfterBlur();
                        return false;
                    }
                },
                // eslint-disable-next-line
                'file.uploaded': (response) => {
                    const { data } = JSON.parse(response);
                    if (!data)
                        return false;
                    editor.file.insert(data.url, data.name, { link: data.url });
                    if (!this.noUpdateAfterImageAndFile) {
                        this.updateAfterBlur();
                        return false;
                    }
                },
                focus: () => {
                    /** Отправляет наверх событие фокусировке на редакторе */
                    this.$emit('focusEditor');
                },
                // eslint-disable-next-line
                'image.inserted': this.updateAfterBlur,
                // eslint-disable-next-line
                'file.inserted': this.updateAfterBlur,
                blur: this.updateAfterBlur,
                input: () => {
                    const innerHtmlFroala = editor?.html.get();
                    this.onInnerDataChanged(innerHtmlFroala);
                    /** Отправляет наверх событие input */
                    this.$emit('input');
                },
                'commands.after': (cmd) => {
                    if (cmd === 'wirisEditor' || cmd === 'imageAlt') {
                        const selector = cmd === 'wirisEditor' ? '.wrs_modal_desktop' : '.fr-image-alt-layer';
                        const element = document.querySelector(selector);
                        this.catchHtmlEvent(element);
                    }
                    const isHideFullScreen = cmd === 'fullscreen' && (!document.querySelectorAll('.fr-fullscreen-wrapper')
                        || !document.querySelectorAll('.fr-fullscreen-wrapper').length);
                    if (isHideFullScreen) {
                        const el = document.querySelector(`#froala_${this.name}`);
                        if (!el)
                            return;
                        const { top } = el.getBoundingClientRect();
                        window.scrollBy({ top: top - 90, behavior: 'smooth' });
                    }
                }
            }
        });
        this.editor = editor;
    }
    /**
     * @vuese
     * Вставляет текст в редактор
     */
    insertText(text) {
        this.editor?.selection.restore();
        this.editor?.html.insert(text);
        this.editor?.undo.saveStep();
    }
    /**
     * @vuese
     * Отправляет наверх событие blur
     * @arg Текст, который нужно передать наверх
     */
    blurEditor(value) {
        /** Отправляет наверх событие blur - клик вне редактора
         * @arg Текст, который нужно передать наверх
         */
        this.$emit('blurEditor', { value, name: this.name });
    }
    /**
     * @vuese
     * Удаляет математический редактор
     */
    mathEditorRemove() {
        let tempArray = [];
        tempArray.push(document.querySelector('.wrs_modal_dialogContainer'));
        tempArray.push(document.querySelector('.wrs_modal_overlay'));
        if (tempArray.length) {
            tempArray.forEach((item) => {
                item?.parentNode?.removeChild(item);
            });
            tempArray = [];
        }
    }
    /**
     * @vuese
     * Вызывается редактором при клике вне его зоны
     */
    updateAfterBlur() {
        // need for save caret position (prevent random insert)
        this.savePosition && this.editor?.selection.save();
        const rootEl = this.froala;
        this.contentPostprocessing(rootEl);
        const htmlContent = this.editor?.codeView?.isActive()
            ? this.editor?.codeView?.get().replaceAll('fr-error', '').replaceAll('fr-draggable', '')
            : this.froala?.querySelector('.fr-element.fr-view').innerHTML
                .replaceAll('fr-error', '').replaceAll('fr-draggable', '');
        const { required, withEmpty } = this;
        if (withEmpty) {
            const clearString = htmlContent.replaceAll('<br>', '')
                .replaceAll('<p></p>', '').trim();
            this.blurEditor(clearString);
            return;
        }
        if (required) {
            const clearString = htmlContent.replaceAll('<br>', '')
                .replaceAll('<p></p>', '').trim();
            if (clearString) {
                this.blurEditor(htmlContent);
                this.isRequiredError = false;
                return;
            }
            this.blurEditor(clearString);
            this.isRequiredError = true;
        }
        if (!required) {
            this.blurEditor(htmlContent);
        }
    }
    /**
     * @vuese
     * Обработка контента перед отдачей из редактора
     */
    contentPostprocessing(rootEl) {
        const hiddenInputs = rootEl?.querySelectorAll('.editor__hidden-text__input');
        Array.from(hiddenInputs).forEach((input) => {
            input.removeAttribute('checked');
        });
    }
    /**
     * @vuese
     * Вызывается при изменении контента редактора пользователем
     */
    onInnerDataChanged(innerHtmlFroala) {
        this.$emit('input', innerHtmlFroala);
        /**
         * Отправляет наверх событие change при изменении контента
         * @arg Текущий контент редактора
         */
        this.$emit('change', innerHtmlFroala);
    }
    /**
     * @vuese
     * Переопределяет контент внутри редактора при его изменении вне
     */
    onValueChange(newVal) {
        this.innerData = newVal || '';
        this.editor?.html?.set(this.innerData.replaceAll('fr-error', '').replaceAll('fr-draggable', ''));
    }
    /**
     * @vuese
     * Переопределяет необходимость показывать ошибку при изменении вне редактора
     */
    inShowErrorChange(value) {
        this.isRequiredError = value;
    }
};
__decorate([
    Ref(),
    __metadata("design:type", typeof (_a = typeof HTMLElement !== "undefined" && HTMLElement) === "function" ? _a : Object)
], FroalaPage.prototype, "froala", void 0);
__decorate([
    Prop({ type: String, default: () => '' }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "value", void 0);
__decorate([
    Prop({ type: [String, Number], default: () => '' }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "name", void 0);
__decorate([
    Prop({ type: [Number, String], default: () => 400 }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "height", void 0);
__decorate([
    Prop({ type: [Number, String], default: () => 10000 }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "heightMax", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "toolbarSticky", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "required", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "showError", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "withEmpty", void 0);
__decorate([
    Prop({ type: String, default: () => '' }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "placeholder", void 0);
__decorate([
    Prop({ type: [], default: () => toolbarButtons }),
    __metadata("design:type", Array)
], FroalaPage.prototype, "buttons", void 0);
__decorate([
    Prop({ type: Boolean, default: () => true }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "hasExtraButtons", void 0);
__decorate([
    Prop({ type: Array, default: () => ['quickInsert'] }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "extraPlugins", void 0);
__decorate([
    Prop({ type: [Number, String], default: () => -1 }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "charCounterMax", void 0);
__decorate([
    Prop({ type: Boolean, default: () => true }),
    __metadata("design:type", Object)
], FroalaPage.prototype, "noUpdateAfterImageAndFile", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Boolean)
], FroalaPage.prototype, "savePosition", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Boolean)
], FroalaPage.prototype, "isOwnChangeRel", void 0);
__decorate([
    Prop({ type: Boolean, default: () => true }),
    __metadata("design:type", Boolean)
], FroalaPage.prototype, "quickInsertEnabled", void 0);
__decorate([
    Bind,
    Debounce(500),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], FroalaPage.prototype, "onInnerDataChanged", null);
__decorate([
    Watch('value'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], FroalaPage.prototype, "onValueChange", null);
__decorate([
    Watch('showError'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], FroalaPage.prototype, "inShowErrorChange", null);
FroalaPage = __decorate([
    Component
], FroalaPage);
export default FroalaPage;
