import { mixinMutations } from '@/store/mixins';
import router from '@/router';
import defaultState from './entities/defaultState';
const freezeState = defaultState();
const state = defaultState();
const mutations = { ...mixinMutations() };
const actions = {
    async createCompilation({ dispatch, rootGetters }, { name, value }) {
        const response = await this.postDataStore(`${rootGetters.API_URL}/course_compilations`, { [name]: value }, 'POST');
        await dispatch('getCompilation', { compilation_id: response.data.id });
        const { id } = response.data;
        const { errors } = response;
        if (errors)
            return response;
        await router.push({
            name: 'CourseCompilation',
            params: { compilation_id: id }
        });
        return response;
    },
    async getCompilation({ commit, rootGetters, state }, { compilation_id }) {
        if (!compilation_id || compilation_id === 'create')
            return;
        const url = `${rootGetters.API_URL}/course_compilations/${compilation_id}`;
        const params = { with: 'subcategories_with_categories;advantagesBySort;coursePackagesBySort:id,course_id,name,color;coursePackagesBySort.course:id,name,img_anons,img_banner,img_banner_mobile,anons' };
        const { data, errors } = await this.getDataStore(url, params);
        if (errors)
            return { errors };
        const { status } = data;
        const currentStatus = state.statuses.find((item) => item.value === status);
        commit('setState', data);
        commit('setProperty', { name: 'currentStatus', value: currentStatus });
    },
    async updateCompilationField({ commit, state, rootGetters, dispatch }, { name, value }) {
        if (!state.id)
            return await dispatch('createCompilation', { name, value });
        const response = await this.postDataStore(`${rootGetters.API_URL}/course_compilations/${state.id}`, { [name]: value }, 'PUT');
        if (!response.errors)
            commit('setProperty', { name, value });
        return response;
    },
    async updateSeveralCompilationField({ commit, state, rootGetters }, dataToSend) {
        const response = await this.postDataStore(`${rootGetters.API_URL}/course_compilations/${state.id}`, { ...dataToSend }, 'PUT');
        if (!response.errors) {
            const dataToSendArr = Object.entries(dataToSend);
            commit('setProperty', dataToSendArr.map(([name, value]) => ({ name, value })));
        }
        return response;
    },
    async deleteCompilation({ dispatch, state, rootGetters }) {
        const url = `${rootGetters.API_URL}/course_compilations/${state.id}`;
        const response = await this.postDataStore(url, {}, 'DELETE');
        await dispatch('showingSuccess', response);
        setTimeout(() => {
            router.push({ name: 'CoursesList' });
        }, 1000);
    },
    async attachPackages({ rootGetters, dispatch }, { id, course_package_id = '' }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/course_compilations/${id}/attach_course_packages`, { course_package_id });
        if (errors)
            return errors;
        await dispatch('updateCompilationPackages');
        return { data };
    },
    async detachPackages({ rootGetters, dispatch }, { id, course_package_id = '' }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/course_compilations/${id}/detach_course_packages`, { course_package_id });
        if (errors)
            return errors;
        await dispatch('updateCompilationPackages');
        return { data };
    },
    async packagesMultiSort({ commit, state, rootGetters }, { sorted_packages }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API_URL}/course_compilations/${state.id}/course_packages_multi_sort`, { course_packages: sorted_packages.map(({ id, sort_in_compilation }) => ({ id, sort: sort_in_compilation })) });
        if (errors)
            return { errors };
        commit('setProperty', { name: 'course_packages_by_sort', value: [...sorted_packages] });
        return { data };
    },
    async updateCompilationPackages({ commit, state, rootGetters }) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/course_compilations/${state.id}`, {
            only: 'coursePackagesBySort',
            with: 'coursePackagesBySort:id,course_id,name,color;coursePackagesBySort.course:id,name,img_anons,img_banner,img_banner_mobile'
        });
        if (errors)
            return { errors };
        commit('setProperty', { name: 'course_packages_by_sort', value: data.course_packages_by_sort });
    },
    async attachAdvantages({ commit, state, rootGetters }, { advantage_id, advantagesList = [] }) {
        const url = `${rootGetters.API_URL}/course_compilations/${state.id}/attach_advantages`;
        const response = await this.postDataStore(url, { advantage_id });
        if (response.status !== true)
            return { errors: 'Произошла ошибка' };
        const advantagesToAppend = advantage_id.reduce((result, id, arr, index) => {
            const targetAdvantage = advantagesList.find((adv) => adv.id === id);
            return targetAdvantage ? [...result, { ...targetAdvantage, sort: state.advantages_by_sort.length + index + 1 }] : result;
        }, []);
        commit('setProperty', { name: 'advantages_by_sort', value: [...state.advantages_by_sort, ...advantagesToAppend] });
    },
    async detachAdvantages({ commit, state, rootGetters }, { advantage_id }) {
        const url = `${rootGetters.API_URL}/course_compilations/${state.id}/detach_advantages`;
        const response = await this.postDataStore(url, { advantage_id });
        if (response.status !== true)
            return { errors: 'Произошла ошибка' };
        const targetIndex = state.advantages_by_sort.findIndex((advantage) => advantage.id === advantage_id);
        if (targetIndex === -1)
            return { errors: 'Произошла ошибка' };
        const advantagesArray = [
            ...state.advantages_by_sort.slice(0, targetIndex),
            ...state.advantages_by_sort.slice(targetIndex + 1)
        ].map((advantage, index) => ({ ...advantage, sort: index + 1 }));
        commit('setProperty', { name: 'advantages_by_sort', value: [...advantagesArray] });
        return {};
    },
    async getCompilationHistory({ rootGetters }, { compilation_id, filters, page = 1 }) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_compilations/${compilation_id}/audits`, { ...filters, page });
    },
    async getCompilationsList({ rootGetters }, params = {}) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_compilations`, { ...params });
    },
    async saveCompilation({ rootGetters, state }, { compilation_id, bitrixForm }) {
        const url = `${rootGetters.API_URL}/course_compilations/${compilation_id}`;
        const { crm_bitrix, crm_bitrix_form_id, crm_bitrix_form_url } = state;
        const params = bitrixForm ? {
            crm_form_id: bitrixForm,
            crm_bitrix: null,
            crm_bitrix_form_id: null,
            crm_bitrix_form_url: null
        } : {
            crm_form_id: null,
            crm_bitrix,
            crm_bitrix_form_id,
            crm_bitrix_form_url
        };
        return await this.postDataStore(url, params, 'PUT');
    },
    async clearCompilationBitrixForm({ rootGetters, commit }, { compilation_id }) {
        const url = `${rootGetters.API_URL}/course_compilations/${compilation_id}`;
        commit('setProperty', [
            { name: 'crm_form_id', value: null },
            { name: 'crm_bitrix', value: null },
            { name: 'crm_bitrix_form_id', value: null },
            { name: 'crm_bitrix_form_url', value: null }
        ]);
        return await this.postDataStore(url, {
            crm_form_id: null,
            crm_bitrix: null,
            crm_bitrix_form_id: null,
            crm_bitrix_form_url: null
        }, 'PUT');
    },
    clearState({ commit }) {
        commit('clearState', { ...freezeState });
    },
    // CRM Methods, copied from course module
    async getFieldsList({ rootGetters, commit }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/custom_fields`, {
            only: 'id,name,description,multiple',
            all: 1,
            subtype: 'bid'
        });
        commit('setFields', data);
        return data;
    },
    async getCourseFields({ rootGetters, commit }, course_id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_custom_fields`, {
            with: 'customField:name,type',
            all: 1,
            sort: 'sort'
        });
        commit('setCourseCustomFields', data);
        return data;
    },
    async updateCourseField({ dispatch, rootGetters }, { course_id, field_id, params }) {
        const response = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_custom_fields/${field_id}`, { ...params }, 'PUT');
        await dispatch('showingSuccess', response);
        return response.data;
    },
    async deleteCourseField({ dispatch, rootGetters }, { course_id, field_id }) {
        const response = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_custom_fields/${field_id}`, {}, 'DELETE');
        await dispatch('showingSuccess', response);
        return response.data;
    },
    async createCourseFields({ dispatch, rootGetters }, { course_id, custom_field_id }) {
        const response = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_custom_fields/multi_create`, { custom_field_id }, 'POST');
        await dispatch('showingSuccess', response);
        return response.data;
    },
    async updateCourseFields({ rootGetters }, { course_id, course_custom_fields }) {
        const response = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_custom_fields/multi_update`, { course_custom_fields }, 'POST');
        return response.data;
    },
    async parseBitrixScript({ rootGetters }, { compilation_id, script_tag }) {
        const url = `${rootGetters.API_URL}/course_compilations/${compilation_id}/parse_crm_bitrix_script_tag`;
        const params = { script_tag };
        return await this.postDataStore(url, params);
    },
    // Compilation form
    async getCompilationFormValues({ rootGetters }, { compilation_id }) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_compilations/${compilation_id}/cf_course_compilation_values`, { all: 1 });
    },
    async updateCompilationFormValues({ rootGetters }, { compilation_id, custom_field_value_items }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_compilations/${compilation_id}/cf_course_compilation_values_multiple`, { custom_field_value_items });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
