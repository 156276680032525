const actions = {
    async getGroups({ rootGetters }, payload) {
        const isInfinity = payload && payload.per_page && payload.per_page === -1;
        let params = {};
        if (isInfinity) {
            const { per_page, ...rest } = payload;
            params = {
                ...rest,
                all: 1
            };
        }
        else {
            params = payload;
        }
        return await this.getDataStore(`${rootGetters.API_URL}/groups`, params);
    },
    async create({ rootGetters }, { params }) {
        return await this.postDataStore(`${rootGetters.API_URL}/groups`, params);
    },
    async list({ rootGetters }, payload) {
        const { params } = payload;
        const resultParams = payload ? params || { ...payload } : {};
        return await this.getDataStore(`${rootGetters.API_URL}/groups`, { ...resultParams });
    },
    async delete({ rootGetters }, { params: { id } }) {
        return await this.postDataStore(`${rootGetters.API_URL}/groups/${id}`, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    actions
};
