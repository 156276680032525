import { mixinMutations } from '@/store/mixins';
const state = {
    categories: [],
    searchCategories: []
};
const mutations = { ...mixinMutations() };
const actions = {
    async list({ rootGetters }, { params }) {
        return await this.getDataStore(`${rootGetters.API_URL}/tests`, params);
    },
    async delete({ rootGetters }, { id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/tests/${id}`, {}, 'DELETE');
    },
    async createTest({ rootGetters }, payload) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/tests`, payload || {}, 'POST');
        return data.id;
    },
    async getCategories({ rootGetters, commit }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/test_categories`, {
            with: 'subcategories',
            type: 'category'
        });
        const result = data.map(({ title, id, subcategories }) => ({
            id,
            title,
            value: [],
            subcategories: subcategories.map(({ title, id }) => ({ title, id }))
        }));
        commit('setProperty', { name: 'categories', value: result });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
