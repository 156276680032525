import { mixinMutations } from '@/store/mixins';
import initState from './entities/galleriesDefaultState';
const state = initState();
const mutations = { ...mixinMutations() };
const actions = {
    async getGalleriesList({ commit, rootGetters }, { params }) {
        const response = await this.getDataStore(`${rootGetters.API_URL}/gallery`, params);
        commit('setProperty', { name: 'galleries', value: response.data });
        return response;
    },
    async getGallery({ rootGetters }, { id }) {
        return await this.getDataStore(`${rootGetters.API_URL}/gallery/${id}`);
    },
    async deleteGallery({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/gallery/${id}`, {}, 'DELETE');
    },
    async createGallery({ rootGetters }, { params }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/gallery`, params);
        return data;
    },
    async updateGallery({ rootGetters }, { id, params }) {
        return await this.postDataStore(`${rootGetters.API_URL}/gallery/${id}`, params, 'PUT');
    },
    async addGalleryToCourse({ rootGetters }, { gallery_id, course_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/gallery/add_to_course`, { gallery_id, course_id });
    },
    async deleteImage({ rootGetters }, { media_id }) {
        return await this.postDataStore(`${rootGetters.API_PUBLIC_URL}/files/delete_media/${media_id}`, {}, 'DELETE');
    },
    changeHasEditingEror({ commit }, payload) {
        commit('setProperty', { name: 'has_editing_error', value: payload });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
