import { mixinMutations } from '@/store/mixins';
import initState from './entities/achievementsDefaultState';
const state = initState();
const mutations = { ...mixinMutations() };
const actions = {
    async getAchievementsList({ commit, rootGetters }, { params }) {
        params.withCount = 'levels';
        params.with = 'levels';
        const response = await this.getDataStore(`${rootGetters.API_URL}/achievements`, params);
        commit('setProperty', { name: 'achievements', value: response.data });
        return response;
    },
    async deleteAchievement({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/achievements/${id}`, { with: 'levels' }, 'DELETE');
    },
    async deleteLevel({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/achievements/${id}/levels`, { with: 'levels' }, 'DELETE');
    },
    async addLevelAchievement({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/achievements/${id}/levels`, { with: 'levels' });
    },
    async createAchievements({ rootGetters }, { params }) {
        params.with = 'levels';
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/achievements`, params);
        return data;
    },
    async updateAchievements({ rootGetters }, { id, params }) {
        params.with = 'levels';
        return await this.postDataStore(`${rootGetters.API_URL}/achievements/${id}`, params, 'PUT');
    },
    async updateLevel({ rootGetters }, { id, description, step }) {
        return await this.postDataStore(`${rootGetters.API_URL}/achievements/${id}/levels/${step}`, { description, with: 'levels' }, 'PUT');
    },
    async deleteImage({ rootGetters }, { media_id, achievement_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/achievements/${achievement_id}/delete_media/${media_id}`, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
