const actions = {
    async getComment({ rootGetters, commit }, { id }) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API}/united_table_comments/${id}`, { with: 'user' });
        if (!errors)
            commit('setComment', data);
        return { data, errors };
    },
    async getCommentList({ rootGetters }, { params } = {}) {
        return await this.getDataStore(`${rootGetters.API}/united_table_comments`, params);
    },
    async createComment({ rootGetters }, params = {}) {
        return await this.postDataStore(`${rootGetters.API}/united_table_comments`, { ...params, with: 'list;listHeader;mainColumn;item;itemCell' }, 'POST');
    },
    async updateComment({ rootGetters }, { id, text }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API}/united_table_comments/${id}`, { text, with: 'list;listHeader;mainColumn;item;itemCell' }, 'PUT');
        return { data, errors };
    },
    async deleteComment({ rootGetters }, { id }) {
        return await this.postDataStore(`${rootGetters.API}/united_table_comments/${id}`, {}, 'DELETE');
    }
};
export default { actions };
