const actions = {
    async create({ rootGetters }, payload) {
        const { query, params } = payload;
        const { formId } = query;
        return await this.postDataStore(`${rootGetters.API_URL}/forms/${formId}/form_fields`, params);
    },
    async get({ rootGetters }, payload) {
        const { query, params } = payload;
        const { formId, fieldId } = query;
        return await this.getDataStore(`${rootGetters.API_URL}/forms/${formId}/form_fields/${fieldId}`, { ...params, with: 'customField:multiple' });
    },
    async update({ rootGetters }, payload) {
        const { query, params } = payload;
        const { formId, fieldId } = query;
        return await this.postDataStore(`${rootGetters.API_URL}/forms/${formId}/form_fields/${fieldId}`, params, 'PUT');
    },
    async delete({ rootGetters }, payload) {
        const { query } = payload;
        const { formId, fieldId } = query;
        return await this.postDataStore(`${rootGetters.API_URL}/forms/${formId}/form_fields/${fieldId}`, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    actions
};
