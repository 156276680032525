export default {
  props: {
    // Закрыть все полностью
    close: {
      type: Function
    },
    // закрывает текущую модалку и открывает предыдущую
    prevComponent: {
      type: Function
    },
    // открывает следующее окно из модального окна
    nextComponent: {
      type: Function
    },
    // определить свойство вызова Emit в модальном окне
    emit: {
      type: String,
      default: 'close'
    }
  }
}
