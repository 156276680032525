import { __decorate, __metadata } from "tslib";
import { Component, Watch, Vue, Prop } from 'nuxt-property-decorator';
let SelectWithIcons = class SelectWithIcons extends Vue {
    constructor() {
        super(...arguments);
        this.dataItems = this.items;
        this.dataValue = this.value;
    }
    get iconStyle() {
        return { color: this.iconColor };
    }
    changeSelect() {
        this.$emit('changeSelect', { name: this.name, value: this.dataValue });
        this.$emit('input', this.dataValue);
    }
    changeParentValue(newVal) {
        this.dataValue = newVal;
    }
};
__decorate([
    Prop({}),
    __metadata("design:type", Object)
], SelectWithIcons.prototype, "value", void 0);
__decorate([
    Prop({ type: Array, default: () => [] }),
    __metadata("design:type", Object)
], SelectWithIcons.prototype, "items", void 0);
__decorate([
    Prop({ type: Array, default: () => [] }),
    __metadata("design:type", Object)
], SelectWithIcons.prototype, "rules", void 0);
__decorate([
    Prop({ type: String, default: () => '' }),
    __metadata("design:type", Object)
], SelectWithIcons.prototype, "name", void 0);
__decorate([
    Prop({ type: String, default: () => '' }),
    __metadata("design:type", Object)
], SelectWithIcons.prototype, "label", void 0);
__decorate([
    Prop({ type: String, default: () => '' }),
    __metadata("design:type", Object)
], SelectWithIcons.prototype, "placeholder", void 0);
__decorate([
    Prop({ type: String, default: () => '' }),
    __metadata("design:type", Object)
], SelectWithIcons.prototype, "iconColor", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Object)
], SelectWithIcons.prototype, "disabled", void 0);
__decorate([
    Prop({ type: Boolean, default: () => false }),
    __metadata("design:type", Object)
], SelectWithIcons.prototype, "error", void 0);
__decorate([
    Prop({ type: [Boolean, String], default: () => false }),
    __metadata("design:type", Object)
], SelectWithIcons.prototype, "hideDetails", void 0);
__decorate([
    Watch('value'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], SelectWithIcons.prototype, "changeParentValue", null);
SelectWithIcons = __decorate([
    Component
], SelectWithIcons);
export default SelectWithIcons;
