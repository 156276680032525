import { mixinMutations } from '@/store/mixins';
import defaultState from './defaultState';
const state = { ...defaultState() };
const mutations = { ...mixinMutations() };
const actions = {
    async getFolders({ rootGetters, commit, state }, search = '') {
        const { folders_page: page, folders, stopLoading } = state;
        if (stopLoading)
            return;
        commit('setProperty', {
            name: 'foldersLoading',
            value: true
        });
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/test_result_folders`, {
            search,
            page,
            per_page: 20
        });
        if (errors)
            return;
        commit('setProperty', [
            {
                name: 'folders',
                value: [...folders, ...data]
            },
            {
                name: 'foldersLoading',
                value: false
            },
            {
                name: 'folders_page',
                value: page + 1
            }
        ]);
        return data;
    },
    async addFolders({ commit, state, dispatch }, search = '') {
        const { foldersLoading, stopLoading } = state;
        if (foldersLoading || stopLoading)
            return;
        const data = await dispatch('getFolders', search);
        if (!data || !Array.isArray(data) || !data.length) {
            commit('setProperty', {
                name: 'stopLoading',
                value: true
            });
        }
    },
    async saveFolder({ rootGetters }, { name, description, user_prize, id }) {
        const url = id ? `${rootGetters.API_URL}/test_result_folders/${id}` : `${rootGetters.API_URL}/test_result_folders`;
        const method = id ? 'PUT' : 'POST';
        return await this.postDataStore(url, {
            name,
            description,
            user_prize
        }, method);
    },
    async deleteFolderItem({ rootGetters, dispatch }, id) {
        const response = await this.postDataStore(`${rootGetters.API_URL}/test_result_folders/${id}`, {}, 'DELETE');
        await dispatch('getFolders', '');
        return response;
    },
    async getFolderTests({ rootGetters, state, commit }, { clear = false, search = '' } = { clear: false, search: '' }) {
        const { currentFolder } = state;
        if (!currentFolder)
            return;
        commit('setProperty', {
            name: 'testsInFolderLoading',
            value: true
        });
        if (clear) {
            commit('setProperty', [
                {
                    name: 'testsInFolder',
                    value: []
                },
                {
                    name: 'tests_page',
                    value: 1
                },
                {
                    name: 'stopTestsLoading',
                    value: false
                }
            ]);
        }
        const { tests_page: page } = state;
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/test_result_folders/${currentFolder.id}/test_results`, {
            with: 'lesson:id,name',
            lessonName: search,
            page,
            per_page: 20
        });
        const { testsInFolder } = state;
        commit('setProperty', [
            {
                name: 'testsInFolder',
                value: [...testsInFolder, ...data]
            },
            {
                name: 'testsInFolderLoading',
                value: false
            },
            {
                name: 'tests_page',
                value: page + 1
            }
        ]);
        return data;
    },
    async addFolderTests({ commit, state, dispatch }, search = '') {
        const { testsInFolderLoading, stopTestsLoading } = state;
        if (testsInFolderLoading || stopTestsLoading)
            return;
        const data = await dispatch('getFolderTests', {
            clear: false,
            search
        });
        if (!data || !Array.isArray(data) || !data.length) {
            commit('setProperty', {
                name: 'stopTestsLoading',
                value: true
            });
        }
    },
    async getTestsInLesson({ rootGetters, commit }, lesson_id) {
        if (!lesson_id)
            return;
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/tests`, {
            hasAttempts: 1,
            lesson_id
        });
        const parsedTests = data.map(({ title, id }) => ({
            value: id,
            text: title
        }));
        commit('setProperty', {
            name: 'testList',
            value: parsedTests
        });
        return parsedTests;
    },
    async generateTestResult({ rootGetters, state }, test_id) {
        const { currentFolder } = state;
        if (!currentFolder || !test_id)
            return;
        try {
            return await this.postDataStore(`${rootGetters.API_URL}/test_result_folders/${currentFolder.id}/user_test_results`, { test_id }, 'POST');
        }
        catch (e) {
            return { errors: e.message || 'Произошла ошибка' };
        }
    },
    async checkResultReady({ rootGetters, state }, test_id) {
        const { currentFolder } = state;
        if (!currentFolder || !test_id)
            return;
        const testList = null;
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/test_result_folders/${currentFolder.id}/table_processed`, { test_id });
        return {
            result: data,
            testList
        };
    },
    async deleteTestInFolder({ rootGetters, commit, state }, test_id) {
        const { currentFolder } = state;
        if (!currentFolder)
            return;
        const response = await this.postDataStore(`${rootGetters.API_URL}/test_result_folders/${currentFolder.id}/user_test_results/test/${test_id}`, {}, 'DELETE');
        commit('setProperty', {
            name: 'testsInFolder',
            value: state.testsInFolder.filter(({ id }) => id !== test_id)
        });
        return response;
    },
    async getUsersInFolder({ rootGetters, commit, state }, { clear = false, search = '' }) {
        const { currentFolder, users_page: page } = state;
        if (!currentFolder)
            return;
        commit('setProperty', {
            name: 'userInFolderLoading',
            value: true
        });
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/test_result_folders/${currentFolder.id}/user_results`, {
            with: 'test.lesson:id,name',
            search,
            page,
            per_page: 20
        });
        if (clear) {
            commit('setProperty', [
                {
                    name: 'usersInFolder',
                    value: []
                }
            ]);
        }
        const { usersInFolder } = state;
        commit('setProperty', [
            {
                name: 'usersInFolder',
                value: [...usersInFolder, ...data]
            },
            {
                name: 'userInFolderLoading',
                value: false
            },
            {
                name: 'users_page',
                value: page + 1
            }
        ]);
        return data;
    },
    async addFolderUsers({ commit, state, dispatch }, search = '') {
        const { userInFolderLoading, stopUsersLoading } = state;
        if (userInFolderLoading || stopUsersLoading)
            return;
        const data = await dispatch('getUsersInFolder', {
            clear: false,
            search
        });
        if (!data || !Array.isArray(data) || !data.length) {
            commit('setProperty', {
                name: 'stopUsersLoading',
                value: true
            });
        }
    },
    async getUsersInTest({ rootGetters, state }, { params = {} } = {}) {
        const { testParams } = state;
        return await this.getDataStore(`${rootGetters.API_URL}/test_result_folders/${testParams.folder_id}/test_results/test/${testParams.test_id}`, { ...params });
    },
    async getCurrentTest({ commit, rootGetters }, { test_id, folder_id }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/test_result_folders/${folder_id}/test/${test_id}`, { with: 'lesson.course:name' });
        commit('setProperty', {
            name: 'currentTest',
            value: data
        });
        return data;
    },
    async updateUserTestResult({ rootGetters, state }, { params, folder_id, result_id }) {
        const { currentFolder } = state;
        const folderId = folder_id || currentFolder && currentFolder.id || 0;
        return await this.postDataStore(`${rootGetters.API_URL}/test_result_folders/${folderId}/user_test_results/${result_id}`, { ...params }, 'PUT');
    },
    async getCurrentUser({ rootGetters, commit }, user_id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/users/${user_id}`);
        commit('setProperty', {
            name: 'currentUser',
            value: data
        });
        return data;
    },
    async getUserResultInFolder({ rootGetters, state, commit }, { params = {} } = {}) {
        commit('setProperty', {
            name: 'school_class',
            value: ''
        });
        const { userParams } = state;
        const response = await this.getDataStore(`${rootGetters.API_URL}/test_result_folders/${userParams.folder_id}/user_results/user/${userParams.user_id}`, { ...params, with: 'test.lesson:id,name' });
        const { data } = response;
        if (!data || !Array.isArray(data) || !data.length) {
            return response;
        }
        const [firstUser] = data;
        commit('setProperty', {
            name: 'school_class',
            value: firstUser?.school_class
        });
        return response;
    },
    async getFolderResults({ commit, rootGetters }, { params = {} }) {
        const { currentFolder } = state;
        if (!currentFolder)
            return;
        commit('setProperty', { name: 'resultsLoading', value: true });
        const response = await this.getDataStore(`${rootGetters.API_URL}/test_result_folders/${currentFolder.id}/user_test_results`, {
            ...params,
            with: 'test.lesson:id,name'
        });
        commit('setProperty', [
            { name: 'resultsLoading', value: false },
            { name: 'resultList', value: response && response.data && Array.isArray(response.data) ? response.data : [] }
        ]);
        return response;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
