const defaultState = () => ({
    folders: [],
    currentFolder: null,
    foldersLoading: false,
    folders_page: 1,
    stopLoading: false,
    testList: [],
    testsInFolder: [],
    testsInFolderLoading: false,
    tests_page: 1,
    stopTestsLoading: false,
    usersInFolder: [],
    userInFolderLoading: false,
    users_page: 1,
    stopUsersLoading: false,
    currentTest: null,
    testParams: {},
    currentUser: null,
    userParams: {},
    school_class: '',
    resultsLoading: false,
    resultList: []
});
export default defaultState;
