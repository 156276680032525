import { __decorate, __metadata } from "tslib";
import { Component, Prop, Vue } from 'nuxt-property-decorator';
let AccountQuestion = class AccountQuestion extends Vue {
};
__decorate([
    Prop({ type: String, required: true }),
    __metadata("design:type", Object)
], AccountQuestion.prototype, "title", void 0);
__decorate([
    Prop({ type: String, required: true }),
    __metadata("design:type", Object)
], AccountQuestion.prototype, "text", void 0);
__decorate([
    Prop({ type: String, default: () => '' }),
    __metadata("design:type", Object)
], AccountQuestion.prototype, "tooltip", void 0);
AccountQuestion = __decorate([
    Component
], AccountQuestion);
export default AccountQuestion;
