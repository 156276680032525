export default [
    {
        text: 'ID', value: 'id', sortable: true, checked: true, subtype: 'courseSimple', sort: 1, type: 'integer', disabled: true
    },
    {
        text: 'Тип', value: 'entity_type', checked: true, subtype: 'courseSimple', sort: 7, type: 'list', sortable: true
    },
    {
        text: 'Логотип', value: 'img', componentName: 'td-course-banner', sortable: false, checked: true, subtype: 'courseSimple', sort: 2, type: 'string', hasFilter: false
    },
    {
        text: 'Название', value: 'name', sortable: true, componentName: 'td-name', checked: true, subtype: 'courseSimple', sort: 3, disabled: true, type: 'string'
    },
    {
        text: 'Категории', value: 'categories', sortable: true, componentName: 'td-labels', checked: false, subtype: 'courseSimple', sort: 4, type: 'list', hasFilter: false
    },
    {
        text: 'Цена', value: 'price', checked: true, subtype: 'courseSimple', sort: 5, type: 'price'
    },
    {
        text: 'Статус', value: 'status', checked: true, subtype: 'courseSimple', sort: 6, type: 'list'
    },
    {
        text: 'Тип курса', value: 'type', checked: true, subtype: 'courseSimple', sort: 7, type: 'list', sortable: true
    },
    {
        text: 'Кастомный тип курса', value: 'custom_type', checked: false, subtype: 'courseSimple', sort: 7, type: 'list', sortable: true
    },
    {
        text: 'Папка', value: 'folder', sortable: false, checked: true, subtype: 'courseSimple', sort: 8, hasFilter: false, type: 'string'
    },
    {
        text: 'Дата начала', value: 'start_date', componentName: 'td-date', sortable: true, checked: true, subtype: 'courseSimple', sort: 9, type: 'date'
    },
    {
        text: 'Дата окончания', value: 'end_date', componentName: 'td-date', sortable: true, checked: true, subtype: 'courseSimple', sort: 10, type: 'date'
    },
    {
        text: 'Количество пользователей', value: 'students_count', sortable: true, checked: true, subtype: 'courseSimple', sort: 11, type: 'integer', hasFilter: false
    },
    {
        text: 'Дата создания', value: 'created_at', componentName: 'td-date', checked: true, subtype: 'courseSimple', sort: 12, type: 'date'
    }
];
