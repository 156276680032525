const defaultState = () => ({
    articles: [],
    categories: [],
    categoriesTree: null,
    page: 1,
    per_page: 20,
    finishLoading: false,
    articles_total: 0
});
export default defaultState;
