import customFieldsVariablesModule from './customFieldsVariables';
import { mixinMutations } from '@/store/mixins';
import defaultState from '@/store/modules/modalRelations/defaultState';
const getItemsIds = (items) => items.map(({ id }) => id);
const findItemById = (items, id) => items.find((i) => i.id === id);
const isSelectedItem = (items, id) => !!findItemById(items, id);
const clearSelected = (items) => items.map((item) => ({ ...item, selected: false }));
const keyOfType = {
    dependent_custom_fields: 'custom_field_id',
    dependent_variables: 'dependent_c_f_variable_id'
};
const state = defaultState();
const getters = {
    isEmptyBinding() {
        const { attachItems, detachItems } = state;
        return attachItems.length === 0 && detachItems.length === 0;
    },
    isEmptyBindingItems(state) {
        return state.bindingItems.length === 0;
    },
    typeRelation(state) {
        return state.typeRelations.find((r) => r.value === state.typeRelation);
    }
};
const mutations = {
    ...mixinMutations(),
    clearSelectedItems(state) {
        state.items = clearSelected(state.items);
    },
    resetItems() {
        state.items = state.items.map((item) => ({
            ...item,
            selected: isSelectedItem(state.selectedItems, item.id)
        }));
    },
    resetBindings() {
        state.attachItems = [];
        state.detachItems = [];
    },
    showModal(state, payload) {
        const { customFieldId, variableId, relations, itemValue, type } = payload;
        state.customFieldId = customFieldId;
        state.variableId = variableId;
        state.itemValue = itemValue;
        state.bindingItems = relations.slice();
        state.selectedItems = relations.slice();
        state.typeRelation = type;
        state.isShowModal = true;
    },
    hideModal(state) {
        state.isShowModal = false;
    }
};
const actions = {
    async getCustomField({ commit, dispatch }) {
        const customFieldId = state.selectCustomField && state.selectCustomField.id || state.bindingItems[0].custom_field_id;
        const query = { id: customFieldId };
        const { data } = await dispatch('customField/getCustomField', { query }, { root: true });
        const { id, name } = data;
        commit('setProperty', { name: 'selectCustomField', value: { id, name } });
    },
    async getRelations({ commit, dispatch }, payload) {
        const { params } = payload;
        const { parseItems } = payload;
        const { queryPath } = payload;
        if (!params)
            return;
        try {
            commit('setProperty', { name: 'isLoading', value: true });
            const { data, meta } = await dispatch(queryPath, params, { root: true });
            commit('setProperty', [
                { name: 'items', value: parseItems(data) },
                { name: 'itemsTotal', value: meta.total }
            ]);
        }
        catch (e) {
            throw new Error(e);
        }
        finally {
            commit('setProperty', { name: 'isLoading', value: false });
        }
    },
    async updateRelations({ commit, dispatch, getters }) {
        if (getters.isEmptyBinding)
            return;
        const { attachItems, detachItems, bindingItems, variableId, typeRelation: type } = state;
        const bindings = {
            attach: {
                method: 'customFieldVariables/attachVariable',
                items: attachItems
            },
            detach: {
                method: 'customFieldVariables/detachVariable',
                items: detachItems
            }
        };
        const query = { variableId };
        const requestBind = async ({ method, items }) => {
            if (items.length === 0)
                return;
            // @ts-ignore
            const params = { [keyOfType[type]]: getItemsIds(items) };
            await dispatch(method, { query, params }, { root: true });
        };
        try {
            commit('setProperty', { name: 'isLoading', value: true });
            Object.values(bindings).map(async (binding) => {
                await requestBind(binding);
            });
            const filteredBindingItems = bindingItems && bindingItems.filter(({ id }) => !findItemById(detachItems, id)) || [];
            const newBindingItems = [...filteredBindingItems, ...attachItems];
            commit('resetBindings');
            commit('setProperty', { name: 'bindingItems', value: newBindingItems });
        }
        catch (e) {
            throw new Error(e);
        }
        finally {
            commit('setProperty', { name: 'isLoading', value: false });
        }
    },
    async deleteRelations({ commit, dispatch }) {
        const { selectedItems } = state;
        commit('setProperty', { name: 'detachItems', value: selectedItems });
        await dispatch('updateRelations');
        commit('setProperty', { name: 'selectedItems', value: [] });
        commit('clearSelectedItems');
    },
    cancelSelected({ commit }) {
        commit('setProperty', { name: 'selectedItems', value: state.bindingItems.slice() });
        commit('resetItems');
        commit('resetBindings');
    },
    async getCustomFields({ dispatch }, payload) {
        return await dispatch('customField/getCustomFields', {
            params: {
                ...payload,
                with: 'variables:value',
                type: 'list',
                except: state.customFieldId,
                per_page: 10
            }
        }, { root: true });
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: { customFieldsVariables: customFieldsVariablesModule }
};
