import { mixinMutations } from '@/store/mixins';
import defaultState from '@/store/modules/courseAndPayments/entities/defaultState';
const state = defaultState;
const mutations = { ...mixinMutations() };
const actions = {
    async getPaymentsList({ rootGetters }, params) {
        const response = await this.getDataStore(`${rootGetters.API_URL}/payment_orders`, {
            with: 'paymentsByCreatedAt;paymentsByCreatedAt.paymentStatus;paymentsByCreatedAt.learners;course;paymentsByCreatedAt.payer;paymentsByCreatedAt.coursePackagesBySort:id,name;paymentsByCreatedAt.courseBranch',
            withSums: '1',
            ...params
        });
        return response;
    },
    async getPaymentOrder({ rootGetters }, params) {
        const response = await this.getDataStore(`${rootGetters.API_URL}/payments`, {
            with: 'paymentStatus;learners;coursePackagesBySort:id,name;courseBranch;payer',
            sort: 'created_at',
            desc: '1',
            paymentOrderId: params.params.paymentOrderId
        });
        return response;
    },
    async getCourses({ rootGetters }, params) {
        return await this.getDataStore(`${rootGetters.API_URL}/courses`, params);
    },
    async getPayers({ rootGetters }, params) {
        return await this.getDataStore(`${rootGetters.API_URL}/users`, params);
    },
    async getPayer({ rootGetters }, id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/users`, { id });
        return data;
    },
    async getStatusesPay({ rootGetters }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/payment_statuses`);
        return data;
    },
    async getCourseBranches({ rootGetters }, id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/courses/${id}/course_branches`, {
            with: 'availableCoursePackagesBySort;course',
            sort: 'sort'
        });
        return data;
    },
    async createPayment({ rootGetters }, params) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/payments`, params);
        return data;
    },
    async updateStatus({ rootGetters }, params) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/payments/${params.id}/short_update`, { status_id: params.status_id }, 'PUT');
        return data;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
