export default (price) => {
    if (!price) {
        return '';
    }
    price = String(price);
    if (!(price.indexOf('.') > -1)) {
        return price.replace(/(.)(?=(.{3})+$)/g, '$1 ');
    }
    return price;
};
