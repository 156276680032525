const mutations = {
    selectList(state, payload) {
        state.currentListId = +payload.id;
    },
    createList(state, payload) {
        state.lists.push({ ...payload, access_users: [] });
        state.currentListId = payload.id;
    },
    updateList(state, payload) {
        const listIndex = state.lists.findIndex((list) => list.id === payload.id);
        if (listIndex !== -1)
            state.lists.splice(listIndex, 1, payload);
        if (state.currentListId === payload.id)
            state.listHeaders.data = payload.headers_by_sort;
    },
    deleteList(state, payload) {
        state.lists = state.lists.filter((list) => list.id !== payload.id);
        if (!state.lists.length) {
            state.currentListId = null;
            state.mainColumns = [];
            state.listHeaders.data = [];
            return;
        }
        if (state.currentListId === payload.id) {
            const list = state.lists[0];
            state.currentListId = list.id;
            state.listHeaders.data = list.headers_by_sort;
        }
    },
    deleteListHeader(state, { listId, id }) {
        const list = state.lists.find((list) => list.id === listId);
        list.headers_by_sort = list.headers_by_sort.filter((header) => header.id !== id);
    },
    actualizeList(state) {
        const list = state.lists.find((list) => list.id === state.currentListId);
        list.update_status = 'process';
    }
};
const actions = {
    async getLists({ rootGetters, commit }) {
        const res = await this.getDataStore(`${rootGetters.API}/united_table_lists`, {
            with: 'headersBySort;headersBySort.accessUsers;accessUsers;',
            sort: 'sort',
            all: 1
        });
        if (!res.errors) {
            commit('setProperty', { name: 'lists', value: res.data });
        }
        return res;
    },
    async getList({ rootGetters }, { id }) {
        return await this.getDataStore(`${rootGetters.API}/united_table_lists/${id}`);
    },
    async createList({ rootGetters, commit }, { access_users, types, ...list }) {
        const params = {
            ...list,
            access_users_ids: access_users.map((user) => user.id),
            with: 'headersBySort;accessUsers'
        };
        const { data, errors } = await this.postDataStore(`${rootGetters.API}/united_table_lists`, params, 'POST');
        const { data: headers } = await this.postDataStore(`${rootGetters.API}/united_table_lists/${data.id}/united_table_list_headers/multi_create`, { types }, 'POST');
        if (!errors) {
            data.headers_by_sort = headers;
            commit('createList', data);
        }
        return { data, errors };
    },
    async actualizeList({ rootGetters, commit, state }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API}/united_table_lists/${state.currentListId}/actualize_data`, {}, 'POST');
        if (!errors)
            commit('actualizeList');
        return { data, errors };
    },
    async actualizeSum({ rootGetters, commit, state }, payload) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API}/united_table_lists/${state.currentListId}/actualize_courses_sums`, { ...payload }, 'POST');
        if (!errors)
            commit('actualizeList');
        return { data, errors };
    },
    async actualizeItems({ rootGetters, commit, state }, { items_ids }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API}/united_table_lists/${state.currentListId}/actualize_data`, { items_ids }, 'POST');
        if (!errors)
            commit('actualizeList');
        return { data, errors };
    },
    async multiSortLists({ rootGetters }, { lists }) {
        return await this.postDataStore(`${rootGetters.API}/united_table_lists/multi_sort`, { lists }, 'POST');
    },
    async updateList({ rootGetters, commit }, { id, access_users, ...list }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API}/united_table_lists/${id}`, {
            ...list,
            access_users_ids: access_users?.length ? { ...access_users.map((user) => user.id) } : undefined,
            with: 'headersBySort;headersBySort.accessUsers;accessUsers;'
        }, 'PUT');
        if (!errors)
            commit('updateList', data);
        return { data, errors };
    },
    async deleteList({ rootGetters, commit }, { id }) {
        const res = await this.postDataStore(`${rootGetters.API}/united_table_lists/${id}`, {}, 'DELETE');
        if (!res.errors)
            commit('deleteList', { id });
        return res;
    },
    async deleteListHeader({ rootGetters, commit, dispatch, state }, { listId, id }) {
        const { data, errors } = await this.postDataStore(`${rootGetters.API}/united_table_lists/${listId}/united_table_list_headers/${id}`, {}, 'DELETE');
        if (!errors)
            commit('deleteListHeader', { id, listId });
        if (state.currentListId === listId) {
            dispatch('getMainColumnList', { id: listId });
            dispatch('getListHeaders', { id: listId });
        }
        return { data, errors };
    },
    async startListDownloading({ rootGetters }, params = {}) {
        return await this.postDataStore(`${rootGetters.API}/united_table_lists/list_download`, params);
    },
    async downloadList({ rootGetters }, params = {}) {
        return await this.getDataStore(`${rootGetters.API}/united_table_lists/list_file`, params);
    },
    async startSumDownloading({ rootGetters }, params = {}) {
        return await this.postDataStore(`${rootGetters.API}/united_table_lists/list_sum_download`, params);
    },
    async downloadSum({ rootGetters }, params = {}) {
        return await this.getDataStore(`${rootGetters.API}/united_table_lists/list_file`, params);
    },
};
export default { mutations, actions };
