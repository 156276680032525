import { mixinActions, mixinMutations } from '@/store/mixins';
import defaultItem from './entities/defaultItem';
const state = () => ({
    types: [],
    defaultItem: defaultItem(),
    freezeItem: { ...defaultItem() },
    statuses: []
});
const mutations = { ...mixinMutations() };
const actions = {
    ...mixinActions(),
    async list({ commit, rootGetters }, payload) {
        const { params } = payload;
        const response = await this.getDataStore(`${rootGetters.API_URL}/order_statuses`, params);
        commit('setProperty', { name: 'statuses', value: response.data });
        return response;
    },
    async getTypes({ commit, rootGetters }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/order_statuses/get_types`);
        commit('setProperty', { name: 'types', value: data });
    },
    async createStatus({ rootGetters }, payload) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/order_statuses`, { ...payload }, 'POST');
        return data;
    },
    async updateStatus({ rootGetters }, payload) {
        const { id, ...rest } = payload;
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/order_statuses/${id}`, { ...rest }, 'PUT');
        return data;
    },
    async deleteStatus({ rootGetters }, id) {
        return await this.postDataStore(`${rootGetters.API_URL}/order_statuses/${id}`, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
