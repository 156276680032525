import { mixinMutations } from '@/store/mixins';
const mutations = { ...mixinMutations() };
const state = { courses_count: 0 };
const actions = {
    async list({ rootGetters }, { params }) {
        params.with = 'courseReviewCourse;courseReviewCourse.user';
        return await this.getDataStore(`${rootGetters.API_URL}/course_reviews`, params);
    },
    async getReviews({ rootGetters }, { course_id, search }) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_reviews/`, {
            notCourseId: course_id,
            with: 'user',
            search: search || ''
        });
    },
    async getReview({ rootGetters }, id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/course_reviews/${id}`, { with: 'courseReviewCourse.user' });
        return data;
    },
    async createReview({ rootGetters }, { params }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/course_reviews_admin`, params);
        return data;
    },
    async updateReview({ rootGetters }, { id, params }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/course_reviews/${id}`, params, 'PUT');
        return data;
    },
    async delete({ rootGetters }, { params: { id } }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_reviews/${id}`, {}, 'DELETE');
    },
    async attachOrDetachCoursesReviews({ rootGetters }, { id, action, ids }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_reviews/${id}/${action}`, { courses_ids: ids });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
