import { mixinMutations } from '@/store/mixins';
import defaultState from '@/store/modules/courseGroupDetails/entities/defaultState';
const state = defaultState();
const mutations = {
    ...mixinMutations(),
    setDefault(state, id) {
        state.coursesInPool.forEach((course) => {
            course.course_group_course_pool_is_default = course.id === id;
        });
    }
};
const getters = {
    typesModel(state) {
        if (!state.attributes.length)
            return [];
        // Получаем mapped-объект для используемых свойств в группировке
        // { <course_group_attribute_id>: [attributeValueId, ...] }
        // Пример { 32: [24, 25, 45], 54: [12, 15, 21] }
        const mappedValues = state.attributeValues.reduce((acc, prop) => {
            acc[prop.course_group_attribute_id] ? acc[prop.course_group_attribute_id].push(prop.id) : acc[prop.course_group_attribute_id] = [prop.id];
            return acc;
        }, {});
        // По полученному mapped-объекту, собираем итоговый массив данных
        // С типом CourseGroupType[]
        const attributes = Object.entries(mappedValues).map(([groupId, propIds]) => {
            const { values, ...attribute } = state.attributes.find((attribute) => attribute.id === +groupId);
            return { ...attribute, values: state.attributeValues.filter((value) => propIds.includes(value.id)) };
        });
        // Сортируем полученные данные но основании порядка заполнения в админке.
        return attributes.sort((a, b) => a.sort - b.sort);
    }
};
const actions = {
    async getGroupData({ rootGetters, commit }, id) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/course_groups/${id}`, { with: 'attributes,attributeValues,sets,attributes.values,coursesInPool' });
        if (errors)
            return;
        commit('setProperty', { name: 'image', value: { id: null, link: '' } });
        commit('setState', data);
    },
    async getGroupTypes({ rootGetters, commit }) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/course_group_attributes`, { per_page: -1, with: 'values' });
        if (errors)
            return;
        commit('setProperty', { name: 'groupTypes', value: data });
    },
    async getSetsCount({ rootGetters }, groupId) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_groups/${groupId}/course_group_course/filled_sets_count`);
    },
    async changeGroupField({ rootGetters, state }, { name, value }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_groups/${state.id}`, { [name]: value }, 'PUT');
    },
    async attachCourse({ rootGetters, state, commit }, course) {
        const { errors } = await this.postDataStore(`${rootGetters.API_URL}/course_groups/${state.id}/attach_course_to_pool`, { course_id: course.id });
        if (errors)
            return;
        commit('setProperty', { name: 'coursesInPool', value: [...state.coursesInPool, { ...course }] });
    },
    async detachCourse({ rootGetters, state, commit }, course_id) {
        const { errors } = await this.postDataStore(`${rootGetters.API_URL}/course_groups/${state.id}/detach_course_to_pool`, { course_id });
        if (errors)
            return;
        commit('setProperty', { name: 'coursesInPool', value: state.coursesInPool.filter((course) => course.id !== course_id) });
    },
    async detachCourseFromPool({ rootGetters, state, commit }, course_id) {
        const { errors } = await this.postDataStore(`${rootGetters.API_URL}/course_groups/${state.id}/detach_course_to_pool_with_course_group_course`, { course_id });
        if (errors)
            return;
        commit('setProperty', { name: 'coursesInPool', value: state.coursesInPool.filter((course) => course.id !== course_id) });
    },
    async attachType({ rootGetters, state, commit }, type) {
        const { errors } = await this.postDataStore(`${rootGetters.API_URL}/course_groups/${state.id}/attach_course_group_attribute`, { course_course_group_attribute_id: type.id });
        if (errors)
            return;
        commit('setProperty', { name: 'attributes', value: [...state.attributes, type] });
    },
    async detachType({ rootGetters, state, commit }, type) {
        const { errors } = await this.postDataStore(`${rootGetters.API_URL}/course_groups/${state.id}/detach_course_group_attribute_with_course_group_course`, { course_course_group_attribute_id: type.id });
        if (errors)
            return;
        commit('setProperty', { name: 'attributes', value: state.attributes.filter(({ id }) => type.id !== id) });
    },
    async attachTypeProp({ rootGetters, state, commit }, props) {
        const { errors } = await this.postDataStore(`${rootGetters.API_URL}/course_groups/${state.id}/attach_course_group_attribute_value`, { course_course_group_attribute_value_id: props.map(({ id }) => id) });
        if (errors)
            return;
        commit('setProperty', { name: 'attributeValues', value: [...state.attributeValues, ...props] });
    },
    async setDefaultCourse({ rootGetters, state, commit }, id) {
        const res = await this.postDataStore(`${rootGetters.API_URL}/course_groups/${state.id}/courses/${id}/course_group_course/set_default`, {}, 'PUT');
        if (!res.errors)
            commit('setDefault', id);
        return res;
    },
    async detachTypeProp({ rootGetters, state, commit }, props) {
        const { errors } = await this.postDataStore(`${rootGetters.API_URL}/course_groups/${state.id}/detach_course_group_attribute_value_with_course_group_course`, { course_course_group_attribute_value_id: props.map(({ id }) => id) });
        if (errors)
            return;
        commit('setProperty', { name: 'attributeValues', value: state.attributeValues.filter((type) => !props.some((prop) => prop.id === type.id)) });
    },
    async findSet({ rootGetters, state }, set_json) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_groups/${state.id}/find_set`, { set_json });
    },
    async updateSet({ rootGetters, state }, { set_id, course_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_groups/${state.id}/sets/${set_id}`, { course_id }, 'PUT');
    },
    async multiUpdateSet({ rootGetters, state }, { course_id, course_group_set_id }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_groups/${state.id}/sets/multi_update`, { course_id, course_group_set_id }, 'PUT');
    },
    async attachPropsToCourse({ rootGetters, state }, { course_id, set_json }) {
        return await this.postDataStore(`${rootGetters.API_URL}/course_groups/${state.id}/courses/${course_id}/course_group_course/multi_update`, { set_json }, 'PUT');
    },
    async getCourseGroupCourse({ rootGetters, state }) {
        return await this.getDataStore(`${rootGetters.API_URL}/course_groups/${state.id}/course_group_course`);
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
