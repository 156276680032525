import defaultState from './defaultCustomVariablesState';
import { mixinMutations } from '@/store/mixins';
const state = defaultState();
const getters = {
    typeRelation(state) {
        return state.typeRelations.find((r) => r.value === state.type);
    }
};
const mutations = {
    ...mixinMutations(),
    init(state, { variableId, customFieldId }) {
        state.variableId = variableId;
        state.customFieldId = customFieldId;
    }
};
const actions = {
    async getCustomFields({ commit, state, rootGetters }, payload) {
        const { data, meta } = await this.getDataStore(`${rootGetters.API_URL}/custom_fields`, {
            ...payload,
            with: 'variables:value',
            type: 'list',
            except: state.customFieldId,
            per_page: 10
        });
        commit('setProperty', [
            { name: 'items', value: data.map(({ id, name }) => ({ id, name })) },
            { name: 'itemsTotal', value: meta.total }
        ]);
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
