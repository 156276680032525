import typeRelations from '@/store/modules/customField/enteties/typeRelations';
const defaultState = () => ({
    customFieldId: null,
    headers: [
        { value: 'name', align: 'start' },
        { value: 'selected' }
    ],
    items: [],
    itemsTotal: 0,
    isLoading: false,
    variableId: null,
    type: 'dependent_variables',
    typeRelations,
    selectedCustomField: null
});
export default defaultState;
