import { mixinActions, mixinMutations } from '@/store/mixins';
import InitState from './entities/defaultState';
const defaultState = InitState.createDefaultState();
const state = InitState.createDefaultState();
const mutations = {
    ...mixinMutations(),
    clearPartState(state, stateName) {
        state[stateName] = defaultState[stateName];
    },
    updateCourseField(state, { name, value }) {
        state.course[name] = value;
    }
};
const getters = {
    countCourseLessons(state) {
        const result = {};
        state.courseStructure.forEach((item) => {
            result[item.id] = item?.lessons_count || 0;
            item.children.forEach((child) => {
                result[child.parent_id] += child.lessons_count;
            });
        });
        return result;
    }
};
const actions = {
    ...mixinActions(),
    async getCourse({ commit, rootGetters }, { course_id }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}`;
        const response = await this.getDataStore(url, {
            with: 'faqsBySort;advantagesBySort;subcategories_with_categories;packageForLanding;locals;galleries;branches;branches.bulletPoints',
            onlyActiveBranches: 1,
            append_avg_rating: true,
            withMedia: 'about_course_image,additional_offer_image,about_course_media,additional_offer_media',
            append_approved_reviews_has_points_count: true,
            withCount: 'students'
        });
        const { data } = response;
        commit('setProperty', [
            { name: 'courseFaq', value: data.faqs_by_sort },
            { name: 'courseAdvantages', value: data.advantages_by_sort },
            { name: 'courseCategories', value: data.subcategories_with_categories },
            { name: 'packageForLanding', value: data.packageForLanding.sort((a, b) => a.sort - b.sort) },
            { name: 'use_custom_sort', value: data.use_custom_sort },
            { name: 'course', value: data }
        ]);
    },
    async getCourseRecommendations({ commit, rootGetters }, { course_id }) {
        commit('setProperty', { name: 'recommendationsLoading', value: true });
        const response = await this.getDataStore(`${rootGetters.API_URL}/course_offers`, {
            mainCourseId: course_id,
            with: 'offerCourse.location',
            all: 1
        });
        commit('setProperty', [{ name: 'recommendations', value: response.data }, { name: 'recommendationsLoading', value: false }]);
        return response.data;
    },
    async addCourseRecommendation({ dispatch, rootGetters }, { course_id, recommendation_id, payload }) {
        const response = await this.postDataStore(`${rootGetters.API_URL}/course_offers/main_courses/${course_id}/offer_courses/${recommendation_id}`, { ...payload }, 'POST');
        await dispatch('getCourseRecommendations', { course_id });
        return response;
    },
    async updateCourseRecommendation({ dispatch, rootGetters }, { course_id, recommendation_id, payload }) {
        const response = await this.postDataStore(`${rootGetters.API_URL}/course_offers/${recommendation_id}`, { ...payload }, 'PUT');
        await dispatch('getCourseRecommendations', { course_id });
        return response;
    },
    async deleteCourseRecommendation({ dispatch, rootGetters }, { course_id, recommendation_id }) {
        const response = await this.postDataStore(`${rootGetters.API_URL}/course_offers/${recommendation_id}`, {}, 'DELETE');
        await dispatch('getCourseRecommendations', { course_id });
        return response;
    },
    async getCoursesListForRecommendations({ rootGetters }, name) {
        const response = await this.getDataStore(`${rootGetters.API_URL}/courses/?only=id,name,price,old_price&with=waves;location`, { name: name || '' });
        return response.data;
    },
    async getCourseNews({ commit, rootGetters }, { course_id }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/course_news`;
        const response = await this.getDataStore(url, {
            sort: 'published_at',
            desc: true
        });
        commit('setProperty', [
            { name: 'courseNews', value: response.data },
            { name: 'lastPage', value: response.meta.last_page }
        ]);
    },
    async getCourseNewsScrollable({ commit, rootGetters, state }, { course_id, page = 1 }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/course_news`;
        const response = await this.getDataStore(url, {
            page,
            sort: 'published_at',
            desc: true
        });
        commit('setProperty', [
            { name: 'courseNews', value: [...state.courseNews, ...response.data] },
            { name: 'lastPage', value: response.meta.last_page }
        ]);
    },
    async saveCourseChanging({ commit, rootGetters }, { course_id, data }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}`;
        const params = {
            ...data,
            with: 'faqsBySort;advantagesBySort;subcategories_with_categories;packageForLanding;locals;galleries;branches;branches.bulletPoints',
            append_avg_rating: true,
            onlyActiveBranches: 1,
            append_approved_reviews_has_points_count: true,
            withCount: 'students'
        };
        const response = await this.postDataStore(url, params, 'PUT');
        commit('setProperty', { name: 'course', value: response.data });
        return response;
    },
    async saveCourseProgramChanging({ commit, rootGetters }, { course_id, data }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}`;
        const params = { ...data };
        const response = await this.postDataStore(url, params, 'PUT');
        commit('setProperty', { name: 'course', value: response.data });
        return response;
    },
    async getAllNews({ commit, rootGetters }, { course_id, search = '' }) {
        const url = `${rootGetters.API_URL}/course_news`;
        const response = await this.getDataStore(url, {
            search,
            active: true,
            sort: 'published_at',
            notCourseId: course_id
        });
        commit('setProperty', { name: 'news', value: response.data });
    },
    async addNewToCourse({ commit, rootGetters, state, dispatch }, { course_id, attachedNew }) {
        if (state.courseNews.some(({ id }) => id === attachedNew.id))
            return;
        const url = `${rootGetters.API_URL}/courses/${course_id}/attach_news`;
        const response = await this.postDataStore(url, { news_id: [attachedNew.id] });
        commit('setProperty', { name: 'news', value: [...state.news.filter((item) => item.id !== attachedNew.id)] });
        await dispatch('getCourseNews', { course_id });
        return response;
    },
    async deleteNewFromCourse({ commit, rootGetters, state }, { course_id, detachedNew }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/detach_news`;
        const response = await this.postDataStore(url, { news_id: [detachedNew.id] });
        commit('setProperty', [
            { name: 'courseNews', value: [...state.courseNews.filter((item) => item.id !== detachedNew.id)] },
            { name: 'news', value: [...state.news, detachedNew] }
        ]);
        return response;
    },
    async getFaq({ commit, rootGetters }, { course_id, search = '' }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/course_faqs`;
        const response = await this.getDataStore(url, {
            search,
            sort: 'sort',
            per_page: 100
        });
        commit('setProperty', { name: 'courseFaq', value: response.data });
    },
    async getAllFaq({ commit, rootGetters }, { course_id, search = '' }) {
        const url = `${rootGetters.API_URL}/course_faqs`;
        const { data } = await this.getDataStore(url, {
            search,
            active: true,
            notCourseId: course_id,
            sort: 'sort'
        });
        commit('setProperty', { name: 'faq', value: data });
    },
    async addFaqToCourse({ commit, rootGetters, state, dispatch }, { course_id, faq }) {
        if (!faq)
            return;
        if (state.courseFaq.some(({ id }) => id === faq.id))
            return;
        const url = `${rootGetters.API_URL}/courses/${course_id}/attach_faqs`;
        const response = await this.postDataStore(url, { faq_id: [faq.id] });
        commit('setProperty', { name: 'faq', value: [...state.faq.filter((item) => item.id !== faq.id)] });
        dispatch('getFaq', { course_id });
        return response;
    },
    async deleteFaqFromCourse({ commit, rootGetters, state }, { course_id, faq }) {
        if (!faq)
            return;
        const url = `${rootGetters.API_URL}/courses/${course_id}/detach_faqs`;
        const response = await this.postDataStore(url, { faq_id: [faq.id] });
        commit('setProperty', [
            { name: 'courseFaq', value: [...state.courseFaq.filter((item) => item.id !== faq.id)] },
            { name: 'faq', value: [...state.faq, faq] }
        ]);
        return response;
    },
    async getAllAdvantages({ commit, rootGetters }, { search = '', course_id = null, compilation_id = null, type = '' }) {
        const url = `${rootGetters.API_URL}/course_advantages`;
        const { data } = await this.getDataStore(url, {
            search,
            notCourseId: course_id,
            notCourseCompilationId: compilation_id,
            active: true,
            sort: 'sort',
            type
        });
        commit('setProperty', { name: 'allAdvantages', value: data });
    },
    async multiUpdateAdvantages({ rootGetters }, { course_id, course_advantages } = {}) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_advantages/multi_sort`, { course_advantages });
    },
    async getCourseAdvantages({ commit, rootGetters }, { course_id }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/course_advantages`;
        const response = await this.getDataStore(url, {
            sort: 'sort',
            per_page: 100
        });
        commit('setProperty', { name: 'courseAdvantages', value: response.data });
    },
    async addAdvantageToCourse({ commit, rootGetters, state, dispatch }, { course_id, advantage }) {
        // if (state.courseAdvantages.some(({id}) => id === advantage.id)) return;
        const url = `${rootGetters.API_URL}/courses/${course_id}/attach_advantages`;
        const response = await this.postDataStore(url, { advantage_id: [advantage.id] });
        commit('setProperty', [
            { name: 'allAdvantages', value: [...state.allAdvantages.filter((item) => item.id !== advantage.id)] }
        ]);
        await dispatch('getCourseAdvantages', { course_id });
        return response;
    },
    async deleteAdvantageFromCourse({ commit, rootGetters, state }, { course_id, advantage }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/detach_advantages`;
        const response = await this.postDataStore(url, { advantage_id: [advantage.id] });
        commit('setProperty', [
            { name: 'courseAdvantages', value: [...state.courseAdvantages.filter((item) => item.id !== advantage.id)] },
            { name: 'allAdvantages', value: [...state.allAdvantages, advantage] }
        ]);
        return response;
    },
    async getUserProfiles({ commit, rootGetters }, { teacher_id }) {
        const url = `${rootGetters.API_URL}/teacher_profiles`;
        const response = await this.getDataStore(url, {
            userId: teacher_id,
            active: true,
            per_page: -1
        });
        commit('setProperty', { name: 'userProfiles', value: response.data });
    },
    async getCourseUserProfiles({ commit, rootGetters }, { course_id }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/course_teacher_profiles`;
        const response = await this.getDataStore(url, {
            sort: 'teacher_profile_course.sort',
            with: 'customFieldsBySort:id,name;customFieldsBySort.userValues:id,custom_field_id,value'
        });
        commit('setProperty', { name: 'courseProfiles', value: response.data });
    },
    async getUserProfile({ commit, rootGetters }, { teacher_id }) {
        const url = `${rootGetters.API_URL}/teacher_profiles/${teacher_id}/full_show`;
        const response = await this.getDataStore(url);
        commit('setProperty', { name: 'profile', value: response.data });
    },
    async createProfile({ rootGetters }, { user_id }) {
        const url = `${rootGetters.API_URL}/teacher_profiles/`;
        return await this.postDataStore(url, { user_id });
    },
    async deleteProfile({ commit, rootGetters, state }, { teacher_id }) {
        const url = `${rootGetters.API_URL}/teacher_profiles/${teacher_id}`;
        const response = await this.postDataStore(url, {}, 'DELETE');
        if (response.errors)
            return response;
        commit('setProperty', {
            name: 'userProfiles',
            value: [...state.userProfiles.filter((profile) => profile.id !== teacher_id)]
        });
        return response;
    },
    async updateUserProfile({ commit, rootGetters, state, dispatch }, { course_id, teacher_id, data }) {
        const url = `${rootGetters.API_URL}/teacher_profiles/${teacher_id}`;
        const params = { ...data };
        const response = await this.postDataStore(url, params, 'PUT');
        commit('setProperty', {
            name: 'userProfiles',
            value: state.userProfiles.reduce((acc, cur) => {
                if (response.data.id === cur.id) {
                    return [...acc, { ...cur, name: response.data.name }];
                }
                return [...acc, cur];
            }, [])
        });
        dispatch('getCourseUserProfiles', { course_id });
        return response;
    },
    async addProfileToCourse({ rootGetters, state, dispatch }, { course_id, profile }) {
        if (state.courseProfiles.some(({ id }) => id === profile.id))
            throw new Error('Профиль уже добавлен');
        const url = `${rootGetters.API_URL}/courses/${course_id}/attach_teacher_profiles`;
        const response = await this.postDataStore(url, { teacher_profile_id: [profile.id] });
        dispatch('getCourseUserProfiles', { course_id });
        return response;
    },
    async multiSortProfile({ commit, rootGetters }, { course_id, itemsList }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/multi_sort_teacher_profiles`;
        const response = await this.postDataStore(url, {
            teacher_profiles: itemsList.map((item) => ({
                id: item.id,
                sort: item.sort
            }))
        });
        commit('setProperty', { name: 'courseProfiles', value: itemsList });
        return response;
    },
    async multiSortCustomFields({ commit, rootGetters, state, dispatch }, { teacher_id, itemsList, course_id }) {
        const url = `${rootGetters.API_URL}/teacher_profiles/${teacher_id}/multi_sort_custom_fields`;
        const response = await this.postDataStore(url, {
            custom_fields: itemsList.map((item) => ({
                id: item.id,
                sort: item.sort
            }))
        });
        commit('setProperty', { name: 'profile', value: { ...state.profile, custom_fields_by_sort: itemsList } });
        dispatch('getCourseUserProfiles', { course_id });
        return response;
    },
    async deleteProfileToCourse({ commit, rootGetters, state }, { course_id, profile }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/detach_teacher_profiles`;
        const response = await this.postDataStore(url, { teacher_profile_id: [profile.id] });
        commit('setProperty', {
            name: 'courseProfiles',
            value: [...state.courseProfiles.filter((item) => item.id !== profile.id)]
        });
        return response;
    },
    async getAllUsers({ rootGetters }, { search }) {
        const url = `${rootGetters.API_URL}/users`;
        const response = await this.getDataStore(url, { search, only: 'name,surname' });
        return response.data;
    },
    async getAllCustomFields({ rootGetters }) {
        const url = `${rootGetters.API_URL}/custom_fields`;
        return await this.getDataStore(url, {
            types: ['string', 'text'],
            multiple: 0,
            subtype: 'user'
        });
    },
    async deleteCustomFieldFromProfile({ rootGetters, dispatch }, { teacher_id, profile }) {
        const url = `${rootGetters.API_URL}/teacher_profiles/${teacher_id}/detach_custom_fields`;
        const response = await this.postDataStore(url, { custom_field_id: [profile.id] });
        dispatch('getUserProfile', { teacher_id });
        return response;
    },
    async addCustomFieldToProfile({ rootGetters, dispatch }, { teacher_id, data }) {
        const url = `${rootGetters.API_URL}/teacher_profiles/${teacher_id}/attach_custom_fields`;
        const response = await this.postDataStore(url, { custom_field_id: [data] });
        dispatch('getUserProfile', { teacher_id });
        return response;
    },
    async addAccessToCourse({ rootGetters }, { course_id, user_id }) {
        const url = `${rootGetters.API_URL}/admin_access/attach`;
        return await this.postDataStore(url, { course_id, user_id });
    },
    async changeAvatar({ commit, rootGetters, state }, { profile_id, image }) {
        const url = `${rootGetters.API_URL}/teacher_profiles/${profile_id}/upload_avatar`;
        const responseNewImage = await this.postDataStore(url, image);
        const newImageUrl = responseNewImage.data.url;
        commit('setProperty', [
            { name: 'profile', value: { ...state.profile, avatar: newImageUrl } },
            {
                name: 'courseProfiles',
                value: [...state.courseProfiles.reduce((acc, cur) => {
                        if (cur.id === profile_id) {
                            return [...acc, { ...cur, avatar: newImageUrl }];
                        }
                        return [...acc, cur];
                    }, [])]
            }
        ]);
        return responseNewImage;
    },
    // Course structure
    async getCourseStructure({ commit, rootGetters }, { course_id }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/get_structure`;
        const response = await this.getDataStore(url);
        const { lessons, parts, themes, data } = response;
        commit('setProperty', [{ name: 'courseStructure', value: data },
            { name: 'courseMeta', value: { lessons, parts, themes } }]);
    },
    async loadCurseStructure({ commit, rootGetters }, { course_id }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/create_from_nature_structure`;
        const response = await this.postDataStore(url, {});
        const { lessons, parts, themes, data } = response;
        commit('setProperty', [{ name: 'courseStructure', value: data },
            { name: 'courseMeta', value: { lessons, parts, themes } }]);
        return response;
    },
    async editCurseStructure({ commit, rootGetters }, { course_id, structure_id, params }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/course_structures/${structure_id}`;
        params.get_structure = true;
        const response = await this.postDataStore(url, params, 'PUT');
        const { lessons, parts, themes, data } = response;
        commit('setProperty', [{ name: 'courseStructure', value: data },
            { name: 'courseMeta', value: { lessons, parts, themes } }]);
        return response;
    },
    async addCurseStructure({ commit, rootGetters }, { course_id, data }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/course_structures?get_structure=true`;
        const response = await this.postDataStore(url, data);
        const { lessons, parts, themes } = response;
        commit('setProperty', [{ name: 'courseStructure', value: response.data },
            { name: 'courseMeta', value: { lessons, parts, themes } }]);
        return response;
    },
    async deleteCurseStructure({ commit, rootGetters }, { course_id, structure_id }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/course_structures/${structure_id}/delete_with_nesting`;
        const response = await this.postDataStore(url, { get_structure: true }, 'DELETE');
        const { lessons, parts, themes, data } = response;
        commit('setProperty', [{ name: 'courseStructure', value: data },
            { name: 'courseMeta', value: { lessons, parts, themes } }]);
        return response;
    },
    async multiSortStructure({ commit, rootGetters }, { course_structures, course_id }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/course_structures/multi_update?get_structure=true`;
        const response = await this.postDataStore(url, { ...course_structures });
        commit('setProperty', { name: 'courseStructure', value: response.data });
        return response;
    },
    // Ratings
    async getRating({ commit, rootGetters }, { course_id, status = 'public', page = 1, isAdminCreated = 0 }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/course_reviews`;
        const response = await this.getDataStore(url, {
            status,
            isAdminCreated,
            with: 'courseRatingPoints:points;courseRatings:name;user',
            avg_rating: true,
            page
        });
        const { data, meta: { last_page } } = response;
        commit('setProperty', { name: 'lastRatingPage', value: last_page });
        return data;
    },
    async getRatingNames({ commit, rootGetters }, { course_id }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/course_ratings`;
        const response = await this.getDataStore(url, {
            avg_points: true,
            per_page: -1,
            sort: 'sort'
        });
        const { data } = response;
        commit('setProperty', { name: 'ratingsNames', value: data });
    },
    async getAllRatingNames({ commit, rootGetters }, { course_id }) {
        const url = `${rootGetters.API_URL}/course_ratings`;
        const response = await this.getDataStore(url, {
            with: 'ratingPoints&withCount=ratingPoints',
            notCourseId: course_id,
            active: true,
            per_page: -1,
            sort: 'sort'
        });
        const { data } = response;
        commit('setProperty', { name: 'allRatingsNames', value: data });
    },
    async addRatingToCourse({ rootGetters }, { course_id, ratings }) {
        const url = `${rootGetters.API_URL}/courses/${course_id}/sync_ratings`;
        return await this.postDataStore(url, { rating_id: ratings });
    },
    async updateReviewStatus({ rootGetters }, { rating }) {
        const url = `${rootGetters.API_URL}/course_reviews/${rating.id}`;
        const response = await this.postDataStore(url, { status: rating.status }, 'PUT');
        return response.data;
    },
    async deleteReview({ commit, rootGetters, state }, { rating }) {
        const url = `${rootGetters.API_URL}/course_reviews/${rating.id}`;
        const response = await this.postDataStore(url, {}, 'DELETE');
        commit('setProperty', {
            name: 'filteredRatings',
            value: state.filteredRatings.filter((filteredRating) => filteredRating.id !== rating.id)
        });
        commit('setProperty', {
            name: 'rating',
            value: state.filteredRatings.filter((currentRating) => currentRating.id !== rating.id)
        });
        return response;
    },
    async createReview({ rootGetters }, { review }) {
        const { course_id, ...rest } = review;
        const url = `${rootGetters.API_URL}/courses/${review.course_id}/course_review_with_rating_points`;
        return await this.postDataStore(url, rest);
    },
    async attachDetachPackages({ commit, rootGetters }, { course_id, newPackages, action }) {
        const package_id = newPackages.map(({ id }) => id);
        commit('setProperty', { name: 'packageForLanding', value: newPackages.sort((a, b) => a.sort - b.sort) });
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/${action}`, { package_id });
    },
    async detachPackages({ commit, rootGetters }, { course_id, newPackages, action, package_id }) {
        commit('setProperty', { name: 'packageForLanding', value: newPackages.sort((a, b) => a.sort - b.sort) });
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/${action}`, { package_id });
    },
    async getPackagesTrs({ commit, rootGetters }, course_id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_package_tr`);
        commit('setProperty', { name: 'packagesTrs', value: data });
        return data;
    },
    async updateTd({ rootGetters }, { course_id, td_id, payload }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_package_td/${td_id}`, payload, 'PUT');
        return data;
    },
    async deleteTableTr({ commit, rootGetters, state, dispatch }, { course_id, tr_id }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_package_tr/${tr_id}`, {}, 'DELETE');
        commit('setProperty', { name: 'packagesTrs', value: state.packagesTrs.filter(({ id }) => id !== tr_id) });
        await dispatch('getPackagesTrs', course_id);
        return data;
    },
    async createTableTr({ dispatch, rootGetters }, { course_id, sort }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_package_tr`, { sort }, 'POST');
        await dispatch('getPackagesTrs', course_id);
        return data;
    },
    async deleteTeacherVideo({ rootGetters, commit, state }, { course_id, media_id }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/delete_media/${media_id}`, {}, 'DELETE');
        const targetIndex = state.course.videos?.findIndex((video) => video.id === media_id);
        if (targetIndex === null || targetIndex === undefined || targetIndex === -1)
            return data;
        const updatedVideos = [
            ...state.course.videos.slice(0, targetIndex),
            ...state.course.videos.slice(targetIndex + 1)
        ];
        commit('updateCourseField', { name: 'videos', value: updatedVideos });
        return data;
    },
    async getDefaultSort({ rootGetters }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/landing_block_defaults`);
        return data;
    },
    async getCourseSort({ rootGetters }, { course_id }) {
        return await this.getDataStore(`${rootGetters.API_URL}/landing_block_course/${course_id}`);
    },
    async getCompanySort({ rootGetters }) {
        return await this.getDataStore(`${rootGetters.API_URL}/landing_block_course`);
    },
    async deleteCompanySort({ rootGetters }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/landing_block/settings_company_delete`, {}, 'DELETE');
        return data;
    },
    async deleteCourseSort({ rootGetters }, { course_id }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/landing_block/settings_course_delete/${course_id}`, {}, 'DELETE');
        return data;
    },
    async updateSorting({ rootGetters }, { blocks, course_id, isCompanySort }) {
        const params = {};
        blocks.forEach((block, index) => {
            params[`block_${block.name}`] = index + 1;
        });
        const { data } = await this.postDataStore(isCompanySort
            ? `${rootGetters.API_URL}/landing_block/company_default`
            : `${rootGetters.API_URL}/landing_block/courses/${course_id}`, { ...params });
        return data;
    },
    async getOneBlock({ rootGetters }, { course_id, block_id }) {
        return await this.getDataStore(`${rootGetters.API_URL}/landing_block_course/${course_id}/block/${block_id}`);
    },
    async addDataToBlock({ rootGetters }, { course_id, block_id, title }) {
        return await this.postDataStore(`${rootGetters.API_URL}/landing_block_course/${course_id}/block_value/${block_id}`, { title });
    },
    async editDataToBlock({ rootGetters }, { course_id, block_id, params }) {
        return await this.postDataStore(`${rootGetters.API_URL}/landing_block_course/${course_id}/block_value/${block_id}`, { ...params }, 'PUT');
    },
    // Reviews
    async getTeacherReviews({ rootGetters }, { course_id, teacher_id }) {
        const { data } = await this.getDataStore(`${rootGetters.API_PUBLIC_URL}/review/course/${course_id}/teacher/${teacher_id}`);
        return data;
    },
    async getSortOrder({ rootGetters }, course_id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/landing_koala_block_course/${course_id}`);
        return data;
    },
    async saveBlockChanges({ rootGetters }, { course_id, order }) {
        return await this.postDataStore(`${rootGetters.API_URL}/landing_koala_block/courses/${course_id}`, order, 'POST');
    },
    async getKoalaBlockDefaults({ rootGetters }) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/landing_koala_block_defaults`);
        return data;
    },
    async getCompanySortKoala({ rootGetters, commit }) {
        const { data, meta } = await this.getDataStore(`${rootGetters.API_URL}/landing_koala_block_defaults/`);
        commit('setProperty', [
            {
                name: 'defaultCompanySort',
                value: meta && meta.default_sort_order && Array.isArray(meta.default_sort_order)
                    ? meta.default_sort_order.sort((a, b) => a.sort - b.sort)
                    : []
            },
            { name: 'isSortDefault', value: Boolean(meta?.is_default) },
            {
                name: 'companySort',
                value: data && Array.isArray(data)
                    ? data.sort((a, b) => a.sort - b.sort)
                    : []
            }
        ]);
    },
    async updateCompanySort({ rootGetters }, order) {
        return await this.postDataStore(`${rootGetters.API_URL}/landing_koala_block_defaults`, order, 'POST');
    },
    async deleteCompanySortKoala({ rootGetters }) {
        return await this.postDataStore(`${rootGetters.API_URL}/landing_koala_block_defaults`, {}, 'DELETE');
    },
    async getFaqSortOrder({ rootGetters }, course_id) {
        const { data } = await this.getDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_faqs/sort_order`);
        return data;
    },
    async updateFaqSortOrder({ rootGetters }, { course_id, course_faqs }) {
        return await this.postDataStore(`${rootGetters.API_URL}/courses/${course_id}/course_faqs/multi_sort`, { course_faqs }, 'POST');
    }
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
