import { mixinActions, mixinMutations } from '@/store/mixins';
const state = {
    searchString: '',
    loading: false,
    page: 1,
    per_page: 20
};
const mutations = { ...mixinMutations() };
const actions = {
    ...mixinActions(),
    async getData({ rootGetters }, { params }) {
        return await this.getDataStore(`${rootGetters.API_URL}/auto_action_templates`, {
            replaceProperties: 1, withCount: 'autoActions', desc: 1, ...params
        });
    },
    async toggleActivity({ rootGetters }, { id, params }) {
        const { data } = await this.postDataStore(`${rootGetters.API_URL}/auto_action_templates/${id}`, { ...params }, 'PUT');
        return data;
    },
    async delete({ rootGetters }, id) {
        const url = `${rootGetters.API_URL}/auto_action_templates/${id}`;
        return await this.postDataStore(url, {}, 'DELETE');
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
