const state = () => ({
    comments: [],
    currentCommentId: 0,
    editCommentId: 0,
    params: {},
    comment_types: [
        {
            text: 'Видит только команда',
            value: 'question'
        },
        {
            text: 'Для всех',
            value: 'comment'
        }
    ]
});
export default state;
