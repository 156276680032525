import { mixinMutations } from '@/store/mixins';
import defaultState from './entities/defaultState';
const state = defaultState();
const mutations = {
    ...mixinMutations(),
};
const actions = {
    async getStatistics({ rootState, rootGetters, commit }) {
        const { data, errors } = await this.getDataStore(`${rootGetters.API_URL}/statistics/get_metriks`, 
        //@ts-ignore
        { startDate: rootState.datesSelect.period_start_date, endDate: rootState.datesSelect.period_end_date });
        if (data)
            commit('setState', data);
        return { data, errors };
    },
    async getTestStatistics({ rootGetters }, payload) {
        const { testIds, withTest, date_end, date_start } = payload;
        const para = { testIds, date_start, date_end };
        if (withTest)
            Object.assign(para, { with: 'test' });
        return await this.getDataStore(`${rootGetters.API_URL}/user_statistics/tests`, para);
    },
    async getCourseStatistics({ rootGetters }, payload) {
        const { courseIds, date_end, date_start } = payload;
        return await this.getDataStore(`${rootGetters.API_URL}/user_statistics/courses`, { courseIds, date_end, date_start });
    },
    async getTopCourses({ rootState, rootGetters }) {
        return await this.getDataStore(`${rootGetters.API_URL}/user_statistics/courses`, {
            per_page: 5,
            //@ts-ignore
            date_start: rootState.datesSelect.period_start_date,
            //@ts-ignore
            date_end: rootState.datesSelect.period_end_date,
            with: 'course'
        });
    },
    async getTopTests({ rootState, rootGetters }) {
        return await this.getDataStore(`${rootGetters.API_URL}/user_statistics/tests`, {
            //@ts-ignore
            date_start: rootState.datesSelect.period_start_date,
            //@ts-ignore
            date_end: rootState.datesSelect.period_end_date,
            per_page: 5,
            with: 'test;test.lesson;test.lesson.course'
        });
    },
    async getTopLessons({ rootGetters }, payload) {
        const { query: { date_start, date_end, courseIds }, params: { page = 1, per_page = 5 } } = payload;
        return await this.getDataStore(`${rootGetters.API_URL}/user_statistics/lessons`, {
            date_start,
            date_end,
            per_page,
            courseIds,
            with: 'lesson',
            page
        });
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
