var _a;
import { __decorate, __metadata } from "tslib";
import { Component, namespace, Prop, Ref, Vue, Watch } from 'nuxt-property-decorator';
// @ts-ignore
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
// @ts-ignore
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import { BASE_URL, postData } from '@/api';
const LibraryModule = namespace('library');
let MultipleDropzone = class MultipleDropzone extends Vue {
    constructor() {
        super(...arguments);
        this.BASE_URL = BASE_URL;
        this.dropzoneOptions = {
            url: this.urlForDropzone,
            headers: {
                Authorization: localStorage.getItem('access_token'),
                Accept: 'application/json'
            },
            // params: this.params,
            acceptedFiles: this.acceptedFiles,
            method: 'POST',
            timeout: this.timeout,
            autoDiscover: false,
            paramName: this.name,
            autoProcessQueue: true,
            thumbnailWidth: 100,
            createImageThumbnails: true,
            maxFiles: 10,
            maxFilesize: this.maxFileSize,
            addRemoveLinks: true,
            chunking: this.isChunking,
            chunkSize: 125000 * 100,
            dictDefaultMessage: this.defaultText,
            dictFileTooBig: 'Файл слишком большой',
            dictInvalidFileType: 'Неизвестный формат файла',
            dictCancelUpload: 'удалить файл',
            dictUploadCanceled: 'удалить файл',
            dictCancelUploadConfirmation: 'Вы действительно хотите прервать загрузку файла?'
        };
        this.files = this.value ? [...this.value] : [];
        this.isCoping = false;
        this.index = null;
        this.posterDefaultMessage = '<div class="dropzone__descr">Загрузите обложку<br>jpg, png<br>не более 1 Mb</div>';
    }
    get urlForDropzone() {
        return !this.imperativeUrl ? this.resultUrl : `${this.BASE_URL}${this.url}`;
    }
    get resultUrl() {
        const { url: copy } = this;
        if (this.imperativeUrl)
            return copy;
        let url = this.userId
            ? `${this.BASE_URL}${this.url}${this.isVideo ? `/users/${this.userId}/video` : ''}/users/${this.userId}/add_media`
            : `${this.BASE_URL}${this.url}${this.isVideo ? '/video' : ''}/add_${this.isBigFile ? 'big_' : ''}media`;
        if (this.isVideo) {
            url = url.replace('/api/', '/api/big_media/');
        }
        return url;
    }
    get baseMediaUrl() {
        return `${this.BASE_URL}${this.url}`;
    }
    async removeFile(file) {
        const confirmTitle = this.isSoftDelete ? 'Вы точно хотите удалить копию файла?' : 'Вы точно хотите удалить файл?';
        const confirmText = this.isVideo
            ? this.isSoftDelete
                ? 'Другие копии не будут затронуты.'
                : 'Этот файл удалится также во всех копиях, если они есть.'
            : 'Восстановить его не получится.';
        const isConfirm = await this.$root.$confirm(confirmText, { title: confirmTitle, successText: 'Удалить' });
        if (!isConfirm)
            return;
        this.$emit('deleteFile', { file, field: this.field });
        const index = this.files.indexOf(file);
        this.files.splice(index, 1);
        this.$emit('removeFile', file, this.files);
        this.$emit('onFileDelete', { value: this.files, name: this.name });
        if (this.userId && file.id) {
            await postData(`${this.url}/users/${this.userId}/delete_media/${file.id}`, {}, 'DELETE');
            return;
        }
        if (this.deleteRemote && file.id) {
            if (!this.imperativeUrl) {
                await postData(`${this.url}/delete_media/${file.id}`, {}, 'DELETE');
                return;
            }
            const replacedUrl = this.resultUrl.replaceAll('add', 'delete');
            return await postData(`${replacedUrl}/${file.id}`, {}, 'DELETE');
        }
    }
    buildIcon(ext) {
        const params = {
            'image/png': () => '/static-images/img.svg',
            'image/jpeg': () => '/static-images/img.svg',
            'image/gif': () => '/static-images/img.svg',
            'image/svg+xml': () => '/static-images/img.svg',
            'application/vnd.ms-powerpoint': () => '/static-images/ppt.svg',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': () => '/static-images/ppt.svg',
            'application/msword': () => '/static-images/word.svg',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': () => '/static-images/word.svg',
            'application/pdf': () => '/static-images/pdf.svg',
            default: () => '/static-images/defaultFile.svg'
        };
        return params[ext] ? params[ext]() : params.default();
    }
    dropzoneSuccess(file, response) {
        if (file?.xhr?.response && JSON.parse(file.xhr.response).errors) {
            const msg = JSON.parse(file.xhr.response).errors;
            this.errorDropzone(file, msg, file.xhr);
            return;
        }
        const newFiles = response ? response.data[this.name] || response.data : JSON.parse(file.xhr.response).data;
        if (newFiles && newFiles.length) {
            this.$set(this, 'files', newFiles);
        }
        else {
            this.files.push(newFiles);
        }
        this.multiDropzone.removeFile(file);
        this.$emit('sendingFile', false);
        this.$emit('changeFiles', { value: this.files, name: this.name, errors: response.errors });
        this.$emit('addFile', {
            data: response.data,
            field: this.field
        });
    }
    dropzoneSending(file, xhr, formData) {
        const { dataToAppend } = this;
        this.$emit('sendingFile', true);
        if (dataToAppend) {
            Object.entries(dataToAppend).forEach(([key, value]) => {
                formData.append(key, value);
            });
        }
    }
    setHref(file) {
        if (!file)
            return '';
        const { link, url, full_url, original_url } = file;
        return link || url || original_url || full_url ? link || url || full_url || original_url : '';
    }
    errorDropzone(file, message, xhr) {
        this.$emit('sendingFile', false);
        if (xhr && xhr.status === 0)
            this.$emit('dropzoneError');
        this.multiDropzone.removeFile(file);
        if (xhr && xhr.status !== 0)
            this.$root.$alert(message?.errors || 'Не удалось загрузить файл, попробуйте еще раз', 'error');
        else
            this.$root.$alert(message, 'error');
    }
    async createFileTemplate(id) {
        this.isCoping = true;
        try {
            await this.createTemplate(`library/media/${id}`);
            this.$root.$alert('Шаблон успешно сохранен');
            this.isCoping = false;
        }
        catch (e) {
            this.$root.$alert('Произошла ошибка', 'error');
            this.isCoping = false;
        }
    }
    async onPosterSuccess({ response }) {
        const { id, url } = response.data;
        await postData(`${this.url}/main_media/${this.files[0].id}/dependent_media/${id}`, {}, 'POST');
        this.$emit('posterSuccess', { id, url });
    }
    async onPosterRemove() {
        this.$emit('posterDelete');
    }
    onError(isError) {
        if (isError)
            this.$root.$alert(this.validateMessage);
    }
    cancelUploading(newVal) {
        if (newVal === 'cancel')
            this.multiDropzone.removeAllFiles(true);
    }
    onFilesChange(newVal) {
        this.files = newVal;
    }
    onUrlChange(newVal) {
        this.dropzoneOptions.url = newVal;
    }
};
__decorate([
    LibraryModule.Action('createTemplate'),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "createTemplate", void 0);
__decorate([
    Ref(),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "multiDropzone", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Boolean)
], MultipleDropzone.prototype, "showPoster", void 0);
__decorate([
    Prop({ type: Object, default: () => ({}) }),
    __metadata("design:type", typeof (_a = typeof VideoPoster !== "undefined" && VideoPoster) === "function" ? _a : Object)
], MultipleDropzone.prototype, "posterImage", void 0);
__decorate([
    Prop({ type: String, default: '' }),
    __metadata("design:type", String)
], MultipleDropzone.prototype, "fileComment", void 0);
__decorate([
    Prop({ type: String, default: '' }),
    __metadata("design:type", String)
], MultipleDropzone.prototype, "fileCommentTooltip", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Boolean)
], MultipleDropzone.prototype, "showCopy", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Boolean)
], MultipleDropzone.prototype, "validateError", void 0);
__decorate([
    Prop({ type: String, default: '' }),
    __metadata("design:type", String)
], MultipleDropzone.prototype, "validateMessage", void 0);
__decorate([
    Prop({ type: Boolean, default: true }),
    __metadata("design:type", Boolean)
], MultipleDropzone.prototype, "multi", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "isSoftDelete", void 0);
__decorate([
    Prop({ type: String, default: '' }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "name", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "showEditButton", void 0);
__decorate([
    Prop({ type: String, default: '/api/profile/images' }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "url", void 0);
__decorate([
    Prop({ type: String, default: '' }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "customCssClass", void 0);
__decorate([
    Prop({
        type: String,
        default: `<div class="multi-inner">
                    <div class="multi-inner__text">Загрузите или перетащите файлы форматов mp4, mkv, avi, doc, docx, gif, html, jpg, jpeg, jif, mp3, mpe, mpg, mpeg, moov, movie, mov, pdf, png, ppt, pptx, pps, rtf, swf, tiff, tif, txt, wav, wma, xls, xlsx, zip.</div>
                    <div class="multi-inner__button">Загрузить файлы</div>
                </div>`
    }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "defaultText", void 0);
__decorate([
    Prop({ type: Boolean }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "deleteRemote", void 0);
__decorate([
    Prop({}),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "value", void 0);
__decorate([
    Prop({ type: Object, default: () => ({}), required: false }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "field", void 0);
__decorate([
    Prop({}),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "params", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "isChunking", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "isVideo", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "isVideoLoading", void 0);
__decorate([
    Prop({ type: String, default: '' }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "cancelLoading", void 0);
__decorate([
    Prop({ type: Object, default: null }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "dataToAppend", void 0);
__decorate([
    Prop({ type: [Number, String], default: '' }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "userId", void 0);
__decorate([
    Prop({ type: Number, default: 1024 * 10 }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "maxFileSize", void 0);
__decorate([
    Prop({ type: Number, default: 300000 }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "timeout", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Boolean)
], MultipleDropzone.prototype, "imperativeUrl", void 0);
__decorate([
    Prop({
        type: [Boolean, String],
        default: '.webp,.mp4,.mkv,.avi,.doc,.docx,.gif,.html,.htm,.jpg,.jpeg,.jif,.mp3,.mpe,.mpg,.mpeg,.moov,.movie,.mov,.pdf,.png,.ppt,.pptx,.pps,.qt,.ra,.ram,.rm,.rtf,.swf,.tiff,.tif,.txt,.wav,.wma,.wmf,.wmv,.wpd,.xls,.xlsx,.zip,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/docx'
    }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "acceptedFiles", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "isBigFile", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], MultipleDropzone.prototype, "isAltRemove", void 0);
__decorate([
    Watch('validateError'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], MultipleDropzone.prototype, "onError", null);
__decorate([
    Watch('cancelLoading'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], MultipleDropzone.prototype, "cancelUploading", null);
__decorate([
    Watch('value'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], MultipleDropzone.prototype, "onFilesChange", null);
__decorate([
    Watch('url'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], MultipleDropzone.prototype, "onUrlChange", null);
MultipleDropzone = __decorate([
    Component({ components: { vueDropzone, CoolLightBox } })
], MultipleDropzone);
export default MultipleDropzone;
