var _a;
import { __decorate, __metadata } from "tslib";
import { Component, namespace, Prop, Ref, Vue } from 'nuxt-property-decorator';
// @ts-ignore
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
// @ts-ignore
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import { BASE_URL, postData } from '@/api';
const LibraryModule = namespace('library');
let SingleDropzone = class SingleDropzone extends Vue {
    constructor() {
        super(...arguments);
        this.isOpenEditor = false;
        this.isOpenToastEditor = false;
        this.BASE_URL = BASE_URL;
        this.isAddModal = false;
        this.isRemoveModal = false;
        this.dropzoneOptions = {
            url: this.urlWithUserId,
            headers: {
                Authorization: localStorage.getItem('access_token'),
                Accept: 'application/json'
            },
            paramName: this.paramName,
            autoProcessQueue: true,
            thumbnailWidth: 150,
            createImageThumbnails: true,
            maxFiles: 1,
            maxFilesize: this.maxFilesize,
            acceptedFiles: this.acceptedFiles,
            addRemoveLinks: true,
            chunking: this.chunking,
            parallelChunkUploads: this.parallelChunkUploads,
            dictDefaultMessage: `<div class="dropzone__descr">${this.dictDefaultMessage}</div>`,
            dictFileTooBig: 'файл слишком большой',
            dictInvalidFileType: 'неизвестный формат файла',
            dictCancelUpload: 'удалить файл',
            dictUploadCanceled: 'удалить файл',
            dictCancelUploadConfirmation: 'Вы действительно хотите прервать загрузку файла?'
        };
        this.index = null;
    }
    get urlWithUserId() {
        if (this.userId)
            return `${this.BASE_URL}${this.url}/users/${this.userId}/add_media`;
        return this.url;
    }
    /**
     * @vuese
     * Открывает модалку добавления файла из шаблона
     */
    showAddModal() {
        this.isAddModal = true;
    }
    /**
     * @vuese
     * Обработка для отображения через v-coolbox
     */
    setIndex(index) {
        this.index = index;
    }
    /**
     * @vuese
     * Сохраняет изображение после редактирования
     * @arg Объект для отправки на бекенд
     */
    async editImage(data) {
        const response = await postData('/api/profile/images', data);
        const { name } = this;
        this.$emit('successDropzone', { response, name });
    }
    /**
     * @vuese
     * Вызывается после успешной загрузки файла
     * @arg Ответ, полученный дропзоной
     */
    dropzoneSuccess(el, response) {
        const { name, isClear } = this;
        this.$emit('successDropzone', { response, name });
        if (isClear) {
            this.$nextTick(() => {
                const refImageDropzone = this.imageDropzone;
                refImageDropzone.removeAllFiles(true);
            });
        }
    }
    /**
     * @vuese
     * Обрабатывает событие удаления файла
     */
    async removeImage() {
        const { name, id, deleteRemote, urlBase, deleteMethod } = this;
        this.$emit('removeImage', name);
        if (deleteRemote && id) {
            const res = await postData(`${urlBase}/delete_media/${id}`, {}, deleteMethod);
            this.$root.$handleErrors(res, 'Файл успешно удален');
        }
        this.isRemoveModal = false;
    }
    /**
     * @vuese
     * Вызывается при ошибке в дропзоне
     * @arg Загружаемый файл
     */
    errorDropzone(file) {
        this.imageDropzone.removeFile(file);
        this.$root.$alert('Не удалось загрузить файл, попробуйте еще раз', 'error');
    }
    /**
     * @vuese
     * Создает шаблон из загруженного файла
     */
    async createBannerTemplate() {
        const { course_id, compilation_id } = this.$route.params;
        if (!course_id && !compilation_id)
            return;
        try {
            await this.createTemplateBanner({
                course_id, compilation_id, bannerType: this.name, entityType: this.entityType
            });
            this.$root.$alert('Шаблон успешно сохранен');
        }
        catch (e) {
            this.$root.$alert('Произошла ошибка', 'error');
        }
    }
    /**
     * @vuese
     * Обрабатывает вставку файла из шаблона
     * @arg Данные о файле
     */
    addBanner({ name, file }) {
        this.$emit('successDropzone', { response: { data: file }, name });
    }
};
__decorate([
    Ref(),
    __metadata("design:type", typeof (_a = typeof MultipleDropzone !== "undefined" && MultipleDropzone) === "function" ? _a : Object)
], SingleDropzone.prototype, "imageDropzone", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "showCopy", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "deleteRemote", void 0);
__decorate([
    Prop({ type: Boolean, default: true }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "chunking", void 0);
__decorate([
    Prop({ type: Boolean, default: true }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "parallelChunkUploads", void 0);
__decorate([
    Prop({ type: Boolean, default: true }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "showEditButton", void 0);
__decorate([
    Prop({ type: String, default: '' }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "name", void 0);
__decorate([
    Prop({ type: String, default: 'image' }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "type", void 0);
__decorate([
    Prop({ type: String, default: 'course' }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "entityType", void 0);
__decorate([
    Prop({ type: String, default: '' }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "title", void 0);
__decorate([
    Prop({ type: String, default: '' }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "file", void 0);
__decorate([
    Prop({ type: String, default: '' }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "dropzoneError", void 0);
__decorate([
    Prop({ type: String, default: 'DELETE' }),
    __metadata("design:type", String)
], SingleDropzone.prototype, "deleteMethod", void 0);
__decorate([
    Prop({ type: String, default: 'image' }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "paramName", void 0);
__decorate([
    Prop({ type: String, default: 'image/*' }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "acceptedFiles", void 0);
__decorate([
    Prop({ default: '' }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "id", void 0);
__decorate([
    Prop({ default: '<div class="dropzone__icon"> </div>Загрузите или перетащите<br> картинку png, jpeg, webp' }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "dictDefaultMessage", void 0);
__decorate([
    Prop({ type: String, default: `${BASE_URL}/api/profile/images` }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "url", void 0);
__decorate([
    Prop({ type: String, default: `${BASE_URL}/api/profile/images` }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "urlBase", void 0);
__decorate([
    Prop({ type: [Number, String], default: '' }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "userId", void 0);
__decorate([
    Prop({ default: 100 }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "maxFilesize", void 0);
__decorate([
    Prop({ type: Boolean, default: false }),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "isClear", void 0);
__decorate([
    LibraryModule.Action('createTemplateBanner'),
    __metadata("design:type", Object)
], SingleDropzone.prototype, "createTemplateBanner", void 0);
SingleDropzone = __decorate([
    Component({
        components: {
            vueDropzone,
            CoolLightBox,
            AddModal: () => import('@/pages/Library/components/AddModal.vue'),
            ToastImageEditor: () => import('@/components/ToastImageEditor/ToastImageEditor.vue')
        }
    })
], SingleDropzone);
export default SingleDropzone;
