// eslint-disable-next-line
export class TreeNode {
    // eslint-disable-next-line
    constructor(id, value, 
    // eslint-disable-next-line
    otherProps = {}, children = [], parent = null) {
        this.id = id;
        this.value = value;
        this.otherProps = otherProps;
        this.children = children;
        this.parent = parent;
    }
    insertChild(node) {
        node.parent = this;
        this.children = [...this.children, node];
    }
    // Returns current node or it's children node that id match, else null
    getNodeById(id) {
        const stack = [this];
        while (stack.length) {
            const node = stack.pop();
            if (!node)
                break;
            if (node.id === id)
                return node;
            stack.push(...node.children);
        }
        return null;
    }
    // Returns array of nodes including current, where each next node is parent of previous
    getPathFromRoot() {
        const path = [this];
        let parentNode = this.parent;
        while (parentNode) {
            path.push(parentNode);
            parentNode = parentNode.parent;
        }
        return path.reverse();
    }
}
