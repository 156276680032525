const state = () => ({
    id: 0,
    title: '',
    description: '',
    access_type: 'open',
    status: 'draft',
    blocks: [],
    categories: [],
    related_articles: [],
    users_access: [],
    courses_access: [],
    max_free_blocks: 0,
    media: null,
    created_at: '',
    published_at: ''
});
export default state;
