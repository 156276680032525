import Vue from 'vue';
import VueRouter from 'vue-router';
import EmptyLayout from '@/layouts/index.vue';
import AccountPage from '@/pages/Account/Account.vue';
import CompanyRoutes from '@/router/CompanyRoutes';
import store from '@/store';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'home',
        redirect: '/login'
    },
    {
        path: '/debug',
        component: () => import(/* webpackChunkName: "DebugPage" */ '@/pages/DebugPage/index.vue'),
        name: 'DebugPage'
    },
    {
        path: '/login',
        component: () => import(/* webpackChunkName: "AuthForm" */ '@/pages/Auth/index.vue'),
        name: 'AuthForm'
    },
    {
        path: '/content',
        component: () => import(/* webpackChunkName: "SettingsPage" */ '@/components/ContentLoader/index.vue'),
        name: 'content',
        meta: { title: 'Контент' }
    },
    {
        path: '/recovery',
        component: () => import(/* webpackChunkName: "Recovery" */ '@/pages/Registration/Recovery.vue'),
        name: 'Recovery'
    },
    {
        path: '/site-error',
        component: () => import(/* webpackChunkName: "SiteError" */ '@/pages/SiteError/index.vue'),
        name: 'SiteError'
    },
    // {
    //   path: '/registration',
    //   component: EmptyLayout,
    //   children: [
    //     {
    //       path: '',
    //       component: () => import(/* webpackChunkName: "Registration" */'@/pages/Registration/Registration.vue'),
    //       name: 'registration'
    //     },
    //     {
    //       path: ':companyName/:formId',
    //       component: () => import(/* webpackChunkName: "RegistrationRole" */'@/pages/Registration/RegistrationForm.vue'),
    //       name: 'registration-role',
    //       props: true
    //     }
    //   ]
    // },
    {
        path: '/companies',
        component: AccountPage,
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "ProfilePage" */ '@/pages/Account/pages/CompaniesPage/CompaniesPage.vue'),
                name: 'companies'
            }
        ]
    },
    {
        path: '/profile',
        component: AccountPage,
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "CompaniesPage" */ '@/pages/Account/pages/ProfilePage.vue'),
                name: 'profile'
            }
        ]
    },
    {
        path: '/:company_name',
        component: EmptyLayout,
        beforeEnter: async (to, from, next) => {
            const { company_name } = to.params;
            const { fullPath } = to;
            if (fullPath) {
                store.commit('company/setProperty', { name: 'firstLink', value: fullPath });
            }
            try {
                await store.dispatch('company/getByCode', company_name);
                const res = await store.dispatch('adminProfile/getProfile');
                return res ? next() : next({ name: 'login' });
            }
            catch (e) {
                next('/login');
            }
        },
        children: CompanyRoutes
    }
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
router.beforeEach(async (to, from, next) => {
    const noAuthRoutes = ['home', 'AuthForm', 'Recovery', 'registration', 'registration-role', 'content'];
    // if (to.name !== 'SiteError') {
    //   return next('/site-error');
    // }
    if (noAuthRoutes.includes(to.name)) {
        return next();
    }
    try {
        const res = await store.dispatch('adminProfile/getProfile');
        if (!res || res.errors || res.hasOwnProperty('errors')) {
            return next('/login');
        }
        return next();
    }
    catch (e) {
        return next('/login');
    }
});
export default router;
